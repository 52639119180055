import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreatableSelectField } from '@application/components';
import { OptionType } from '@application/components/select-field/select';

import { Section } from '../../components';
import { RequestFormFields } from '../../schema';

type SoftSkillsFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const SoftSkillsFields = ({
  control,
  errors,
  setValue,
  trigger,
  watch,
}: SoftSkillsFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const softSkills = watch('softSkills');

  return (
    <Section legend={t('subtitles.skillsCompetenciesSought')}>
      <Controller
        name="softSkills"
        control={control}
        render={({ field: { name } }) => (
          <CreatableSelectField
            name={name}
            label={t('labels.skillsCompetencies')}
            value={
              softSkills?.map((s) => ({
                label: s,
                value: s,
              })) as OptionType[]
            }
            className="flex-1 min-w-fit"
            onChange={(options: any) => {
              setValue(
                name,
                options.map((option: any) => option.value)
              );
              trigger(name);
            }}
            invalid={!!errors.softSkills}
            helperText={
              errors.softSkills?.message && tGlobal(errors.softSkills?.message)
            }
          />
        )}
      />
    </Section>
  );
};

export default SoftSkillsFields;
