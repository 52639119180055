import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AccountContext, AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { OfferStatusTypeCode } from '@domain/graphql.types';
import { useOfferCreate } from '@domain/offer';

import { toViewModel } from '../view-models/create-offer.vm';

const useCreateOffer = () => {
  const { data, fetching, error, createOffer } = useOfferCreate();

  const { t } = useTranslation('offers', { keyPrefix: 'messages' });

  const navigate = useNavigate();
  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const viewModel = toViewModel({ offer: { data, fetching, error } });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('createError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.offerCreate?.offer) {
      setAlertContent({
        action: t('createSuccess'),
        modifier: 'alert-success',
      });

      if (data?.offerCreate?.offer.status === OfferStatusTypeCode.Published) {
        refreshAccount();
      }

      navigate(
        generatePath(PrivatePage.VIEW_OFFER, {
          id: data.offerCreate.offer.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { createOffer, viewModel };
};

export default useCreateOffer;
