import { useContext } from 'react';

import { AccountContext } from '@application/context';
import { AccountBillingSubscriptionType } from '@domain/graphql.types';

const useAgencyProCandidateRequestValidation = () => {
  const { account } = useContext(AccountContext);

  const canPublishCandidateRequest = !(
    account?.subscriptionType === AccountBillingSubscriptionType.AgencyPro &&
    account?.agencySubscriptionInformations !== undefined &&
    account?.agencySubscriptionInformations?.proRemainingCandidateRequests === 0
  );

  return {
    canPublishCandidateRequest,
    remainingCandidateRequest:
      account?.agencySubscriptionInformations?.proAllowedCandidateRequests,
  };
};

export default useAgencyProCandidateRequestValidation;
