import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountContext, ModalContext } from '@application/context';
import { AccountTypeCode } from '@domain/graphql.types';

import { InviteUserModal, NoAvailableSeatsModal } from '../components';
import { InvitationsListContext } from '../context';

export type UseInviteUserModalProps = {
  openModal: () => void;
};

const useInviteUserModal = (): UseInviteUserModalProps => {
  const { t } = useTranslation('organization', { keyPrefix: 'users' });

  const { setModal } = useContext(ModalContext);
  const { setShouldRefreshList } = useContext(InvitationsListContext);
  const { refreshAccount, account } = useContext(AccountContext);

  const handleOnCloseNoAvailableSeatsModal = useCallback(
    (shouldRefresh: boolean) => {
      if (shouldRefresh) {
        refreshAccount();
      }
      setModal(null);
    },
    [refreshAccount, setModal]
  );

  const onHasNoAvailableSeatsError = useCallback(() => {
    setModal({
      title: t('noAvailableSeatsModal.title'),
      content: (
        <NoAvailableSeatsModal
          onClose={handleOnCloseNoAvailableSeatsModal}
          accountType={account?.type || AccountTypeCode.Enterprise}
          modifier="invite"
        />
      ),
    });
  }, [account?.type, handleOnCloseNoAvailableSeatsModal, setModal, t]);

  const afterSubmit = useCallback(() => {
    refreshAccount();
    setShouldRefreshList(true);
    setModal(null);
  }, [refreshAccount, setModal, setShouldRefreshList]);

  const openModal = useCallback(() => {
    setShouldRefreshList(false);

    setModal({
      title: t('createInvitation'),
      content: (
        <InviteUserModal
          onClose={() => setModal(null)}
          afterSubmit={afterSubmit}
          onHasNoAvailableSeatsError={onHasNoAvailableSeatsError}
        />
      ),
    });
  }, [
    afterSubmit,
    onHasNoAvailableSeatsError,
    setModal,
    setShouldRefreshList,
    t,
  ]);

  return {
    openModal,
  };
};

export default useInviteUserModal;
