import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputField } from '@application/components';
import { NoContent, Table } from '@application/components/table';
import { AccountsFilters, AccountTypeCode } from '@domain/graphql.types';

import useAccountsFilters from './accounts-list/hooks/useAccountsFilters';
import { useAccountsList } from './accounts-list/hooks/useAccountsList';

const AgencyAccountsListPage = () => {
  const [tableFilters, setTableFilters] =
    useState<Omit<AccountsFilters, 'type'>>();
  const {
    columns,
    accounts,
    currentPage,
    count,
    sorting,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    isLoading,
  } = useAccountsList({ type: AccountTypeCode.Agency, ...tableFilters });
  const { filters, updateTextFilter } = useAccountsFilters({
    resetPagination: onPaginationReset,
  });
  const { t } = useTranslation('organization', {
    keyPrefix: 'superAdmin',
  });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <div>
      <h1 className="mb-s-24">{t('agencyListTitle')}</h1>

      <TextInputField
        className="mb-s-24"
        label=""
        placeholder={t('search')}
        name="text"
        withIcon="ri-search-2-line text-24"
        onChange={updateTextFilter}
        hideIconBorder
      />

      <Table
        data={accounts}
        columns={columns}
        count={count}
        sortingState={sorting}
        onSortingChange={onSortingChange}
        paginationState={currentPage}
        onPaginationChange={onPaginationChange}
        onPaginationReset={onPaginationReset}
        noDataComponent={<NoContent title={t('noAgencies')} />}
        isLoading={isLoading}
        isRowColorChangeOnHover
      />
    </div>
  );
};

export default AgencyAccountsListPage;
