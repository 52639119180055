import { gql } from 'urql';

const MutationRequestChatRoomMessageCreate = gql`
  mutation RequestChatRoomMessageCreate(
    $input: RequestChatRoomMessageCreateInput!
  ) {
    requestChatRoomMessageCreate(input: $input) {
      message {
        id
      }
    }
  }
`;

export default MutationRequestChatRoomMessageCreate;
