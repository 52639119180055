/**
 * When adding or changing URLs, add or modify translations keys and values
 * in Breadcrumbs translations files.
 */

export const enum PublicPage {
  HOME = '/',
}

export const enum RootPrivatePage {
  AGENCY_ACCOUNTS = '/agency-accounts',
  CANDIDATE_NEGOTIATIONS = '/candidate-negotiations',
  CANDIDATE_NEGOTIATION_ROOMS = '/candidate-negotiation-rooms',
  CANDIDATE_OFFERS = '/candidate-offers',
  CANDIDATE_OPPORTUNITIES = `/candidate-opportunities`,
  CANDIDATE_REQUESTS = '/candidate-requests',
  DASHBOARD = '/dashboard',
  ENTERPRISE_ACCOUNTS = '/enterprise-accounts',
  NOT_FOUND = '/404',
  OFFERS = '/offers',
  ORGANIZATION = '/organization',
  RECRUITMENT_NEGOTIATIONS = `/recruitment-negotiations`,
  RECRUITMENT_NEGOTIATION_ROOMS = `/recruitment-negotiation-rooms`,
  RECRUITMENT_OPPORTUNITIES = `/recruitment-opportunities`,
  REQUESTS = '/requests',
  USER = '/profile',
}

export const enum PrivatePage {
  NOT_FOUND = RootPrivatePage.NOT_FOUND,
  DASHBOARD = RootPrivatePage.DASHBOARD,
  // User
  USER_PROFILE = `${RootPrivatePage.USER}/information`,
  USER_MANAGEMENT = `${RootPrivatePage.USER}/management`,
  USER_SECURITY_MANAGEMENT = `${PrivatePage.USER_MANAGEMENT}/security`,
  USER_NOTIFICATIONS_MANAGEMENT = `${PrivatePage.USER_MANAGEMENT}/notifications`,
  // Organization
  ORGANIZATION_PROFILE = `${RootPrivatePage.ORGANIZATION}/information`,
  ORGANIZATION_PUBLIC_PROFILE = `${RootPrivatePage.ORGANIZATION}/:id/information`,
  ORGANIZATION_USERS = `${RootPrivatePage.ORGANIZATION}/users`,
  ORGANIZATION_ACCOUNT_MANAGEMENT = `${RootPrivatePage.ORGANIZATION}/management`,
  ORGANIZATION_LOCATIONS_MANAGEMENT = `${PrivatePage.ORGANIZATION_ACCOUNT_MANAGEMENT}/locations`,
  ORGANIZATION_REQUESTS_OFFERS = `${RootPrivatePage.ORGANIZATION}/requests-offers`,
  ORGANIZATION_JOB_OPPORTUNITIES = `${PrivatePage.ORGANIZATION_REQUESTS_OFFERS}/job-opportunities`,
  // Recruitment Requests
  REQUEST_CHAT = `${RootPrivatePage.REQUESTS}/:id/chat`,
  REQUEST_CREATE = `${RootPrivatePage.REQUESTS}/create`,
  REQUEST_DETAILS = `${RootPrivatePage.REQUESTS}/:id/details`,
  REQUEST_EDIT = `${RootPrivatePage.REQUESTS}/:id/edit`,
  // Candidate Requests
  CANDIDATE_REQUEST_CHAT = `${RootPrivatePage.CANDIDATE_REQUESTS}/:id/chat`,
  CANDIDATE_REQUEST_CREATE = `${RootPrivatePage.CANDIDATE_REQUESTS}/create`,
  CANDIDATE_REQUEST_DETAILS = `${RootPrivatePage.CANDIDATE_REQUESTS}/:id/details`,
  CANDIDATE_REQUEST_EDIT = `${RootPrivatePage.CANDIDATE_REQUESTS}/:id/edit`,
  // Recruitment Opportunities
  RECRUITMENT_OPPORTUNITY_CHAT = `${RootPrivatePage.RECRUITMENT_OPPORTUNITIES}/:id/chat`,
  RECRUITMENT_OPPORTUNITY_DETAILS = `${RootPrivatePage.RECRUITMENT_OPPORTUNITIES}/:id/details`,
  RECRUITMENT_OPPORTUNITY_OFFER_CREATE = `${RootPrivatePage.RECRUITMENT_OPPORTUNITIES}/:id/create-offer`,
  // Candidate Opportunities
  CANDIDATE_OPPORTUNITY_CHAT = `${RootPrivatePage.CANDIDATE_OPPORTUNITIES}/:id/chat`,
  CANDIDATE_OPPORTUNITY_DETAILS = `${RootPrivatePage.CANDIDATE_OPPORTUNITIES}/:id/details`,
  CANDIDATE_OPPORTUNITY_OFFER_CREATE = `${RootPrivatePage.CANDIDATE_OPPORTUNITIES}/:id/create-offer`,
  // Recruitment Offers
  VIEW_OFFER = `${RootPrivatePage.OFFERS}/:id/details`,
  OFFER_EDIT = `${RootPrivatePage.OFFERS}/:id/edit`,
  OFFER_COMPARISON = `${RootPrivatePage.REQUESTS}/:id/offers`,
  OFFER_CANDIDATES = `${RootPrivatePage.REQUESTS}/:id/offers/:offerId/candidates`,
  OFFER_DETAILS = `${RootPrivatePage.REQUESTS}/:id/offers/:offerId/details`,
  OFFER_CANDIDATE_DETAILS = `${RootPrivatePage.REQUESTS}/offers/candidate/:candidateId/details`,
  VIEW_OFFER_CANDIDATE = `${RootPrivatePage.OFFERS}/candidate/:candidateId/details`,
  // Candidate Offers
  CANDIDATE_OFFER_COMPARISON = `${RootPrivatePage.CANDIDATE_REQUESTS}/:id/offers`,
  CANDIDATE_OFFER_CREATE = `${RootPrivatePage.CANDIDATE_OFFERS}/:id/create-offer`,
  VIEW_CANDIDATE_OFFER = `${RootPrivatePage.CANDIDATE_OFFERS}/:id/details`,
  CANDIDATE_OFFER_EDIT = `${RootPrivatePage.CANDIDATE_OFFERS}/:id/edit`,
  CANDIDATE_OFFER_REQUESTS = `${RootPrivatePage.CANDIDATE_REQUESTS}/:id/offers/:offerId/requests`,
  CANDIDATE_OFFER_DETAILS = `${RootPrivatePage.CANDIDATE_REQUESTS}/:id/offers/:offerId/details`,
  // Recruitment negotiation
  RECRUITMENT_NEGOTIATION_DETAILS = `${RootPrivatePage.RECRUITMENT_NEGOTIATIONS}/:id/details`,
  RECRUITMENT_NEGOTIATION_AGREEMENT = `${RootPrivatePage.RECRUITMENT_NEGOTIATIONS}/:id/agreement`,
  RECRUITMENT_NEGOTIATION_ROOM_DETAILS = `${RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS}/:id/details`,
  RECRUITMENT_NEGOTIATION_ROOM_AGREEMENT = `${RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS}/:id/agreement`,
  // Candidate negotiation
  CANDIDATE_NEGOTIATION_DETAILS = `${RootPrivatePage.CANDIDATE_NEGOTIATIONS}/:id/details`,
  CANDIDATE_NEGOTIATION_AGREEMENT = `${RootPrivatePage.CANDIDATE_NEGOTIATIONS}/:id/agreement`,
  CANDIDATE_NEGOTIATION_ROOM_DETAILS = `${RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS}/:id/details`,
  CANDIDATE_NEGOTIATION_ROOM_AGREEMENT = `${RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS}/:id/agreement`,
}

export const enum OnboardingPage {
  ACCEPT_INVITATION = '/accept-invitation',
  ACCOUNT_TYPE = '/account-type',
  CREATE_COMPANY = '/create-company',
  CREATE_AGENCY = '/create-agency',
  JOIN_COMPANY = '/join-company',
}

export const ENTERPRISE_TO_AGENCY_ROUTES = {
  [PrivatePage.REQUEST_DETAILS]: PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS,
};

export const AGENCY_TO_ENTERPRISE_ROUTES = {
  [PrivatePage.CANDIDATE_REQUEST_DETAILS]:
    PrivatePage.CANDIDATE_OPPORTUNITY_DETAILS,
};
