import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AccountContext, AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { OfferStatusTypeCode } from '@domain/graphql.types';
import { useOfferUpdate } from '@domain/offer';

import { toViewModel } from '../view-models/update-offer.vm';

const useUpdateOffer = () => {
  const { data, fetching, error, updateOffer } = useOfferUpdate();

  const { t } = useTranslation('offers', {
    keyPrefix: 'messages',
  });

  const navigate = useNavigate();

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const viewModel = toViewModel({
    offer: { data, fetching, error },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('updateError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.offerUpdate.offer) {
      setAlertContent({
        action: t('updateSuccess'),
        modifier: 'alert-success',
      });

      if (data?.offerUpdate?.offer.status === OfferStatusTypeCode.Published) {
        refreshAccount();
      }

      navigate(
        generatePath(PrivatePage.VIEW_OFFER, {
          id: data.offerUpdate.offer.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { updateOffer, viewModel };
};

export default useUpdateOffer;
