import { useContext } from 'react';
import { useNavigate } from 'react-router';

import { UserContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';

import useImpersonateUser from './useImpersonateUser';

const useExecuteImpersonateUser = () => {
  const { impersonateUser } = useImpersonateUser();

  const { user, initializeUser } = useContext(UserContext);
  const navigate = useNavigate();

  const executeImpersonateUser = async (accountId: string) => {
    await impersonateUser({
      input: {
        userId: user?.id ?? '',
        impersonatingUserId: accountId,
      },
    });
    initializeUser();
    navigate(PrivatePage.DASHBOARD);
  };

  const stopImpersonatingUser = async () => {
    await impersonateUser({
      input: {
        userId: user?.impersonatedByUserId ?? '',
        impersonatingUserId: null,
      },
    });
    initializeUser();
    navigate(PrivatePage.DASHBOARD);
  };

  return { executeImpersonateUser, stopImpersonatingUser };
};

export default useExecuteImpersonateUser;
