import { gql } from '@urql/core';

import { CandidateOpportunityMatchingRequestListFragment } from '../candidate/opportunity/fragment';

const MutationRequestCreate = gql`
  ${CandidateOpportunityMatchingRequestListFragment}
  mutation RequestCreate($requestCreateInput: RequestCreateInput!) {
    requestCreate(requestCreateInput: $requestCreateInput) {
      request {
        id
        status
        matchingCandidateOpportunities {
          ...CandidateOpportunityMatchingRequestListFragment
        }
      }
    }
  }
`;

export default MutationRequestCreate;
