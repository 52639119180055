import { useQuery } from 'urql';

import { Query, QueryAccountsArgs } from '@domain/graphql.types';
import { Accounts } from '@infrastructure/graphql/account';

const useAccounts = (input?: QueryAccountsArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'accounts'>,
    QueryAccountsArgs
  >({
    query: Accounts,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useAccounts;
