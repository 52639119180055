import { gql } from 'urql';

const RequestChatRooms = gql`
  query RequestChatRooms($input: RequestChatRoomsInput!) {
    requestChatRooms(input: $input) {
      results {
        id
        requestId
        enterpriseAccountId
        agencyAccountId
        creationDate
        isEnterpriseAnonym
        agencyLastMessage {
          message
          sentAt
        }
        agencyName
        offerData {
          rank
          hasMadeOffer
          isUnderNegotiation
        }
      }
      totalCount
    }
  }
`;

export default RequestChatRooms;
