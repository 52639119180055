import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonLink,
  Cluster,
  FormActions,
  PagePanel,
} from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';

import { RequestFormFields } from '../../schema';
import HRNeedsFields from '../conditions/HRNeedsFields';
import JobInformationFields from './JobInformationFields';
import MoreInformationFields from './MoreInformationFields';
import NotesFields from './NotesFields';
import SoftSkillsFields from './SoftSkillsFields';

type DescriptionPanelProps = {
  control: Control<RequestFormFields, any>;
  register: UseFormRegister<RequestFormFields>;
  setError: UseFormSetError<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  errors: FieldErrors<RequestFormFields>;
  clearErrors: UseFormClearErrors<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  nextStep?: () => void;
  onValidateHrNeeds?: () => void;
  isHRNeedsValidated: boolean;
  isEditing?: boolean;
};

const DescriptionPanel = ({
  control,
  errors,
  clearErrors,
  isEditing,
  register,
  setError,
  setValue,
  nextStep,
  trigger,
  watch,
  onValidateHrNeeds,
  isHRNeedsValidated,
}: DescriptionPanelProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <PagePanel
      title={t('steps.description')}
      text={t('steps.descriptionText')}
      isEditing={isEditing}
    >
      <HRNeedsFields
        control={control}
        setValue={setValue}
        trigger={trigger}
        watch={watch}
      />

      {isHRNeedsValidated && (
        <>
          <JobInformationFields
            control={control}
            setValue={setValue}
            register={register}
            errors={errors}
            watch={watch}
            trigger={trigger}
          />
          <MoreInformationFields
            control={control}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            register={register}
            watch={watch}
            errors={errors}
            trigger={trigger}
            isEditing={isEditing}
          />
          <SoftSkillsFields
            control={control}
            setValue={setValue}
            errors={errors}
            trigger={trigger}
            watch={watch}
          />
          <NotesFields control={control} errors={errors} />
          <FormActions
            nextStep={nextStep}
            cancelPath={RootPrivatePage.REQUESTS}
          />
        </>
      )}

      {!isHRNeedsValidated && (
        <Cluster justify="end">
          <ButtonLink to={RootPrivatePage.REQUESTS}>
            {tGlobal('button.cancel')}
          </ButtonLink>

          <Button primary onClick={onValidateHrNeeds} type="submit">
            {tGlobal('button.validate')}
          </Button>
        </Cluster>
      )}
    </PagePanel>
  );
};

export default DescriptionPanel;
