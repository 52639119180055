import { DateTime } from 'luxon';

import {
  JobPaymentOtherTypesCode,
  JobScheduleTypeCode,
  LinkFileName,
  Offer,
  OfferCandidate,
  OperationTerritoryCode,
  RequestTypeCode,
} from '@domain/graphql.types';
import { parseNumber } from '@utils/data-utils';

import { OfferCandidateFormFields, OfferFormFields } from './types';

export const normalizeCandidateData = (data: OfferCandidateFormFields) => ({
  ...data,
  hourlyRateMin: data.hourlyRateMin?.toFixed(2) || null,
  hourlyRateMax: data.hourlyRateMax?.toFixed(2) || null,
  hourlyBonus: data.hourlyBonus?.toFixed(2) || null,
  remunerationAmount: data.remunerationAmount?.toFixed(2) || null,
  otherRemunerationAmount: data.otherRemunerationAmount?.toFixed(2) || null,
  metAt: data.metAt ? DateTime.fromISO(data.metAt).toJSDate() : null,
  experience: Number(data.experience) || undefined,
  linkFileNames: undefined,
});

export const normalizeOfferInputData = (data: OfferFormFields) => ({
  ...data,
  requestTypes: (data.requestTypes as RequestTypeCode[]) || [],
  offerCandidates: data.offerCandidates?.map((d) => normalizeCandidateData(d)),
  conditionDeposit: data.conditionDeposit?.toFixed(2) || null,
  conditionRemunerationAmount:
    data.conditionRemunerationAmount?.toFixed(2) || null,
  conditionRemunerationHourlyRate:
    data.conditionRemunerationHourlyRate?.toFixed(2) || null,
  conditionBonus: data.conditionBonusMonth
    ? data.conditionBonus?.toFixed(2) || null
    : null,
  outsourcingConditionMaximumBudget:
    data.outsourcingConditionMaximumBudget?.toFixed(2) || null,
  temporaryPlacementConditionPossibleBuybackBonus:
    data.temporaryPlacementConditionPossibleBuybackBonus?.toFixed(2) || null,
  conditionMinGuaranteedPeriodBonus: data.conditionMinGuaranteedPeriod
    ? data.conditionMinGuaranteedPeriodBonus?.toFixed(2) || null
    : null,
  conditionHiredDateBonusRequirement: data.conditionHiredDateBonusRequirement
    ? DateTime.fromISO(data.conditionHiredDateBonusRequirement).toJSDate()
    : null,
  conditionHiredDateBonus: data.conditionHiredDateBonusRequirement
    ? data.conditionHiredDateBonus?.toFixed(2) || null
    : null,
});

export const normalizeOfferEditCandidateData = (data: OfferCandidate) => ({
  id: data.id,
  firstName: data.firstName,
  firstNameDisplay: data.firstNameDisplay,
  lastName: data.lastName,
  lastNameDisplay: data.lastNameDisplay,
  email: data.email,
  emailDisplay: data.emailDisplay,
  experience: data.experience,
  educationLevelCode: data.educationLevelCode,
  studyField: data.studyField,
  specialty: data.specialty,
  canWorkInCanadaOrQuebec: data.canWorkInCanadaOrQuebec,
  citiesSubRegions: (data.citiesSubRegions as string[]) || [],
  operationTerritoryCodes:
    data.operationTerritoryCodes as OperationTerritoryCode[],
  requestTypes: (data.requestTypes as RequestTypeCode[]) || [],
  resume: data.cvUrl,
  otherFiles:
    (data.otherFilesUrls?.map((otherFile) => otherFile.link) as string[]) || [],
  linkFileNames: (data.otherFilesUrls as LinkFileName[]) || [],
  notes: data.notes,
  metAt: data.metAt || null,
  jobTypeCode: data.jobTypeCode,
  jobDurationInMonths: data.jobDurationInMonths,
  jobModeCode: data.jobModeCode,
  fullTimeAvailability: data.fullTimeAvailability,
  partTimeAvailability: data.partTimeAvailability,
  hoursPerWeek: (data.hoursPerWeek as string[]) || [],
  schedules: (data.schedules as JobScheduleTypeCode[]) || [],
  allowedRemoteDays: data.allowedRemoteDays,
  annualSalaryMin: data.annualSalaryMin,
  annualSalaryMax: data.annualSalaryMax,
  paymentFrequencyCode: data.paymentFrequencyCode,
  hourlyRateMin: parseNumber(data.hourlyRateMin) || null,
  hourlyRateMax: parseNumber(data.hourlyRateMax) || null,
  hourlyBonus: parseNumber(data.hourlyBonus) || null,
  remunerationAmount: parseNumber(data.remunerationAmount) || null,
  otherPaymentTypes:
    (data.otherPaymentTypes as JobPaymentOtherTypesCode[]) || [],
  otherRemunerationAmount: parseNumber(data.otherRemunerationAmount) || null,
  softSkills: (data.softSkills as string[]) || [],
});

export const normalizeOfferEditData = (data: Offer): OfferFormFields => ({
  requestId: data.requestId,
  linkProfile: data.linkProfile,
  noCandidatesExplaination: data.noCandidatesExplaination,
  candidatesAvailable: data.candidatesAvailable || false,
  requestTypes: (data.requestTypes as RequestTypeCode[]) || [],
  salesPitch: data.salesPitch,
  offerCandidates: data.candidates?.map((d) =>
    normalizeOfferEditCandidateData(d)
  ),

  // GENERAL CONDITIONS
  conditionMaxRecruitmentPeriod:
    data.generalContractualConditions.maxRecruitmentPeriod,
  conditionProbationPeriod: data.generalContractualConditions.probationPeriod,
  conditionPaymentMethod: data.generalContractualConditions.paymentMethod,
  conditionStartOfAgreementPaymentMethod:
    data.generalContractualConditions.startOfAgreementPaymentMethod,
  conditionDeposit: parseNumber(data.generalContractualConditions.deposit),
  conditionPaymentFrequency: data.generalContractualConditions.paymentFrequency,
  conditionRemunerationType: data.generalContractualConditions.remunerationType,
  conditionRemunerationPercentage:
    data.generalContractualConditions.remunerationPercentage,
  conditionRemunerationAmount: parseNumber(
    data.generalContractualConditions.remunerationAmount
  ),
  conditionRemunerationHourlyRate: parseNumber(
    data.generalContractualConditions.remunerationHourlyRate
  ),
  conditionRemunerationEstimatedHoursMin:
    data.generalContractualConditions.remunerationEstimatedHoursMin,
  conditionRemunerationEstimatedHoursMax:
    data.generalContractualConditions.remunerationEstimatedHoursMax,

  conditionMinGuaranteedPeriod:
    data.generalContractualConditions.minGuaranteedPeriod,
  conditionMinGuaranteedPeriodBonus: parseNumber(
    data.generalContractualConditions?.minGuaranteedPeriodBonus
  ),
  conditionBonus: parseNumber(data.generalContractualConditions?.bonus),
  conditionBonusMonth: data.generalContractualConditions?.bonusMonth,
  conditionHiredDateBonusRequirement:
    data.generalContractualConditions.hiredDateBonusRequirement || null,
  conditionHiredDateBonus: parseNumber(
    data.generalContractualConditions?.hiredDateBonus
  ),

  // HEADHUNTER CONDITIONS
  headhunterConditionMaximumReplacement:
    data.headhunterContractualConditions?.maximumReplacement,
  headhunterConditionExclusivityPeriod:
    data.headhunterContractualConditions?.headhuntingExclusivityPeriod,

  // TEMPORARY PLACEMENT CONDITIONS
  temporaryPlacementConditionPossibleBuyback:
    data.temporaryPlacementContractualConditions?.possibleBuyback,
  temporaryPlacementConditionPossibleBuybackBonus: parseNumber(
    data.temporaryPlacementContractualConditions?.possibleBuybackBonus
  ),
  temporaryPlacementConditionRisks:
    data.temporaryPlacementContractualConditions?.risks,
  temporaryPlacementConditionTravelExpensesResponsibility:
    data.temporaryPlacementContractualConditions?.travelExpensesResponsibility,
  temporaryPlacementConditionTrainingExpensesResponsibility:
    data.temporaryPlacementContractualConditions
      ?.trainingExpensesResponsibility,
  temporaryPlacementConditionEmployeeHelpProgramResponsibility:
    data.temporaryPlacementContractualConditions
      ?.employeeHelpProgramResponsibility,
  temporaryPlacementConditionCnesstExpensesResponsibility:
    data.temporaryPlacementContractualConditions?.cnesstExpensesResponsibility,
  temporaryPlacementConditionOtherResponsibilities:
    data.temporaryPlacementContractualConditions?.otherResponsibilities,
  temporaryPlacementConditionForeignApplicants:
    data.temporaryPlacementContractualConditions?.foreignApplicants,

  // INTERNATIONAL RECRUITMENT CONDITIONS
  internationalRecruitmentConditionMandatoryWorkPermit:
    data.internationalRecruitmentContractualConditions?.mandatoryWorkPermit,
  internationalRecruitmentConditionExclusivityPeriod:
    data.internationalRecruitmentContractualConditions?.exclusivityPeriod,
  internationalRecruitmentConditionMaximumReplacement:
    data.internationalRecruitmentContractualConditions?.maximumReplacement,

  // OUTSOURCING CONDITIONS
  outsourcingConditionMaximumDuration:
    data.outsourcingContractualConditions?.maximumDuration,
  outsourcingConditionExclusivityPeriod:
    data.outsourcingContractualConditions?.exclusivityPeriod,
  outsourcingConditionMaximumBudget: parseNumber(
    data.outsourcingContractualConditions?.maximumBudget
  ),
  outsourcingConditionResponsibilities:
    data.outsourcingContractualConditions?.responsibilities,
});
