import { useCandidateOpportunities } from '@domain/candidate';
import {
  CandidateOpportunitiesFilters,
  CandidateOpportunitiesSortDirective,
} from '@domain/graphql.types';
import { Pagination } from '@domain/pagination/pagination';

import { toViewModel } from '../view-models/get-candidate-opportunities.vm';

const useGetCandidateOpportunities = (
  sortBy: CandidateOpportunitiesSortDirective[],
  filterBy?: CandidateOpportunitiesFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } = useCandidateOpportunities({
    sortBy,
    filterBy,
    ...pagination,
  });

  const viewModel = toViewModel({
    candidateOpportunities: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchCandidateOpportunities: reexecuteQuery,
  };
};

export default useGetCandidateOpportunities;
