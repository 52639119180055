import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useParams } from 'react-router';

import {
  Badge,
  Button,
  ConfirmationModal,
  PageHeader,
  PageLoader,
  SelectField,
} from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';
import { ButtonLink } from '@application/components/links';
import { PictureBadges } from '@application/components/picture-badges';
import { OptionType } from '@application/components/select-field/select';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import useAgencyProCandidateRequestValidation from '@application/views/billing/useAgencyProCandidateRequestValidation';
import useIsViewOnlySubscription from '@application/views/billing/useIsViewOnlySubscription';
import { RequestStatusTypeCode } from '@domain/graphql.types';
import { calculateTimeSince } from '@utils/date-utils';
import { cn } from '@utils/lib-utils';
import { getRequestDropDownStatusClassNames } from '@utils/styles-utils';

import {
  useDeleteCandidateRequest,
  useDuplicateCandidateRequest,
  useGetCandidateRequest,
  useUpdateCandidateRequestStatus,
} from './hooks';
import { ConditionsSummary, DescriptionSummary } from './summary';

const ViewCandidateRequestPage = () => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { id = '' } = useParams();
  const location = useLocation();
  const hasViewOnlyRestriction = useIsViewOnlySubscription();
  const { canPublishCandidateRequest } =
    useAgencyProCandidateRequestValidation();

  const { setModal } = useContext(ModalContext);

  const { deleteCandidateRequest } = useDeleteCandidateRequest();
  const { duplicateCandidateRequest } = useDuplicateCandidateRequest();
  const { updateCandidateRequestStatus } = useUpdateCandidateRequestStatus();

  const {
    viewModel: { data, isLoading },
    refetchCandidate,
  } = useGetCandidateRequest(id);

  const timeSinceCreated = useMemo(
    () => calculateTimeSince(data?.createdAt),
    [data?.createdAt]
  );

  const statusOptions = useMemo(() => {
    const options: OptionType[] = data?.status
      ? [
          {
            value: data?.status,
            label: t(
              `enum.requestStatusTypeCode.${data?.status.toLowerCase()}`
            ),
          },
        ]
      : [];
    data?.availableTransitions.map((a) =>
      options.push({
        value: a,
        label: t(`enum.requestStatusTypeCode.${a.toLowerCase()}`),
      })
    );
    return options;
  }, [data, t]);

  const handleStatusUpdate = useCallback(
    async (status: RequestStatusTypeCode) => {
      await updateCandidateRequestStatus({
        input: { id, status },
      });
      refetchCandidate();
    },
    [updateCandidateRequestStatus, id, refetchCandidate]
  );

  const handleDuplicate = useCallback(async () => {
    await duplicateCandidateRequest({ input: { candidateRequestId: id } });
  }, [duplicateCandidateRequest, id]);

  const handleDelete = useCallback(async () => {
    setModal(null);
    await deleteCandidateRequest({ input: { candidateRequestId: id } });
  }, [deleteCandidateRequest, setModal, id]);

  const confirmDelete = useCallback(() => {
    setModal({
      title: t('modal.titles.deleteRequest'),
      content: (
        <ConfirmationModal
          content={t('modal.contents.confirmDelete')}
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
        />
      ),
    });
  }, [setModal, t, handleDelete]);

  const statusClassName = getRequestDropDownStatusClassNames(
    data?.status || RequestStatusTypeCode.Draft
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={t('title.requestDetails')}
        backTo={RootPrivatePage.CANDIDATE_REQUESTS}
      >
        {data?.candidateNegotiation && (
          <ButtonLink
            primary
            className="mr-s-16"
            icon={<i className="ri-shake-hands-line" />}
            to={generatePath(PrivatePage.CANDIDATE_NEGOTIATION_DETAILS, {
              id: data?.candidateNegotiation?.id || null,
            })}
            from={location.pathname}
          >
            {t('button.negotiationsZone')}
          </ButtonLink>
        )}
      </PageHeader>

      <div>
        <Stack space={24}>
          <Cluster className="justify-between mb-s-24">
            <div className="flex flex-row items-center gap-s-24">
              {data?.status === RequestStatusTypeCode.Draft &&
              !hasViewOnlyRestriction &&
              canPublishCandidateRequest ? (
                <SelectField
                  label="status"
                  hideLabel
                  name="status"
                  controlClassName={statusClassName}
                  options={statusOptions}
                  value={statusOptions.find(
                    (o: OptionType) => o.value === data?.status
                  )}
                  onChange={(option: any) =>
                    handleStatusUpdate(option.value as RequestStatusTypeCode)
                  }
                />
              ) : (
                <Badge
                  value={t(
                    `enum.requestStatusTypeCode.${data?.status.toLowerCase()}`
                  )}
                  placeholder="-"
                  className={cn(
                    getRequestDropDownStatusClassNames(
                      data?.status || RequestStatusTypeCode.Draft
                    ),
                    'rounded-md h-s-48 w-s-112'
                  )}
                  valueClassName="text-16 font-semibold"
                />
              )}

              <PictureBadges
                pictures={[
                  {
                    name: data?.user.name ?? undefined,
                    url: data?.user.avatarUrl,
                  },
                ]}
                rounded="rounded-md"
                tooltipPosition="right"
              />

              <span>
                {timeSinceCreated.value > 1
                  ? tGlobal('date.since', {
                      date: tGlobal(`date.options.${timeSinceCreated.unit}`, {
                        count: timeSinceCreated.value,
                      }),
                    })
                  : tGlobal('date.today')}
              </span>
            </div>

            <div>
              <Button
                icon={<i className="ri-file-copy-line" />}
                className="mr-s-16"
                onClick={handleDuplicate}
              >
                {tGlobal('button.duplicate')}
              </Button>

              {!data?.candidateNegotiation && (
                <Button
                  icon={<i className="ri-delete-bin-line" />}
                  className="mr-s-16"
                  onClick={confirmDelete}
                >
                  {tGlobal('button.delete')}
                </Button>
              )}

              {data?.status === RequestStatusTypeCode.Draft && (
                <ButtonLink
                  to={generatePath(PrivatePage.CANDIDATE_REQUEST_EDIT, { id })}
                  icon={<i className="ri-pencil-line" />}
                >
                  {tGlobal('button.edit')}
                </ButtonLink>
              )}
            </div>
          </Cluster>
        </Stack>

        <DescriptionSummary data={data} />
        <ConditionsSummary data={data} />
      </div>
    </>
  );
};

export default ViewCandidateRequestPage;
