import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PageLoader } from '@application/components/page';
import { ModalContext, UserContext } from '@application/context';
import { dispatchLogout } from '@domain/authentication';
import { AccountUserStatus } from '@domain/graphql.types';

import { OkModal } from '../modal';

type UserGuardProps = {
  children: React.ReactNode;
};

const UserGuard = ({ children }: UserGuardProps) => {
  const { error, initializeUser, initializationCompleted, accountUser } =
    useContext(UserContext);
  const { setModal } = useContext(ModalContext);

  const { t } = useTranslation('user');

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  if (!initializationCompleted) {
    return <PageLoader />;
  }

  if (accountUser?.status === AccountUserStatus.Inactive) {
    setModal({
      title: t('modal.inactiveUserCannotConnectTitle'),
      content: (
        <OkModal
          content={t('modal.inactiveUserCannotConnectMessage')}
          onOk={dispatchLogout}
        />
      ),
      modifier: 'error',
    });
  }

  if (error) {
    throw error;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default UserGuard;
