import { DateTime } from 'luxon';
import { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  LanguageButtons,
  NotificationCenter,
} from '@application/components';
import { OnboardingGuideButton } from '@application/components/onboarding-guide';
import { AccountContext, UserContext } from '@application/context';
import { PromoButton } from '@application/views/billing';
import TrialButton from '@application/views/billing/TrialButton';
import {
  AccountBillingSubscriptionStatusCode,
  AccountTypeCode,
  UserRole,
} from '@domain/graphql.types';

import { UserNav, UserNavButton } from '../user-nav';
import Breadcrumbs from './Breadcrumbs';

type TopNavProps = {
  onClick: () => void;
};

const TopNav = ({ onClick }: TopNavProps) => {
  const [isUserNavOpen, setUserNavOpen] = useState(false);

  const { t } = useTranslation();

  const { user, avatarUrl } = useContext(UserContext);
  const { account } = useContext(AccountContext);
  const { accountUser } = useContext(UserContext);

  const isAllowedToUpgradeSubscription = useMemo(
    () =>
      accountUser?.role === UserRole.Owner ||
      accountUser?.role === UserRole.Admin,
    [accountUser]
  );

  const userNavButtonRef = useRef<HTMLButtonElement>(null);

  const BLACK_FRIDAY_END = DateTime.fromISO('2024-11-25T23:59:59');

  return (
    <div className="navbar sticky z-sticky top-s-0 bg-base-100 border-b px-s-12 lg:px-s-32">
      <IconButton
        className="lg:hidden"
        ghost
        onClick={onClick}
        icon={<i className="ri-menu-line" />}
        text={t('sidebar.openButton')}
      />

      <Breadcrumbs />

      {user && (
        <div className="navbar-end flex grow gap-x-s-8">
          {account?.type === AccountTypeCode.Enterprise &&
            isAllowedToUpgradeSubscription &&
            BLACK_FRIDAY_END > DateTime.now() && (
              <PromoButton endPromoDate={BLACK_FRIDAY_END} />
            )}

          <OnboardingGuideButton />

          {account?.type === AccountTypeCode.Enterprise &&
            isAllowedToUpgradeSubscription &&
            account?.subscriptionStatus !==
              AccountBillingSubscriptionStatusCode.Active && (
              <TrialButton
                trialEndDate={new Date(account?.subscriptionTrialEnd)}
                extendedDisplayInDays={7}
              />
            )}

          <div className="self-stretch w-[1px] border-l" />

          <LanguageButtons ghost />

          <div className="self-stretch w-[1px] border-l" />

          <NotificationCenter userExternalId={user.externalNotificationId} />

          <div className="self-stretch w-[1px] border-l" />

          <div className="dropdown">
            <UserNavButton
              ref={userNavButtonRef}
              isUserNavOpen={isUserNavOpen}
              user={user}
              avatarUrl={avatarUrl}
              account={account}
              onClick={() => setUserNavOpen(!isUserNavOpen)}
            />
            <UserNav
              buttonRef={userNavButtonRef}
              isUserNavOpen={isUserNavOpen}
              onClick={() => setUserNavOpen(!isUserNavOpen)}
              impersonatingByUserId={user.impersonatedByUserId ?? undefined}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopNav;
