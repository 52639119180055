import * as yup from 'yup';
import 'yup-phone-lite';

import {
  AccountAdvantageTypeCode,
  AccountIndustryType,
  AccountSizeTypeCode,
  AccountTypeCode,
} from '@domain/graphql.types';
import { URL_REGEX } from '@utils/yup-utils';

export const PROFILE_SCHEMA = yup.object({
  name: yup
    .string()
    .max(128, 'validations.maxLength')
    .required('validations.required.name'),
  type: yup.mixed<AccountTypeCode>().oneOf(Object.values(AccountTypeCode)),
  website: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  address: yup
    .string()
    .nullable()
    .max(255, 'validations.maxLength')
    .required('validations.required.address'),
  phoneNumber: yup.string().nullable().phone('CA', 'validations.phone'),
  phoneDisplay: yup.boolean().nullable(),
  industryTypeCode: yup
    .mixed<AccountIndustryType>()
    .oneOf(Object.values(AccountIndustryType))
    .nullable(),
  sectorCode: yup.string().nullable(),
  sizeTypeCode: yup
    .mixed<AccountSizeTypeCode>()
    .oneOf(Object.values(AccountSizeTypeCode))
    .nullable(),
  employerOfferDescription: yup
    .string()
    .required('validations.required.employerOfferDescription')
    .min(500, 'validations.minLength')
    .max(3000, 'validations.maxLength'),
  facebookUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  instagramUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  linkedinUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  twitterUrl: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  advantageCodes: yup
    .array(
      yup
        .mixed<AccountAdvantageTypeCode>()
        .oneOf(Object.values(AccountAdvantageTypeCode))
    )
    .nullable(),
});
