import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import AccountListFragment from './account.list.fragment';

const Accounts = gql`
${AccountListFragment}
  query Accounts(
    $filterBy: AccountsFilters!
    $sortBy: [AccountsSortDirective!]
    $before: String
    $after: String
    $first: Float
    $last: Float
  ) {
    accounts(
      filterBy: $filterBy
      sortBy: $sortBy
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
        ${getPage(['AccountListFragment'])}
  }
`;

export default Accounts;
