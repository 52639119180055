import { useMutation } from 'urql';

import {
  Mutation,
  MutationAccountUserUpdateStatusArgs,
} from '@domain/graphql.types';
import { AccountUserUpdateStatus } from '@infrastructure/graphql/account-user';

const useAccountUserUpdateStatus = () => {
  const [{ data, fetching, error }, updateAccountUserStatus] = useMutation<
    Pick<Mutation, 'accountUserUpdateStatus'>,
    MutationAccountUserUpdateStatusArgs
  >(AccountUserUpdateStatus);

  return {
    updateAccountUserStatus,
    data,
    fetching,
    error,
  };
};

export default useAccountUserUpdateStatus;
