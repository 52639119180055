import { UseQueryExecute } from 'urql';

import { RequestChatRoom, RequestChatRoomsInput } from '@domain/graphql.types';

import useGetRequestChatRooms from './useGetRequestChatRooms';

type UseRequestChatRoomsListProps = {
  count?: number;
  requestChatRooms: RequestChatRoom[];
  isLoading: boolean;
  refetchRequestChatRooms: UseQueryExecute;
};

const useRequestChatRoomsList = (
  input: RequestChatRoomsInput
): UseRequestChatRoomsListProps => {
  const {
    viewModel: { data, isLoading },
    refetchRequestChatRooms,
  } = useGetRequestChatRooms(input);

  return {
    count: data?.requestChatRooms.totalCount,
    requestChatRooms: data?.requestChatRooms.results.slice() || [],
    isLoading,
    refetchRequestChatRooms,
  };
};

export default useRequestChatRoomsList;
