import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components/buttons';

import Modal from './Modal';

export type OkModalProps = {
  confirmButtonClassName?: string;
  content: ReactNode | null;
  onOk: () => void;
};

const OkModal = ({
  confirmButtonClassName: customConfirmButtonClassName,
  content,
  onOk,
}: OkModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div>{content}</div>
      <Modal.Action>
        <Button className={customConfirmButtonClassName} onClick={onOk} primary>
          {t('button.ok')}
        </Button>
      </Modal.Action>
    </>
  );
};

export default OkModal;
