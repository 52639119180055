import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, TextAreaField } from '@application/components';

import { Section } from '../../components';
import { CandidateOfferFormFields } from '../../schema';

type SalesPitchFieldsProps = {
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
};

const SalesPitchFields = ({ control, errors }: SalesPitchFieldsProps) => {
  const { t } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  return (
    <Section legend={t('subtitles.salesPitch')} className="mt-s-40">
      <Stack space={24}>
        <Controller
          name="salesPitch"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              value={value || ''}
              className="mt-s-0"
              label={t('labels.salesPitch')}
              rows={4}
              maxChar={1024}
              onChange={onChange}
              invalid={!!errors.salesPitch}
              helperText={
                errors.salesPitch?.message &&
                tGlobal(errors.salesPitch?.message, { max: '1024' })
              }
            />
          )}
        />
      </Stack>
    </Section>
  );
};

export default SalesPitchFields;
