import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { RequestChatRoomMessageResponse } from '@domain/graphql.types';

type ToViewModel = {
  requestChatRoomMessage: {
    data: RequestChatRoomMessageResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  requestChatRoomMessage: { data, fetching, error },
}: ToViewModel): ViewModel<RequestChatRoomMessageResponse | undefined> =>
  new ViewModel<RequestChatRoomMessageResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
