import React, { ForwardedRef, ReactNode, useMemo } from 'react';

import { SECONDARY_STYLES } from '@application/components/buttons/constants';
import { Tooltip } from '@application/components/tooltip';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

type RadioProps = React.ComponentPropsWithRef<'label'> & {
  id?: string;
  label: ReactNode | string;
  tooltipMessage?: string;
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  asButton?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type Ref = HTMLLabelElement;

const Radio = React.forwardRef<Ref, RadioProps>(
  (
    {
      id,
      name,
      value,
      label,
      tooltipMessage,
      checked,
      disabled,
      className: customClassName,
      onChange,
      asButton,
      ...rest
    }: RadioProps,
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    const inputId = useMemo(() => id || randomString(), [id]);

    const radioClassName =
      'absolute top-s-0 left-s-0 h-s-0 w-s-0 opacity-0 -ml-s-8 peer';

    const labelClassName = cn(
      'flex flex-row relative form-control justify-start cursor-pointer',
      asButton &&
        `btn border-none h-auto px-s-24 py-s-10 max-h-[3rem] ${SECONDARY_STYLES}`,
      !asButton && 'label',
      {
        'pointer-events-none bg-disabled-default text-disabled-content':
          disabled,
      },
      customClassName
    );

    const labelTextClassName = cn(
      'label-text font-normal normal-case text-16 z-[1]',
      { 'text-disabled-content': disabled }
    );

    return (
      <>
        {tooltipMessage && (
          <Tooltip message={tooltipMessage}>
            <i className="z-20 ml-s-4 mt-s-4 absolute ri-information-line text-20 leading-none text-neutral-secondary" />
          </Tooltip>
        )}
        <label ref={ref} htmlFor={inputId} className={labelClassName} {...rest}>
          <input
            type="radio"
            id={inputId}
            name={name}
            value={value}
            checked={checked}
            className={radioClassName}
            onChange={onChange}
            disabled={disabled}
          />
          <span className={labelTextClassName}>{label}</span>

          {asButton && (
            <span className="absolute inset-s-0 z-0 border border-stroke-default rounded-[inherit] pointer-events-none peer-checked:bg-secondary" />
          )}
        </label>
      </>
    );
  }
);

export default Radio;
