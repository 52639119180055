import { useContext, useMemo } from 'react';

import { AccountContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { useIsUserRoleAuthorized } from '@application/hooks';
import { AccountTypeCode, UserRole } from '@domain/graphql.types';

import NavButton from './NavButton';
import NavLink from './NavLink';

type TopLinksProps = {
  isNavOpen: boolean;
};

const TopLinks = ({ isNavOpen }: TopLinksProps) => {
  const { account } = useContext(AccountContext);
  const isUserSuperAdmin = useIsUserRoleAuthorized({
    authorizedUserRoles: [UserRole.SuperAdmin],
  });

  const negoRoomLinks = useMemo(() => {
    const links = [];

    if (account?.type === AccountTypeCode.Enterprise) {
      links.push({
        label: 'callsForOffer',
        url: RootPrivatePage.RECRUITMENT_NEGOTIATIONS,
      });
      if (import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION === 'true') {
        links.push({
          label: 'availableCandidates',
          url: RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS,
        });
      }
    } else {
      links.push({
        label: 'opportunities',
        url: RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS,
      });
      if (import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION === 'true') {
        links.push({
          label: 'availableCandidates',
          url: RootPrivatePage.CANDIDATE_NEGOTIATIONS,
        });
      }
    }

    return links;
  }, [account?.type]);

  const NAV_LINKS =
    account?.type === AccountTypeCode.Enterprise
      ? [
          {
            label: 'callsForOffer',
            icon: 'ri-suitcase-line',
            url: RootPrivatePage.REQUESTS,
          },
          {
            label: 'discussionZone',
            icon: 'ri-shake-hands-line',
            options: negoRoomLinks,
          },

          {
            label: 'availableCandidates',
            icon: 'ri-alarm-warning-line',
            url: RootPrivatePage.CANDIDATE_OPPORTUNITIES,
          },
          {
            label: 'meetingRequests',
            icon: 'ri-money-dollar-circle-line',
            url: RootPrivatePage.CANDIDATE_OFFERS,
          },
        ]
      : [
          {
            label: 'opportunities',
            icon: 'ri-alarm-warning-line',

            url: RootPrivatePage.RECRUITMENT_OPPORTUNITIES,
          },
          {
            label: 'myOffers',
            icon: 'ri-money-dollar-circle-line',
            url: RootPrivatePage.OFFERS,
          },
          {
            label: 'discussionZone',
            icon: 'ri-shake-hands-line',
            options: negoRoomLinks,
          },
          {
            label: 'availableCandidates',
            icon: 'ri-suitcase-line',
            url: RootPrivatePage.CANDIDATE_REQUESTS,
          },
        ];
  if (isUserSuperAdmin) {
    NAV_LINKS.push({
      label: 'accounts',
      icon: 'ri-user-star-fill',
      options: [
        {
          label: 'enterprises',
          url: RootPrivatePage.ENTERPRISE_ACCOUNTS,
        },
        {
          label: 'agencies',
          url: RootPrivatePage.AGENCY_ACCOUNTS,
        },
      ],
    });
  }

  return (
    <ul className="mx-0">
      {NAV_LINKS.map((link) =>
        link.url ? (
          <NavLink
            key={link.label}
            label={link.label}
            isNavOpen={isNavOpen}
            icon={link.icon}
            url={link.url}
          />
        ) : (
          <NavButton
            key={link.label}
            label={link.label}
            isNavOpen={isNavOpen}
            icon={link.icon}
            options={link.options}
          />
        )
      )}
    </ul>
  );
};

export default TopLinks;
