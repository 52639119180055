import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useAccountUserUpdateStatus } from '@domain/account-user';
import { AccountUserErrors } from '@domain/graphql.types';

import { toViewModel } from '../view-models/update-account-user-status.vm';

const useUpdateAccountUserStatus = () => {
  const { data, fetching, error, updateAccountUserStatus } =
    useAccountUserUpdateStatus();

  const { t } = useTranslation('organization', {
    keyPrefix: 'users.list',
  });

  const { setAlertContent } = useContext(AlertContext);

  const viewModel = toViewModel({
    accountUserUpdateStatus: {
      data: data?.accountUserUpdateStatus,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    const availableSeatsError = errorCodes.find(
      (e) => e.message === AccountUserErrors.NoAvailableSeats
    );

    // We handle this error in useUsersList.tsx
    if (!availableSeatsError) {
      setAlertContent({
        action: t('actions.updateMemberStatusError'),
        modifier: 'alert-error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  useEffect(() => {
    if (data?.accountUserUpdateStatus.accountUser) {
      setAlertContent({
        action: t('actions.updateMemberStatusSuccess'),
        modifier: 'alert-success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    viewModel,
    updateAccountUserStatus,
  };
};

export default useUpdateAccountUserStatus;
