import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountContext, AlertContext } from '@application/context';
import { useCandidateOfferDelete } from '@domain/candidate';

import { toViewModel } from '../view-models/delete-candidate-offer.vm';

const useCandidateDeleteOffer = () => {
  const { data, fetching, error, deleteCandidateOffer } =
    useCandidateOfferDelete();

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    candidateOfferDelete: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      modifier: 'alert-error',
      action: t('deleteOfferError'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateOfferDelete?.ok) {
      setAlertContent({
        modifier: 'alert-success',
        action: t('deleteOfferSuccess'),
      });

      refreshAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateOfferDelete?.ok]);

  return {
    viewModel,
    deleteCandidateOffer,
  };
};

export default useCandidateDeleteOffer;
