import { useCallback, useEffect, useMemo, useState } from 'react';

import { PageLoader } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { RequestChatRoom } from '@domain/graphql.types';

import { ChatRoom } from './components';
import { ChatContent } from './components/ChatRoom';
import {
  useCreateRequestChatRoomMessage,
  useRequestChatRoomMessagesList,
} from './hooks';
import useRequestChatRoomsList from './hooks/chat-room/useRequestChatRoomsList';

type RecruitmentOpportunityChatContentProps = {
  opportunityId: string;
  accountId: string;
  title: string;
  subtitle: string;
};
const RecruitmentOpportunityChatContent = ({
  opportunityId,
  accountId,
  title,
  subtitle,
}: RecruitmentOpportunityChatContentProps) => {
  const [selectedChatRoom, setSelectedChatRoom] = useState<RequestChatRoom>();
  const [enterpriseName, setEnterpriseName] = useState('');

  const { createRequestChatRoomMessage } = useCreateRequestChatRoomMessage();

  const { requestChatRooms, isLoading: requestChatRoomsIsLoading } =
    useRequestChatRoomsList({
      requestId: opportunityId,
      agencyAccountId: accountId,
    });

  const {
    requestChatRoomMessages,
    isLoading: requestChatRoomMessagesIsLoading,
    refetchRequestChatRoomMessages,
  } = useRequestChatRoomMessagesList({
    requestChatRoomId: selectedChatRoom?.id ?? '',
  });

  const chatContent: ChatContent = useMemo(
    () => ({
      messageList: requestChatRoomMessages?.map((message) => ({
        position:
          message.accountId === accountId
            ? ('right' as const)
            : ('left' as const),
        date: message.sentAt,
        viewed: true,
        content: message.message,
      })),
    }),
    [accountId, requestChatRoomMessages]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createRequestChatRoomMessage({
        input: {
          requestChatRoomId: selectedChatRoom?.id ?? '',
          message,
        },
      });

      if (requestChatRoomMessages.length < 1) {
        refetchRequestChatRoomMessages();
      }
    },
    [
      createRequestChatRoomMessage,
      selectedChatRoom?.id,
      requestChatRoomMessages.length,
      refetchRequestChatRoomMessages,
    ]
  );

  useEffect(() => {
    if (selectedChatRoom && !selectedChatRoom?.isEnterpriseAnonym && subtitle) {
      setEnterpriseName(subtitle);
    }
  }, [subtitle, selectedChatRoom, selectedChatRoom?.isEnterpriseAnonym]);

  useEffect(() => {
    if (requestChatRooms?.length) {
      setSelectedChatRoom(requestChatRooms[0]);
    }
  }, [requestChatRooms]);

  return requestChatRoomsIsLoading ? (
    <PageLoader />
  ) : (
    <ChatRoom
      isLoading={requestChatRoomMessagesIsLoading}
      title={title}
      subtitle={enterpriseName}
      chatContent={chatContent}
      backTo={RootPrivatePage.RECRUITMENT_OPPORTUNITIES}
      onSendMessage={handleSendMessage}
      refetchMessages={refetchRequestChatRoomMessages}
    />
  );
};

export default RecruitmentOpportunityChatContent;
