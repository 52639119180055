import { useCallback, useEffect, useMemo, useState } from 'react';

import { PageLoader } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';

import { ChatRoom } from './components';
import { ChatListItemProps } from './components/ChatListItem';
import { ChatContent } from './components/ChatRoom';
import {
  useCreateRequestChatRoomMessage,
  useRequestChatRoomMessagesList,
} from './hooks';
import useRequestChatRoomsList from './hooks/chat-room/useRequestChatRoomsList';

type RequestChatContentProps = {
  requestId: string;
  accountId: string;
  title: string;
  subtitle: string;
};

const RequestChatContent = ({
  requestId,
  accountId,
  title,
  subtitle,
}: RequestChatContentProps) => {
  const [selectedChatRoomId, setSelectedChatRoomId] = useState<string>('');

  const { createRequestChatRoomMessage } = useCreateRequestChatRoomMessage();

  const {
    requestChatRooms,
    isLoading: requestChatRoomsIsLoading,
    refetchRequestChatRooms,
  } = useRequestChatRoomsList({
    requestId,
    enterpriseAccountId: accountId,
  });

  const {
    requestChatRoomMessages,
    isLoading: requestChatRoomMessagesIsLoading,
    refetchRequestChatRoomMessages,
  } = useRequestChatRoomMessagesList({ requestChatRoomId: selectedChatRoomId });

  const [
    chatListUnderNegotiation,
    chatListNotUnderNegotiation,
    chatListNoOffer,
    chatListNoOfferNoMessage,
  ]: [
    ChatListItemProps[],
    ChatListItemProps[],
    ChatListItemProps[],
    ChatListItemProps[],
  ] = useMemo(() => {
    const underNegotiation: ChatListItemProps[] = [];
    const notUnderNegotiation: ChatListItemProps[] = [];
    const noOffer: ChatListItemProps[] = [];
    const noOfferNoMessage: ChatListItemProps[] = [];

    requestChatRooms?.forEach((chatRoom) => {
      const chatItem: ChatListItemProps = {
        selected: selectedChatRoomId === chatRoom.id,
        rank: chatRoom.offerData?.rank ?? undefined,
        title: chatRoom.agencyName ?? '',
        preview: chatRoom.agencyLastMessage?.message ?? '',
        date: chatRoom.agencyLastMessage?.sentAt,
        newMessageCount: 0, // TODO for v2 get the count of unread message by the last read message date
        onClick: () => setSelectedChatRoomId(chatRoom.id),
      };

      if (chatRoom.offerData?.isUnderNegotiation) {
        underNegotiation.push(chatItem);
      } else if (chatRoom.offerData?.hasMadeOffer) {
        notUnderNegotiation.push(chatItem);
      } else if (chatRoom.agencyLastMessage) {
        noOffer.push(chatItem);
      } else {
        noOfferNoMessage.push(chatItem);
      }
    });

    return [underNegotiation, notUnderNegotiation, noOffer, noOfferNoMessage];
  }, [requestChatRooms, selectedChatRoomId]);

  const selectedChatRoom = useMemo(
    () =>
      requestChatRooms?.find((chatRoom) => chatRoom.id === selectedChatRoomId),
    [requestChatRooms, selectedChatRoomId]
  );

  const chatContent: ChatContent = useMemo(
    () => ({
      title: selectedChatRoom?.agencyName ?? '',
      messageList: requestChatRoomMessages?.map((message) => ({
        position:
          message.accountId === accountId
            ? ('right' as const)
            : ('left' as const),
        date: message.sentAt,
        viewed: true,
        content: message.message,
      })),
    }),
    [requestChatRoomMessages, accountId, selectedChatRoom?.agencyName]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createRequestChatRoomMessage({
        input: {
          requestChatRoomId: selectedChatRoomId,
          message,
        },
      });

      if (requestChatRoomMessages.length < 1) {
        refetchRequestChatRoomMessages();
      }
    },
    [
      createRequestChatRoomMessage,
      requestChatRoomMessages.length,
      refetchRequestChatRoomMessages,
      selectedChatRoomId,
    ]
  );

  const refetchChatRoomsAndMessages = useCallback(async () => {
    refetchRequestChatRooms();
    refetchRequestChatRoomMessages();
  }, [refetchRequestChatRooms, refetchRequestChatRoomMessages]);

  useEffect(() => {
    if (requestChatRooms?.length && !selectedChatRoomId) {
      setSelectedChatRoomId(requestChatRooms[0].id);
    }
  }, [requestChatRooms, selectedChatRoomId]);

  return requestChatRoomsIsLoading ? (
    <PageLoader />
  ) : (
    <ChatRoom
      isLoading={requestChatRoomMessagesIsLoading}
      title={title}
      subtitle={subtitle}
      chatListUnderNegotiation={chatListUnderNegotiation}
      chatListNotUnderNegotiation={chatListNotUnderNegotiation}
      chatListNoOffer={chatListNoOffer}
      chatListNoOfferNoMessage={chatListNoOfferNoMessage}
      chatContent={chatContent}
      backTo={RootPrivatePage.REQUESTS}
      onSendMessage={handleSendMessage}
      refetchMessages={refetchChatRoomsAndMessages}
    />
  );
};

export default RequestChatContent;
