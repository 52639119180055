import { useMutation } from 'urql';

import {
  MutationRequestChatRoomMessageCreateArgs,
  RequestChatRoomMessageResponse,
} from '@domain/graphql.types';
import { RequestChatRoomMessageCreate } from '@infrastructure/graphql/chat';

const useRequestChatRoomMessageCreate = () => {
  const [{ data, fetching, error }, createRequestChatRoomMessage] = useMutation<
    RequestChatRoomMessageResponse,
    MutationRequestChatRoomMessageCreateArgs
  >(RequestChatRoomMessageCreate);

  return {
    createRequestChatRoomMessage,
    data,
    fetching,
    error,
  };
};

export default useRequestChatRoomMessageCreate;
