import { useTranslation } from 'react-i18next';

import { StatusTabs } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { RecruitmentOpportunityType } from '@domain/graphql.types';

import RecruitmentOpportunitiesList from './RecruitmentOpportunitiesList';

const RecruitmentOpportunitiesListPage = () => {
  const { t } = useTranslation('recruitment');

  return (
    <>
      <div className="flex w-full justify-between mb-s-32">
        <StatusTabs
          t={t}
          type="recruitment-opportunity"
          mainPath={RootPrivatePage.RECRUITMENT_OPPORTUNITIES}
          statuses={[
            RecruitmentOpportunityType.InstantApplication,
            RecruitmentOpportunityType.ServiceRequest,
          ]}
        />
      </div>

      <RecruitmentOpportunitiesList />
    </>
  );
};

export default RecruitmentOpportunitiesListPage;
