import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { PageLoader } from '@application/components';
import { AccountContext } from '@application/context';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { useGetRequest } from '../recruitment/request/hooks';
import RequestChatContent from './RequestChatContent';

const RequestChat = () => {
  const { account } = useContext(AccountContext);
  const { id = '' } = useParams();
  const { i18n } = useTranslation();

  const {
    viewModel: { data: requestData, isLoading: isRequestLoading },
  } = useGetRequest(id);

  return !isRequestLoading && requestData && account ? (
    <RequestChatContent
      requestId={id}
      accountId={account.id}
      title={
        getLocalizedDescription(
          requestData.jobSpecialty?.descriptions,
          i18n.language
        ) ?? ''
      }
      subtitle={requestData.operationUnit?.name ?? ''}
    />
  ) : (
    <PageLoader />
  );
};

export default RequestChat;
