import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import { CandidateOpportunityListFragment } from './fragment';

const CandidateOpportunities = gql`
  ${CandidateOpportunityListFragment}

  query CandidateOpportunities(
    $filterBy: CandidateOpportunitiesFilters,
    $sortBy: [CandidateOpportunitiesSortDirective!],
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    candidateOpportunities(
      filterBy: $filterBy,
      sortBy: $sortBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['CandidateOpportunityListFragment'])}
  }
`;

export default CandidateOpportunities;
