import * as yup from 'yup';
import 'yup-phone-lite';

import {
  AccountIndustryType,
  AccountTypeCode,
  ServiceType,
} from '@domain/graphql.types';
import { URL_REGEX } from '@utils/yup-utils';

export const CREATE_ACCOUNT_SCHEMA = yup.object({
  name: yup
    .string()
    .max(128, 'validations.maxLength')
    .required('validations.required.name'),
  type: yup.mixed<AccountTypeCode>().oneOf(Object.values(AccountTypeCode)),
  website: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .matches(URL_REGEX, 'validations.url'),
  address: yup
    .string()
    .nullable()
    .max(255, 'validations.maxLength')
    .required('validations.required.address'),
  phoneNumber: yup.string().nullable().phone('CA', 'validations.phone'),
  industryTypeCode: yup
    .mixed<AccountIndustryType>()
    .oneOf(Object.values(AccountIndustryType))
    .when('type', ([type], schema) =>
      type !== AccountTypeCode.Agency
        ? schema.required('validations.required.industryType')
        : schema.nullable()
    ),
  sectorCode: yup
    .string()
    .when('type', ([type], schema) =>
      type !== AccountTypeCode.Agency
        ? schema.required('validations.required.sectorCode')
        : schema.nullable()
    ),
  services: yup
    .array(yup.mixed<ServiceType>().oneOf(Object.values(ServiceType)))
    .nullable()
    .test({
      name: 'requiredServices',
      message: 'validations.required.services',
      test(value) {
        if (this.parent.type !== AccountTypeCode.Agency) {
          return true;
        }
        return !!value && value.length >= 1;
      },
    }),
  termsAndConditionsAccepted: yup
    .boolean()
    .required('validations.required.termsAndConditions'),
});

export type CreateAccountFormFields = yup.InferType<
  typeof CREATE_ACCOUNT_SCHEMA
>;
