import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DisplayButton,
  FormInputWithSuffix,
  SectionGrid,
  SectionGridRow,
} from '@application/components';

import { RequestFormFields } from '../../schema';

type HeadHunterFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const HeadHunterFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
}: HeadHunterFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const proposeCandidates = watch('conditionCandidateInHandMandatoryValue');

  return (
    <SectionGrid title={t('subtitles.forHeadHunting')} className="mt-s-40">
      <SectionGridRow
        isTopRow
        rightChildren={
          <DisplayButton
            name="headhunterConditionMaximumReplacementDisplay"
            control={control}
            setValue={setValue}
            watch={watch}
          />
        }
      >
        <FormInputWithSuffix
          label={t('labels.maxReplacements')}
          type="number"
          minValue={1}
          noMargin
          invalid={!!errors.headhunterConditionMaximumReplacementValue}
          helperText={
            errors.headhunterConditionMaximumReplacementValue?.message &&
            tGlobal(
              errors.headhunterConditionMaximumReplacementValue?.message,
              {
                min: '1',
              }
            )
          }
          {...register('headhunterConditionMaximumReplacementValue')}
        />
      </SectionGridRow>
      {!proposeCandidates && (
        <SectionGridRow
          rightChildren={
            <DisplayButton
              name="headhunterConditionExclusivityPeriodDisplay"
              control={control}
              setValue={setValue}
              watch={watch}
            />
          }
        >
          <FormInputWithSuffix
            label={t('labels.exclusivityPeriod')}
            suffix={t('suffix.weeks')}
            type="number"
            mediumSuffix
            noMargin
            minValue={1}
            invalid={!!errors.headhunterConditionExclusivityPeriodValue}
            helperText={
              errors.headhunterConditionExclusivityPeriodValue?.message &&
              tGlobal(
                errors.headhunterConditionExclusivityPeriodValue?.message,
                {
                  min: '1',
                }
              )
            }
            {...register('headhunterConditionExclusivityPeriodValue')}
          />
        </SectionGridRow>
      )}
      {proposeCandidates}
    </SectionGrid>
  );
};

export default HeadHunterFields;
