import { useQuery } from 'urql';

import { Query, QueryRequestChatRoomsArgs } from '@domain/graphql.types';
import { RequestChatRooms } from '@infrastructure/graphql/chat';

const useRequestChatRooms = (input: QueryRequestChatRoomsArgs) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'requestChatRooms'>,
    QueryRequestChatRoomsArgs
  >({
    query: RequestChatRooms,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useRequestChatRooms;
