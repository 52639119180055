import { gql } from 'urql';

import { AccountFragment } from '../account';
import {
  OfferGeneralConditionsFragment,
  OfferHeadhunterConditionsFragment,
  OfferInternationalRecruitmentConditionsFragment,
  OfferOutsourcingConditionsFragment,
  OfferTemporaryPlacementConditionsFragment,
} from './conditions';

const NegotiatingOfferFragment = gql`
  ${AccountFragment}
  ${OfferOutsourcingConditionsFragment}
  ${OfferInternationalRecruitmentConditionsFragment}
  ${OfferTemporaryPlacementConditionsFragment}
  ${OfferHeadhunterConditionsFragment}
  ${OfferGeneralConditionsFragment}

  fragment NegotiatingOfferFragment on Offer {
    id
    accountId
    requestId
    userId
    status
    requestTypes
    matchingScore
    totalCosts
    totalCostsMin
    totalCostsMax
    rank
    negotiationRoomId
    generalContractualConditions {
      ...OfferGeneralConditionsFragment
    }
    headhunterContractualConditions {
      ...OfferHeadhunterConditionsFragment
    }
    temporaryPlacementContractualConditions {
      ...OfferTemporaryPlacementConditionsFragment
    }
    internationalRecruitmentContractualConditions {
      ...OfferInternationalRecruitmentConditionsFragment
    }
    outsourcingContractualConditions {
      ...OfferOutsourcingConditionsFragment
    }
    createdAt
    updatedAt
    matchingScore
    account {
      ...AccountFragment
    }
    availableTransitions
    user {
      name
      email
    }
  }
`;

export default NegotiatingOfferFragment;
