import { useCallback, useContext, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { AccountContext } from '@application/context';
import {
  AGENCY_TO_ENTERPRISE_ROUTES,
  ENTERPRISE_TO_AGENCY_ROUTES,
  PrivatePage,
} from '@application/enums/pagesUrl';
import { AccountTypeCode } from '@domain/graphql.types';

import { PageLoader } from '../page';

type AccountTypeGuardProps = {
  children: React.ReactNode;
  authorizedAccountType: AccountTypeCode;
};

const AccountTypeGuard = ({
  children,
  authorizedAccountType,
}: AccountTypeGuardProps) => {
  const { account, isLoading } = useContext(AccountContext);
  const navigate = useNavigate();
  const location = useLocation();

  const getRedirectUrl = useCallback(
    (routesMap: Record<string, string>): string | null => {
      const routeToSwitch = Object.entries(routesMap).find((route) =>
        matchPath({ path: route[0], end: true }, location.pathname)
      );

      if (routeToSwitch) {
        const [sourcePath, targetPath] = routeToSwitch;
        const path = matchPath(
          { path: sourcePath, end: true },
          location.pathname
        );

        if (path && path.params) {
          return Object.entries(path.params).reduce(
            (updatedPath, [paramKey, paramValue]) => {
              if (paramValue === undefined) {
                throw new Error(
                  `Parameter ${paramKey} is undefined in the URL.`
                );
              }
              return updatedPath.replace(`:${paramKey}`, paramValue);
            },
            targetPath
          );
        }
      }

      return null;
    },
    [location.pathname]
  );

  useEffect(() => {
    let switchedUrl;

    if (account?.type === AccountTypeCode.Agency) {
      switchedUrl = getRedirectUrl(ENTERPRISE_TO_AGENCY_ROUTES);
    }

    if (account?.type === AccountTypeCode.Enterprise) {
      switchedUrl = getRedirectUrl(AGENCY_TO_ENTERPRISE_ROUTES);
    }

    if (switchedUrl) {
      navigate(switchedUrl);
    } else if (!isLoading && account?.type !== authorizedAccountType) {
      navigate(PrivatePage.DASHBOARD);
    }
  }, [
    account?.type,
    authorizedAccountType,
    getRedirectUrl,
    navigate,
    isLoading,
  ]);

  // cast as JSX element to remove type error in router file
  return isLoading ? <PageLoader /> : (children as React.JSX.Element);
};

export default AccountTypeGuard;
