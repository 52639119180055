import { gql } from '@urql/core';

import { CandidateOpportunityMatchingRequestListFragment } from '../candidate/opportunity/fragment';

const MutationSaveAndPublishRequest = gql`
  ${CandidateOpportunityMatchingRequestListFragment}

  mutation SaveAndPublish(
    $input: RequestUpdateInput!
    $statusInput: RequestStatusUpdate!
  ) {
    requestStatusUpdate(requestStatusUpdateInput: $statusInput) {
      request {
        id
      }
    }
    requestUpdate(requestUpdateInput: $input) {
      request {
        id
        matchingCandidateOpportunities {
          ...CandidateOpportunityMatchingRequestListFragment
        }
      }
    }
  }
`;

export default MutationSaveAndPublishRequest;
