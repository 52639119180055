import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@application/components';

import useGetSubscriptionPromo from './hooks/useGetSubscriptionPromo';

export type PromoModalProps = {
  closeModal: () => void;
};

const PromoModal = ({ closeModal }: PromoModalProps) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'topnav.promo',
  });

  const { fetching, getSubscriptionPromo } = useGetSubscriptionPromo();

  const handleButtonClick = async () => {
    const response = await getSubscriptionPromo({
      input: { language: i18n.language },
    });

    if (response.data?.getSubscriptionPromo.url) {
      closeModal();
      window.open(response.data.getSubscriptionPromo.url, '_blank');
    }
  };

  return (
    <>
      <Modal.CloseBtn onClick={closeModal} />
      <div className="mb-s-8 text-18">{t('modalContent')}</div>
      <div className="text-center text-14 mt-s-32">
        {t('modalPricing')}{' '}
        <div className="relative inline-block text-18">
          <span
            style={{ top: '-18px' }}
            className="block font-bold absolute ml-s-16"
          >
            1495$
          </span>
          <span className="block font-normal line-through">2988$</span>
        </div>
        &nbsp;{t('modalPricingLapse')}
      </div>
      <Modal.Action>
        <Button onClick={closeModal}>{t('modalCancel')}</Button>
        <Button
          loading={fetching}
          primary
          onClick={handleButtonClick}
          disabled={fetching}
        >
          {t('modalConfirm')}
        </Button>
      </Modal.Action>
    </>
  );
};

export default PromoModal;
