import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { AccountContext, AlertContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { useCandidateOfferCreate } from '@domain/candidate/offer';
import { OfferStatusTypeCode } from '@domain/graphql.types';

import { toViewModel } from '../view-models/create-candidate-offer.vm';

const useCreateCandidateOffer = () => {
  const { data, fetching, error, createCandidateOffer } =
    useCandidateOfferCreate();

  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const navigate = useNavigate();
  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const viewModel = toViewModel({ candidateOffer: { data, fetching, error } });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('createOfferError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateOfferCreate?.candidateOffer) {
      setAlertContent({
        action: t('createOfferSuccess'),
        modifier: 'alert-success',
      });

      if (
        data?.candidateOfferCreate?.candidateOffer.status ===
        OfferStatusTypeCode.Published
      ) {
        refreshAccount();
      }

      navigate(
        generatePath(PrivatePage.VIEW_CANDIDATE_OFFER, {
          id: data.candidateOfferCreate.candidateOffer.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { createCandidateOffer, viewModel };
};

export default useCreateCandidateOffer;
