import { gql } from 'urql';

const AccountListFragment = gql`
  fragment AccountListFragment on Account {
    id
    ownerId
    customerId
    subscriptionId
    subscriptionType
    subscriptionStatus
    subscriptionTrialEnd
    logoUrl
    name
    type
  }
`;

export default AccountListFragment;
