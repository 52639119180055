import { ChangeEvent, useEffect, useState } from 'react';
import { validate as isUUID } from 'uuid';

import { debounce } from '@application/utils';
import { AccountsFilters } from '@domain/graphql.types';

const defaultFilters = {
  id: undefined,
  text: undefined,
};

type UseAccountsFiltersProps = {
  resetPagination?: () => void;
};

const useAccountsFilters = ({ resetPagination }: UseAccountsFiltersProps) => {
  const [filters, setFilters] =
    useState<Omit<AccountsFilters, 'type'>>(defaultFilters);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateTextFilter = debounce((event: ChangeEvent<HTMLInputElement>) => {
    if (
      isUUID(event.target.value) ||
      event.target.value.startsWith('cus_') ||
      event.target.value.startsWith('sub_')
    ) {
      setFilters(() => ({
        id: event.target.value,
      }));
    } else {
      setFilters(() => ({
        text: event.target.value,
      }));
    }
  });

  return {
    filters,
    updateTextFilter,
  };
};

export default useAccountsFilters;
