import { gql } from '@urql/core';

import AccountUserFragment from './account.user.fragment';

const AccountUserUpdateStatus = gql`
  ${AccountUserFragment}

  mutation AccountUserUpdateStatus($input: UpdateAccountUserStatusInput!) {
    accountUserUpdateStatus(input: $input) {
      accountUser {
        ...AccountUserFragment
      }
    }
  }
`;

export default AccountUserUpdateStatus;
