import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import type { i18n, TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, IconButton } from '@application/components';
import { usePagination } from '@application/hooks';
import { useExecuteImpersonateUser } from '@application/views/user/information/hooks';
import {
  Account,
  AccountsFilters,
  AccountsSortBy,
  AccountsSortDirective,
  SortDirection,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import { formatDate } from '@utils/date-utils';

import useGetAccounts from './useGetAccounts';

const columnIds = {
  logoUrl: 'logoUrl',
  name: 'name',
  subscriptionType: 'subscriptionType',
  subscriptionStatus: 'subscriptionStatus',
  subscriptionTrialEnd: 'subscriptionTrialEnd',
  actions: 'actions',
} as const;

const stringSortToEnum = (by: string): AccountsSortBy => {
  switch (by) {
    case columnIds.subscriptionType:
      return AccountsSortBy.SubscriptionType;
    case columnIds.subscriptionStatus:
      return AccountsSortBy.SubscriptionStatus;
    case columnIds.subscriptionTrialEnd:
      return AccountsSortBy.SubscriptionTrialEnd;
    default:
      return AccountsSortBy.Name;
  }
};

const mapSorting = (sorting: SortingState): AccountsSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id),
  }));

const columnHelper = createColumnHelper<Account>();

const getColumns = (
  handleImpersonateClick: (ownerAccountId: string) => void,
  tGlobal: TFunction,
  t: TFunction,
  i18n: i18n
): ColumnDef<Account, any>[] => [
  columnHelper.accessor((row) => row.logoUrl, {
    id: columnIds.logoUrl,
    cell: (info) => (
      <Avatar
        size="small"
        name={info.row.original.name}
        url={info.row.original.logoUrl}
      />
    ),
    header: () => '',
    minSize: 40,
    maxSize: 40,
    size: 40,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.name, {
    id: columnIds.name,
    cell: (info) => info.getValue(),
    header: () => t('accountName'),
    minSize: 200,
    size: 1000,
  }),
  columnHelper.accessor((row) => row.subscriptionType, {
    id: columnIds.subscriptionType,
    cell: (info) =>
      info.row.original.subscriptionType
        ? tGlobal(`membership.${info.row.original.subscriptionType}`)
        : '-',
    header: () => t('subscriptionType'),
    minSize: 200,
    size: 1000,
  }),
  columnHelper.accessor((row) => row.subscriptionStatus, {
    id: columnIds.subscriptionStatus,
    cell: (info) =>
      info.row.original.subscriptionStatus
        ? tGlobal(`subscriptionStatus.${info.row.original.subscriptionStatus}`)
        : '-',
    header: () => t('subscriptionStatus'),
    minSize: 150,
    size: 1000,
  }),
  columnHelper.accessor((row) => row.subscriptionTrialEnd, {
    id: columnIds.subscriptionTrialEnd,
    cell: (info) =>
      info.row.original.subscriptionTrialEnd
        ? formatDate(
            info.row.original.subscriptionTrialEnd,
            i18n.language,
            'long'
          )
        : '-',
    header: () => t('subscriptionTrialEnd'),
    minSize: 200,
    size: 1000,
  }),
  columnHelper.accessor((row) => row.id, {
    id: columnIds.actions,
    cell: (info) => (
      <IconButton
        className="ml-auto"
        text={t('button.close')}
        icon={<i className="ri-p2p-line text-24" />}
        onClick={() => handleImpersonateClick(info.row.original.ownerId)}
        radius="square"
        size="x-small"
      />
    ),
    header: () => <span className="block text-right">{t('action')}</span>,
    minSize: 90,
    maxSize: 90,
    size: 90,
    enableSorting: false,
  }),
];

type AccountsList = {
  columnIds: typeof columnIds;
  columns: ColumnDef<Account>[];
  count?: number;
  currentPage?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  accounts: Account[];
  isLoading: boolean;
};

export const useAccountsList = (filters: AccountsFilters): AccountsList => {
  const { executeImpersonateUser } = useExecuteImpersonateUser();
  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();
  const [sorting, setSorting] = useState<SortingState>([
    { id: columnIds.name, desc: true },
  ]);
  const {
    viewModel: { data, isLoading },
  } = useGetAccounts(mapSorting(sorting), filters, pagination);
  const { t: tGlobal, i18n } = useTranslation();
  const { t } = useTranslation('organization', {
    keyPrefix: 'superAdmin',
  });

  const handleImpersonateClick = async (ownerAccountId: string) => {
    executeImpersonateUser(ownerAccountId);
  };

  const columns = getColumns(handleImpersonateClick, tGlobal, t, i18n);

  useEffect(() => {
    setCursors({
      startCursor: data?.accounts.page.pageInfo?.startCursor || undefined,
      endCursor: data?.accounts.page.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.accounts.page.pageInfo]);

  useEffect(() => {
    resetPagination();
  }, [sorting, resetPagination]);

  return {
    columnIds,
    columns: columns as ColumnDef<Account>[],
    count: data?.accounts.pageData?.count,
    currentPage,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    accounts: flattenEdges(data?.accounts.page.edges?.slice() || []),
    sorting,
    onSortingChange: setSorting,
    isLoading,
  };
};
