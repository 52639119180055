import { gql } from 'urql';

const MutationRequestChatRoomCreate = gql`
  mutation RequestChatRoomCreate($input: RequestChatRoomCreateInput!) {
    requestChatRoomCreate(input: $input) {
      chatRoom {
        id
      }
    }
  }
`;

export default MutationRequestChatRoomCreate;
