import { gql } from 'urql';

const ImpersonateUser = gql`
  mutation ImpersonateUser($input: ImpersonateUserInput!) {
    impersonateUser(input: $input) {
      user {
        id
        impersonatingUserId
      }
    }
  }
`;

export default ImpersonateUser;
