import { useTranslation } from 'react-i18next';

type DateSeparatorProps = {
  type: 'yesterday' | 'today';
};

const DateSeparator = ({ type }: DateSeparatorProps) => {
  const { t } = useTranslation('chatRoom');

  const classMainWrapper = 'border-t mt-s-16';
  const classText =
    'mx-auto mt-[-1rem] mb-s-16 w-[max-content] h-s-32 flex items-center rounded-sm px-s-32 text-14 border bg-[#ffffff]';

  return (
    <div className={classMainWrapper}>
      <div className={classText}>{t(`dateSeparator.${type}`)}</div>
    </div>
  );
};

export default DateSeparator;
