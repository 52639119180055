import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Fieldset,
  TextAreaField,
  TextInputField,
} from '@application/components';
import {
  Box,
  Stack,
  Switcher,
} from '@application/components/container-layouts';
import { AddressAutocomplete } from '@application/components/form/address-autocomplete';
import { SwitchField } from '@application/components/form/switch-field';
import { OptionType } from '@application/components/select-field/select';
import { AccountTypeCode } from '@domain/graphql.types';

import { OrganizationProfileFormFields } from '../../types';

type ContactFieldsProps = {
  control: Control<OrganizationProfileFormFields>;
  errors: FieldErrors<OrganizationProfileFormFields>;
  register: UseFormRegister<OrganizationProfileFormFields>;
  watch: UseFormWatch<OrganizationProfileFormFields>;
  disabled?: boolean;
};

const ContactFields = ({
  control,
  register,
  watch,
  errors,
  disabled = false,
}: ContactFieldsProps) => {
  const { t } = useTranslation('organization');
  const { t: tGlobal } = useTranslation();

  const type = watch('type');

  return (
    <Box>
      <Fieldset legend="contact" hideLegend>
        <Stack space={24}>
          <Switcher space={24}>
            <TextInputField
              disabled={disabled}
              maxChar={128}
              label={t('labels.name')}
              invalid={!!errors.name}
              helperText={
                errors.name?.message &&
                tGlobal(errors.name?.message, {
                  max: '128',
                })
              }
              {...register('name')}
            />

            <TextInputField
              maxChar={128}
              label={t('labels.website')}
              invalid={!!errors.website}
              helperText={
                errors.website?.message &&
                tGlobal(errors.website?.message, {
                  max: '128',
                })
              }
              withIcon="ri-global-line"
              {...register('website')}
            />
          </Switcher>

          <Switcher space={24}>
            <Controller
              name="address"
              control={control}
              render={({
                field: { onBlur, onChange, name, value },
                fieldState: { error },
              }) =>
                disabled ? (
                  <TextInputField
                    disabled={disabled}
                    maxChar={128}
                    label={t('labels.address')}
                    invalid={!!error}
                    value={value}
                    helperText={
                      errors.address?.message &&
                      tGlobal(errors.address?.message, {
                        max: '128',
                      })
                    }
                    {...register('address')}
                  />
                ) : (
                  <AddressAutocomplete
                    className="flex-grow"
                    label={t('labels.address')}
                    name={name}
                    value={value}
                    invalid={!!error}
                    helperText={
                      error?.message && tGlobal(error?.message, { max: '255' })
                    }
                    onChange={(option: OptionType) => onChange(option?.label)}
                    onBlur={onBlur}
                  />
                )
              }
            />

            <div>
              <TextInputField
                disabled={disabled}
                type="tel"
                label={t('labels.phoneNumber')}
                invalid={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber?.message &&
                  tGlobal(errors.phoneNumber?.message)
                }
                {...register('phoneNumber')}
              />

              <SwitchField
                disabled={disabled}
                className="mt-s-8"
                label={t('labels.phoneDisplay')}
                {...register('phoneDisplay')}
              />
            </div>
          </Switcher>

          <Controller
            name="employerOfferDescription"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <TextAreaField
                disabled={disabled}
                name={name}
                value={value || ''}
                maxChar={3000}
                rows={5}
                label={
                  type === AccountTypeCode.Agency
                    ? t(
                        'updateProfileForm.generalInformation.labels.agencyDescription'
                      )
                    : t(
                        'updateProfileForm.generalInformation.labels.anonymizedCompanyDescription'
                      )
                }
                onChange={onChange}
                invalid={!!errors.employerOfferDescription}
                helperText={
                  errors.employerOfferDescription?.message &&
                  tGlobal(errors.employerOfferDescription?.message, {
                    max: '3000',
                    min: '500',
                  })
                }
              />
            )}
          />
        </Stack>
      </Fieldset>
    </Box>
  );
};
export default ContactFields;
