import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { Stack } from '@application/components/container-layouts';

import { OrganizationProfileFormFields } from '../../types';
import Contact from './ContactFields';
import IndustryEmployees from './IndustryEmployeesFields';
import Perks from './PerksFields';

type GeneralInformationFieldsProps = {
  control: Control<OrganizationProfileFormFields>;
  errors: FieldErrors<OrganizationProfileFormFields>;
  register: UseFormRegister<OrganizationProfileFormFields>;
  setValue: UseFormSetValue<OrganizationProfileFormFields>;
  watch: UseFormWatch<OrganizationProfileFormFields>;
  disabled?: boolean;
};

const GeneralInformationFields = ({
  control,
  setValue,
  watch,
  register,
  errors,
  disabled = false,
}: GeneralInformationFieldsProps) => (
  <Stack>
    <Contact
      control={control}
      register={register}
      watch={watch}
      errors={errors}
      disabled={disabled}
    />

    <Perks
      control={control}
      setValue={setValue}
      watch={watch}
      disabled={disabled}
    />

    <IndustryEmployees
      control={control}
      disabled={disabled}
      setValue={setValue}
      watch={watch}
    />
  </Stack>
);

export default GeneralInformationFields;
