import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@application/components';
import { UserRoleGuard } from '@application/components/guards';
import { Table } from '@application/components/table';
import { usePremiumInviteUserValidation } from '@application/views/billing';
import { UserRole } from '@domain/graphql.types';

import useInviteUserModal from '../hooks/useInviteUserModal';
import { useUsersList } from '../hooks/useUsersList';

const UsersList = () => {
  const { t } = useTranslation('organization', { keyPrefix: 'users' });

  const { columns, users, sorting, onSortingChange, isLoading } =
    useUsersList();

  const { openModal } = useInviteUserModal();
  const { canInviteUser, allowedSeats } = usePremiumInviteUserValidation();

  const inviteButton = useMemo(
    () => (
      <Button
        className="w-fit"
        primary
        icon={<i className="ri-user-add-line" />}
        onClick={openModal}
        disabled={!canInviteUser}
      >
        {t('createInvitation')}
      </Button>
    ),
    [canInviteUser, openModal, t]
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-between mb-s-24 gap-s-24">
        <div className="flex flex-col gap-s-12">
          <h2 className="h3">{t('members')}</h2>

          <span className="text-16 text-neutral-secondary">
            {t('inviteMembers')}
          </span>
        </div>
        <UserRoleGuard
          authorizedUserRoles={[
            UserRole.SuperAdmin,
            UserRole.Owner,
            UserRole.Admin,
          ]}
        >
          {!canInviteUser ? (
            <Tooltip
              message={t('premiumLimitReached', {
                allowedSeats,
              })}
            >
              {inviteButton}
            </Tooltip>
          ) : (
            inviteButton
          )}
        </UserRoleGuard>
      </div>

      <div data-testid="TEST-users-table">
        <Table
          data={users}
          columns={columns}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          rowHeight="tall"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default UsersList;
