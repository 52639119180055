import { useMemo } from 'react';

import { cn } from '@utils/lib-utils';

export type ModalTitleProps = {
  children: React.ReactNode | null;
  id: string;
  className?: string;
  modifier?: 'error' | 'warning' | 'info' | 'success';
};

const ModalTitle = ({
  id,
  children,
  className: customClassName,
  modifier,
}: ModalTitleProps) => {
  const titleClassName = cn('text-22 font-semibold', customClassName);

  const params = useMemo(() => {
    switch (modifier) {
      case 'success':
        return {
          classNames: 'alert-success',
          icon: <i className="ri-checkbox-circle-fill" />,
          iconColor: 'text-accent',
        };
      case 'info':
        return {
          classNames: 'alert-info',
          icon: <i className="ri-information-fill" />,
          iconColor: 'text-info-content',
        };
      case 'warning':
        return {
          classNames: 'alert-warning',
          icon: <i className="ri-error-warning-fill" />,
          iconColor: 'text-warning-content',
        };
      case 'error':
        return {
          classNames: 'alert-error',
          icon: <i className="ri-error-warning-fill" />,
          iconColor: 'text-error-content',
        };
      default:
        return null;
    }
  }, [modifier]);

  return modifier ? (
    <div
      className={cn(
        'flex items-center gap-s-16 mr-s-32 mb-s-24',
        params?.classNames
      )}
    >
      <span className={cn('text-32', params?.iconColor)}>{params?.icon}</span>
      <h2 id={id} className={titleClassName}>
        {children}
      </h2>
    </div>
  ) : (
    <h2 id={id} className={cn('mr-s-32 mb-s-24', titleClassName)}>
      {children}
    </h2>
  );
};

export default ModalTitle;
