import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import {
  ConditionRemunerationType,
  JobPaymentFrequencyCode,
  Offer,
  OfferGeneralConditions,
  Request,
  RequestGeneralConditions,
} from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type GeneralConditionsProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const GENERAL_CONDITIONS = [
  {
    key: 'maxRecruitmentPeriod',
    label: 'maxRecruitmentPeriod',
    valueType: 'weeks',
  },
  {
    key: 'probationPeriod',
    label: 'probationPeriod',
    valueType: 'weeks',
  },
  {
    key: 'paymentMethod',
    label: 'paymentMethod',
    isRequestShort: true,
    valueType: 'text',
    children: {
      key: 'paymentFrequency',
      label: 'paymentFrequency',
      valueType: 'frequency',
    },
  },
  {
    key: 'deposit',
    label: 'depositPayment',
    valueType: 'currency',
  },
];

const GeneralConditions = ({ data, request }: GeneralConditionsProps) => {
  const { t, i18n } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tGlobal } = useTranslation();

  const conditions = data?.generalContractualConditions;
  const requestConditions = request?.generalContractualConditions;

  const displayHourlyRate = useMemo(() => {
    const {
      remunerationHourlyRate: rate,
      remunerationEstimatedHoursMin: hoursMin,
      remunerationEstimatedHoursMax: hoursMax,
    } = conditions || {};

    if (!rate || !hoursMin || !hoursMax) {
      return t('labels.remunerationType.hourlyRateOnly');
    }

    return hoursMin !== hoursMax
      ? t('labels.remunerationType.hourlyRate', { rate, hoursMin, hoursMax })
      : t('labels.remunerationType.hourlyRateMinMax', {
          rate,
          hours: hoursMin,
        });
  }, [conditions, t]);

  const remuneration = useMemo(() => {
    switch (conditions?.remunerationType) {
      case ConditionRemunerationType.AnnualSalaryPercentage:
        return conditions?.remunerationPercentage
          ? t('labels.remunerationType.salaryPercentage', {
              amount: conditions.remunerationPercentage,
            })
          : t('labels.remunerationType.salaryPercentageOnly');
      case ConditionRemunerationType.FixedAmount:
        return conditions?.remunerationAmount
          ? t('labels.remunerationType.fixedAmount', {
              amount: conditions.remunerationAmount,
            })
          : t('labels.remunerationType.fixedAmountOnly');
      case ConditionRemunerationType.HourlyRate:
        return displayHourlyRate;
      default:
        return '-';
    }
  }, [conditions, displayHourlyRate, t]);

  const opportunityRemuneration = useMemo(() => {
    if (request?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.Yearly) {
      return t('labels.perYear', {
        min: request.jobSalaryMin,
        max: request.jobSalaryMax,
      });
    }

    if (request?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.Hourly) {
      return t('labels.perHour', {
        min: request.jobHourlyRateMin,
        max: request.jobHourlyRateMax,
      });
    }

    if (
      request?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount
    ) {
      return t('labels.fixedAmount', {
        amount: request.jobRemunerationAmount,
      });
    }

    return '-';
  }, [request, t]);

  const getContentValue = useCallback(
    (
      type: string,
      value: string | number | boolean | null | undefined,
      isShort?: boolean
    ) => {
      const shortSuffix = isShort ? 'short.' : '';

      switch (type) {
        case 'weeks':
          return value ? t('suffix.week', { count: Number(value) }) : '-';
        case 'months':
          return value ? t('suffix.month', { count: Number(value) }) : '-';
        case 'text':
          return value
            ? t(
                `enum.requestConditionPaymentMethodCode.${shortSuffix}${
                  value.toString().toLowerCase() as string
                }`
              )
            : '-';
        case 'frequency':
          return value
            ? t(
                `enum.requestConditionPaymentFrequencyCode.${shortSuffix}${
                  value.toString().toLowerCase() as string
                }`
              )
            : '-';
        case 'currency':
          return value
            ? tGlobal('suffix.currencyValue', { value: Number(value) })
            : '-';
        case 'date':
          return value ? formatDate(String(value), i18n.language) : '-';
        default:
          return '-';
      }
    },
    [t, tGlobal, i18n.language]
  );

  return (
    <>
      {GENERAL_CONDITIONS.map(
        (
          condition: {
            key: string;
            label: string;
            valueType: string;
            isRequestShort?: boolean;
            children?: { key: string; label: string; valueType: string };
          },
          index
        ) =>
          (condition.key !== 'maxRecruitmentPeriod' ||
            !data?.candidates?.length) && (
            <SectionGridRow
              key={condition.key}
              isTopRow={
                index === 0 || (!!data?.candidates?.length && index === 1)
              }
              isOffer
              rightChildren={
                <PlaceholderSwitcher
                  value={
                    requestConditions?.[
                      `${condition.key}Value` as keyof RequestGeneralConditions
                    ]
                  }
                >
                  <div className="flex flex-col">
                    <span className="text-16 font-semibold text-primary">
                      {getContentValue(
                        condition.valueType,
                        requestConditions?.[
                          `${condition.key}Value` as keyof RequestGeneralConditions
                        ],
                        condition.isRequestShort
                      )}
                    </span>
                    {condition.children &&
                      requestConditions?.[
                        `${condition.children.key}Value` as keyof RequestGeneralConditions
                      ] && (
                        <div className="mr-s-8 mt-s-24">
                          <span className="text-16 font-semibold text-primary">
                            {getContentValue(
                              condition.children.valueType,
                              requestConditions?.[
                                `${condition.children.key}Value` as keyof RequestGeneralConditions
                              ]
                            )}
                          </span>
                        </div>
                      )}
                  </div>
                </PlaceholderSwitcher>
              }
            >
              <div className="flex flex-col">
                <span className="flex flex-wrap items-center">
                  <span className="mr-s-8">
                    {t(`labels.${condition.label}`)}
                  </span>

                  <PlaceholderSwitcher
                    value={
                      conditions?.[
                        condition.key as keyof OfferGeneralConditions
                      ]
                    }
                  >
                    <span className="text-16 font-semibold text-primary">
                      {getContentValue(
                        condition.valueType,
                        conditions?.[
                          condition.key as keyof OfferGeneralConditions
                        ]
                      )}
                    </span>
                  </PlaceholderSwitcher>
                </span>
                {condition.children &&
                  conditions?.[
                    `${condition.children.key}` as keyof OfferGeneralConditions
                  ] && (
                    <span className="flex flex-wrap mt-s-24 items-center">
                      <span className="mr-s-8">
                        {t(`labels.${condition.children.label}`)}
                      </span>
                      <PlaceholderSwitcher
                        value={
                          conditions?.[
                            condition.children
                              .key as keyof OfferGeneralConditions
                          ]
                        }
                      >
                        <span className="text-16 font-semibold text-primary">
                          {getContentValue(
                            condition.children?.valueType,
                            conditions?.[
                              condition.children
                                .key as keyof OfferGeneralConditions
                            ]
                          )}
                        </span>
                      </PlaceholderSwitcher>
                    </span>
                  )}
              </div>
            </SectionGridRow>
          )
      )}

      <SectionGridRow
        isOffer
        rightChildren={
          <span className="text-16 text-center font-semibold text-primary">
            {opportunityRemuneration}
          </span>
        }
      >
        <>
          <span className="mr-s-8">{t('labels.remuneration')}</span>

          <span className="text-16 font-semibold text-primary">
            {remuneration}
          </span>
        </>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <div className="flex flex-col mt-s-36">
            {requestConditions?.minGuaranteedPeriodValue ? (
              <span className="text-16 font-semibold text-primary">
                {`${requestConditions.minGuaranteedPeriodValue} ${t('suffix.months')}`}
              </span>
            ) : (
              '-'
            )}

            {requestConditions?.minGuaranteedPeriodBonusValue && (
              <div className="mt-s-24">
                <span className="text-16 font-semibold text-primary">
                  {tGlobal('suffix.currencyValue', {
                    value: requestConditions?.minGuaranteedPeriodBonusValue,
                  })}
                </span>
              </div>
            )}
          </div>
        }
      >
        <div className="flex flex-col">
          <span className="mr-s-8">{t('labels.additionalBonusTitle')}</span>

          <div className="mt-s-24">
            <span className="mr-s-8">{t('labels.minGuaranteedPeriod')}</span>

            <span className="text-16 font-semibold text-primary">
              {conditions?.minGuaranteedPeriod
                ? `${conditions.minGuaranteedPeriod} ${t('suffix.months')}`
                : '-'}
            </span>
          </div>

          <div className="mt-s-24">
            <span className="mr-s-8">
              {t('labels.minGuaranteedPeriodBonusValueOffer')}
            </span>
            {conditions?.minGuaranteedPeriodBonus ? (
              <span className="text-16 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: conditions?.minGuaranteedPeriodBonus,
                })}
              </span>
            ) : (
              <span>-</span>
            )}
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        partialBorderTop
        rightChildren={
          <div className="flex flex-col">
            <p className="mr-s-8">
              <span className="text-16 font-semibold text-primary">
                {requestConditions?.bonusMonthValue
                  ? `${requestConditions.bonusMonthValue} ${t('suffix.months')}`
                  : '-'}
              </span>
            </p>

            <p className="mr-s-8 mt-s-24">
              <span className="text-16 font-semibold text-primary">
                {requestConditions?.bonusValue
                  ? tGlobal('suffix.currencyValue', {
                      value: requestConditions.bonusValue,
                    })
                  : '-'}
              </span>
            </p>
          </div>
        }
      >
        <div className="flex flex-col">
          <p className="mr-s-8">
            {t('labels.ifCandidate')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.bonusMonth
                ? `${conditions.bonusMonth} ${t('suffix.months')}`
                : '-'}
            </span>
            <span className="ml-s-8">{t('labels.stayAfterHired')}</span>
          </p>

          <p className="mr-s-8 mt-s-24">
            {t('labels.additionalBonusRequested')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.bonus
                ? tGlobal('suffix.currencyValue', {
                    value: conditions?.bonus,
                  })
                : '-'}
            </span>
          </p>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        partialBorderTop
        rightChildren={
          <div className="flex flex-col">
            <p className="mr-s-8">
              <span className="text-16 font-semibold text-primary">
                {requestConditions?.hiredDateBonusRequirement
                  ? formatDate(
                      requestConditions.hiredDateBonusRequirement,
                      i18n.language
                    )
                  : '-'}
              </span>
            </p>

            <p className="mr-s-8 mt-s-24">
              <span className="text-16 font-semibold text-primary">
                {requestConditions?.hiredDateBonusValue
                  ? tGlobal('suffix.currencyValue', {
                      value: requestConditions.hiredDateBonusValue,
                    })
                  : '-'}
              </span>
            </p>
          </div>
        }
      >
        <div className="flex flex-col">
          <p className="mr-s-8">
            {t('labels.ifHiredBefore')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.hiredDateBonusRequirement
                ? formatDate(
                    conditions.hiredDateBonusRequirement,
                    i18n.language
                  )
                : '-'}
            </span>
          </p>

          <p className="mr-s-8 mt-s-24">
            {t('labels.additionalBonusRequested')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.hiredDateBonus
                ? tGlobal('suffix.currencyValue', {
                    value: conditions?.hiredDateBonus,
                  })
                : '-'}
            </span>
          </p>
        </div>
      </SectionGridRow>
    </>
  );
};

export default GeneralConditions;
