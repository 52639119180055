import { gql } from 'urql';

const AccountUserFragment = gql`
  fragment AccountUserFragment on AccountUser {
    id
    accountId
    userId
    role
    status
    createdAt
    lastUpdatedAt
    user {
      id
      name
      avatarUrl
      email
    }
    account {
      name
    }
  }
`;

export default AccountUserFragment;
