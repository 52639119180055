import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import { Button } from '@application/components';
import { Badge, BadgeList } from '@application/components/badge';
import { CandidateRequestTypeBadge } from '@application/components/request-type-badges';
import { PrivatePage } from '@application/enums/pagesUrl';
import { mapDescriptions } from '@application/utils';
import {
  CandidateOpportunity,
  OperationTerritory,
} from '@domain/graphql.types';
import { getHoursSince } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';

const columnIds = {
  jobSpecialty: 'jobSpecialty',
  experience: 'experience',
  jobAvailability: 'jobAvailability',
  type: 'type',
  operationTerritories: 'operationTerritories',
  action: 'action',
} as const;

const columnHelper = createColumnHelper<CandidateOpportunity>();

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>
) => {
  const columns: ColumnDef<CandidateOpportunity, any>[] = [
    columnHelper.accessor(
      (row) =>
        getLocalizedDescription(row.jobSpecialty?.descriptions, language),
      {
        id: columnIds.jobSpecialty,
        cell: (info) => (
          <div>
            {getHoursSince(info.row.original.createdAt) < 72 ? (
              <Badge value={t('column.new')} modifier="badge-info" />
            ) : undefined}

            <span className="ml-s-4">{info.getValue() || '-'}</span>
          </div>
        ),
        header: () => t('column.job'),
        size: 320,
        minSize: 320,
        maxSize: undefined,
        enableSorting: false,
      }
    ),
    columnHelper.accessor((row) => row.experience, {
      id: columnIds.experience,
      cell: (info) => (
        <span>
          {info.getValue()
            ? t('experience.yearsCount', { count: info.getValue() })
            : '-'}
        </span>
      ),
      header: () => t('column.experience'),
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.jobAvailability,
      cell: ({ row }) => {
        let availability = '-';

        if (
          row.original.fullTimeAvailability &&
          row.original.partTimeAvailability
        ) {
          availability = t('column.fullAndPartTime');
        } else if (row.original.fullTimeAvailability) {
          availability = t('column.fullTime');
        } else if (row.original.partTimeAvailability) {
          availability = t('column.partTime');
        }
        return <span>{availability}</span>;
      },
      header: () => t('column.availability'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.type, {
      id: columnIds.type,
      cell: (info) => <CandidateRequestTypeBadge type={info.getValue()} />,
      header: () => t('column.type'),
      size: 88,
      minSize: 88,
      maxSize: 88,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.operationTerritories, {
      id: columnIds.operationTerritories,
      cell: (info) => {
        const operationTerritories = mapDescriptions(
          info
            .getValue()
            ?.map((territory: OperationTerritory) => territory.descriptions) ||
            [],
          language
        );

        return <BadgeList values={operationTerritories} />;
      },
      header: () => t('column.operationTerritories'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.action,
      cell: (info) => (
        <Button
          onClick={() => {
            window.open(
              generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_DETAILS, {
                id: info.row.original.id,
              }),
              '_blank'
            );
          }}
          size="x-small"
          className="animate-none py-s-0 whitespace-nowrap mr-s-16"
        >
          {t('actions.makeOffer')}
        </Button>
      ),
      header: () => null,
      enableSorting: false,
      size: 180,
      minSize: 180,
      maxSize: 180,
    }),
  ];

  return columns;
};

type UseCandidateOpportunitiesMatchingRequestListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<CandidateOpportunity>[];
  count?: number;
  candidateOpportunitiesMatchingRequest: CandidateOpportunity[];
};

export const useCandidateOpportunitiesMatchingRequestList = (
  candidateOpportunitiesMatchingRequest: CandidateOpportunity[]
): UseCandidateOpportunitiesMatchingRequestListProps => {
  const { t, i18n } = useTranslation('candidates', { keyPrefix: 'list' });
  const columns = getColumns(i18n.language, t);

  return {
    columnIds,
    columns: columns as ColumnDef<CandidateOpportunity>[],
    count: candidateOpportunitiesMatchingRequest.length,
    candidateOpportunitiesMatchingRequest,
  };
};
