import { gql } from 'urql';

import NegotiatingOfferFragment from '@infrastructure/graphql/offer/negotiating.offer.fragment';
import PlainRequestFragment from '@infrastructure/graphql/request/fragment/plain.request.fragment';

import PlainOfferFragment from '../../../offer/plain.offer.fragment';
import RecruitmentNegotiationGeneralPropertiesStateFragment from './recruitment.negotiation.general.properties.state.fragment';
import RecruitmentNegotiationHeadhunterPropertiesStateFragment from './recruitment.negotiation.headhunter.properties.state.fragment';
import RecruitmentNegotiationInternationalRecruitmentPropertiesStateFragment from './recruitment.negotiation.international.recruitment.properties.state.fragment';
import RecruitmentNegotiationOutsourcingPropertiesStateFragment from './recruitment.negotiation.outsourcing.properties.state.fragment';
import RecruitmentNegotiationTemporaryPlacementPropertiesStateFragment from './recruitment.negotiation.temporary.placement.properties.state.fragment';

const RecruitmentNegotiationRoomFragment = gql`
  ${RecruitmentNegotiationGeneralPropertiesStateFragment}
  ${RecruitmentNegotiationHeadhunterPropertiesStateFragment}
  ${RecruitmentNegotiationTemporaryPlacementPropertiesStateFragment}
  ${RecruitmentNegotiationInternationalRecruitmentPropertiesStateFragment}
  ${RecruitmentNegotiationOutsourcingPropertiesStateFragment}
  ${NegotiatingOfferFragment}
  ${PlainOfferFragment}
  ${PlainRequestFragment}

  fragment RecruitmentNegotiationRoomFragment on RecruitmentNegotiationRoom {
    id
    recruitmentNegotiationId
    requestId
    requestAccountId
    requestUserId
    offerId
    offerAccountId
    offerUserId
    negotiatingOfferId
    status
    generalPropertiesState {
      ...RecruitmentNegotiationGeneralPropertiesStateFragment
    }
    headhunterPropertiesState {
      ...RecruitmentNegotiationHeadhunterPropertiesStateFragment
    }
    temporaryPlacementPropertiesState {
      ...RecruitmentNegotiationTemporaryPlacementPropertiesStateFragment
    }
    internationalRecruitmentPropertiesState {
      ...RecruitmentNegotiationInternationalRecruitmentPropertiesStateFragment
    }
    outsourcingPropertiesState {
      ...RecruitmentNegotiationOutsourcingPropertiesStateFragment
    }
    createdAt
    updatedAt
    negotiatingOffer {
      ...NegotiatingOfferFragment
    }
    offer {
      ...PlainOfferFragment
    }
    request {
      ...PlainRequestFragment
    }
  }
`;

export default RecruitmentNegotiationRoomFragment;
