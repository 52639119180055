import { OffersFilters, OffersSortDirective } from '@domain/graphql.types';
import { useOffers } from '@domain/offer';
import { Pagination } from '@domain/pagination/pagination';

import { toViewModel } from '../view-models/get-offers.vm';

const useGetOffers = (
  sortBy?: OffersSortDirective[],
  filterBy?: OffersFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } = useOffers({
    sortBy,
    filterBy,
    ...pagination,
  });

  const viewModel = toViewModel({
    offers: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchOffers: reexecuteQuery,
  };
};

export default useGetOffers;
