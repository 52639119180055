import { gql } from '@urql/core';

const MutationCandidateOfferStatusUpdate = gql`
  mutation CandidateOfferUpdateStatus(
    $input: CandidateOfferUpdateStatusInput!
  ) {
    candidateOfferUpdateStatus(input: $input) {
      candidateOffer {
        id
        status
      }
    }
  }
`;

export default MutationCandidateOfferStatusUpdate;
