import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import SummaryBadges from '@application/components/summary/SummaryBadges';
import SummaryDescription from '@application/components/summary/SummaryDescription';
import { JobPaymentFrequencyCode, Request } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

type RemunerationProps = {
  data: Request | undefined;
};

const Remuneration = ({ data }: RemunerationProps) => {
  const { t, i18n } = useTranslation('requests');

  const remuneration = useMemo(() => {
    if (data?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.Yearly) {
      return t('labels.perYear', {
        min: data.jobSalaryMin,
        max: data.jobSalaryMax,
      });
    }

    if (data?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.Hourly) {
      return t('labels.perHour', {
        min: data.jobHourlyRateMin,
        max: data.jobHourlyRateMax,
      });
    }

    if (data?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount) {
      return t('labels.fixedAmount', {
        amount: data.jobRemunerationAmount,
      });
    }

    return '-';
  }, [data, t]);

  const jobTypeOne = useMemo(() => {
    const duration = getLocalizedDescription(
      data?.jobDuration?.descriptions,
      i18n.language
    );

    const period = data?.jobDurationInMonths
      ? t('labels.forPeriod', { months: data.jobDurationInMonths })
      : '';

    return period ? `${duration} ${period}` : duration;
  }, [data, i18n.language, t]);

  const jobTypeTwo = useMemo(() => {
    const options = [];

    if (data?.jobFullTimeAvailability) {
      options.push(t('labels.fullTime'));
    }

    if (data?.jobPartTimeAvailability) {
      options.push(t('labels.partTime'));
    }

    return options;
  }, [data, t]);

  const workingHours = useMemo(
    () =>
      data?.jobWorkingHours?.map((j) =>
        t(`enum.jobWorkingHours.${j.toLowerCase()}`)
      ) || [],
    [data, t]
  );

  const perks = useMemo(
    () =>
      data?.perks
        ?.map((perk) =>
          t(`enum.accountAdvantageTypeCode.${perk.toLowerCase()}`)
        )
        .concat(data?.otherPerks || []),
    [data?.otherPerks, data?.perks, t]
  );

  const remunerationFields = useMemo(
    () => [
      {
        text: t('labels.set1'),
        description: jobTypeOne || '-',
      },
      {
        text: t('labels.hoursPerWeekSummary'),
        badges:
          data?.jobAvailabilityInHoursPerWeek
            ?.map((hoursPerWeek) => `${hoursPerWeek}h`)
            .concat(jobTypeTwo) || [],
      },
      {
        text: t('labels.schedule'),
        badges: workingHours,
      },
      {
        text: t('labels.remunerationType'),
        description: remuneration,
      },
      {
        text: t('labels.otherCompensation'),
        badges:
          data?.jobPaymentOtherTypesCodes?.map((jobPaymentOtherTypesCode) =>
            t(
              `labels.otherCompensations.${jobPaymentOtherTypesCode.toLowerCase()}`,
              { amount: data.jobOtherRemunerationAmount }
            )
          ) || [],
      },
      {
        text: t('labels.perks'),
        badges: perks || [],
      },
    ],
    [
      data?.jobAvailabilityInHoursPerWeek,
      data?.jobPaymentOtherTypesCodes,
      data?.jobOtherRemunerationAmount,
      jobTypeOne,
      jobTypeTwo,
      perks,
      remuneration,
      t,
      workingHours,
    ]
  );

  return (
    <>
      <SummaryHeader
        className="mt-s-16"
        headerTitle={t('subtitles.offeredConditionsInformation')}
      />

      <section className="grid grid-cols-[1fr] border rounded-b-md">
        {remunerationFields.map((remunerationField, index) => (
          <SectionGridRow key={remunerationField.text} isTopRow={index === 0}>
            {remunerationField.text} : &nbsp;
            {remunerationField.badges ? (
              <SummaryBadges badges={remunerationField.badges} />
            ) : (
              <SummaryDescription description={remunerationField.description} />
            )}
          </SectionGridRow>
        ))}
      </section>
    </>
  );
};

export default Remuneration;
