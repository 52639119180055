import { gql } from 'urql';

import { DescriptionsFragment } from '../../../descriptions';

const RecruitmentNegotiationRoomListFragment = gql`
  ${DescriptionsFragment}

  fragment RecruitmentNegotiationRoomListFragment on RecruitmentNegotiationRoom {
    id
    updatedAt
    notificationCount
    request {
      id
      operationTerritoryCodes
      operationUnit {
        name
      }
      types
      jobSpecialty {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
      operationTerritories {
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
  }
`;

export default RecruitmentNegotiationRoomListFragment;
