import { gql } from '@urql/core';

const MutationCandidateRequestCreate = gql`
  mutation CandidateRequestCreate($input: CandidateRequestCreateInput!) {
    candidateRequestCreate(input: $input) {
      candidateRequest {
        id
        status
      }
    }
  }
`;

export default MutationCandidateRequestCreate;
