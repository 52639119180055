import { Languages } from '@application/enums/languages';

type Translations = {
  [key in Languages]: string;
};

export type ValueText = {
  value: string;
  text: Translations;
  tooltipMessage?: string;
};

const mapCodeDescriptions = <T>(values: T[] = []): ValueText[] =>
  values.map(({ code, descriptions }: any) => ({
    value: code,
    text: {
      fr: descriptions?.fr,
      en: descriptions?.en,
    },
  })) || [];

export default mapCodeDescriptions;
