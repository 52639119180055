import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import SummaryBadges from '@application/components/summary/SummaryBadges';
import SummaryDescription from '@application/components/summary/SummaryDescription';
import { RecruitmentOpportunity } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type SkillProps = {
  data: RecruitmentOpportunity['softSkills'];
  isInOpportunityOffer: boolean;
};

const Skills = ({ data, isInOpportunityOffer }: SkillProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });

  const skillFields = useMemo(
    () => [
      {
        text: t('labels.skillsCompetencies'),
        badges: data?.map((softSkill) => softSkill) || [],
      },
    ],
    [data, t]
  );

  return (
    <>
      <SummaryHeader
        className={cn('mt-s-16', {
          '!border-0 !rounded-none': isInOpportunityOffer,
        })}
        headerTitle={t('subtitles.skillsCompetenciesSought')}
      />

      <section
        className={cn('grid grid-cols-[1fr] border rounded-b-md', {
          'border-0': isInOpportunityOffer,
        })}
      >
        {skillFields.map((remuneration, index) => (
          <SectionGridRow key={remuneration.text} isTopRow={index === 0}>
            {remuneration.text} : &nbsp;
            {remuneration.badges ? (
              <SummaryBadges badges={remuneration.badges} />
            ) : (
              <SummaryDescription description={remuneration.text} />
            )}
          </SectionGridRow>
        ))}
      </section>
    </>
  );
};

export default Skills;
