import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { NoContent, Table } from '@application/components/table';
import { RecruitmentOpportunitiesFilters } from '@domain/graphql.types';

import { FiltersFields } from './components';
import { useRecruitmentOpportunitiesList } from './hooks/useRecruitmentOpportunitiesList';
import useRecruitmentOpportunityFilters from './hooks/useRecruitmentOpportunityFilters';

const RecruitmentOpportunitiesList = () => {
  const [tableFilters, setTableFilters] =
    useState<RecruitmentOpportunitiesFilters>();

  const { t } = useTranslation('recruitment', {
    keyPrefix: 'list.noContent.opportunities',
  });

  const location = useLocation();

  const hash = useMemo(
    () =>
      location.hash ? location.hash.replace('#', '').replace('-', '_') : 'all',
    [location]
  );

  const {
    columns,
    currentPage,
    recruitmentOpportunities,
    sorting,
    count,
    onSortingChange,
    onPaginationChange,
    onPaginationReset,
    isLoading,
  } = useRecruitmentOpportunitiesList(tableFilters);

  const { filters, updateSelectFilter, updateTextFilter } =
    useRecruitmentOpportunityFilters({
      resetPagination: onPaginationReset || (() => {}),
    });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="grow w-full mb-s-16">
        <FiltersFields
          updateTextFilter={updateTextFilter}
          updateSelectFilter={updateSelectFilter}
        />
      </div>

      <div
        className="relative mt-s-32"
        id="recruitment-opportunities-list"
        aria-labelledby={
          filters.recruitmentOpportunityType
            ? `tab-${String(filters.recruitmentOpportunityType).toLowerCase()}`
            : 'tab-all'
        }
        role="tabpanel"
      >
        <Table
          data={recruitmentOpportunities}
          columns={columns}
          count={count}
          onSortingChange={onSortingChange}
          sortingState={sorting}
          paginationState={currentPage}
          onPaginationChange={onPaginationChange}
          onPaginationReset={onPaginationReset}
          isRowColorChangeOnHover
          noDataComponent={
            <NoContent
              title={t(`${hash}.title`)}
              buttonLabel={t(`${hash}.button`)}
            />
          }
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default RecruitmentOpportunitiesList;
