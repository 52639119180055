import { useContext } from 'react';

import { AccountContext } from '@application/context';
import { AccountBillingSubscriptionType } from '@domain/graphql.types';

const useAgencyProOfferValidation = () => {
  const { account } = useContext(AccountContext);

  const canPublishOffer = !(
    account?.subscriptionType === AccountBillingSubscriptionType.AgencyPro &&
    account?.agencySubscriptionInformations !== undefined &&
    account?.agencySubscriptionInformations?.proRemainingOffers === 0
  );

  return {
    canPublishOffer,
    remainingOffer: account?.agencySubscriptionInformations?.proAllowedOffers,
  };
};

export default useAgencyProOfferValidation;
