import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const CandidateOpportunityMatchingRequestListFragment = gql`
  ${DescriptionsFragment}

  fragment CandidateOpportunityMatchingRequestListFragment on CandidateOpportunity {
    id
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    experience
    fullTimeAvailability
    partTimeAvailability
    type
    createdAt
    updatedAt
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
  }
`;

export default CandidateOpportunityMatchingRequestListFragment;
