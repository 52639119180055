import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Button } from '@application/components';
import Countdown from '@application/components/countdown/CountDown';

import usePromoModal from './usePromoModal';

interface PromoButtonProps {
  endPromoDate: DateTime;
}

const PromoButton = ({ endPromoDate }: PromoButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.promo',
  });

  const { openModal } = usePromoModal();

  return (
    <Button className="w-fit p-0" onClick={openModal}>
      <div className="flex flex-col">
        <span className="font-bold">{t('modalButton')}</span>
        <Countdown targetDate={endPromoDate} />
      </div>
    </Button>
  );
};

export default PromoButton;
