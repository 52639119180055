import { gql } from '@urql/core';

import { getPage } from '@infrastructure/graphql/pagination';

import AccountUserFragment from './account.user.fragment';

const AccountUsers = gql`
  ${AccountUserFragment}
  query AccountUsers(
    $filterBy: AccountUsersFilters
    $sortBy: [AccountUsersSortDirective!]
    $before: String
    $after: String
    $first: Float
    $last: Float
  ) {
    accountUsers(
      filterBy: $filterBy
      sortBy: $sortBy
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ${getPage(['AccountUserFragment'])}
  }
`;

export default AccountUsers;
