import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { AccountContext, AlertContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { useCandidateRequestDelete } from '@domain/candidate';
import { CandidateRequestErrors } from '@domain/graphql.types';

import { toViewModel } from '../view-models/delete-candidate-request.vm';

const useDeleteCandidateRequest = () => {
  const { data, fetching, error, deleteCandidateRequest } =
    useCandidateRequestDelete();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('candidates', { keyPrefix: 'messages' });

  const { pathname } = useLocation();
  const { refreshAccount } = useContext(AccountContext);

  const navigate = useNavigate();

  const viewModel = toViewModel({
    candidateRequestDelete: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    const associatedCandidateOfferError = error.graphQLErrors.find(
      (e) => e.message === CandidateRequestErrors.AssociatedCandidateOffer
    );

    setAlertContent(
      associatedCandidateOfferError
        ? {
            modifier: 'alert-error',
            text: t('hasAssociatedCandidateOfferError'),
          }
        : {
            modifier: 'alert-error',
            action: t('deleteError'),
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateRequestDelete?.ok) {
      setAlertContent({
        action: t('deleteSuccess'),
        modifier: 'alert-success',
      });

      refreshAccount();

      if (pathname.includes('details')) {
        navigate(RootPrivatePage.CANDIDATE_REQUESTS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateRequestDelete?.ok]);

  return {
    viewModel,
    deleteCandidateRequest,
  };
};

export default useDeleteCandidateRequest;
