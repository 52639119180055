import { ReactElement } from 'react';
import { Navigate } from 'react-router';

import {
  OnboardingPage,
  PrivatePage,
  PublicPage,
  RootPrivatePage,
} from '@application/enums/pagesUrl';
import {
  AcceptInvitationPage,
  AccountTypePage,
  CandidateNegotiationAgreementPage,
  CandidateNegotiationListPage,
  CandidateNegotiationRoomAgreementPage,
  CandidateNegotiationRoomListPage,
  CandidateOfferDetailsPage,
  CandidateOfferListPage,
  CandidateOfferRequestsPage,
  CandidateOffersComparisonPage,
  CandidateOpportunitiesListPage,
  CandidateOpportunityChatPage,
  CandidateRequestChatPage,
  CandidateRequestListPage,
  CreateAccountPage,
  CreateCandidateOfferPage,
  CreateCandidateRequestPage,
  CreateOfferPage,
  CreateRequestPage,
  Dashboard,
  EditCandidateOfferPage,
  EditCandidateRequestPage,
  EditOfferPage,
  EditRequestPage,
  Home,
  JoinCompanyPage,
  NotFoundPage,
  OfferCandidatesPage,
  OfferDetailsPage,
  OfferListPage,
  OffersComparisonPage,
  OrganizationAccountPage,
  OrganizationPublicProfilePage,
  RecruitmentNegotiationAgreementPage,
  RecruitmentNegotiationListPage,
  RecruitmentNegotiationRoomListPage,
  RecruitmentOpportunitiesListPage,
  RecruitmentOpportunityChatPage,
  RequestChatPage,
  RequestListPage,
  UserAccountPage,
  ViewCandidateNegotiationPage,
  ViewCandidateNegotiationRoomPage,
  ViewCandidateOfferPage,
  ViewCandidateOpportunityPage,
  ViewCandidateRequestPage,
  ViewOfferPage,
  ViewRecruitmentNegotiationPage,
  ViewRecruitmentNegotiationRoomPage,
  ViewRecruitmentOpportunityPage,
  ViewRequestPage,
} from '@application/views';
import {
  AgencyAccountsListPage,
  EnterpriseAccountsListPage,
} from '@application/views/organization/super-admin';
import ViewOfferCandidatePage from '@application/views/recruitment/offer/candidate/ViewOfferCandidatePage';
import OfferCandidateDetailsPage from '@application/views/recruitment/request/candidate/OfferCandidateDetailsPage';
import { AccountTypeCode } from '@domain/graphql.types';

import { RecruitmentNegotiationRoomAgreementPage } from '../views/recruitment';

const featureFlaggedRoute = (page: ReactElement, flag: any) =>
  flag === 'true' ? page : <Navigate to={PrivatePage.DASHBOARD} replace />;

export const PUBLIC_ROUTES: Record<PublicPage, ReactElement> = {
  [PublicPage.HOME]: <Home />,
};

export const PRIVATE_ROUTES: Record<string, ReactElement> = {
  [PrivatePage.NOT_FOUND]: <NotFoundPage />,
  [PrivatePage.DASHBOARD]: <Dashboard />,
  [RootPrivatePage.ORGANIZATION]: <OrganizationAccountPage />,
  [PrivatePage.ORGANIZATION_PUBLIC_PROFILE]: <OrganizationPublicProfilePage />,
  [RootPrivatePage.USER]: <UserAccountPage />,
};

export const PRIVATE_AGENCY_ROUTES: Record<string, ReactElement> = {
  // Candidate Requests
  [PrivatePage.CANDIDATE_REQUEST_CHAT]: <CandidateRequestChatPage />,
  [PrivatePage.CANDIDATE_REQUEST_CREATE]: <CreateCandidateRequestPage />,
  [PrivatePage.CANDIDATE_REQUEST_DETAILS]: <ViewCandidateRequestPage />,
  [PrivatePage.CANDIDATE_REQUEST_EDIT]: <EditCandidateRequestPage />,
  [RootPrivatePage.CANDIDATE_REQUESTS]: <CandidateRequestListPage />,
  // Recruitment Offers
  [RootPrivatePage.OFFERS]: <OfferListPage />,
  [PrivatePage.VIEW_OFFER]: <ViewOfferPage />,
  [PrivatePage.OFFER_EDIT]: <EditOfferPage />,
  [PrivatePage.VIEW_OFFER_CANDIDATE]: <ViewOfferCandidatePage />,
  // Recruitment Opportunities
  [RootPrivatePage.RECRUITMENT_OPPORTUNITIES]: (
    <RecruitmentOpportunitiesListPage />
  ),
  [PrivatePage.RECRUITMENT_OPPORTUNITY_CHAT]: (
    <RecruitmentOpportunityChatPage />
  ),
  [PrivatePage.RECRUITMENT_OPPORTUNITY_OFFER_CREATE]: <CreateOfferPage />,
  [PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS]: (
    <ViewRecruitmentOpportunityPage />
  ),
  // Candidate Offers
  [PrivatePage.CANDIDATE_OFFER_DETAILS]: <CandidateOfferDetailsPage />,
  [PrivatePage.CANDIDATE_OFFER_COMPARISON]: <CandidateOffersComparisonPage />,
  [PrivatePage.CANDIDATE_OFFER_REQUESTS]: <CandidateOfferRequestsPage />,
  // Negotiations
  [RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS]: (
    <RecruitmentNegotiationRoomListPage />
  ),
  [PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_DETAILS]: (
    <ViewRecruitmentNegotiationRoomPage />
  ),
  [PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_AGREEMENT]: (
    <RecruitmentNegotiationRoomAgreementPage />
  ),
  [RootPrivatePage.CANDIDATE_NEGOTIATIONS]: featureFlaggedRoute(
    <CandidateNegotiationListPage />,
    import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION
  ),
  [PrivatePage.CANDIDATE_NEGOTIATION_DETAILS]: featureFlaggedRoute(
    <ViewCandidateNegotiationPage />,
    import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION
  ),
  [PrivatePage.CANDIDATE_NEGOTIATION_AGREEMENT]: featureFlaggedRoute(
    <CandidateNegotiationAgreementPage />,
    import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION
  ),
};

export const PRIVATE_ENTERPRISE_ROUTES: Record<string, ReactElement> = {
  // Recruitment Requests
  [PrivatePage.REQUEST_CHAT]: <RequestChatPage />,
  [PrivatePage.REQUEST_CREATE]: <CreateRequestPage />,
  [PrivatePage.REQUEST_DETAILS]: <ViewRequestPage />,
  [PrivatePage.REQUEST_EDIT]: <EditRequestPage />,
  [RootPrivatePage.REQUESTS]: <RequestListPage />,
  // Candidate Offers
  [RootPrivatePage.CANDIDATE_OFFERS]: <CandidateOfferListPage />,
  [PrivatePage.VIEW_CANDIDATE_OFFER]: <ViewCandidateOfferPage />,
  [PrivatePage.CANDIDATE_OFFER_EDIT]: <EditCandidateOfferPage />,
  // Candidate Opportunity
  [RootPrivatePage.CANDIDATE_OPPORTUNITIES]: <CandidateOpportunitiesListPage />,
  [PrivatePage.CANDIDATE_OPPORTUNITY_OFFER_CREATE]: (
    <CreateCandidateOfferPage />
  ),
  [PrivatePage.CANDIDATE_OPPORTUNITY_CHAT]: <CandidateOpportunityChatPage />,
  [PrivatePage.CANDIDATE_OPPORTUNITY_DETAILS]: <ViewCandidateOpportunityPage />,
  // Recruitment Offers
  [PrivatePage.OFFER_DETAILS]: <OfferDetailsPage />,
  [PrivatePage.OFFER_COMPARISON]: <OffersComparisonPage />,
  [PrivatePage.OFFER_CANDIDATES]: <OfferCandidatesPage />,
  [PrivatePage.OFFER_CANDIDATE_DETAILS]: <OfferCandidateDetailsPage />,
  // Negotiations
  [RootPrivatePage.CANDIDATE_NEGOTIATION_ROOMS]: featureFlaggedRoute(
    <CandidateNegotiationRoomListPage />,
    import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION
  ),
  [PrivatePage.CANDIDATE_NEGOTIATION_ROOM_DETAILS]: featureFlaggedRoute(
    <ViewCandidateNegotiationRoomPage />,
    import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION
  ),
  [PrivatePage.CANDIDATE_NEGOTIATION_ROOM_AGREEMENT]: featureFlaggedRoute(
    <CandidateNegotiationRoomAgreementPage />,
    import.meta.env.VITE_FEATURE_FLAG_NEGOTIATION
  ),
  [RootPrivatePage.RECRUITMENT_NEGOTIATIONS]: (
    <RecruitmentNegotiationListPage />
  ),
  [PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS]: (
    <ViewRecruitmentNegotiationPage />
  ),
  [PrivatePage.RECRUITMENT_NEGOTIATION_AGREEMENT]: (
    <RecruitmentNegotiationAgreementPage />
  ),
  // Super Admin
  [RootPrivatePage.ENTERPRISE_ACCOUNTS]: <EnterpriseAccountsListPage />,
  [RootPrivatePage.AGENCY_ACCOUNTS]: <AgencyAccountsListPage />,
};

export const ONBOARDING_ROUTES: Record<string, ReactElement> = {
  [OnboardingPage.ACCEPT_INVITATION]: <AcceptInvitationPage />,
  [OnboardingPage.ACCOUNT_TYPE]: <AccountTypePage />,
  [OnboardingPage.CREATE_COMPANY]: (
    <CreateAccountPage type={AccountTypeCode.Enterprise} />
  ),
  [OnboardingPage.CREATE_AGENCY]: (
    <CreateAccountPage type={AccountTypeCode.Agency} />
  ),
  [OnboardingPage.JOIN_COMPANY]: <JoinCompanyPage />,
};
