import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';
import * as yup from 'yup';

import {
  ConfirmationModal,
  PageHeader,
  PageLoader,
  Stack,
  ToggleButtons,
} from '@application/components';
import { LoadingSpinner } from '@application/components/spinner';
import { ModalContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { useWizard } from '@application/hooks';
import { OfferStatusTypeCode } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

import { CREATE_OFFER_STEPS, DEFAULT_VALUES } from './constants';
import { useGetOffer, useSaveAndPublishOffer, useUpdateOffer } from './hooks';
import {
  normalizeOfferEditCandidateData,
  normalizeOfferEditData,
  normalizeOfferInputData,
} from './normalizers';
import {
  OFFER_CANDIDATE_INFORMATIONS_PUBLISH_SCHEMA,
  OFFER_PUBLISH_SCHEMA,
  OFFER_SCHEMA,
} from './schema';
import { CandidatePanel, ConditionsPanel } from './steps';
import { useSaveCandidate } from './steps/candidate/candidates-proposal';
import { OfferFormFields } from './types';

const EditOfferPage = () => {
  const [saving, setSaving] = useState<boolean>(false);

  const [currentSchema, setCurrentSchema] =
    useState<yup.ObjectSchema<any>>(OFFER_SCHEMA);

  const { t, i18n } = useTranslation('offers');

  const {
    updateOffer,
    viewModel: { isLoading: isUpdatingOffer },
  } = useUpdateOffer();
  const {
    saveAndPublish,
    viewModel: { isLoading: isSavingAndPublishingOffer },
  } = useSaveAndPublishOffer();
  const { saveCandidate, isLoading: isSavingCandidates } = useSaveCandidate();

  const { setModal } = useContext(ModalContext);

  const { id = '' } = useParams();
  const navigate = useNavigate();

  const isSaving =
    isUpdatingOffer || isSavingCandidates || isSavingAndPublishingOffer;

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const { activeStep, setActive, nextStep } = useWizard({
    steps: CREATE_OFFER_STEPS,
  });

  const methods = useForm<OfferFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(currentSchema),
  });

  const { handleSubmit, watch, formState } = methods;

  const candidates = watch('offerCandidates');
  const publishNowWatch = watch('publishNow');

  const {
    viewModel: { data: offer, isLoading },
    refetchOffer,
  } = useGetOffer(id);

  const candidateInHandMandatoryValue =
    offer?.recruitmentOpportunity?.generalContractualConditions
      .candidateInHandMandatoryValue || false;

  useEffect(() => {
    const foundErrorsFromOfferCandidateInformations = Object.keys(
      formState.errors
    ).filter((e) =>
      Object.keys(OFFER_CANDIDATE_INFORMATIONS_PUBLISH_SCHEMA.fields).includes(
        e
      )
    );

    if (
      saving &&
      (offer?.status === OfferStatusTypeCode.Published || publishNowWatch) &&
      candidateInHandMandatoryValue &&
      (!candidates || (candidates && candidates.length < 1))
    ) {
      setModal({
        title: t('modal.mustProvideCandidatesTitle'),
        maxWidth: '2xl',
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>{t('modal.mustProvideCandidates')}</p>{' '}
                <p>{t('modal.confirmRedirectStepOfferCandidate')}</p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              setModal(null);
              setActive(0);
            }}
          />
        ),
      });
    } else if (
      saving &&
      (offer?.status === OfferStatusTypeCode.Published || publishNowWatch) &&
      (formState.errors?.offerCandidates ||
        foundErrorsFromOfferCandidateInformations.length > 0)
    ) {
      setModal({
        title: t('modal.fillOutEmptyRequiredField'),
        maxWidth: '2xl',
        content: (
          <ConfirmationModal
            content={
              <Stack>
                <p>
                  <Trans
                    i18nKey="modal.requiredFieldError"
                    t={t}
                    values={{ step: t(CREATE_OFFER_STEPS[0].title) }}
                  />
                </p>
                <p>{t('modal.confirmRedirectStepOffer')}</p>
              </Stack>
            }
            onCancel={() => setModal(null)}
            onConfirm={() => {
              setModal(null);
              setActive(0);
            }}
          />
        ),
      });
    }
    setSaving(false);
  }, [
    candidateInHandMandatoryValue,
    candidates,
    formState.errors,
    methods,
    offer?.status,
    publishNowWatch,
    saving,
    setActive,
    setModal,
    t,
  ]);

  useEffect(() => {
    if (offer) {
      const d = {
        ...normalizeOfferEditData(offer),
        conditionHiredDateBonusRequirement: offer.generalContractualConditions
          .hiredDateBonusRequirement
          ? formatDate(
              offer.generalContractualConditions.hiredDateBonusRequirement,
              i18n.language,
              'short'
            )
          : undefined,
        offerCandidates: offer.candidates.map((candidate) => ({
          ...normalizeOfferEditCandidateData(candidate),
          metAt: candidate.metAt
            ? formatDate(candidate.metAt, i18n.language, 'short')
            : undefined,
        })),

        id,
      };
      methods.reset(d as OfferFormFields);

      if (offer.status === OfferStatusTypeCode.Published) {
        setCurrentSchema(OFFER_PUBLISH_SCHEMA(candidateInHandMandatoryValue));
      }
    }
  }, [id, offer, methods, i18n.language, candidateInHandMandatoryValue]);

  const onSubmit: SubmitHandler<OfferFormFields> = useCallback(
    async (values) => {
      const { publishNow, requestId, offerCandidates, ...otherValues } = values;
      const modifiedValues = { ...otherValues };

      await offerCandidates?.map(async (candidate) =>
        saveCandidate(candidate, id)
      );

      if (publishNow) {
        await saveAndPublish({
          updateInput: normalizeOfferInputData(modifiedValues) as any,
          statusUpdateInput: {
            id,
            status: OfferStatusTypeCode.Published,
          },
        });
      } else {
        await updateOffer({
          offerUpdateInput: normalizeOfferInputData(modifiedValues) as any,
        });
      }
    },
    [saveCandidate, id, saveAndPublish, updateOffer]
  );

  const handleOnSave = useCallback(() => {
    methods.setValue('publishNow', false);
    if (offer?.status !== OfferStatusTypeCode.Published) {
      setCurrentSchema(OFFER_SCHEMA);
    }

    setSaving(true);
  }, [methods, offer?.status]);

  const handleOnPublish = useCallback(() => {
    methods.setValue('publishNow', true);
    setCurrentSchema(OFFER_PUBLISH_SCHEMA(candidateInHandMandatoryValue));
    setSaving(true);
  }, [candidateInHandMandatoryValue, methods]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      {isSaving && <LoadingSpinner size="lg" overPage />}
      <PageHeader title={t('title.editOffer')} backTo={RootPrivatePage.OFFERS}>
        <ToggleButtons
          activeStep={activeStep}
          setActive={setActive}
          leftLabel={t('subtitles.candidate')}
          rightLabel={t('subtitles.conditions')}
        />
      </PageHeader>

      <FormProvider {...methods}>
        <form className="md:px-s-48" onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && (
            <CandidatePanel
              recruitmentOpportunity={
                offer?.recruitmentOpportunity || undefined
              }
              nextStep={nextStep}
              refetchOffer={refetchOffer}
              isEditing
            />
          )}

          {activeStep === 1 && (
            <ConditionsPanel
              recruitmentOpportunity={
                offer?.recruitmentOpportunity || undefined
              }
              onPublish={
                offer?.status === OfferStatusTypeCode.Draft
                  ? handleOnPublish
                  : undefined
              }
              onSave={handleOnSave}
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default EditOfferPage;
