import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountContext, AlertContext } from '@application/context';
import { OfferErrors, OfferStatusTypeCode } from '@domain/graphql.types';
import { useOfferUpdateStatus } from '@domain/offer';

import { toViewModel } from '../view-models/update-offer-status.vm';

const useUpdateOfferStatus = () => {
  const { data, fetching, error, updateOfferStatus } = useOfferUpdateStatus();

  const { t } = useTranslation('offers', {
    keyPrefix: 'messages',
  });

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const viewModel = toViewModel({
    offer: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    const missingRequiredFields = error.graphQLErrors.find(
      (e) => e.message === OfferErrors.MissingRequiredFields
    );

    setAlertContent(
      missingRequiredFields
        ? {
            modifier: 'alert-error',
            text: t('hasMissingRequiredFieldsError'),
          }
        : {
            modifier: 'alert-error',
            action: t('updateStatusError'),
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.offerStatusUpdate.offer) {
      setAlertContent({
        action: t('updateStatusSuccess'),
        modifier: 'alert-success',
      });

      if (
        data?.offerStatusUpdate.offer.status === OfferStatusTypeCode.Published
      ) {
        refreshAccount();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { updateOfferStatus, viewModel };
};

export default useUpdateOfferStatus;
