import { useContext } from 'react';

import { AccountContext } from '@application/context';
import { AccountBillingSubscriptionType } from '@domain/graphql.types';

const usePremiumInviteUserValidation = () => {
  const { account } = useContext(AccountContext);

  const canInviteUser = !(
    account?.subscriptionType ===
      AccountBillingSubscriptionType.EnterprisePremium &&
    account?.enterpriseSubscriptionInformations !== undefined &&
    account?.enterpriseSubscriptionInformations?.premiumRemainingSeats === 0
  );

  return {
    canInviteUser,
    allowedSeats:
      account?.enterpriseSubscriptionInformations?.premiumAllowedSeats,
  };
};

export default usePremiumInviteUserValidation;
