import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { UpdateAccountUserStatusResponse } from '@domain/graphql.types';

type ToViewModel = {
  accountUserUpdateStatus: {
    data: UpdateAccountUserStatusResponse | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  accountUserUpdateStatus: { data, fetching, error },
}: ToViewModel): ViewModel<UpdateAccountUserStatusResponse | undefined> =>
  new ViewModel<UpdateAccountUserStatusResponse | undefined>({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
