import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '@application/context';
import { CandidateOpportunity } from '@domain/graphql.types';

import { CandidateOpportunitiesMatchingRequestModal } from '../components';

const useCandidateOpportunitiesMatchingRequestModal = () => {
  const { t } = useTranslation('candidates', { keyPrefix: 'modal' });

  const { setModal } = useContext(ModalContext);

  const openModal = useCallback(
    (requestMatchingCandidateOpportunities: CandidateOpportunity[]) => {
      setModal({
        title: t('titles.beProactive'),
        content: (
          <CandidateOpportunitiesMatchingRequestModal
            requestMatchingCandidateOpportunities={
              requestMatchingCandidateOpportunities
            }
            onClose={() => setModal(null)}
          />
        ),
        maxWidth: 'fit',
      });
    },
    [setModal, t]
  );

  return {
    openModal,
  };
};

export default useCandidateOpportunitiesMatchingRequestModal;
