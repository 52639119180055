import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { Modal } from '@application/components/modal';
import { ModalMaxWidth } from '@application/components/modal/Modal';
import { ModalContext } from '@application/context';

type ModalProviderProps = {
  children: React.ReactNode;
};

/**
 * @example
 * const { setModal } = useContext(ModalContext);
 *
 * setModal({ // somewhere in an event handler or useEffect
 *  title: 'I'm a spider',
 *  content: (
 *   <>
 *     <Modal.CloseBtn onClick={() => setModal(null)} />
 *     <div>I'm not a spider! I'm a tiny land octopus.</div>
 *     <Modal.Action>
 *       <Button onClick={() => setModal(null)}>Okidoki</Button>
 *     </Modal.Action>
 *   </>
 *  )
 * });
 */

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, setModal] = useState<{
    title: string;
    content: React.ReactNode | null;
    hideTitle?: boolean;
    maxWidth?: ModalMaxWidth;
    centered?: boolean;
    modifier?: 'error' | 'warning' | 'info' | 'success';
  } | null>({
    title: '',
    content: null,
    hideTitle: false,
    centered: false,
    modifier: undefined,
  });

  const contextValue = useMemo(() => ({ setModal }), [setModal]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}

      {modal &&
        modal?.content &&
        createPortal(
          <Modal
            title={modal.title}
            hideTitle={modal.hideTitle}
            centered={modal.centered}
            maxWidth={modal.maxWidth}
            modifier={modal.modifier}
          >
            {modal.content}
          </Modal>,
          document.body
        )}
    </ModalContext.Provider>
  );
};

export default React.memo(ModalProvider);
