import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectField } from '@application/components/select-field';
import { useSpecialties } from '@application/hooks';
import { AccountIndustryType } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { TRANSLATION_PREFIX_KEYS } from '../constants';
import { CreateAccountFormFields } from '../schema';

type SectorProps = {
  type: 'ENTERPRISE' | 'AGENCY';
};

const Sector = ({ type }: SectorProps) => {
  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('signup', {
    keyPrefix: `${TRANSLATION_PREFIX_KEYS[type]}.form`,
  });

  const { control, watch, setValue } =
    useFormContext<CreateAccountFormFields>();

  const industryCode = watch('industryTypeCode');
  const sectorCode = watch('sectorCode');

  const { INDUSTRY_SECTORS_OPTIONS, onIndustrySectorChange } = useSpecialties({
    industryType: industryCode ?? undefined,
    industrySector: sectorCode ?? undefined,
    language: extractLanguage(i18n.language),
    setSector: (value: string | undefined) => setValue('sectorCode', value),
    setType: (value: AccountIndustryType | undefined) =>
      setValue('industryTypeCode', value),
    useCodeAsValue: true,
  });

  return (
    <fieldset className="mt-s-16">
      <legend className="mb-s-16 text-20 font-semibold">
        {t('sector.heading')}
      </legend>

      <Controller
        name="sectorCode"
        control={control}
        render={({
          field: { onChange, onBlur, value, name },
          fieldState: { error },
        }) => (
          <SelectField
            className="max-w-[25rem]"
            onChange={onIndustrySectorChange(onChange, value)}
            onBlur={onBlur}
            value={INDUSTRY_SECTORS_OPTIONS.find(
              (size) => size.value === value
            )}
            label={t('sector.list')}
            name={name}
            options={INDUSTRY_SECTORS_OPTIONS}
            helperText={error?.message && tGlobal(error.message)}
            invalid={!!error}
            menuPlacement="top"
            searchable
          />
        )}
      />
    </fieldset>
  );
};

export default Sector;
