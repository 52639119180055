import { useTranslation } from 'react-i18next';

import KehopsLogo from '@application/assets/logo.svg?react';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { cn } from '@utils/lib-utils';

import { IconButton } from '../buttons';
import { Link } from '../links';
import BottomLinks from './BottomLinks';
import TopLinks from './TopLinks';

type SidebarProps = {
  isNavOpen: boolean;
  toggleNav: () => void;
};

const Sidebar = ({ isNavOpen, toggleNav }: SidebarProps) => {
  const { t } = useTranslation();

  const sidebarClassName = cn(
    'z-fixed border-r duration-300 bg-base-100 absolute flex flex-col h-full lg:relative lg:h-auto',
    { 'w-[100%] lg:max-w-[20.1rem]': isNavOpen },
    { 'w-[7rem] hidden lg:flex': !isNavOpen }
  );
  const iconClassName = cn('block ri-arrow-left-s-line text-20 duration-300', {
    'rotate-180': !isNavOpen,
  });

  return (
    <div className={sidebarClassName}>
      <div className="flex justify-between items-center flex-wrap mt-s-24 mx-s-24 relative">
        <Link to={RootPrivatePage.DASHBOARD}>
          <KehopsLogo title={t('appName')} className="min-w-[4rem] w-s-64" />
        </Link>
        <IconButton
          onClick={toggleNav}
          text={t(isNavOpen ? 'button.open' : 'button.close')}
          className="absolute -right-s-40 top-s-80 z-10 hidden lg:block"
          size="x-small"
          icon={<i className={iconClassName} />}
        />
        <IconButton
          onClick={toggleNav}
          text={t('button.open')}
          className="lg:hidden"
          icon={<i className="ri-close-line font-normal" />}
        />
      </div>

      <nav
        className="menu menu-vertical py-s-40 px-s-24 pb-s-24 flex-grow justify-between"
        role="navigation"
      >
        <TopLinks isNavOpen={isNavOpen} />
        <BottomLinks isNavOpen={isNavOpen} />
      </nav>
    </div>
  );
};

export default Sidebar;
