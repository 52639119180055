import { UseQueryExecute } from 'urql';

import {
  RequestChatRoomMessage,
  RequestChatRoomMessagesInput,
} from '@domain/graphql.types';

import useGetRequestChatRoomMessages from './useGetRequestChatRoomMessages';

type UseRequestChatRoomMessagesListProps = {
  count?: number;
  requestChatRoomMessages: RequestChatRoomMessage[];
  isLoading: boolean;
  refetchRequestChatRoomMessages: UseQueryExecute;
};

const useRequestChatRoomMessagesList = (
  input: RequestChatRoomMessagesInput
): UseRequestChatRoomMessagesListProps => {
  const {
    viewModel: { data, isLoading },
    refetchRequestChatRoomMessages,
  } = useGetRequestChatRoomMessages(input);

  return {
    count: data?.requestChatRoomMessages.totalCount,
    requestChatRoomMessages:
      data?.requestChatRoomMessages.results.slice() || [],
    isLoading,
    refetchRequestChatRoomMessages,
  };
};

export default useRequestChatRoomMessagesList;
