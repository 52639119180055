import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '@application/context';

import PromoModal from './PromoModal';

export type UsePromoModalProps = {
  openModal: () => void;
};

const usePromoModal = (): UsePromoModalProps => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.promo',
  });

  const { setModal } = useContext(ModalContext);

  const openModal = useCallback(() => {
    setModal({
      title: t('modalTitle'),
      content: <PromoModal closeModal={() => setModal(null)} />,
    });
  }, [setModal, t]);

  return {
    openModal,
  };
};

export default usePromoModal;
