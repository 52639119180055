import { useTranslation } from 'react-i18next';

import { RankBadge } from '@application/components';
import { chatPreviewDateFormatter } from '@utils/date-utils';
import { cn } from '@utils/lib-utils';

export type ChatListItemProps = {
  selected: boolean;
  rank?: number;
  title: string;
  preview: string;
  date?: string;
  newMessageCount: number;
  onClick: () => void;
};

const ChatListItem = ({
  selected,
  rank,
  title,
  preview,
  date,
  newMessageCount,
  onClick,
}: ChatListItemProps) => {
  const { t } = useTranslation('chatRoom');

  const formattedDate = date
    ? chatPreviewDateFormatter(date, t('dateSeparator.yesterday'))
    : '';

  const hasNewMessage = newMessageCount > 0;

  const classMainWrapper = cn(
    'hover:shadow-sm transition-shadow cursor-pointer text-left relative w-full h-[88px] grid grid-cols-[max-content_1fr_max-content] gap-x-s-10 items-center mb-s-16 px-s-18 py-s-12 border rounded-md overflow-hidden ',
    {
      'grid-cols-[1fr_max-content]': !rank,
      'before:absolute before:top-s-0 before:bottom-s-0 before:left-s-0 before:w-s-6 before:bg-primary':
        hasNewMessage,
      'border-stroke-dark': selected,
    }
  );
  const classTitle =
    'row-start-2 col-span-3 md:row-auto md:col-auto text-18 font-semibold  overflow-ellipsis overflow-hidden whitespace-nowrap';
  const classDate = 'hidden md:block text-14';
  const classPreview =
    'hidden md:block col-span-2 text-14 text-neutral-secondary  overflow-ellipsis overflow-hidden whitespace-nowrap';
  // TODO message count for v2
  // const classNewMessageCount =
  //   ' text-12 font-semibold flex items-center justify-center rounded-full w-s-24 h-s-24 justify-self-end bg-gray-200';
  // const classNoNewMessage = ' ri-check-double-line text-24 text-right';

  return (
    <button className={classMainWrapper} onClick={onClick}>
      {rank && <RankBadge rank={rank} />}
      <div className={classTitle}>{title}</div>
      <div className={classDate}>{formattedDate}</div>
      <div className={classPreview}>{preview}</div>
      {/* {hasNewMessage ? (
        <div className={classNewMessageCount}>{newMessageCount}</div>
      ) : (
        <i className={classNoNewMessage} />
      )} */}
    </button>
  );
};

export default ChatListItem;
