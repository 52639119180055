import { useMutation } from 'urql';

import { Mutation, MutationImpersonateUserArgs } from '@domain/graphql.types';
import { ImpersonateUser } from '@infrastructure/graphql/user';

const useImpersonateUserMutation = () => {
  const [{ data, fetching, error }, impersonateUser] = useMutation<
    Pick<Mutation, 'impersonateUser'>,
    MutationImpersonateUserArgs
  >(ImpersonateUser);

  return {
    impersonateUser,
    data: data?.impersonateUser,
    fetching,
    error,
  };
};

export default useImpersonateUserMutation;
