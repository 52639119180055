import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoContent, Table } from '@application/components/table';
import { CandidateOpportunitiesFilters } from '@domain/graphql.types';

import { FiltersFields } from './components';
import { useCandidateFilters } from './hooks';
import { useCandidateOpportunitiesList } from './hooks/useCandidateOpportunitiesList';

const CandidateOpportunitiesListPage = () => {
  const [tableFilters, setTableFilters] =
    useState<CandidateOpportunitiesFilters>();

  const { t } = useTranslation('candidates');

  const {
    columns,
    currentPage,
    candidateOpportunities,
    count,
    onPaginationChange,
    onPaginationReset,
    onSortingChange,
    sorting,
    isLoading,
  } = useCandidateOpportunitiesList(tableFilters);

  const { filters, updateSelectFilters, updateTextFilter } =
    useCandidateFilters({
      resetPagination: onPaginationReset,
    });

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="grow w-full mb-s-16">
        <FiltersFields
          updateTextFilter={updateTextFilter}
          updateSelectFilters={updateSelectFilters}
        />
      </div>

      <Table
        data={candidateOpportunities}
        columns={columns}
        count={count}
        paginationState={currentPage}
        onPaginationChange={onPaginationChange}
        onPaginationReset={onPaginationReset}
        isRowColorChangeOnHover
        noDataComponent={
          <NoContent title={t('list.noContent.opportunities')} />
        }
        sortingState={sorting}
        onSortingChange={onSortingChange}
        isLoading={isLoading}
      />
    </>
  );
};

export default CandidateOpportunitiesListPage;
