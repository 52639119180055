import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useImpersonateUserMutation } from '@domain/user';

import { toViewModel } from '../view-models/impersonate-user.vm';

const useImpersonateUser = () => {
  const { data, fetching, error, impersonateUser } =
    useImpersonateUserMutation();

  const { setAlertContent } = useContext(AlertContext);

  const { t } = useTranslation('user');

  const viewModel = toViewModel({
    user: {
      data,
      fetching,
      error,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    setAlertContent({
      action: t('messages.updateError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    viewModel,
    impersonateUser,
  };
};

export default useImpersonateUser;
