import { useRequestChatRoomMessages } from '@domain/chat';
import { RequestChatRoomMessagesInput } from '@domain/graphql.types';

import { toViewModel } from '../../view-models/message/get-request-chat-room-message.vm';

const useGetRequestChatRoomMessages = (input: RequestChatRoomMessagesInput) => {
  const { data, fetching, error, reexecuteQuery } = useRequestChatRoomMessages({
    input,
  });

  const viewModel = toViewModel({
    requestChatRoomMessages: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRequestChatRoomMessages: reexecuteQuery,
  };
};

export default useGetRequestChatRoomMessages;
