import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGridRow, VisibilitySwitcher } from '@application/components';
import { ConditionRemunerationType, Offer } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type GeneralConditionsProps = {
  data: Offer | undefined;
};

const GeneralConditions = ({ data }: GeneralConditionsProps) => {
  const { t, i18n } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tGlobal } = useTranslation();

  const recruitmentOpportunity = data?.recruitmentOpportunity;
  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.generalContractualConditions;
  const conditions = data?.generalContractualConditions;

  const displayHourlyRate = useMemo(() => {
    const {
      remunerationHourlyRate: rate,
      remunerationEstimatedHoursMin: hoursMin,
      remunerationEstimatedHoursMax: hoursMax,
    } = conditions || {};

    if (!rate || !hoursMin || !hoursMax) {
      return t('labels.remunerationType.hourlyRateOnly');
    }

    return hoursMin !== hoursMax
      ? t('labels.remunerationType.hourlyRate', { rate, hoursMin, hoursMax })
      : t('labels.remunerationType.hourlyRateMinMax', {
          rate,
          hours: hoursMin,
        });
  }, [conditions, t]);

  const remuneration = useMemo(() => {
    switch (conditions?.remunerationType) {
      case ConditionRemunerationType.AnnualSalaryPercentage:
        return conditions?.remunerationPercentage
          ? t('labels.remunerationType.salaryPercentage', {
              amount: conditions.remunerationPercentage,
            })
          : t('labels.remunerationType.salaryPercentageOnly');
      case ConditionRemunerationType.FixedAmount:
        return conditions?.remunerationAmount
          ? t('labels.remunerationType.fixedAmount', {
              amount: conditions.remunerationAmount,
            })
          : t('labels.remunerationType.fixedAmountOnly');
      case ConditionRemunerationType.HourlyRate:
        return displayHourlyRate;
      default:
        return '-';
    }
  }, [conditions, displayHourlyRate, t]);

  return (
    <>
      {!data?.candidates?.length && (
        <SectionGridRow
          isTopRow
          isOffer
          rightChildren={
            <VisibilitySwitcher
              content={
                recruitmentOpportunityConditions?.maxRecruitmentPeriodValue ? (
                  <span className="text-16 font-semibold text-primary">
                    {t('suffix.week', {
                      count: Number(
                        recruitmentOpportunityConditions?.maxRecruitmentPeriodValue
                      ),
                    })}
                  </span>
                ) : (
                  <span>-</span>
                )
              }
              visible={
                !!recruitmentOpportunityConditions?.maxRecruitmentPeriodDisplay
              }
            />
          }
        >
          <span className="mr-s-8">{t('labels.maxRecruitmentPeriod')}</span>

          {conditions?.maxRecruitmentPeriod ? (
            <span className="text-16 font-semibold text-primary">
              {t('suffix.week', {
                count: Number(conditions?.maxRecruitmentPeriod),
              })}
            </span>
          ) : (
            <span>-</span>
          )}
        </SectionGridRow>
      )}

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              recruitmentOpportunityConditions?.probationPeriodValue ? (
                <span className="text-16 font-semibold text-primary">
                  {t('suffix.week', {
                    count: Number(
                      recruitmentOpportunityConditions?.probationPeriodValue
                    ),
                  })}
                </span>
              ) : (
                <span>-</span>
              )
            }
            visible={!!recruitmentOpportunityConditions?.probationPeriodDisplay}
          />
        }
      >
        <span className="mr-s-8">{t('labels.probationPeriod')}</span>

        {conditions?.probationPeriod ? (
          <span className="text-16 font-semibold text-primary">
            {t('suffix.week', {
              count: Number(conditions.probationPeriod),
            })}
          </span>
        ) : (
          <span>-</span>
        )}
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="flex flex-col">
                <div className="mr-s-8">
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.paymentMethodValue
                      ? t(
                          `enum.requestConditionPaymentMethodCode.short.${recruitmentOpportunityConditions?.paymentMethodValue?.toLowerCase()}`
                        )
                      : '-'}
                  </span>
                </div>
                {recruitmentOpportunityConditions?.paymentFrequencyValue && (
                  <div className="mr-s-8 mt-s-24">
                    <span className="text-16 font-semibold text-primary">
                      {t(
                        `enum.requestConditionPaymentFrequencyCode.${recruitmentOpportunityConditions?.paymentFrequencyValue?.toLowerCase()}`
                      )}
                    </span>
                  </div>
                )}
              </div>
            }
            visible={!!recruitmentOpportunityConditions?.paymentMethodDisplay}
          />
        }
      >
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.paymentMethod')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.paymentMethod
                ? t(
                    `enum.requestConditionPaymentMethodCode.${conditions?.paymentMethod?.toLowerCase()}`
                  )
                : '-'}
            </span>
          </div>
          {conditions?.paymentFrequency && (
            <div className="mr-s-8 mt-s-24 flex">
              <span className="mr-s-8">{t(`labels.paymentFrequency`)}</span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.paymentFrequency
                  ? t(
                      `enum.requestConditionPaymentFrequencyCode.${conditions?.paymentFrequency?.toLowerCase()}`
                    )
                  : '-'}
              </span>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <span className="text-16 font-semibold text-primary">
                {recruitmentOpportunityConditions?.depositValue
                  ? tGlobal('suffix.currencyValue', {
                      value: recruitmentOpportunityConditions?.depositValue,
                    })
                  : '-'}
              </span>
            }
            visible={!!recruitmentOpportunityConditions?.depositDisplay}
          />
        }
      >
        <span className="mr-s-8">{t('labels.depositPayment')}</span>

        <span className="text-16 font-semibold text-primary">
          {conditions?.deposit
            ? tGlobal('suffix.currencyValue', {
                value: conditions?.deposit,
              })
            : '-'}
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="flex flex-col">
                <span className="text-16 text-center font-semibold text-primary">
                  {t('labels.baseBudget')}
                </span>
                <div className="flex flex-col">
                  <span className="text-16 text-center font-semibold text-primary  mt-s-18">
                    {t('labels.min')}
                    {tGlobal('suffix.currencyValue', {
                      value:
                        recruitmentOpportunityConditions?.minimumBudgetValue,
                    })}
                  </span>
                  <span className="text-16 text-center font-semibold text-primary  mt-s-18">
                    {t('labels.max')}
                    {tGlobal('suffix.currencyValue', {
                      value:
                        recruitmentOpportunityConditions?.maximumBudgetValue,
                    })}
                  </span>
                </div>
              </div>
            }
            visible={!!recruitmentOpportunityConditions?.budgetDisplay}
          />
        }
      >
        <>
          <span className="mr-s-8">{t('labels.remuneration')}</span>

          <span className="text-16 font-semibold text-primary">
            {remuneration}
          </span>
        </>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="flex flex-col mt-s-36">
                {recruitmentOpportunityConditions?.minGuaranteedPeriodValue ? (
                  <span className="text-16 font-semibold text-primary">
                    {`${recruitmentOpportunityConditions.minGuaranteedPeriodValue} ${t('suffix.months')}`}
                  </span>
                ) : (
                  '-'
                )}

                {recruitmentOpportunityConditions?.minGuaranteedPeriodBonusValue && (
                  <div className="mt-s-24">
                    <span className="text-16 font-semibold text-primary">
                      {tGlobal('suffix.currencyValue', {
                        value:
                          recruitmentOpportunityConditions?.minGuaranteedPeriodBonusValue,
                      })}
                    </span>
                  </div>
                )}
              </div>
            }
            visible={
              !!recruitmentOpportunity?.generalContractualConditions
                ?.minGuaranteedPeriodDisplay
            }
          />
        }
      >
        <div className="flex flex-col">
          <span className="mr-s-8">{t('labels.additionalBonusTitle')}</span>

          <div className="mt-s-24">
            <span className="mr-s-8">{t('labels.minGuaranteedPeriod')}</span>

            <span className="text-16 font-semibold text-primary">
              {conditions?.minGuaranteedPeriod
                ? `${conditions.minGuaranteedPeriod} ${t('suffix.months')}`
                : '-'}
            </span>
          </div>

          <div className="mt-s-24">
            <span className="mr-s-8">
              {t('labels.minGuaranteedPeriodBonusValueOffer')}
            </span>
            {conditions?.minGuaranteedPeriodBonus ? (
              <span className="text-16 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: conditions?.minGuaranteedPeriodBonus,
                })}
              </span>
            ) : (
              <span>-</span>
            )}
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        partialBorderTop
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="flex flex-col">
                <p className="mr-s-8">
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.bonusMonthValue
                      ? `${recruitmentOpportunityConditions.bonusMonthValue} ${t('suffix.months')}`
                      : '-'}
                  </span>
                </p>

                <p className="mr-s-8 mt-s-24">
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.bonusValue
                      ? tGlobal('suffix.currencyValue', {
                          value: recruitmentOpportunityConditions?.bonusValue,
                        })
                      : '-'}
                  </span>
                </p>
              </div>
            }
            visible={!!recruitmentOpportunityConditions?.bonusDisplay}
          />
        }
      >
        <div className="flex flex-col">
          <p className="mr-s-8">
            {t('labels.ifCandidate')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.bonusMonth
                ? `${conditions.bonusMonth} ${t('suffix.months')}`
                : '-'}
            </span>
            <span className="ml-s-8">{t('labels.stayAfterHired')}</span>
          </p>

          <p className="mr-s-8 mt-s-24">
            {t('labels.additionalBonusRequested')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.bonus
                ? tGlobal('suffix.currencyValue', {
                    value: conditions?.bonus,
                  })
                : '-'}
            </span>
          </p>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        partialBorderTop
        rightChildren={
          <VisibilitySwitcher
            content={
              <div className="flex flex-col">
                <p className="mr-s-8">
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.hiredDateBonusRequirement
                      ? formatDate(
                          recruitmentOpportunityConditions?.hiredDateBonusRequirement,
                          i18n.language
                        )
                      : '-'}
                  </span>
                </p>

                <p className="mr-s-8 mt-s-24">
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.hiredDateBonusValue
                      ? tGlobal('suffix.currencyValue', {
                          value:
                            recruitmentOpportunityConditions?.hiredDateBonusValue,
                        })
                      : '-'}
                  </span>
                </p>
              </div>
            }
            visible={!!recruitmentOpportunityConditions?.hiredDateBonusDisplay}
          />
        }
      >
        <div className="flex flex-col">
          <p className="mr-s-8">
            {t('labels.ifHiredBefore')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.hiredDateBonusRequirement
                ? formatDate(
                    conditions.hiredDateBonusRequirement,
                    i18n.language
                  )
                : '-'}
            </span>
          </p>

          <p className="mr-s-8 mt-s-24">
            {t('labels.additionalBonusRequested')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.hiredDateBonus
                ? tGlobal('suffix.currencyValue', {
                    value: conditions?.hiredDateBonus,
                  })
                : '-'}
            </span>
          </p>
        </div>
      </SectionGridRow>
    </>
  );
};

export default GeneralConditions;
