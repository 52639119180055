import { gql } from 'urql';

const UserFragment = gql`
  fragment UserFragment on User {
    id
    bio
    defaultAccountId
    email
    emailVerified
    facebookUrl
    firstName
    instagramUrl
    jobDescription
    language
    lastName
    linkedinUrl
    location
    name
    phoneNumber
    phoneDisplay
    professionalEmail
    spokenLanguages
    twitterUrl
    why
    impersonatingUserId
  }
`;

export default UserFragment;
