import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { validate as isUUID } from 'uuid';

import {
  ButtonLink,
  ConfirmationModal,
  IconButton,
  PageHeader,
  PageLoader,
} from '@application/components';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { RecruitmentNegotiationRoomStatus } from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';

import ListIconButtonLink from '../components/ListIconButtonLink';
import {
  useGetRecruitmentNegotiationRoom,
  useRejectRecruitmentNegotiationRoom,
} from '../hooks';
import { RequestTable } from '../tables';
import { InitialOfferTable } from '../tables/initial-offer-table';
import { NegotiatingOfferTable } from '../tables/negotiating-offer-table';

const ViewRecruitmentNegotiationRoomPage = () => {
  const [showLoader, setShowLoader] = useState(false);

  const { i18n, t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.room',
  });
  const { id = '' } = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const { setModal } = useContext(ModalContext);

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
    refetchRecruitmentRoom,
  } = useGetRecruitmentNegotiationRoom(id);

  const { rejectNegotiationRoom } = useRejectRecruitmentNegotiationRoom();

  const requestDetailsPath = useMemo(
    () =>
      data?.requestId
        ? generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, {
            id: data.requestId,
          })
        : '#',
    [data?.requestId]
  );

  const agreementPath = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_AGREEMENT, {
        id,
      }),
    [id]
  );

  const pathToRecruitmentOpportunityChatRoom = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_CHAT, {
        id: data?.request.id ?? '',
      }),
    [data?.request.id]
  );

  const handleLeave = useCallback(async () => {
    setModal(null);
    const result = await rejectNegotiationRoom({ input: { roomId: id } });

    if (result?.data?.recruitmentNegotiationRoomReject.room) {
      refetchRecruitmentRoom();
    }
  }, [refetchRecruitmentRoom, rejectNegotiationRoom, setModal, id]);

  const confirmLeave = useCallback(() => {
    setModal({
      title: t('modal.leaveNegotiationRoomTitle'),
      content: (
        <ConfirmationModal
          confirmButtonClassName="hover:btn-error border-error-content bg-error-content text-white"
          content={t('modal.leaveNegotiationRoomMessage', {
            accountName: data?.request.account.name,
          })}
          onCancel={() => setModal(null)}
          onConfirm={handleLeave}
        />
      ),
    });
  }, [setModal, t, handleLeave, data]);

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  return showLoader && isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={getLocalizedDescription(
          data?.request?.jobSpecialty?.descriptions,
          i18n.language
        )}
        subtitle={data?.request?.operationUnit?.name}
        backTo={RootPrivatePage.RECRUITMENT_NEGOTIATION_ROOMS}
      >
        <div className="flex gap-s-16">
          {data?.status === RecruitmentNegotiationRoomStatus.Accepted && (
            <ButtonLink
              icon={<i className="ri-shake-hands-line" />}
              primary
              to={agreementPath}
            >
              {t('button.preAgreement')}
            </ButtonLink>
          )}

          <ButtonLink to={requestDetailsPath} from={location.pathname}>
            {t('button.requestDetails')}
          </ButtonLink>

          <ListIconButtonLink
            // count={10}
            iconName="ri-question-answer-line"
            to={pathToRecruitmentOpportunityChatRoom}
            size="medium"
            from={location.pathname}
          />

          {data?.status === RecruitmentNegotiationRoomStatus.InProgress && (
            <IconButton
              text={t('button.leave')}
              icon={<i className="ri-logout-box-r-line" />}
              onClick={confirmLeave}
            />
          )}
        </div>
      </PageHeader>

      <div className="relative">
        <div className="overflow-x-auto">
          <div className="flex gap-s-16">
            {data?.request && (
              <RequestTable className="bg-neutral" request={data?.request} />
            )}

            {data?.offer && <InitialOfferTable offer={data?.offer} />}

            {data && (
              <NegotiatingOfferTable
                room={data}
                refetchRoom={refetchRecruitmentRoom}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRecruitmentNegotiationRoomPage;
