export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** File upload scalar type */
  Upload: { input: any; output: any; }
};

/** For accepting an invitation */
export type AcceptInvitationInput = {
  /** the unique id of the account user accepting the invitation */
  readonly accountUserId: Scalars['String']['input'];
  /** whether the user accepts the terms and conditions or not */
  readonly termsAndConditionsAccepted: Scalars['Boolean']['input'];
};

/** Response when accepting an invitation */
export type AcceptInvitationResponse = {
  readonly __typename?: 'AcceptInvitationResponse';
  readonly accountUser: AccountUser;
};

/**
 * An account is the representation of a company/agency/candidate in Kehops,
 *   An account can have multiple operation units but should have at least one which represents
 *   the copmany itself, (the headquarters if you'd like)
 */
export type Account = {
  readonly __typename?: 'Account';
  /** address of the account */
  readonly address?: Maybe<Scalars['String']['output']>;
  /** advantage codes associated to a given account */
  readonly advantageCodes: ReadonlyArray<AccountAdvantageTypeCode>;
  /** informations about the subscription of an agency account */
  readonly agencySubscriptionInformations?: Maybe<AccountAgencySubscriptionInformations>;
  /** seats available of the subscription */
  readonly availableSeats?: Maybe<Scalars['Float']['output']>;
  /** customer id */
  readonly customerId?: Maybe<Scalars['String']['output']>;
  /** employer offer description */
  readonly employerOfferDescription?: Maybe<Scalars['String']['output']>;
  /** informations about the subscription of an enterprise account */
  readonly enterpriseSubscriptionInformations?: Maybe<AccountEnterpriseSubscriptionInformations>;
  /** facebook Url of the account phone */
  readonly facebookUrl?: Maybe<Scalars['String']['output']>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** industry type as identified by the industry type code of the account */
  readonly industryType?: Maybe<IndustryType>;
  /** industry type of the account */
  readonly industryTypeCode?: Maybe<AccountIndustryType>;
  /** instagram Url of the account phone */
  readonly instagramUrl?: Maybe<Scalars['String']['output']>;
  /** number of invitations pending for this account */
  readonly invitationsCount: Scalars['Float']['output'];
  /** linkedin Url of the account phone */
  readonly linkedinUrl?: Maybe<Scalars['String']['output']>;
  /** absolute URL of the account logo */
  readonly logoUrl?: Maybe<Scalars['String']['output']>;
  /** name of the account */
  readonly name: Scalars['String']['output'];
  /** operation territories linked to a given account */
  readonly operationTerritories?: Maybe<ReadonlyArray<AccountOperationTerritory>>;
  /** operation units linked to a given account */
  readonly operationUnits?: Maybe<ReadonlyArray<OperationUnit>>;
  /** owner of given account */
  readonly owner?: Maybe<User>;
  /** owner Id of the account */
  readonly ownerId: Scalars['String']['output'];
  /** display of the account phone number  */
  readonly phoneDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** phone number of the account */
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  /** preferred specialties of a given account. */
  readonly preferredSpecialties: ReadonlyArray<AccountPreferredSpecialty>;
  /** sector of this account */
  readonly sector?: Maybe<IndustrySector>;
  /** sector code of the account */
  readonly sectorCode?: Maybe<Scalars['String']['output']>;
  /** Services offered by an agency account */
  readonly services?: Maybe<ReadonlyArray<ServiceType>>;
  /** account size type */
  readonly sizeType?: Maybe<AccountSize>;
  /** employees number range of the account */
  readonly sizeTypeCode?: Maybe<AccountSizeTypeCode>;
  /** subcription id */
  readonly subscriptionId?: Maybe<Scalars['String']['output']>;
  /** billing subscription status */
  readonly subscriptionStatus?: Maybe<AccountBillingSubscriptionStatusCode>;
  /** billing subscription trial end date */
  readonly subscriptionTrialEnd?: Maybe<Scalars['DateTime']['output']>;
  /** billing subscription type */
  readonly subscriptionType?: Maybe<AccountBillingSubscriptionType>;
  /** twitter Url of the account phone */
  readonly twitterUrl?: Maybe<Scalars['String']['output']>;
  /** type of the account */
  readonly type: AccountTypeCode;
  /** users linked to a given account */
  readonly users?: Maybe<ReadonlyArray<AccountUser>>;
  /** website of the account */
  readonly website?: Maybe<Scalars['String']['output']>;
};

/** account advantage */
export type AccountAdvantage = {
  readonly __typename?: 'AccountAdvantage';
  /** unique code */
  readonly code: AccountAdvantageTypeCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** unique code of an account advantage type */
export const AccountAdvantageTypeCode = {
  AdditionalTime: 'ADDITIONAL_TIME',
  CollectiveInsurance: 'COLLECTIVE_INSURANCE',
  CollectiveRrsp: 'COLLECTIVE_RRSP',
  DiverseAllowance: 'DIVERSE_ALLOWANCE',
  ExtraPaidHolidays: 'EXTRA_PAID_HOLIDAYS',
  FlexibleHours: 'FLEXIBLE_HOURS',
  FourDaysWeek: 'FOUR_DAYS_WEEK',
  MedicalAssistance: 'MEDICAL_ASSISTANCE',
  RemoteWork: 'REMOTE_WORK',
  SocialClub: 'SOCIAL_CLUB',
  Trainings: 'TRAININGS',
  WorkSecurity: 'WORK_SECURITY'
} as const;

export type AccountAdvantageTypeCode = typeof AccountAdvantageTypeCode[keyof typeof AccountAdvantageTypeCode];
/** Response to the account advantage types list */
export type AccountAdvantageTypesResponse = {
  readonly __typename?: 'AccountAdvantageTypesResponse';
  readonly values: ReadonlyArray<AccountAdvantage>;
};

/** The informations about the subscription of an agency account */
export type AccountAgencySubscriptionInformations = {
  readonly __typename?: 'AccountAgencySubscriptionInformations';
  /** number of allowed candidate requests per month for the account */
  readonly proAllowedCandidateRequests?: Maybe<Scalars['Float']['output']>;
  /** number of allowed offers on BRH requests per month for the account */
  readonly proAllowedOffers?: Maybe<Scalars['Float']['output']>;
  /** number of remaining candidate requests per month for the account */
  readonly proRemainingCandidateRequests?: Maybe<Scalars['Float']['output']>;
  /** number of remaining offers on BRH requests per month for the account */
  readonly proRemainingOffers?: Maybe<Scalars['Float']['output']>;
};

/** unique code of a billing subscription status of an account */
export const AccountBillingSubscriptionStatusCode = {
  Active: 'ACTIVE',
  Canceled: 'CANCELED',
  Incomplete: 'INCOMPLETE',
  IncompleteExpired: 'INCOMPLETE_EXPIRED',
  PastDue: 'PAST_DUE',
  Paused: 'PAUSED',
  Trialing: 'TRIALING',
  Unpaid: 'UNPAID'
} as const;

export type AccountBillingSubscriptionStatusCode = typeof AccountBillingSubscriptionStatusCode[keyof typeof AccountBillingSubscriptionStatusCode];
/** unique code of a billing subscription type of an account */
export const AccountBillingSubscriptionType = {
  AgencyPro: 'AGENCY_PRO',
  AgencyTeam: 'AGENCY_TEAM',
  AgencyViewOnly: 'AGENCY_VIEW_ONLY',
  EnterpriseFreemium: 'ENTERPRISE_FREEMIUM',
  EnterprisePremium: 'ENTERPRISE_PREMIUM',
  EnterpriseSignature: 'ENTERPRISE_SIGNATURE'
} as const;

export type AccountBillingSubscriptionType = typeof AccountBillingSubscriptionType[keyof typeof AccountBillingSubscriptionType];
export type AccountConnection = {
  readonly __typename?: 'AccountConnection';
  readonly edges?: Maybe<ReadonlyArray<AccountEdge>>;
  readonly pageInfo?: Maybe<AccountPageInfo>;
};

export type AccountCreateInput = {
  /** the account address */
  readonly address?: InputMaybe<Scalars['String']['input']>;
  /** the account employer offer description */
  readonly employerOfferDescription?: InputMaybe<Scalars['String']['input']>;
  /** the account facebookUrl */
  readonly facebookUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account industry type */
  readonly industryTypeCode?: InputMaybe<AccountIndustryType>;
  /** the account instagramUrl */
  readonly instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account linkedinUrl */
  readonly linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account name */
  readonly name: Scalars['String']['input'];
  /** the account operation territory */
  readonly operationTerritoryCode?: InputMaybe<OperationTerritoryCode>;
  /** the account phone visibility */
  readonly phoneDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** the account phone number */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** the account sector */
  readonly sectorCode?: InputMaybe<Scalars['String']['input']>;
  /** Services of the agency account */
  readonly services?: InputMaybe<ReadonlyArray<ServiceType>>;
  /** the account employees number range */
  readonly sizeTypeCode?: InputMaybe<AccountSizeTypeCode>;
  /** are the terms and conditions accepted */
  readonly termsAndConditionsAccepted: Scalars['Boolean']['input'];
  /** the account twitterUrl */
  readonly twitterUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account type */
  readonly type: AccountTypeCode;
  /** the account website */
  readonly website?: InputMaybe<Scalars['String']['input']>;
};

/** response to the logo delete request */
export type AccountDeleteLogoResponse = {
  readonly __typename?: 'AccountDeleteLogoResponse';
  readonly ok: Scalars['Boolean']['output'];
};

export type AccountEdge = {
  readonly __typename?: 'AccountEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Account>;
};

/** The informations about the subscription of an enterprise account */
export type AccountEnterpriseSubscriptionInformations = {
  readonly __typename?: 'AccountEnterpriseSubscriptionInformations';
  /** number of allowed candidate offers for a premium or freemium enterprise account */
  readonly allowedCandidateOffers?: Maybe<Scalars['Float']['output']>;
  /** number of allowed request for a freemium enterprise account */
  readonly freemiumAllowedRequests?: Maybe<Scalars['Float']['output']>;
  /** number of remaining request for a freemium enterprise account */
  readonly freemiumRemainingRequests?: Maybe<Scalars['Float']['output']>;
  /** number of allowed seats for a premium enterprise account */
  readonly premiumAllowedSeats?: Maybe<Scalars['Float']['output']>;
  /** number of remaining seats for a premium enterprise account */
  readonly premiumRemainingSeats?: Maybe<Scalars['Float']['output']>;
  /** number of remaining candidate offer for a premium or freemium enterprise account */
  readonly remainingCandidateOffers?: Maybe<Scalars['Float']['output']>;
};

export const AccountIndustryType = {
  AdministrativeProfessionalScientificAndTechnicalServices: 'ADMINISTRATIVE_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES',
  AgricultureForestryFishingAndHunting: 'AGRICULTURE_FORESTRY_FISHING_AND_HUNTING',
  ArtsShowsSportsAndLeisure: 'ARTS_SHOWS_SPORTS_AND_LEISURE',
  ConstructionCivilEngineeringBuildingAndLandscapingMaintenance: 'CONSTRUCTION_CIVIL_ENGINEERING_BUILDING_AND_LANDSCAPING_MAINTENANCE',
  EducationEarlyChildhood: 'EDUCATION_EARLY_CHILDHOOD',
  FinanceBanksInsuranceAccounting: 'FINANCE_BANKS_INSURANCE_ACCOUNTING',
  HealthCareAndSocialAssistance: 'HEALTH_CARE_AND_SOCIAL_ASSISTANCE',
  HospitalityTourismCatering: 'HOSPITALITY_TOURISM_CATERING',
  ItTechnologiesStorageAndDataProcessing: 'IT_TECHNOLOGIES_STORAGE_AND_DATA_PROCESSING',
  ManufacturingAndProcessing: 'MANUFACTURING_AND_PROCESSING',
  MediaCultureInformationServices: 'MEDIA_CULTURE_INFORMATION_SERVICES',
  MiningQuarryingOilAndGasExtraction: 'MINING_QUARRYING_OIL_AND_GAS_EXTRACTION',
  PublicServicesPublicAdministration: 'PUBLIC_SERVICES_PUBLIC_ADMINISTRATION',
  RealEstateLeasing: 'REAL_ESTATE_LEASING',
  TransportationAndStorage: 'TRANSPORTATION_AND_STORAGE',
  WholesaleAndRetailTrade: 'WHOLESALE_AND_RETAIL_TRADE'
} as const;

export type AccountIndustryType = typeof AccountIndustryType[keyof typeof AccountIndustryType];
export type AccountOperationTerritoriesCreateInput = {
  /** operation territory code */
  readonly operationTerritoryCodes: ReadonlyArray<OperationTerritoryCode>;
};

export type AccountOperationTerritoriesFilters = {
  /** filter on operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** filter on description of account operation territories */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing account operation territories */
export type AccountOperationTerritoriesResponse = {
  readonly __typename?: 'AccountOperationTerritoriesResponse';
  readonly page: AccountOperationTerritoryConnection;
  readonly pageData?: Maybe<PageData>;
};

/** The fields by which account operation territories can be sorted */
export const AccountOperationTerritoriesSortBy = {
  OperationTerritoryEn: 'OPERATION_TERRITORY_EN',
  OperationTerritoryFr: 'OPERATION_TERRITORY_FR'
} as const;

export type AccountOperationTerritoriesSortBy = typeof AccountOperationTerritoriesSortBy[keyof typeof AccountOperationTerritoriesSortBy];
/** For requesting account operation territories to be sorted */
export type AccountOperationTerritoriesSortDirective = {
  readonly by: AccountOperationTerritoriesSortBy;
  readonly direction: SortDirection;
};

/** Representation of an account operation territory */
export type AccountOperationTerritory = {
  readonly __typename?: 'AccountOperationTerritory';
  /** account Id */
  readonly accountId: Scalars['String']['output'];
  /** indicate if the account operation territory is active */
  readonly active?: Maybe<Scalars['Boolean']['output']>;
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** operation territory */
  readonly operationTerritory: OperationTerritory;
  /** operation territory code */
  readonly operationTerritoryCode: OperationTerritoryCode;
  /** update time */
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type AccountOperationTerritoryConnection = {
  readonly __typename?: 'AccountOperationTerritoryConnection';
  readonly edges?: Maybe<ReadonlyArray<AccountOperationTerritoryEdge>>;
  readonly pageInfo?: Maybe<AccountOperationTerritoryPageInfo>;
};

export type AccountOperationTerritoryEdge = {
  readonly __typename?: 'AccountOperationTerritoryEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<AccountOperationTerritory>;
};

export type AccountOperationTerritoryPageInfo = {
  readonly __typename?: 'AccountOperationTerritoryPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

export type AccountOperationTerritoryResponse = {
  readonly __typename?: 'AccountOperationTerritoryResponse';
  /** account operation territory */
  readonly accountOperationTerritory: AccountOperationTerritory;
};

export type AccountOperationTerritoryUpdateInput = {
  /** is the account operation territory active */
  readonly active: Scalars['Boolean']['input'];
  /** the account operation territory unique id */
  readonly id: Scalars['String']['input'];
};

export type AccountPageInfo = {
  readonly __typename?: 'AccountPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

export type AccountPreferredSpecialtiesCreateInput = {
  /** unique id of the account associated with the preferred specialties */
  readonly accountId: Scalars['String']['input'];
  /** unique ids of the specialties to create */
  readonly jobSpecialtyIds: ReadonlyArray<Scalars['String']['input']>;
};

export type AccountPreferredSpecialtiesCreateResponse = {
  readonly __typename?: 'AccountPreferredSpecialtiesCreateResponse';
  /** created account preferred specialties */
  readonly accountPreferredSpecialties: ReadonlyArray<AccountPreferredSpecialty>;
};

export type AccountPreferredSpecialtiesFilters = {
  /** filter on industry codes */
  readonly industryCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on sector codes */
  readonly sectorCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on parts of the account preferred specialty */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing account preferred specialties */
export type AccountPreferredSpecialtiesResponse = {
  readonly __typename?: 'AccountPreferredSpecialtiesResponse';
  readonly page: AccountPreferredSpecialtyConnection;
  readonly pageData?: Maybe<PageData>;
};

/** For requesting account preferred specialties to be sorted */
export type AccountPreferredSpecialtiesSortDirective = {
  readonly by: AccountPreferredSpecialtySortBy;
  readonly direction: SortDirection;
};

/** A preferred specialty of a particular account. */
export type AccountPreferredSpecialty = {
  readonly __typename?: 'AccountPreferredSpecialty';
  /** unique id of the account related to this specialty */
  readonly accountId: Scalars['String']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** specialty info */
  readonly specialty: JobSpecialty;
};

export type AccountPreferredSpecialtyConnection = {
  readonly __typename?: 'AccountPreferredSpecialtyConnection';
  readonly edges?: Maybe<ReadonlyArray<AccountPreferredSpecialtyEdge>>;
  readonly pageInfo?: Maybe<AccountPreferredSpecialtyPageInfo>;
};

export type AccountPreferredSpecialtyEdge = {
  readonly __typename?: 'AccountPreferredSpecialtyEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<AccountPreferredSpecialty>;
};

/** errors that can occur when calling account preferred specialty endpoints. */
export const AccountPreferredSpecialtyErrors = {
  /** The specialty you're trying to create to is already created for this account. */
  AlreadyExistsForAccount: 'ALREADY_EXISTS_FOR_ACCOUNT'
} as const;

export type AccountPreferredSpecialtyErrors = typeof AccountPreferredSpecialtyErrors[keyof typeof AccountPreferredSpecialtyErrors];
export type AccountPreferredSpecialtyPageInfo = {
  readonly __typename?: 'AccountPreferredSpecialtyPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** The fields by which account preferred specialties can be sorted */
export const AccountPreferredSpecialtySortBy = {
  IndustryCode: 'INDUSTRY_CODE',
  SectorCode: 'SECTOR_CODE',
  SpecialtyCode: 'SPECIALTY_CODE'
} as const;

export type AccountPreferredSpecialtySortBy = typeof AccountPreferredSpecialtySortBy[keyof typeof AccountPreferredSpecialtySortBy];
/** Account info */
export type AccountResponse = {
  readonly __typename?: 'AccountResponse';
  readonly account?: Maybe<Account>;
};

/** size of a company owning an account */
export type AccountSize = {
  readonly __typename?: 'AccountSize';
  /** unique code */
  readonly code: AccountSizeTypeCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** unique code of the size of a company */
export const AccountSizeTypeCode = {
  EnterpriseSize_01: 'ENTERPRISE_SIZE_01',
  EnterpriseSize_02: 'ENTERPRISE_SIZE_02',
  EnterpriseSize_03: 'ENTERPRISE_SIZE_03',
  EnterpriseSize_04: 'ENTERPRISE_SIZE_04'
} as const;

export type AccountSizeTypeCode = typeof AccountSizeTypeCode[keyof typeof AccountSizeTypeCode];
/** Response to the account sizes list */
export type AccountSizesResponse = {
  readonly __typename?: 'AccountSizesResponse';
  readonly values: ReadonlyArray<AccountSize>;
};

/** type of a company */
export type AccountType = {
  readonly __typename?: 'AccountType';
  /** unique code */
  readonly code: AccountTypeCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** unique code of a company type */
export const AccountTypeCode = {
  Agency: 'AGENCY',
  Candidate: 'CANDIDATE',
  Enterprise: 'ENTERPRISE'
} as const;

export type AccountTypeCode = typeof AccountTypeCode[keyof typeof AccountTypeCode];
/** Response to the account types list */
export type AccountTypesResponse = {
  readonly __typename?: 'AccountTypesResponse';
  readonly values: ReadonlyArray<AccountType>;
};

export type AccountUpdateInput = {
  /** the account address */
  readonly address?: InputMaybe<Scalars['String']['input']>;
  /** advantage codes of the account */
  readonly advantageCodes?: InputMaybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** the account employer offer description */
  readonly employerOfferDescription?: InputMaybe<Scalars['String']['input']>;
  /** the account facebookUrl */
  readonly facebookUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account industry type */
  readonly industryTypeCode?: InputMaybe<AccountIndustryType>;
  /** the account instagramUrl */
  readonly instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account linkedinUrl */
  readonly linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account name */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** the account operation territory */
  readonly operationTerritoryCode?: InputMaybe<OperationTerritoryCode>;
  /** the account phone visibility */
  readonly phoneDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** the account phone number */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** the account sector */
  readonly sectorCode?: InputMaybe<Scalars['String']['input']>;
  /** Services of the agency account */
  readonly services?: InputMaybe<ReadonlyArray<ServiceType>>;
  /** the account employees number range */
  readonly sizeTypeCode?: InputMaybe<AccountSizeTypeCode>;
  /** the account twitterUrl */
  readonly twitterUrl?: InputMaybe<Scalars['String']['input']>;
  /** the account website */
  readonly website?: InputMaybe<Scalars['String']['input']>;
};

/** for updating the logo of the current user's account */
export type AccountUpdateLogoInput = {
  /** the filename to use */
  readonly filename: Scalars['String']['input'];
};

/** response to the logo update request */
export type AccountUpdateLogoResponse = {
  readonly __typename?: 'AccountUpdateLogoResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** A user attached to an account with a given role */
export type AccountUser = {
  readonly __typename?: 'AccountUser';
  /** account info of given accountUser link */
  readonly account?: Maybe<Account>;
  /** account Id */
  readonly accountId: Scalars['String']['output'];
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** last update time */
  readonly lastUpdatedAt: Scalars['DateTime']['output'];
  /** role of the user in the account */
  readonly role: UserRole;
  /** current status of the account user */
  readonly status: AccountUserStatus;
  /** user info of given accountUser link */
  readonly user?: Maybe<User>;
  /** used Id of the user linked to the account */
  readonly userId: Scalars['String']['output'];
};

export type AccountUserConnection = {
  readonly __typename?: 'AccountUserConnection';
  readonly edges?: Maybe<ReadonlyArray<AccountUserEdge>>;
  readonly pageInfo?: Maybe<AccountUserPageInfo>;
};

export type AccountUserEdge = {
  readonly __typename?: 'AccountUserEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<AccountUser>;
};

/** errors that can occur when calling account user endpoints. */
export const AccountUserErrors = {
  /** The account does not have enough available seats to invite another user. */
  NoAvailableSeats: 'NO_AVAILABLE_SEATS',
  /** Only the owner and admins can upgrade the subscription of the account */
  OnlyOwnerOrAdminCanUpgradeSubscription: 'ONLY_OWNER_OR_ADMIN_CAN_UPGRADE_SUBSCRIPTION',
  /** The premium subscription limit of 10 users has been reached. */
  PremiumLimitReached: 'PREMIUM_LIMIT_REACHED'
} as const;

export type AccountUserErrors = typeof AccountUserErrors[keyof typeof AccountUserErrors];
export type AccountUserPageInfo = {
  readonly __typename?: 'AccountUserPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** The fields by which account users can be sorted */
export const AccountUserSortBy = {
  CreatedAt: 'CREATED_AT',
  LastUpdatedAt: 'LAST_UPDATED_AT',
  Name: 'NAME'
} as const;

export type AccountUserSortBy = typeof AccountUserSortBy[keyof typeof AccountUserSortBy];
/** status of an account user */
export const AccountUserStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  PendingInvitation: 'PENDING_INVITATION'
} as const;

export type AccountUserStatus = typeof AccountUserStatus[keyof typeof AccountUserStatus];
/** For searching account users */
export type AccountUsersFilters = {
  /** exclude account user of this status */
  readonly excludedStatus?: InputMaybe<AccountUserStatus>;
  /** filters on the status of the account user */
  readonly status?: InputMaybe<AccountUserStatus>;
};

/** Response containing account users */
export type AccountUsersResponse = {
  readonly __typename?: 'AccountUsersResponse';
  readonly page: AccountUserConnection;
  readonly pageData?: Maybe<PageData>;
};

/** For requesting account users to be sorted */
export type AccountUsersSortDirective = {
  readonly by: AccountUserSortBy;
  readonly direction: SortDirection;
};

export type AccountsFilters = {
  /** filters on the account id or owner id or customer id or subscription id */
  readonly id?: InputMaybe<Scalars['String']['input']>;
  /** performs a text search across various fields */
  readonly text?: InputMaybe<Scalars['String']['input']>;
  /** filter on the account type */
  readonly type?: InputMaybe<AccountTypeCode>;
};

/** Accounts info */
export type AccountsResponse = {
  readonly __typename?: 'AccountsResponse';
  readonly page: AccountConnection;
  readonly pageData?: Maybe<PageData>;
};

/** The fields by which accounts can be sorted */
export const AccountsSortBy = {
  Name: 'NAME',
  SubscriptionStatus: 'SUBSCRIPTION_STATUS',
  SubscriptionTrialEnd: 'SUBSCRIPTION_TRIAL_END',
  SubscriptionType: 'SUBSCRIPTION_TYPE'
} as const;

export type AccountsSortBy = typeof AccountsSortBy[keyof typeof AccountsSortBy];
/** For requesting accounts to be sorted */
export type AccountsSortDirective = {
  readonly by: AccountsSortBy;
  readonly direction: SortDirection;
};

export type BooleanAnswer = {
  readonly __typename?: 'BooleanAnswer';
  readonly isTrue: Scalars['Boolean']['output'];
};

/** billing period in the available candidate flow */
export const CandidateConditionBillingPeriod = {
  BiMonthly: 'BI_MONTHLY',
  HalfYearly: 'HALF_YEARLY',
  Monthly: 'MONTHLY',
  Quarterly: 'QUARTERLY',
  Weekly: 'WEEKLY',
  Yearly: 'YEARLY'
} as const;

export type CandidateConditionBillingPeriod = typeof CandidateConditionBillingPeriod[keyof typeof CandidateConditionBillingPeriod];
/** decide which party is responsible of a contractual field in the available candidates flow */
export const CandidateConditionResponsibility = {
  Agency: 'AGENCY',
  Enterprise: 'ENTERPRISE',
  NonApplicable: 'NON_APPLICABLE'
} as const;

export type CandidateConditionResponsibility = typeof CandidateConditionResponsibility[keyof typeof CandidateConditionResponsibility];
/**
 * A candidate negotiation is the entity that represent a negotiation between
 *   a request and multiple offers (negotiation room) in the candidate flow. Technically, only
 *   the company that made the request can see this entity because it is linked to every offers.
 */
export type CandidateNegotiation = {
  readonly __typename?: 'CandidateNegotiation';
  /** account id of the negotiation creator, should be the same as the candidate request's account id */
  readonly accountId: Scalars['String']['output'];
  /** candidate request linked to this negotiation */
  readonly candidateRequest: CandidateRequest;
  /** request id of the candidate request to which the negotiation is from */
  readonly candidateRequestId: Scalars['String']['output'];
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** latest activity on negotiation */
  readonly latestActivity: Scalars['DateTime']['output'];
  /** number of unread notification for this negotiation */
  readonly notificationCount: Scalars['Int']['output'];
  /** negotiations rooms linked to this negotiation */
  readonly rooms: ReadonlyArray<CandidateNegotiationRoom>;
  /** number of rooms linked to this negotiation */
  readonly roomsCount: Scalars['Int']['output'];
  /** status of the negotiation */
  readonly status: CandidateNegotiationStatus;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** user id of the negotiation creator */
  readonly userId: Scalars['String']['output'];
};

export type CandidateNegotiationCompleteInput = {
  /** negotiation id to complete */
  readonly negotiationId: Scalars['String']['input'];
  /** room id to accept */
  readonly roomId: Scalars['String']['input'];
};

export type CandidateNegotiationCreateInput = {
  /** candidate offer ids to create the negotiation from */
  readonly candidateOfferIds: ReadonlyArray<Scalars['String']['input']>;
  /** candidate request id to create the negotiation from */
  readonly candidateRequestId: Scalars['String']['input'];
};

/** Define the state of the general properties that are negotiable. */
export type CandidateNegotiationGeneralPropertiesState = {
  readonly __typename?: 'CandidateNegotiationGeneralPropertiesState';
  /** duration state */
  readonly duration: CandidateNegotiationPropertyState;
  /** hours state */
  readonly hours: CandidateNegotiationPropertyState;
  /** job mode state */
  readonly jobMode: CandidateNegotiationPropertyState;
  /** job type state */
  readonly jobType: CandidateNegotiationPropertyState;
  /** operation territory state */
  readonly operationTerritory: CandidateNegotiationPropertyState;
  /** other remunerations state */
  readonly otherRemunerations: CandidateNegotiationPropertyState;
  /** salary state */
  readonly salary: CandidateNegotiationPropertyState;
};

export type CandidateNegotiationInput = {
  /** candidate negotiation unique id */
  readonly negotiationId: Scalars['String']['input'];
};

/** Define the state of the outsourcing conditions properties that are negotiable. */
export type CandidateNegotiationOutsourcingPropertiesState = {
  readonly __typename?: 'CandidateNegotiationOutsourcingPropertiesState';
  /** billing period state */
  readonly billingPeriod: CandidateNegotiationPropertyState;
  /** minimum notice state */
  readonly minimumNotice: CandidateNegotiationPropertyState;
};

/** Statuses that a candidate negotiation room can have. */
export const CandidateNegotiationPropertyState = {
  Accepted: 'ACCEPTED',
  Initial: 'INITIAL',
  Modified: 'MODIFIED',
  Rejected: 'REJECTED'
} as const;

export type CandidateNegotiationPropertyState = typeof CandidateNegotiationPropertyState[keyof typeof CandidateNegotiationPropertyState];
/** Define the state of the recruitment conditions properties that are negotiable. */
export type CandidateNegotiationRecruitmentPropertiesState = {
  readonly __typename?: 'CandidateNegotiationRecruitmentPropertiesState';
  /** additional bonus */
  readonly bonus: CandidateNegotiationPropertyState;
  /** maximum budget state */
  readonly budget: CandidateNegotiationPropertyState;
  /** maximum guaranteed period value (in months) */
  readonly guaranteedPeriod?: Maybe<CandidateNegotiationPropertyState>;
  /** payment method state */
  readonly paymentMethod: CandidateNegotiationPropertyState;
};

export type CandidateNegotiationResponse = {
  readonly __typename?: 'CandidateNegotiationResponse';
  /** candidate negotiation */
  readonly negotiation: CandidateNegotiation;
};

/**
 * A candidate negotiation room is the entity that represent a negotiation between
 *   a request and a single offer in the candidate flow. It is linked to a single candidate negotiation.
 *   Both parties can see everything in this model.
 */
export type CandidateNegotiationRoom = {
  readonly __typename?: 'CandidateNegotiationRoom';
  /** id of the negotiation this room is linked to */
  readonly candidateNegotiationId: Scalars['String']['output'];
  /** Candidate offer linked to this negotiation room */
  readonly candidateOffer: CandidateOffer;
  /** account id of the candidate offer creator */
  readonly candidateOfferAccountId: Scalars['String']['output'];
  /** id of the candidate offer linked to this room */
  readonly candidateOfferId: Scalars['String']['output'];
  /** user id of the candidate offer creator */
  readonly candidateOfferUserId: Scalars['String']['output'];
  /** candidate request linked to this negotiation room */
  readonly candidateRequest: CandidateRequest;
  /** account id of the candidate request creator */
  readonly candidateRequestAccountId: Scalars['String']['output'];
  /** id of the candidate request linked to this room */
  readonly candidateRequestId: Scalars['String']['output'];
  /** user id of the candidate request creator */
  readonly candidateRequestUserId: Scalars['String']['output'];
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** state of the general properties */
  readonly generalPropertiesState: CandidateNegotiationGeneralPropertiesState;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** Negotiating candidate offer linked to this negotiation room */
  readonly negotiatingCandidateOffer: CandidateOffer;
  /**
   * id of the candidate offer the negotiation is being performed upon.
   *       This is different that the candidateOfferId.
   *       This offer will never be shown to any user, it is only there to be updated.
   *       This is because we cannot update the original offer because once it's published, it's frozen in time.
   *       This also gives us the ability to keep using our current matching score algorithm.
   */
  readonly negotiatingCandidateOfferId: Scalars['String']['output'];
  /** number of unread notification for this room */
  readonly notificationCount: Scalars['Int']['output'];
  /** state of the outsourcing conditions properties */
  readonly outsourcingPropertiesState: CandidateNegotiationOutsourcingPropertiesState;
  /** state of the recruitment conditions properties */
  readonly recruitmentPropertiesState: CandidateNegotiationRecruitmentPropertiesState;
  /** status of the negotiation room */
  readonly status: CandidateNegotiationRoomStatus;
  /** state of the temporary placement conditions properties */
  readonly temporaryPlacementPropertiesState: CandidateNegotiationTemporaryPlacementPropertiesState;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type CandidateNegotiationRoomInput = {
  /** candidate negotiation room unique id */
  readonly roomId: Scalars['String']['input'];
};

export type CandidateNegotiationRoomResponse = {
  readonly __typename?: 'CandidateNegotiationRoomResponse';
  /** candidate negotiation room */
  readonly room: CandidateNegotiationRoom;
};

/** Statuses that a candidate negotiation room can have. */
export const CandidateNegotiationRoomStatus = {
  Accepted: 'ACCEPTED',
  InProgress: 'IN_PROGRESS',
  Rejected: 'REJECTED'
} as const;

export type CandidateNegotiationRoomStatus = typeof CandidateNegotiationRoomStatus[keyof typeof CandidateNegotiationRoomStatus];
export type CandidateNegotiationRoomsFilters = {
  /** filter by candidate negotiation statuses */
  readonly statuses?: InputMaybe<ReadonlyArray<CandidateNegotiationRoomStatus>>;
};

export type CandidateNegotiationRoomsRejectInput = {
  /** candidate negotiation rooms ids to reject */
  readonly roomIds: ReadonlyArray<Scalars['String']['input']>;
};

export type CandidateNegotiationRoomsRejectResponse = {
  readonly __typename?: 'CandidateNegotiationRoomsRejectResponse';
  /** rejected candidate negotiation rooms */
  readonly rooms: ReadonlyArray<CandidateNegotiationRoom>;
};

export type CandidateNegotiationRoomsResponse = {
  readonly __typename?: 'CandidateNegotiationRoomsResponse';
  /** candidate negotiation rooms */
  readonly results: ReadonlyArray<CandidateNegotiationRoom>;
  /** number of items */
  readonly totalCount: Scalars['Int']['output'];
};

export type CandidateNegotiationStateUpdateInput = {
  /** candidate negotiation general properties state */
  readonly generalPropertiesState?: InputMaybe<PartialCandidateNegotiationGeneralPropertiesState>;
  /** candidate negotiation room unique id */
  readonly id: Scalars['String']['input'];
  /** candidate negotiation outsourcing properties state */
  readonly outsourcingPropertiesState?: InputMaybe<PartialCandidateNegotiationOutsourcingPropertiesState>;
  /** candidate negotiation recruitment properties state */
  readonly recruitmentPropertiesState?: InputMaybe<PartialCandidateNegotiationRecruitmentPropertiesState>;
  /** candidate negotiation temporary placement properties state */
  readonly temporaryPlacementPropertiesState?: InputMaybe<PartialCandidateNegotiationTemporaryPlacementPropertiesState>;
};

/** Statuses that a candidate negotiation can have. */
export const CandidateNegotiationStatus = {
  Completed: 'COMPLETED',
  InProgress: 'IN_PROGRESS'
} as const;

export type CandidateNegotiationStatus = typeof CandidateNegotiationStatus[keyof typeof CandidateNegotiationStatus];
/** Define the state of the temporary placement conditions properties that are negotiable. */
export type CandidateNegotiationTemporaryPlacementPropertiesState = {
  readonly __typename?: 'CandidateNegotiationTemporaryPlacementPropertiesState';
  /** billing period state */
  readonly billingPeriod: CandidateNegotiationPropertyState;
  /** minimum notice before ending contract (in weeks) */
  readonly minimumNotice: CandidateNegotiationPropertyState;
  /** possible buyback bonus state */
  readonly possibleBuybackBonus: CandidateNegotiationPropertyState;
};

export type CandidateNegotiationsFilters = {
  /** filter by candidate negotiation statuses */
  readonly statuses?: InputMaybe<ReadonlyArray<CandidateNegotiationStatus>>;
};

export type CandidateNegotiationsResponse = {
  readonly __typename?: 'CandidateNegotiationsResponse';
  /** candidate negotiations */
  readonly results: ReadonlyArray<CandidateNegotiation>;
  /** number of items */
  readonly totalCount: Scalars['Int']['output'];
};

/**
 * A candidate offer is made by a enterprise towards the agencies' requests.
 *   It's a way for enterprise to offer their available employees.
 */
export type CandidateOffer = {
  readonly __typename?: 'CandidateOffer';
  /** candidate offer's account */
  readonly account: Account;
  /** account Id */
  readonly accountId: Scalars['String']['output'];
  /** annual maximum salary */
  readonly annualSalaryMax?: Maybe<Scalars['Int']['output']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: Maybe<Scalars['Int']['output']>;
  /** candidate offer available transitions */
  readonly availableTransitions: ReadonlyArray<OfferStatusTypeCode>;
  /** bonus to the candidate */
  readonly candidateBonus?: Maybe<CandidateOfferBonus>;
  /** amount of the bonus to the candidate */
  readonly candidateBonusAmount?: Maybe<Scalars['String']['output']>;
  /** Candidate opportunity on which the offer was made */
  readonly candidateOpportunity: CandidateOpportunity;
  /** id of the candidate request this offer is associated with */
  readonly candidateRequestId: Scalars['String']['output'];
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** candidate full-time availability */
  readonly fullTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** hourly bonus */
  readonly hourlyBonus?: Maybe<Scalars['String']['output']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: Maybe<Scalars['String']['output']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: Maybe<Scalars['String']['output']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** duration of contract in months */
  readonly jobDurationInMonths?: Maybe<Scalars['Int']['output']>;
  /** job work mode */
  readonly jobModeCode?: Maybe<JobModeCode>;
  /** job specialty of the candidate request associated with this offer */
  readonly jobSpecialty?: Maybe<JobSpecialty>;
  /** job type */
  readonly jobType?: Maybe<JobDurationCode>;
  /** whether or not offerer profile should be linked to offer */
  readonly linkProfile?: Maybe<Scalars['Boolean']['output']>;
  /** candidate offer matching score */
  readonly matchingScore?: Maybe<Scalars['String']['output']>;
  /** id of the negotiation room room associated to this offer */
  readonly negotiationRoomId?: Maybe<Scalars['String']['output']>;
  /** operation territories */
  readonly operationTerritories?: Maybe<ReadonlyArray<OperationTerritory>>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: Maybe<ReadonlyArray<OperationTerritoryCode>>;
  /** operation unit */
  readonly operationUnit?: Maybe<OperationUnit>;
  /** id of the operation unit this offer is associated with */
  readonly operationUnitId?: Maybe<Scalars['String']['output']>;
  /** other payment amount */
  readonly otherPaymentAmount?: Maybe<Scalars['String']['output']>;
  /** other payment type */
  readonly otherPaymentTypeCode?: Maybe<JobPaymentOtherTypesCode>;
  /** contractual outsourcing conditions */
  readonly outsourcingConditions?: Maybe<CandidateOfferOutsourcingConditions>;
  /** candidate part-time availability */
  readonly partTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: Maybe<JobPaymentFrequencyCode>;
  /** perks wanted */
  readonly perks?: Maybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** candidate offer potential amount (or potential value) */
  readonly potentialAmount?: Maybe<Scalars['String']['output']>;
  /** candidate offer potential job duration */
  readonly potentialJobDuration?: Maybe<Scalars['Int']['output']>;
  /** candidate offer rank */
  readonly rank?: Maybe<Scalars['Int']['output']>;
  /** contractual recruitment conditions */
  readonly recruitmentConditions?: Maybe<CandidateOfferRecruitmentConditions>;
  /** number of allowed remote days */
  readonly remoteDaysAllowed?: Maybe<Scalars['Int']['output']>;
  /** remuneration amount */
  readonly remunerationAmount?: Maybe<Scalars['String']['output']>;
  /** sales pitch */
  readonly salesPitch?: Maybe<Scalars['String']['output']>;
  /** candidate schedules */
  readonly schedules?: Maybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** seasons a candidate need to work when selecting seasonal job type */
  readonly seasons?: Maybe<ReadonlyArray<Season>>;
  /** status of the offer */
  readonly status: OfferStatusTypeCode;
  /** contractual temporary placement conditions */
  readonly temporaryPlacementConditions?: Maybe<CandidateOfferTemporaryPlacementConditions>;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** candidate offer's user */
  readonly user: User;
  /** user id of the offer creator */
  readonly userId: Scalars['String']['output'];
};

/** determine when we want to offer a bonus */
export const CandidateOfferBonus = {
  Hiring: 'HIRING',
  Interview: 'INTERVIEW'
} as const;

export type CandidateOfferBonus = typeof CandidateOfferBonus[keyof typeof CandidateOfferBonus];
export type CandidateOfferConnection = {
  readonly __typename?: 'CandidateOfferConnection';
  readonly edges?: Maybe<ReadonlyArray<CandidateOfferEdge>>;
  readonly pageInfo?: Maybe<CandidateOfferPageInfo>;
};

export type CandidateOfferCreateInput = {
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** bonus to the candidate */
  readonly candidateBonus?: InputMaybe<CandidateOfferBonus>;
  /** amount of the bonus to the candidate */
  readonly candidateBonusAmount?: InputMaybe<Scalars['String']['input']>;
  /** unique id of the candidate request on which we want to create an offer */
  readonly candidateRequestId: Scalars['String']['input'];
  /** candidate full-time availability */
  readonly fullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job type */
  readonly jobType?: InputMaybe<JobDurationCode>;
  /** whether or not offerer profile should be linked to offer */
  readonly linkProfile?: InputMaybe<Scalars['Boolean']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** id of the operation unit this offer is associated with */
  readonly operationUnitId?: InputMaybe<Scalars['String']['input']>;
  /** other payment amount */
  readonly otherPaymentAmount?: InputMaybe<Scalars['String']['input']>;
  /** other payment type */
  readonly otherPaymentTypeCode?: InputMaybe<JobPaymentOtherTypesCode>;
  /** outsourcing billing period */
  readonly outsourcingConditionBillingPeriod?: InputMaybe<CandidateConditionBillingPeriod>;
  /** outsourcing minimum notice before ending contract (in weeks) */
  readonly outsourcingConditionMinimumNotice?: InputMaybe<Scalars['Int']['input']>;
  /** candidate part-time availability */
  readonly partTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** perks wanted */
  readonly perks?: InputMaybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** publish offer now */
  readonly publishNow: Scalars['Boolean']['input'];
  /** recruitment additional bonus */
  readonly recruitmentConditionBonus?: InputMaybe<Scalars['String']['input']>;
  /** recruitment maximum budget */
  readonly recruitmentConditionBudget?: InputMaybe<Scalars['String']['input']>;
  /** recruitment deposit value */
  readonly recruitmentConditionDeposit?: InputMaybe<Scalars['String']['input']>;
  /** recruitment maximum guaranteed period value (in months) */
  readonly recruitmentConditionGuaranteedPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** recruitment number of payments */
  readonly recruitmentConditionPaymentAmount?: InputMaybe<Scalars['Int']['input']>;
  /** recruitment frequency of payments */
  readonly recruitmentConditionPaymentFrequency?: InputMaybe<OfferConditionPaymentFrequencyCode>;
  /** recruitment payment method condition value */
  readonly recruitmentConditionPaymentMethod?: InputMaybe<OfferConditionPaymentMethodCode>;
  /** recruitment probation period (in weeks) */
  readonly recruitmentConditionProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** recruitment payment method condition for start of agreement value */
  readonly recruitmentConditionStartOfAgreementPaymentMethod?: InputMaybe<OfferConditionStartOfAgreementPaymentMethodCode>;
  /** number of allowed remote days */
  readonly remoteDaysAllowed?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** sales pitch */
  readonly salesPitch?: InputMaybe<Scalars['String']['input']>;
  /** candidate schedules */
  readonly schedules?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** seasons a candidate need to work when selecting seasonal job type */
  readonly seasons?: InputMaybe<ReadonlyArray<Season>>;
  /** temporary placement billing period */
  readonly temporaryPlacementConditionBillingPeriod?: InputMaybe<CandidateConditionBillingPeriod>;
  /** temporary placement cnesst responsibility */
  readonly temporaryPlacementConditionCnesstResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** temporary placement employee help program responsibility */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** temporary placement minimum notice before ending contract (in weeks) */
  readonly temporaryPlacementConditionMinimumNotice?: InputMaybe<Scalars['Int']['input']>;
  /** temporary placement other responsibilities */
  readonly temporaryPlacementConditionOtherResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** temporary placement possible buyback of the candidate within 6 months  */
  readonly temporaryPlacementConditionPossibleBuyback?: InputMaybe<Scalars['Boolean']['input']>;
  /** temporary placement bonus when buying back candidate */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
  /** temporary placement minimum probation period (in weeks) */
  readonly temporaryPlacementConditionProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** temporary placement training expenses responsibility */
  readonly temporaryPlacementConditionTrainingExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** temporary placement travel expenses responsibility */
  readonly temporaryPlacementConditionTravelExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
};

export type CandidateOfferEdge = {
  readonly __typename?: 'CandidateOfferEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<CandidateOffer>;
};

/** errors that can occur when calling candidate offer endpoints. */
export const CandidateOfferErrors = {
  /** Cannot delete an offer that is currently in a negotiation. */
  CannotDeleteInNegotiationOffer: 'CANNOT_DELETE_IN_NEGOTIATION_OFFER',
  /** Cannot delete an offer that has already been processed. */
  CannotDeleteProcessedOffer: 'CANNOT_DELETE_PROCESSED_OFFER',
  /** Cannot publish candidate offer. The request has already been completed. */
  CannotPublishOfferRequestCompleted: 'CANNOT_PUBLISH_OFFER_REQUEST_COMPLETED',
  /** Cannot update a candidate offer that is currently in a negotiation. */
  CannotUpdateInNegotiationOffer: 'CANNOT_UPDATE_IN_NEGOTIATION_OFFER',
  /** Cannot update an offer that has already been processed. */
  CannotUpdateProcessedOffer: 'CANNOT_UPDATE_PROCESSED_OFFER',
  /** Cannot publish candidate offer because some required fields are missing. */
  MissingRequiredFields: 'MISSING_REQUIRED_FIELDS',
  /** Cannot create or update candidate offer because some fields have restricted values. */
  RestrictedFields: 'RESTRICTED_FIELDS',
  /** The subscription limit has been reached. */
  SubscriptionLimitReached: 'SUBSCRIPTION_LIMIT_REACHED',
  /** Cannot publish request because you have the VIEW ONLY subscription */
  ViewOnlyCreateRestriction: 'VIEW_ONLY_CREATE_RESTRICTION'
} as const;

export type CandidateOfferErrors = typeof CandidateOfferErrors[keyof typeof CandidateOfferErrors];
/** Input for getting a candidate offer */
export type CandidateOfferInput = {
  /** the id of the candidate offer to get */
  readonly candidateOfferId: Scalars['String']['input'];
};

/** Outsourcing contractual conditions of a candidate offer. */
export type CandidateOfferOutsourcingConditions = {
  readonly __typename?: 'CandidateOfferOutsourcingConditions';
  /** billing period */
  readonly billingPeriod?: Maybe<CandidateConditionBillingPeriod>;
  /** minimum notice before ending contract (in weeks) */
  readonly minimumNotice?: Maybe<Scalars['Int']['output']>;
};

export type CandidateOfferPageInfo = {
  readonly __typename?: 'CandidateOfferPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Contractual recruitment conditions of a candidate offer. */
export type CandidateOfferRecruitmentConditions = {
  readonly __typename?: 'CandidateOfferRecruitmentConditions';
  /** additional bonus */
  readonly bonus?: Maybe<Scalars['String']['output']>;
  /** maximum budget */
  readonly budget?: Maybe<Scalars['String']['output']>;
  /** deposit value */
  readonly deposit?: Maybe<Scalars['String']['output']>;
  /** maximum guaranteed period value (in months) */
  readonly guaranteedPeriod?: Maybe<Scalars['Int']['output']>;
  /** number of payments */
  readonly paymentAmount?: Maybe<Scalars['Int']['output']>;
  /** frequency of payments */
  readonly paymentFrequency?: Maybe<OfferConditionPaymentFrequencyCode>;
  /** payment method condition value */
  readonly paymentMethod?: Maybe<OfferConditionPaymentMethodCode>;
  /** probation period (in weeks) */
  readonly probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** payment method condition for start of agreement value */
  readonly startOfAgreementPaymentMethod?: Maybe<OfferConditionStartOfAgreementPaymentMethodCode>;
};

/** Candidate offer response */
export type CandidateOfferResponse = {
  readonly __typename?: 'CandidateOfferResponse';
  readonly candidateOffer?: Maybe<CandidateOffer>;
};

/** Temporary placement contractual conditions of a candidate offer. */
export type CandidateOfferTemporaryPlacementConditions = {
  readonly __typename?: 'CandidateOfferTemporaryPlacementConditions';
  /** billing period */
  readonly billingPeriod?: Maybe<CandidateConditionBillingPeriod>;
  /** cnesst responsibility */
  readonly cnesstResponsibility?: Maybe<CandidateConditionResponsibility>;
  /** employee help program responsibility */
  readonly employeeHelpProgramResponsibility?: Maybe<CandidateConditionResponsibility>;
  /** minimum notice before ending contract (in weeks) */
  readonly minimumNotice?: Maybe<Scalars['Int']['output']>;
  /** other responsibilities */
  readonly otherResponsibilities?: Maybe<Scalars['String']['output']>;
  /** possible buyback of the candidate within 6 months  */
  readonly possibleBuyback?: Maybe<Scalars['Boolean']['output']>;
  /** bonus when buying back candidate */
  readonly possibleBuybackBonus?: Maybe<Scalars['String']['output']>;
  /** minimum probation period (in weeks) */
  readonly probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** training expenses responsibility */
  readonly trainingExpensesResponsibility?: Maybe<CandidateConditionResponsibility>;
  /** travel expenses responsibility */
  readonly travelExpensesResponsibility?: Maybe<CandidateConditionResponsibility>;
};

export type CandidateOfferUpdateInput = {
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** bonus to the candidate */
  readonly candidateBonus?: InputMaybe<CandidateOfferBonus>;
  /** amount of the bonus to the candidate */
  readonly candidateBonusAmount?: InputMaybe<Scalars['String']['input']>;
  /** candidate full-time availability */
  readonly fullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** candidate offer unique id */
  readonly id: Scalars['String']['input'];
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job type */
  readonly jobType?: InputMaybe<JobDurationCode>;
  /** whether or not offerer profile should be linked to offer */
  readonly linkProfile?: InputMaybe<Scalars['Boolean']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** id of the operation unit this offer is associated with */
  readonly operationUnitId?: InputMaybe<Scalars['String']['input']>;
  /** other payment amount */
  readonly otherPaymentAmount?: InputMaybe<Scalars['String']['input']>;
  /** other payment type */
  readonly otherPaymentTypeCode?: InputMaybe<JobPaymentOtherTypesCode>;
  /** outsourcing billing period */
  readonly outsourcingConditionBillingPeriod?: InputMaybe<CandidateConditionBillingPeriod>;
  /** outsourcing minimum notice before ending contract (in weeks) */
  readonly outsourcingConditionMinimumNotice?: InputMaybe<Scalars['Int']['input']>;
  /** candidate part-time availability */
  readonly partTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** perks wanted */
  readonly perks?: InputMaybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** recruitment additional bonus */
  readonly recruitmentConditionBonus?: InputMaybe<Scalars['String']['input']>;
  /** recruitment maximum budget */
  readonly recruitmentConditionBudget?: InputMaybe<Scalars['String']['input']>;
  /** recruitment deposit value */
  readonly recruitmentConditionDeposit?: InputMaybe<Scalars['String']['input']>;
  /** recruitment maximum guaranteed period value (in months) */
  readonly recruitmentConditionGuaranteedPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** recruitment number of payments */
  readonly recruitmentConditionPaymentAmount?: InputMaybe<Scalars['Int']['input']>;
  /** recruitment frequency of payments */
  readonly recruitmentConditionPaymentFrequency?: InputMaybe<OfferConditionPaymentFrequencyCode>;
  /** recruitment payment method condition value */
  readonly recruitmentConditionPaymentMethod?: InputMaybe<OfferConditionPaymentMethodCode>;
  /** recruitment probation period (in weeks) */
  readonly recruitmentConditionProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** recruitment payment method condition for start of agreement value */
  readonly recruitmentConditionStartOfAgreementPaymentMethod?: InputMaybe<OfferConditionStartOfAgreementPaymentMethodCode>;
  /** number of allowed remote days */
  readonly remoteDaysAllowed?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** sales pitch */
  readonly salesPitch?: InputMaybe<Scalars['String']['input']>;
  /** candidate schedules */
  readonly schedules?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** seasons a candidate need to work when selecting seasonal job type */
  readonly seasons?: InputMaybe<ReadonlyArray<Season>>;
  /** temporary placement billing period */
  readonly temporaryPlacementConditionBillingPeriod?: InputMaybe<CandidateConditionBillingPeriod>;
  /** temporary placement cnesst responsibility */
  readonly temporaryPlacementConditionCnesstResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** temporary placement employee help program responsibility */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** temporary placement minimum notice before ending contract (in weeks) */
  readonly temporaryPlacementConditionMinimumNotice?: InputMaybe<Scalars['Int']['input']>;
  /** temporary placement other responsibilities */
  readonly temporaryPlacementConditionOtherResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** temporary placement possible buyback of the candidate within 6 months  */
  readonly temporaryPlacementConditionPossibleBuyback?: InputMaybe<Scalars['Boolean']['input']>;
  /** temporary placement bonus when buying back candidate */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
  /** temporary placement minimum probation period (in weeks) */
  readonly temporaryPlacementConditionProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** temporary placement training expenses responsibility */
  readonly temporaryPlacementConditionTrainingExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** temporary placement travel expenses responsibility */
  readonly temporaryPlacementConditionTravelExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
};

export type CandidateOfferUpdateStatusInput = {
  /** candidate offer unique id */
  readonly id: Scalars['String']['input'];
  /** new status of the candidate offer */
  readonly status: OfferStatusTypeCode;
};

export type CandidateOffersFilters = {
  /** filter on offer's candidate request id */
  readonly candidateRequestId?: InputMaybe<Scalars['String']['input']>;
  /** filter on candidate request types */
  readonly candidateRequestTypes?: InputMaybe<ReadonlyArray<CandidateRequestType>>;
  /** filter on creation date of the candidate request */
  readonly dates?: InputMaybe<ReadonlyArray<Scalars['DateTime']['input']>>;
  /** filter on candidate offer ids */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** filter on candidate offer status */
  readonly status?: InputMaybe<ReadonlyArray<OfferStatusTypeCode>>;
  /** filter on parts of the candidate offer */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing candidate offers */
export type CandidateOffersResponse = {
  readonly __typename?: 'CandidateOffersResponse';
  readonly page: CandidateOfferConnection;
  readonly pageData?: Maybe<PageData>;
};

/** the fields by which candidate offers can be sorted */
export const CandidateOffersSortBy = {
  Date: 'DATE',
  Deadline: 'DEADLINE',
  JobSpecialtyEn: 'JOB_SPECIALTY_EN',
  JobSpecialtyFr: 'JOB_SPECIALTY_FR',
  MatchingScore: 'MATCHING_SCORE',
  OperationTerritoryCode: 'OPERATION_TERRITORY_CODE',
  Rank: 'RANK'
} as const;

export type CandidateOffersSortBy = typeof CandidateOffersSortBy[keyof typeof CandidateOffersSortBy];
/** For requesting candidate offers to be sorted */
export type CandidateOffersSortDirective = {
  readonly by: CandidateOffersSortBy;
  readonly direction: SortDirection;
};

export type CandidateOpportunitiesFilters = {
  /** filter candidate opportunity operation territory */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** filter on parts of the candidate opportunity */
  readonly text?: InputMaybe<Scalars['String']['input']>;
  /** filter on candidate request types */
  readonly types?: InputMaybe<ReadonlyArray<CandidateRequestType>>;
};

/** Input for getting candidate opportunities matching a request */
export type CandidateOpportunitiesMatchingRequestInput = {
  /** the id of request that we want the matching candidate opportunities */
  readonly requestId: Scalars['String']['input'];
};

/** Response when fetching the candidate opportunities matching a request */
export type CandidateOpportunitiesMatchingRequestResponse = {
  readonly __typename?: 'CandidateOpportunitiesMatchingRequestResponse';
  readonly CandidateOpportunities: ReadonlyArray<CandidateOpportunity>;
  readonly totalCount: Scalars['Int']['output'];
};

/** Response containing candidate opportunities */
export type CandidateOpportunitiesResponse = {
  readonly __typename?: 'CandidateOpportunitiesResponse';
  readonly page: CandidateOpportunityConnection;
  readonly pageData?: Maybe<PageData>;
};

/** For requesting recruitment opportunities to be sorted */
export type CandidateOpportunitiesSortDirective = {
  readonly by: CandidateRequestsSortBy;
  readonly direction: SortDirection;
};

/**
 * A candidate opportunity is basically a candidate request with all its fields related to the agency and the candidate stripped out.
 *   This means that when an enterprise looks at a recruitment opportunity, it cannot identify the company or the candidate.
 *
 */
export type CandidateOpportunity = {
  readonly __typename?: 'CandidateOpportunity';
  /** additional notes */
  readonly additionalNotes?: Maybe<Scalars['String']['output']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: Maybe<Scalars['Int']['output']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: Maybe<Scalars['Int']['output']>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: Maybe<Scalars['Boolean']['output']>;
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** desired start date */
  readonly desiredStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** candidate experience in years */
  readonly experience?: Maybe<Scalars['Int']['output']>;
  /** candidate full-time availability */
  readonly fullTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** hourly bonus */
  readonly hourlyBonus?: Maybe<Scalars['String']['output']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: Maybe<Scalars['String']['output']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: Maybe<Scalars['String']['output']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** job duration */
  readonly jobDuration?: Maybe<JobDuration>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: Maybe<Scalars['Int']['output']>;
  /** job work mode */
  readonly jobMode?: Maybe<JobMode>;
  /** job work mode */
  readonly jobModeCode?: Maybe<JobModeCode>;
  /** job specialty */
  readonly jobSpecialty?: Maybe<JobSpecialty>;
  /** job speciality id */
  readonly jobSpecialtyId?: Maybe<Scalars['String']['output']>;
  /** job type */
  readonly jobType?: Maybe<JobDurationCode>;
  /** date on which the candidate was met. */
  readonly metAt?: Maybe<Scalars['DateTime']['output']>;
  /** operation territories */
  readonly operationTerritories?: Maybe<ReadonlyArray<OperationTerritory>>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: Maybe<ReadonlyArray<OperationTerritoryCode>>;
  /** other payment amount */
  readonly otherPaymentAmount?: Maybe<Scalars['String']['output']>;
  /** other payment type */
  readonly otherPaymentType?: Maybe<JobPaymentOtherType>;
  /** other payment type code */
  readonly otherPaymentTypeCode?: Maybe<JobPaymentOtherTypesCode>;
  /** contractual outsourcing conditions */
  readonly outsourcingConditions?: Maybe<CandidateRequestOutsourcingConditions>;
  /** candidate part-time availability */
  readonly partTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: Maybe<JobPaymentFrequencyCode>;
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** contractual recruitment conditions */
  readonly recruitmentConditions?: Maybe<CandidateRequestRecruitmentConditions>;
  /** number of allowed remote days */
  readonly remoteDaysAllowed?: Maybe<Scalars['Int']['output']>;
  /** remuneration amount */
  readonly remunerationAmount?: Maybe<Scalars['String']['output']>;
  /** resume url */
  readonly resumeUrl?: Maybe<Scalars['String']['output']>;
  /** candidate schedules */
  readonly schedules?: Maybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** seasons a candidate wants to work when selecting seasonal job type */
  readonly seasons?: Maybe<ReadonlyArray<Season>>;
  /** soft skills */
  readonly softSkills?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** additional specialty info (optional) */
  readonly specialty?: Maybe<Scalars['String']['output']>;
  /** contractual temporary placement conditions */
  readonly temporaryPlacementConditions?: Maybe<CandidateRequestTemporaryPlacementConditions>;
  /** type of the candidate */
  readonly type?: Maybe<CandidateRequestType>;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type CandidateOpportunityConnection = {
  readonly __typename?: 'CandidateOpportunityConnection';
  readonly edges?: Maybe<ReadonlyArray<CandidateOpportunityEdge>>;
  readonly pageInfo?: Maybe<CandidateOpportunityPageInfo>;
};

export type CandidateOpportunityEdge = {
  readonly __typename?: 'CandidateOpportunityEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<CandidateOpportunity>;
};

export type CandidateOpportunityInput = {
  /** unique id of the candidate request representing this opportunity */
  readonly candidateRequestId: Scalars['String']['input'];
};

export type CandidateOpportunityPageInfo = {
  readonly __typename?: 'CandidateOpportunityPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

export type CandidateOpportunityResponse = {
  readonly __typename?: 'CandidateOpportunityResponse';
  /** candidate opportunity */
  readonly opportunity: CandidateOpportunity;
};

/**
 * A candidate request is made by an agency towards the companies.
 *   It's a way for agencies to show their available candidates.
 */
export type CandidateRequest = {
  readonly __typename?: 'CandidateRequest';
  /** candidate request's account */
  readonly account: Account;
  /** account Id */
  readonly accountId: Scalars['String']['output'];
  /** additional notes */
  readonly additionalNotes?: Maybe<Scalars['String']['output']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: Maybe<Scalars['Int']['output']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: Maybe<Scalars['Int']['output']>;
  /** candidate request available transitions */
  readonly availableTransitions: ReadonlyArray<RequestStatusTypeCode>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: Maybe<Scalars['Boolean']['output']>;
  /** candidate negotiation associated to this request */
  readonly candidateNegotiation?: Maybe<CandidateNegotiation>;
  /** number of offers for this candidate request */
  readonly candidateOffersCount: Scalars['Int']['output'];
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** desired start date */
  readonly desiredStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** candidate email */
  readonly email?: Maybe<Scalars['String']['output']>;
  /** candidate experience in years */
  readonly experience?: Maybe<Scalars['Int']['output']>;
  /** candidate first name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** candidate full-time availability */
  readonly fullTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** hourly bonus */
  readonly hourlyBonus?: Maybe<Scalars['String']['output']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: Maybe<Scalars['String']['output']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: Maybe<Scalars['String']['output']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** job duration */
  readonly jobDuration?: Maybe<JobDuration>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: Maybe<Scalars['Int']['output']>;
  /** job work mode */
  readonly jobMode?: Maybe<JobMode>;
  /** job work mode */
  readonly jobModeCode?: Maybe<JobModeCode>;
  /** job specialty */
  readonly jobSpecialty?: Maybe<JobSpecialty>;
  /** job speciality id */
  readonly jobSpecialtyId?: Maybe<Scalars['String']['output']>;
  /** job type */
  readonly jobType?: Maybe<JobDurationCode>;
  /** candidate last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** date on which the candidate was met. */
  readonly metAt?: Maybe<Scalars['DateTime']['output']>;
  /** operation territories */
  readonly operationTerritories?: Maybe<ReadonlyArray<OperationTerritory>>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: Maybe<ReadonlyArray<OperationTerritoryCode>>;
  /** other payment amount */
  readonly otherPaymentAmount?: Maybe<Scalars['String']['output']>;
  /** other payment type */
  readonly otherPaymentType?: Maybe<JobPaymentOtherType>;
  /** other payment type code */
  readonly otherPaymentTypeCode?: Maybe<JobPaymentOtherTypesCode>;
  /** contractual outsourcing conditions */
  readonly outsourcingConditions?: Maybe<CandidateRequestOutsourcingConditions>;
  /** candidate part-time availability */
  readonly partTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: Maybe<JobPaymentFrequencyCode>;
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** contractual recruitment conditions */
  readonly recruitmentConditions?: Maybe<CandidateRequestRecruitmentConditions>;
  /** number of allowed remote days */
  readonly remoteDaysAllowed?: Maybe<Scalars['Int']['output']>;
  /** remuneration amount */
  readonly remunerationAmount?: Maybe<Scalars['String']['output']>;
  /** resume url */
  readonly resumeUrl?: Maybe<Scalars['String']['output']>;
  /** candidate schedules */
  readonly schedules?: Maybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** seasons a candidate wants to work when selecting seasonal job type */
  readonly seasons?: Maybe<ReadonlyArray<Season>>;
  /** soft skills */
  readonly softSkills?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** additional specialty info (optional) */
  readonly specialty?: Maybe<Scalars['String']['output']>;
  /** status of the request */
  readonly status: RequestStatusTypeCode;
  /** contractual temporary placement conditions */
  readonly temporaryPlacementConditions?: Maybe<CandidateRequestTemporaryPlacementConditions>;
  /** test url */
  readonly testUrl?: Maybe<Scalars['String']['output']>;
  /** type of the candidate */
  readonly type?: Maybe<CandidateRequestType>;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** candidate request's user */
  readonly user: User;
  /** user id of the request creator */
  readonly userId: Scalars['String']['output'];
};

/** Input for getting candidate accepted offer of a candidate request */
export type CandidateRequestAcceptedOfferInput = {
  /** the id of the candidate request */
  readonly candidateRequestId: Scalars['String']['input'];
};

/** Response when fetching the candidate accepted offer of a candidate request */
export type CandidateRequestAcceptedOfferResponse = {
  readonly __typename?: 'CandidateRequestAcceptedOfferResponse';
  readonly candidateOffer?: Maybe<CandidateOffer>;
};

export type CandidateRequestConnection = {
  readonly __typename?: 'CandidateRequestConnection';
  readonly edges?: Maybe<ReadonlyArray<CandidateRequestEdge>>;
  readonly pageInfo?: Maybe<CandidateRequestPageInfo>;
};

export type CandidateRequestCreateInput = {
  /** additional notes */
  readonly additionalNotes?: InputMaybe<Scalars['String']['input']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: InputMaybe<Scalars['Boolean']['input']>;
  /** desired start date */
  readonly desiredStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** candidate email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** candidate experience in years */
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  /** candidate request files */
  readonly files?: InputMaybe<ReadonlyArray<CandidateRequestFile>>;
  /** candidate first name */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  /** candidate full-time availability */
  readonly fullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job speciality id */
  readonly jobSpecialtyId?: InputMaybe<Scalars['String']['input']>;
  /** job type */
  readonly jobType?: InputMaybe<JobDurationCode>;
  /** candidate last name */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** date on which the candidate was met. */
  readonly metAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** other payment amount */
  readonly otherPaymentAmount?: InputMaybe<Scalars['String']['input']>;
  /** other payment type */
  readonly otherPaymentTypeCode?: InputMaybe<JobPaymentOtherTypesCode>;
  /** billing period visibility */
  readonly outsourcingConditionBillingPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** billing period value */
  readonly outsourcingConditionBillingPeriodValue?: InputMaybe<CandidateConditionBillingPeriod>;
  /** minimum notice before ending contract visibility */
  readonly outsourcingConditionMinimumNoticeDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum notice before ending contract value (in weeks) */
  readonly outsourcingConditionMinimumNoticeValue?: InputMaybe<Scalars['Int']['input']>;
  /** candidate part-time availability */
  readonly partTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** perks wanted */
  readonly perks?: InputMaybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** publish request now */
  readonly publishNow: Scalars['Boolean']['input'];
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  /** bonus visibility */
  readonly recruitmentConditionBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus if candidate stays a certain amount of months */
  readonly recruitmentConditionBonusMonthValue?: InputMaybe<Scalars['Int']['input']>;
  /** bonus value */
  readonly recruitmentConditionBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** bonus if recruitment is completed under a certain amount of weeks */
  readonly recruitmentConditionBonusWeekValue?: InputMaybe<Scalars['Int']['input']>;
  /** deposit value */
  readonly recruitmentConditionDepositValue?: InputMaybe<Scalars['String']['input']>;
  /** maximum guaranteed period visibility */
  readonly recruitmentConditionGuaranteedPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum guaranteed period value (in months) */
  readonly recruitmentConditionGuaranteedPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** number of payments */
  readonly recruitmentConditionPaymentAmountValue?: InputMaybe<Scalars['Int']['input']>;
  /** frequency of payments */
  readonly recruitmentConditionPaymentFrequencyValue?: InputMaybe<RequestConditionPaymentFrequencyCode>;
  /** payment method condition visibility */
  readonly recruitmentConditionPaymentMethodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment method condition value */
  readonly recruitmentConditionPaymentMethodValue?: InputMaybe<RequestConditionPaymentMethodCode>;
  /** probation period condition visibility */
  readonly recruitmentConditionProbationPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** probation period condition (in weeks) */
  readonly recruitmentConditionProbationPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting fixed amount type */
  readonly recruitmentConditionRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly recruitmentConditionRemunerationEstimatedHours?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting hourly rate type */
  readonly recruitmentConditionRemunerationHourlyRate?: InputMaybe<Scalars['String']['input']>;
  /** remuneration percentage when selecting annual percentage type */
  readonly recruitmentConditionRemunerationPercentage?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration type visibility */
  readonly recruitmentConditionRemunerationTypeDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** remuneration type value */
  readonly recruitmentConditionRemunerationTypeValue?: InputMaybe<ConditionRemunerationType>;
  /** payment method condition for start of agreement value */
  readonly recruitmentConditionStartOfAgreementPaymentMethodValue?: InputMaybe<RequestConditionStartOfAgreementPaymentMethodCode>;
  /** number of allowed remote days */
  readonly remoteDaysAllowed?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** candidate schedules */
  readonly schedules?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** seasons a candidate wants to work when selecting seasonal job type */
  readonly seasons?: InputMaybe<ReadonlyArray<Season>>;
  /** soft skills */
  readonly softSkills?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** additional specialty info (optional) */
  readonly specialty?: InputMaybe<Scalars['String']['input']>;
  /** billing period visibility */
  readonly temporaryPlacementConditionBillingPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** billing period value */
  readonly temporaryPlacementConditionBillingPeriodValue?: InputMaybe<CandidateConditionBillingPeriod>;
  /** cnesst responsibility */
  readonly temporaryPlacementConditionCnesstResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** employee help program responsibility */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** minimum notice before ending contract visibility */
  readonly temporaryPlacementConditionMinimumNoticeDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum notice before ending contract value (in weeks) */
  readonly temporaryPlacementConditionMinimumNoticeValue?: InputMaybe<Scalars['Int']['input']>;
  /** other responsibilities */
  readonly temporaryPlacementConditionOtherResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** bonus when buying back candidate visibility */
  readonly temporaryPlacementConditionPossibleBuybackBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus when buying back candidate value */
  readonly temporaryPlacementConditionPossibleBuybackBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback of the candidate within 6 months visibility */
  readonly temporaryPlacementConditionPossibleBuybackDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** possible buyback of the candidate within 6 months value */
  readonly temporaryPlacementConditionPossibleBuybackValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum probation period visibility */
  readonly temporaryPlacementConditionProbationPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum probation period value (in weeks) */
  readonly temporaryPlacementConditionProbationPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** responsibilities visibility */
  readonly temporaryPlacementConditionResponsibilitiesDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** training expenses responsibility */
  readonly temporaryPlacementConditionTrainingExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** travel expenses responsibility */
  readonly temporaryPlacementConditionTravelExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** type of the candidate */
  readonly type?: InputMaybe<CandidateRequestType>;
};

/** Input for duplicating a candidate request */
export type CandidateRequestDuplicateInput = {
  /** the id of the candidate request to duplicate */
  readonly candidateRequestId: Scalars['String']['input'];
};

export type CandidateRequestEdge = {
  readonly __typename?: 'CandidateRequestEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<CandidateRequest>;
};

/** errors that can occur when calling candidate request endpoints. */
export const CandidateRequestErrors = {
  /** Cannot publish request because the PRO subscription limit has been reached */
  AgencyProLimitReached: 'AGENCY_PRO_LIMIT_REACHED',
  /** The candidate request is associated to one or many candidate offers and cannot be deleted. */
  AssociatedCandidateOffer: 'ASSOCIATED_CANDIDATE_OFFER',
  /** Cannot update a candidate request with a different status than draft. */
  CannotUpdateNonDraftRequest: 'CANNOT_UPDATE_NON_DRAFT_REQUEST',
  /** Cannot publish candidate request because some required fields are missing. */
  MissingRequiredFields: 'MISSING_REQUIRED_FIELDS',
  /** Cannot create or update candidate request because some fields have restricted values. */
  RestrictedFields: 'RESTRICTED_FIELDS',
  /** Cannot publish request because you have the VIEW ONLY subscription */
  ViewOnlyCreateRestriction: 'VIEW_ONLY_CREATE_RESTRICTION'
} as const;

export type CandidateRequestErrors = typeof CandidateRequestErrors[keyof typeof CandidateRequestErrors];
export type CandidateRequestFile = {
  /** file */
  readonly file: Scalars['Upload']['input'];
  /** type of the file */
  readonly type: FileType;
};

/** Input for getting a candidate request */
export type CandidateRequestInput = {
  /** the id of the candidate request to get */
  readonly candidateRequestId: Scalars['String']['input'];
};

/** Input for getting candidate offers of candidate request */
export type CandidateRequestOffersInput = {
  /** the id of the candidate request */
  readonly candidateRequestId: Scalars['String']['input'];
};

/** Response when fetching the candidate offers of a candidate request */
export type CandidateRequestOffersResponse = {
  readonly __typename?: 'CandidateRequestOffersResponse';
  readonly candidateOffers: ReadonlyArray<CandidateOffer>;
  readonly totalCount: Scalars['Int']['output'];
};

/** Outsourcing contractual conditions of a candidate request. */
export type CandidateRequestOutsourcingConditions = {
  readonly __typename?: 'CandidateRequestOutsourcingConditions';
  /** billing period visibility */
  readonly billingPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** billing period value */
  readonly billingPeriodValue?: Maybe<CandidateConditionBillingPeriod>;
  /** minimum notice before ending contract visibility */
  readonly minimumNoticeDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** minimum notice before ending contract value (in weeks) */
  readonly minimumNoticeValue?: Maybe<Scalars['Int']['output']>;
};

export type CandidateRequestPageInfo = {
  readonly __typename?: 'CandidateRequestPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Contractual conditions of a candidate request when selecting recruitment type. */
export type CandidateRequestRecruitmentConditions = {
  readonly __typename?: 'CandidateRequestRecruitmentConditions';
  /** bonus visibility */
  readonly bonusDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** bonus if candidate stays a certain amount of months */
  readonly bonusMonthValue?: Maybe<Scalars['Int']['output']>;
  /** bonus value */
  readonly bonusValue?: Maybe<Scalars['String']['output']>;
  /** bonus if recruitment is completed under a certain amount of weeks */
  readonly bonusWeekValue?: Maybe<Scalars['Int']['output']>;
  /** deposit value */
  readonly depositValue?: Maybe<Scalars['String']['output']>;
  /** maximum guaranteed period visibility */
  readonly guaranteedPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** maximum guaranteed period value (in months) */
  readonly guaranteedPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** number of payments */
  readonly paymentAmountValue?: Maybe<Scalars['Int']['output']>;
  /** frequency of payments */
  readonly paymentFrequencyValue?: Maybe<RequestConditionPaymentFrequencyCode>;
  /** payment method condition visibility */
  readonly paymentMethodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** payment method condition value */
  readonly paymentMethodValue?: Maybe<RequestConditionPaymentMethodCode>;
  /** probation period condition visibility */
  readonly probationPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** probation period condition (in weeks) */
  readonly probationPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** remuneration amount when selecting fixed amount type */
  readonly remunerationAmount?: Maybe<Scalars['String']['output']>;
  /** number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly remunerationEstimatedHours?: Maybe<Scalars['Int']['output']>;
  /** remuneration amount when selecting hourly rate type */
  readonly remunerationHourlyRate?: Maybe<Scalars['String']['output']>;
  /** remuneration percentage when selecting annual percentage type */
  readonly remunerationPercentage?: Maybe<Scalars['Int']['output']>;
  /** remuneration type visibility */
  readonly remunerationTypeDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** remuneration type value */
  readonly remunerationTypeValue?: Maybe<ConditionRemunerationType>;
  /** payment method condition for start of agreement value */
  readonly startOfAgreementPaymentMethodValue?: Maybe<RequestConditionStartOfAgreementPaymentMethodCode>;
};

/** Candidate request info */
export type CandidateRequestResponse = {
  readonly __typename?: 'CandidateRequestResponse';
  readonly candidateRequest?: Maybe<CandidateRequest>;
};

/** Temporary placement contractual conditions of a candidate request. */
export type CandidateRequestTemporaryPlacementConditions = {
  readonly __typename?: 'CandidateRequestTemporaryPlacementConditions';
  /** billing period visibility */
  readonly billingPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** billing period value */
  readonly billingPeriodValue?: Maybe<CandidateConditionBillingPeriod>;
  /** cnesst responsibility */
  readonly cnesstResponsibility?: Maybe<CandidateConditionResponsibility>;
  /** employee help program responsibility */
  readonly employeeHelpProgramResponsibility?: Maybe<CandidateConditionResponsibility>;
  /** minimum notice before ending contract visibility */
  readonly minimumNoticeDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** minimum notice before ending contract value (in weeks) */
  readonly minimumNoticeValue?: Maybe<Scalars['Int']['output']>;
  /** other responsibilities */
  readonly otherResponsibilities?: Maybe<Scalars['String']['output']>;
  /** bonus when buying back candidate visibility */
  readonly possibleBuybackBonusDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** bonus when buying back candidate value */
  readonly possibleBuybackBonusValue?: Maybe<Scalars['String']['output']>;
  /** possible buyback of the candidate within 6 months visibility */
  readonly possibleBuybackDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** possible buyback of the candidate within 6 months value */
  readonly possibleBuybackValue?: Maybe<Scalars['Boolean']['output']>;
  /** minimum probation period visibility */
  readonly probationPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** minimum probation period value (in weeks) */
  readonly probationPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** responsibilities visibility */
  readonly responsibilitiesDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** training expenses responsibility */
  readonly trainingExpensesResponsibility?: Maybe<CandidateConditionResponsibility>;
  /** travel expenses responsibility */
  readonly travelExpensesResponsibility?: Maybe<CandidateConditionResponsibility>;
};

/** type of candidate */
export const CandidateRequestType = {
  Outsourcing: 'OUTSOURCING',
  Recruitment: 'RECRUITMENT',
  TemporaryPlacement: 'TEMPORARY_PLACEMENT'
} as const;

export type CandidateRequestType = typeof CandidateRequestType[keyof typeof CandidateRequestType];
export type CandidateRequestUpdateInput = {
  /** additional notes */
  readonly additionalNotes?: InputMaybe<Scalars['String']['input']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: InputMaybe<Scalars['Boolean']['input']>;
  /** desired start date */
  readonly desiredStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** candidate email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** candidate experience in years */
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  /** candidate request files */
  readonly files?: InputMaybe<ReadonlyArray<CandidateRequestFile>>;
  /** candidate first name */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  /** candidate full-time availability */
  readonly fullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** candidate request unique id */
  readonly id: Scalars['String']['input'];
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job speciality id */
  readonly jobSpecialtyId?: InputMaybe<Scalars['String']['input']>;
  /** job type */
  readonly jobType?: InputMaybe<JobDurationCode>;
  /** candidate last name */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** date on which the candidate was met. */
  readonly metAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** other payment amount */
  readonly otherPaymentAmount?: InputMaybe<Scalars['String']['input']>;
  /** other payment type */
  readonly otherPaymentTypeCode?: InputMaybe<JobPaymentOtherTypesCode>;
  /** billing period visibility */
  readonly outsourcingConditionBillingPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** billing period value */
  readonly outsourcingConditionBillingPeriodValue?: InputMaybe<CandidateConditionBillingPeriod>;
  /** minimum notice before ending contract visibility */
  readonly outsourcingConditionMinimumNoticeDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum notice before ending contract value (in weeks) */
  readonly outsourcingConditionMinimumNoticeValue?: InputMaybe<Scalars['Int']['input']>;
  /** candidate part-time availability */
  readonly partTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** perks wanted */
  readonly perks?: InputMaybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  /** bonus visibility */
  readonly recruitmentConditionBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus if candidate stays a certain amount of months */
  readonly recruitmentConditionBonusMonthValue?: InputMaybe<Scalars['Int']['input']>;
  /** bonus value */
  readonly recruitmentConditionBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** bonus if recruitment is completed under a certain amount of weeks */
  readonly recruitmentConditionBonusWeekValue?: InputMaybe<Scalars['Int']['input']>;
  /** deposit value */
  readonly recruitmentConditionDepositValue?: InputMaybe<Scalars['String']['input']>;
  /** maximum guaranteed period visibility */
  readonly recruitmentConditionGuaranteedPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum guaranteed period value (in months) */
  readonly recruitmentConditionGuaranteedPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** number of payments */
  readonly recruitmentConditionPaymentAmountValue?: InputMaybe<Scalars['Int']['input']>;
  /** frequency of payments */
  readonly recruitmentConditionPaymentFrequencyValue?: InputMaybe<RequestConditionPaymentFrequencyCode>;
  /** payment method condition visibility */
  readonly recruitmentConditionPaymentMethodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment method condition value */
  readonly recruitmentConditionPaymentMethodValue?: InputMaybe<RequestConditionPaymentMethodCode>;
  /** probation period condition visibility */
  readonly recruitmentConditionProbationPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** probation period condition (in weeks) */
  readonly recruitmentConditionProbationPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting fixed amount type */
  readonly recruitmentConditionRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly recruitmentConditionRemunerationEstimatedHours?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting hourly rate type */
  readonly recruitmentConditionRemunerationHourlyRate?: InputMaybe<Scalars['String']['input']>;
  /** remuneration percentage when selecting annual percentage type */
  readonly recruitmentConditionRemunerationPercentage?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration type visibility */
  readonly recruitmentConditionRemunerationTypeDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** remuneration type value */
  readonly recruitmentConditionRemunerationTypeValue?: InputMaybe<ConditionRemunerationType>;
  /** payment method condition for start of agreement value */
  readonly recruitmentConditionStartOfAgreementPaymentMethodValue?: InputMaybe<RequestConditionStartOfAgreementPaymentMethodCode>;
  /** number of allowed remote days */
  readonly remoteDaysAllowed?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** candidate schedules */
  readonly schedules?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** seasons a candidate wants to work when selecting seasonal job type */
  readonly seasons?: InputMaybe<ReadonlyArray<Season>>;
  /** soft skills */
  readonly softSkills?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** additional specialty info (optional) */
  readonly specialty?: InputMaybe<Scalars['String']['input']>;
  /** billing period visibility */
  readonly temporaryPlacementConditionBillingPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** billing period value */
  readonly temporaryPlacementConditionBillingPeriodValue?: InputMaybe<CandidateConditionBillingPeriod>;
  /** cnesst responsibility */
  readonly temporaryPlacementConditionCnesstResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** employee help program responsibility */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** minimum notice before ending contract visibility */
  readonly temporaryPlacementConditionMinimumNoticeDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum notice before ending contract value (in weeks) */
  readonly temporaryPlacementConditionMinimumNoticeValue?: InputMaybe<Scalars['Int']['input']>;
  /** other responsibilities */
  readonly temporaryPlacementConditionOtherResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** bonus when buying back candidate visibility */
  readonly temporaryPlacementConditionPossibleBuybackBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus when buying back candidate value */
  readonly temporaryPlacementConditionPossibleBuybackBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback of the candidate within 6 months visibility */
  readonly temporaryPlacementConditionPossibleBuybackDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** possible buyback of the candidate within 6 months value */
  readonly temporaryPlacementConditionPossibleBuybackValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum probation period visibility */
  readonly temporaryPlacementConditionProbationPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** minimum probation period value (in weeks) */
  readonly temporaryPlacementConditionProbationPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** responsibilities visibility */
  readonly temporaryPlacementConditionResponsibilitiesDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** training expenses responsibility */
  readonly temporaryPlacementConditionTrainingExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** travel expenses responsibility */
  readonly temporaryPlacementConditionTravelExpensesResponsibility?: InputMaybe<CandidateConditionResponsibility>;
  /** type of the candidate */
  readonly type?: InputMaybe<CandidateRequestType>;
};

export type CandidateRequestUpdateStatusInput = {
  /** candidate request unique id */
  readonly id: Scalars['String']['input'];
  /** new status of the candidate request */
  readonly status: RequestStatusTypeCode;
};

export type CandidateRequestsFilters = {
  /** filter on creation date of the candidate request */
  readonly dates?: InputMaybe<ReadonlyArray<Scalars['DateTime']['input']>>;
  /** filter on candidate request ids */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on job specialty ids */
  readonly jobSpecialtyIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** filter on candidate request status */
  readonly status?: InputMaybe<ReadonlyArray<RequestStatusTypeCode>>;
  /** filter on parts of the candidate request */
  readonly text?: InputMaybe<Scalars['String']['input']>;
  /** filter on candidate request types */
  readonly types?: InputMaybe<ReadonlyArray<CandidateRequestType>>;
};

/** Response containing candidate requests */
export type CandidateRequestsResponse = {
  readonly __typename?: 'CandidateRequestsResponse';
  readonly page: CandidateRequestConnection;
  readonly pageData?: Maybe<PageData>;
};

/** the fields by which candidate requests can be sorted */
export const CandidateRequestsSortBy = {
  Date: 'DATE',
  Deadline: 'DEADLINE',
  Experience: 'EXPERIENCE',
  JobSpecialtyEn: 'JOB_SPECIALTY_EN',
  JobSpecialtyFr: 'JOB_SPECIALTY_FR',
  Name: 'NAME',
  OperationTerritoryCode: 'OPERATION_TERRITORY_CODE'
} as const;

export type CandidateRequestsSortBy = typeof CandidateRequestsSortBy[keyof typeof CandidateRequestsSortBy];
/** For requesting candidate requests to be sorted */
export type CandidateRequestsSortDirective = {
  readonly by: CandidateRequestsSortBy;
  readonly direction: SortDirection;
};

/** type of remuneration */
export const ConditionRemunerationType = {
  AnnualSalaryPercentage: 'ANNUAL_SALARY_PERCENTAGE',
  FixedAmount: 'FIXED_AMOUNT',
  HourlyRate: 'HOURLY_RATE'
} as const;

export type ConditionRemunerationType = typeof ConditionRemunerationType[keyof typeof ConditionRemunerationType];
export type CreateAccountOperationTerritoriesResponse = {
  readonly __typename?: 'CreateAccountOperationTerritoriesResponse';
  /** created account operation territories */
  readonly accountOperationTerritories: ReadonlyArray<AccountOperationTerritory>;
};

/** For deleting an account operation territory */
export type DeleteAccountOperationTerritoryInput = {
  /** the unique id of account operation territory to delete */
  readonly accountOperationTerritoryId: Scalars['String']['input'];
};

/** Response when deleting an account operation territory */
export type DeleteAccountOperationTerritoryResponse = {
  readonly __typename?: 'DeleteAccountOperationTerritoryResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting an account preferred specialty */
export type DeleteAccountPreferredSpecialtyInput = {
  /** the unique id of the preferred specialty to delete */
  readonly accountPreferredSpecialtyId: Scalars['String']['input'];
};

/** Response when deleting an account preferred specialty */
export type DeleteAccountPreferredSpecialtyResponse = {
  readonly __typename?: 'DeleteAccountPreferredSpecialtyResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting an account user */
export type DeleteAccountUserInput = {
  /** the unique id of account user to delete */
  readonly accountUserId: Scalars['String']['input'];
};

/** Response when deleting an account user */
export type DeleteAccountUserResponse = {
  readonly __typename?: 'DeleteAccountUserResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting a candidate offer */
export type DeleteCandidateOfferInput = {
  /** the unique id of the candidate offer to delete */
  readonly candidateOfferId: Scalars['String']['input'];
};

/** Response when deleting a candidate offer */
export type DeleteCandidateOfferResponse = {
  readonly __typename?: 'DeleteCandidateOfferResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting a candidate request */
export type DeleteCandidateRequestInput = {
  /** the unique id of the candidate request to delete */
  readonly candidateRequestId: Scalars['String']['input'];
};

/** Response when deleting a candidate request */
export type DeleteCandidateRequestResponse = {
  readonly __typename?: 'DeleteCandidateRequestResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting an invitation */
export type DeleteInvitationInput = {
  /** the unique id of the account user to delete */
  readonly accountUserId: Scalars['String']['input'];
};

/** Response when deleting an invitation */
export type DeleteInvitationResponse = {
  readonly __typename?: 'DeleteInvitationResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting an offer */
export type DeleteOfferInput = {
  /** the unique id of the offer to delete */
  readonly offerId: Scalars['String']['input'];
};

/** Response when deleting an offer */
export type DeleteOfferResponse = {
  readonly __typename?: 'DeleteOfferResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting an operation unit */
export type DeleteOperationUnitInput = {
  /** the unique id of operation unit to delete */
  readonly operationUnitId: Scalars['String']['input'];
};

/** Response when deleting an operation unit */
export type DeleteOperationUnitResponse = {
  readonly __typename?: 'DeleteOperationUnitResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** For deleting a request */
export type DeleteRequestInput = {
  /** the unique id of the request to delete */
  readonly requestId: Scalars['String']['input'];
};

/** Response when deleting a request */
export type DeleteRequestResponse = {
  readonly __typename?: 'DeleteRequestResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** generic multilingual description of a domain model value */
export type Descriptions = {
  readonly __typename?: 'Descriptions';
  /** english description */
  readonly en: Scalars['String']['output'];
  /** french description */
  readonly fr: Scalars['String']['output'];
};

/** Input for duplicating a request */
export type DuplicateRequestInput = {
  /** the id of the request to duplicate */
  readonly requestId: Scalars['String']['input'];
};

/** Education level */
export type EducationLevel = {
  readonly __typename?: 'EducationLevel';
  /** unique code */
  readonly code: EducationLevelCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** Education level code */
export const EducationLevelCode = {
  College: 'COLLEGE',
  FirstCycleUniversity: 'FIRST_CYCLE_UNIVERSITY',
  HighSchool: 'HIGH_SCHOOL',
  Professional: 'PROFESSIONAL',
  SecondCycleUniversity: 'SECOND_CYCLE_UNIVERSITY',
  ThirdCycleUniversity: 'THIRD_CYCLE_UNIVERSITY'
} as const;

export type EducationLevelCode = typeof EducationLevelCode[keyof typeof EducationLevelCode];
/** Education levels */
export type EducationLevelsResponse = {
  readonly __typename?: 'EducationLevelsResponse';
  readonly values: ReadonlyArray<EducationLevel>;
};

/** file types the platform supports */
export const FileType = {
  AccountLogo: 'ACCOUNT_LOGO',
  CandidateRequestResume: 'CANDIDATE_REQUEST_RESUME',
  CandidateRequestTest: 'CANDIDATE_REQUEST_TEST',
  OfferCandidateCv: 'OFFER_CANDIDATE_CV',
  OfferCandidateOtherFiles: 'OFFER_CANDIDATE_OTHER_FILES',
  UserAvatar: 'USER_AVATAR'
} as const;

export type FileType = typeof FileType[keyof typeof FileType];
export type GetOfferInput = {
  /** offer id */
  readonly offerId: Scalars['String']['input'];
};

export type GetPricingTableResponse = {
  readonly __typename?: 'GetPricingTableResponse';
  /** customer session client secret */
  readonly clientSecret: Scalars['String']['output'];
};

export type GetSubscriptionBillingPortalLinkInput = {
  /** Specify the language of the portal billing */
  readonly language: Scalars['String']['input'];
};

export type GetSubscriptionBillingPortalLinkResponse = {
  readonly __typename?: 'GetSubscriptionBillingPortalLinkResponse';
  /** url */
  readonly url: Scalars['String']['output'];
};

export type GetSubscriptionPromoInput = {
  /** Specify the language of the checkout session */
  readonly language: Scalars['String']['input'];
};

export type GetSubscriptionPromoResponse = {
  readonly __typename?: 'GetSubscriptionPromoResponse';
  /** url */
  readonly url: Scalars['String']['output'];
};

/** for adding the impersonating user id */
export type ImpersonateUserInput = {
  /** impersonating user id */
  readonly impersonatingUserId?: InputMaybe<Scalars['String']['input']>;
  /** the id of the user to update */
  readonly userId: Scalars['String']['input'];
};

/** response to the impersonating user id request */
export type ImpersonateUserResponse = {
  readonly __typename?: 'ImpersonateUserResponse';
  readonly user: User;
};

/**
 * Representation of a sector (ex: for the industry type "real estate",
 *     we have "real estate services" and "building inspection" sectors)
 */
export type IndustrySector = {
  readonly __typename?: 'IndustrySector';
  /** unique code */
  readonly code: Scalars['String']['output'];
  /** i18n descriptions */
  readonly descriptions: Descriptions;
  /** industry type the job specialty belongs to */
  readonly industry: IndustryType;
};

/** Industry sectors */
export type IndustrySectorsResponse = {
  readonly __typename?: 'IndustrySectorsResponse';
  readonly values: ReadonlyArray<IndustrySector>;
};

/** Representation of an industry type */
export type IndustryType = {
  readonly __typename?: 'IndustryType';
  /** unique code */
  readonly code: AccountIndustryType;
  /** i18n descriptions */
  readonly descriptions: Descriptions;
};

/** Industry types */
export type IndustryTypesArrayResponse = {
  readonly __typename?: 'IndustryTypesArrayResponse';
  readonly values: ReadonlyArray<IndustryType>;
};

/** For inviting an account user */
export type InviteAccountUserInput = {
  /** the email of the user to invite */
  readonly email: Scalars['String']['input'];
  /** whether or not the endpoint should upgrade the subscription if needed */
  readonly upgradeSubscription: Scalars['Boolean']['input'];
};

/** Response when inviting an account user */
export type InviteAccountUserResponse = {
  readonly __typename?: 'InviteAccountUserResponse';
  readonly accountUser: AccountUser;
};

/** duration of a job */
export type JobDuration = {
  readonly __typename?: 'JobDuration';
  /** unique code */
  readonly code: JobDurationCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** duration of a job */
export const JobDurationCode = {
  Contract: 'CONTRACT',
  Freelance: 'FREELANCE',
  Internship: 'INTERNSHIP',
  Occasional: 'OCCASIONAL',
  OpenEndedContract: 'OPEN_ENDED_CONTRACT',
  Permanent: 'PERMANENT',
  Seasonal: 'SEASONAL'
} as const;

export type JobDurationCode = typeof JobDurationCode[keyof typeof JobDurationCode];
/** Response to the job duration list */
export type JobDurationsResponse = {
  readonly __typename?: 'JobDurationsResponse';
  readonly values: ReadonlyArray<JobDuration>;
};

/** experience level for a job */
export type JobExperienceLevel = {
  readonly __typename?: 'JobExperienceLevel';
  /** unique code */
  readonly code: JobExperienceLevelCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** expected experience level */
export const JobExperienceLevelCode = {
  JobLevel_01: 'JOB_LEVEL_01',
  JobLevel_02: 'JOB_LEVEL_02',
  JobLevel_03: 'JOB_LEVEL_03',
  JobLevel_04: 'JOB_LEVEL_04'
} as const;

export type JobExperienceLevelCode = typeof JobExperienceLevelCode[keyof typeof JobExperienceLevelCode];
/** Response to the job experience list */
export type JobExperienceLevelsResponse = {
  readonly __typename?: 'JobExperienceLevelsResponse';
  readonly values: ReadonlyArray<JobExperienceLevel>;
};

/** work mode for a job */
export type JobMode = {
  readonly __typename?: 'JobMode';
  /** unique code */
  readonly code: JobModeCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** whether the job is on site, remote, or hybrid */
export const JobModeCode = {
  Hybrid: 'HYBRID',
  OnSite: 'ON_SITE',
  Remote: 'REMOTE'
} as const;

export type JobModeCode = typeof JobModeCode[keyof typeof JobModeCode];
/** Response to the job modes list */
export type JobModesResponse = {
  readonly __typename?: 'JobModesResponse';
  readonly values: ReadonlyArray<JobMode>;
};

/** Response to the job payment frequency list */
export type JobPaymentFrequenciesResponse = {
  readonly __typename?: 'JobPaymentFrequenciesResponse';
  readonly values: ReadonlyArray<JobPaymentFrequency>;
};

/** frequency of salary payments */
export type JobPaymentFrequency = {
  readonly __typename?: 'JobPaymentFrequency';
  /** unique code */
  readonly code: JobPaymentFrequencyCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** salary payment frequency codes */
export const JobPaymentFrequencyCode = {
  FixedAmount: 'FIXED_AMOUNT',
  Hourly: 'HOURLY',
  Yearly: 'YEARLY'
} as const;

export type JobPaymentFrequencyCode = typeof JobPaymentFrequencyCode[keyof typeof JobPaymentFrequencyCode];
/** other offered payment types */
export type JobPaymentOtherType = {
  readonly __typename?: 'JobPaymentOtherType';
  /** unique code */
  readonly code: JobPaymentOtherTypesCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** other offered payment types codes */
export const JobPaymentOtherTypesCode = {
  Commissions: 'COMMISSIONS',
  HiringBonus: 'HIRING_BONUS',
  PerformanceBonus: 'PERFORMANCE_BONUS',
  Tips: 'TIPS'
} as const;

export type JobPaymentOtherTypesCode = typeof JobPaymentOtherTypesCode[keyof typeof JobPaymentOtherTypesCode];
/** Response to the job other payment types list */
export type JobPaymentOtherTypesResponse = {
  readonly __typename?: 'JobPaymentOtherTypesResponse';
  readonly values: ReadonlyArray<JobPaymentOtherType>;
};

/** job schedule type */
export type JobScheduleType = {
  readonly __typename?: 'JobScheduleType';
  /** unique code */
  readonly code: JobScheduleTypeCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** job schedule type codes */
export const JobScheduleTypeCode = {
  DayShift: 'DAY_SHIFT',
  EveningShift: 'EVENING_SHIFT',
  MondayToFriday: 'MONDAY_TO_FRIDAY',
  NightShift: 'NIGHT_SHIFT',
  OnCall: 'ON_CALL',
  WeekendAsNeeded: 'WEEKEND_AS_NEEDED',
  WeekendOnly: 'WEEKEND_ONLY'
} as const;

export type JobScheduleTypeCode = typeof JobScheduleTypeCode[keyof typeof JobScheduleTypeCode];
/** Response to the job schedule types list */
export type JobScheduleTypesResponse = {
  readonly __typename?: 'JobScheduleTypesResponse';
  readonly values: ReadonlyArray<JobScheduleType>;
};

export type JobSpecialtiesRequest = {
  /** industry type codes to search with */
  readonly industryTypeCodes?: InputMaybe<ReadonlyArray<AccountIndustryType>>;
  /** only specialities used in with a given account id */
  readonly onlyUsedInAccountId?: InputMaybe<Scalars['String']['input']>;
  /** industry sector codes to search with */
  readonly sectorCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** specialty codes to search with */
  readonly specialtyCodes?: InputMaybe<Scalars['String']['input']>;
  /** performs a text search across various fields */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response of the job specialties search */
export type JobSpecialtiesResponse = {
  readonly __typename?: 'JobSpecialtiesResponse';
  readonly page: JobSpecialtyConnection;
  readonly pageData?: Maybe<PageData>;
};

/** Representation of a job specialty (ex: "nurse", "frontend developer", etc.) */
export type JobSpecialty = {
  readonly __typename?: 'JobSpecialty';
  /** unique code */
  readonly code: Scalars['String']['output'];
  /** i18n descriptions */
  readonly descriptions: Descriptions;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** sector the job specialty belongs to */
  readonly sector: IndustrySector;
};

export type JobSpecialtyConnection = {
  readonly __typename?: 'JobSpecialtyConnection';
  readonly edges?: Maybe<ReadonlyArray<JobSpecialtyEdge>>;
  readonly pageInfo?: Maybe<JobSpecialtyPageInfo>;
};

export type JobSpecialtyEdge = {
  readonly __typename?: 'JobSpecialtyEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<JobSpecialty>;
};

export type JobSpecialtyPageInfo = {
  readonly __typename?: 'JobSpecialtyPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Object returning the link and the fileName of a uploaded file */
export type LinkFileName = {
  readonly __typename?: 'LinkFileName';
  readonly fileName: Scalars['String']['output'];
  readonly link: Scalars['String']['output'];
};

/** User info */
export type MeResponse = {
  readonly __typename?: 'MeResponse';
  /** the user */
  readonly user: User;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly accountCreate: AccountResponse;
  readonly accountDeleteLogo: AccountDeleteLogoResponse;
  readonly accountOperationTerritoriesCreate: CreateAccountOperationTerritoriesResponse;
  readonly accountOperationTerritoryDelete: DeleteAccountOperationTerritoryResponse;
  readonly accountOperationTerritoryUpdate: AccountOperationTerritoryResponse;
  readonly accountPreferredSpecialtiesCreate: AccountPreferredSpecialtiesCreateResponse;
  readonly accountPreferredSpecialtyDelete: DeleteAccountPreferredSpecialtyResponse;
  readonly accountUpdate: AccountResponse;
  readonly accountUpdateLogo: AccountUpdateLogoResponse;
  readonly accountUserDelete: DeleteAccountUserResponse;
  readonly accountUserInvite: InviteAccountUserResponse;
  readonly accountUserUpdateRole: UpdateAccountUserRoleResponse;
  readonly accountUserUpdateStatus: UpdateAccountUserStatusResponse;
  /** Complete a candidate negotiation */
  readonly candidateNegotiationComplete: CandidateNegotiationResponse;
  /** Create a candidate negotiation */
  readonly candidateNegotiationCreate: CandidateNegotiationResponse;
  readonly candidateNegotiationRoomAcceptValues: CandidateNegotiationRoomResponse;
  readonly candidateNegotiationRoomReject: CandidateNegotiationRoomResponse;
  readonly candidateNegotiationRoomRejectValues: CandidateNegotiationRoomResponse;
  readonly candidateNegotiationRoomsReject: CandidateNegotiationRoomsRejectResponse;
  /** @deprecated Only to be used before negotiation room is completed. Will be remove once the negotiation room is functional. */
  readonly candidateOfferAccept: CandidateOfferResponse;
  readonly candidateOfferCreate: CandidateOfferResponse;
  readonly candidateOfferDelete: DeleteCandidateOfferResponse;
  readonly candidateOfferUpdate: CandidateOfferResponse;
  readonly candidateOfferUpdateStatus: CandidateOfferResponse;
  readonly candidateOffersReject: RejectCandidateOffersResponse;
  readonly candidateRequestCreate: CandidateRequestResponse;
  readonly candidateRequestDelete: DeleteCandidateRequestResponse;
  readonly candidateRequestDuplicate: CandidateRequestResponse;
  readonly candidateRequestUpdate: CandidateRequestResponse;
  readonly candidateRequestUpdateStatus: CandidateRequestResponse;
  readonly getPricingTable: GetPricingTableResponse;
  readonly getSubscriptionBillingPortalLink: GetSubscriptionBillingPortalLinkResponse;
  readonly getSubscriptionPromo: GetSubscriptionPromoResponse;
  readonly impersonateUser: ImpersonateUserResponse;
  readonly invitationAccept: AcceptInvitationResponse;
  readonly invitationDelete: DeleteInvitationResponse;
  readonly invitationResend: ResendInvitationResponse;
  readonly negotiatingCandidateOfferUpdate: CandidateOfferResponse;
  readonly negotiatingOfferUpdate: OfferResponse;
  /** @deprecated Only to be used before negotiation room is completed. Will be remove once the negotiation room is functional. */
  readonly offerAccept: OfferResponse;
  readonly offerCandidateCreate: OfferCandidateResponse;
  readonly offerCandidateDelete: OfferCandidateDeleteResponse;
  readonly offerCandidateDeleteCV: OfferCandidateDeleteCvResponse;
  readonly offerCandidateDeleteOtherFile: OfferCandidateDeleteCvResponse;
  readonly offerCandidateUpdate: OfferCandidateResponse;
  readonly offerCandidateUpdateCV: OfferCandidateUpdateCvResponse;
  readonly offerCandidateUpdateFeedback: OfferCandidateResponse;
  readonly offerCandidateUpdateOtherFiles: OfferCandidateUpdateOtherFilesResponse;
  readonly offerCreate: OfferResponse;
  readonly offerDelete: DeleteOfferResponse;
  readonly offerStatusUpdate: OfferResponse;
  readonly offerUpdate: OfferResponse;
  readonly offersReject: RejectOffersResponse;
  readonly operationUnitCreate: OperationUnitResponse;
  readonly operationUnitDelete: DeleteOperationUnitResponse;
  readonly operationUnitUpdate: OperationUnitResponse;
  /** Add rooms to a recruitment negotiation */
  readonly recruitmentNegotiationAddRooms: RecruitmentNegotiationResponse;
  /** Complete a recruitment negotiation */
  readonly recruitmentNegotiationComplete: RecruitmentNegotiationResponse;
  /** Create a recruitment negotiation */
  readonly recruitmentNegotiationCreate: RecruitmentNegotiationResponse;
  readonly recruitmentNegotiationRoomAcceptValues: RecruitmentNegotiationRoomResponse;
  /** Create a recruitment negotiation room */
  readonly recruitmentNegotiationRoomCreate: RecruitmentNegotiationRoomResponse;
  readonly recruitmentNegotiationRoomReject: RecruitmentNegotiationRoomResponse;
  readonly recruitmentNegotiationRoomRejectValues: RecruitmentNegotiationRoomResponse;
  readonly recruitmentNegotiationRoomsReject: RecruitmentNegotiationRoomsRejectResponse;
  /** Create a request chat room */
  readonly requestChatRoomCreate: RequestChatRoomResponse;
  /** Create a request chat room message */
  readonly requestChatRoomMessageCreate: RequestChatRoomMessageResponse;
  readonly requestCreate: RequestResponse;
  readonly requestDelete: DeleteRequestResponse;
  readonly requestDuplicate: RequestResponse;
  readonly requestStatusUpdate: RequestResponse;
  readonly requestUpdate: RequestResponse;
  /** Sets the anonymity status of an enterprise in a request chat room */
  readonly setEnterpriseAnonymity: Scalars['Boolean']['output'];
  readonly userDeleteAvatar: UserDeleteAvatarResponse;
  readonly userSendEmailVerificationLink: UserSendEmailVerificationLinkResponse;
  readonly userSendPasswordChangeRequest: UserSendPasswordChangeRequestResponse;
  readonly userUpdateAvatar: UserUpdateAvatarResponse;
  readonly userUpdateEmail: UserUpdateEmailResponse;
  readonly userUpdateNotificationPreferences: UserUpdateNotificationPreferencesResponse;
  readonly userUpdateProfile: MeResponse;
  readonly userVerifyEmail: UserVerifyEmailResponse;
};


export type MutationAccountCreateArgs = {
  accountCreateInput: AccountCreateInput;
};


export type MutationAccountOperationTerritoriesCreateArgs = {
  input: AccountOperationTerritoriesCreateInput;
};


export type MutationAccountOperationTerritoryDeleteArgs = {
  input: DeleteAccountOperationTerritoryInput;
};


export type MutationAccountOperationTerritoryUpdateArgs = {
  input: AccountOperationTerritoryUpdateInput;
};


export type MutationAccountPreferredSpecialtiesCreateArgs = {
  input: AccountPreferredSpecialtiesCreateInput;
};


export type MutationAccountPreferredSpecialtyDeleteArgs = {
  input: DeleteAccountPreferredSpecialtyInput;
};


export type MutationAccountUpdateArgs = {
  accountUpdateInput: AccountUpdateInput;
};


export type MutationAccountUpdateLogoArgs = {
  files: ReadonlyArray<Scalars['Upload']['input']>;
  input: AccountUpdateLogoInput;
};


export type MutationAccountUserDeleteArgs = {
  input: DeleteAccountUserInput;
};


export type MutationAccountUserInviteArgs = {
  input: InviteAccountUserInput;
};


export type MutationAccountUserUpdateRoleArgs = {
  input: UpdateAccountUserRoleInput;
};


export type MutationAccountUserUpdateStatusArgs = {
  input: UpdateAccountUserStatusInput;
};


export type MutationCandidateNegotiationCompleteArgs = {
  input: CandidateNegotiationCompleteInput;
};


export type MutationCandidateNegotiationCreateArgs = {
  input: CandidateNegotiationCreateInput;
};


export type MutationCandidateNegotiationRoomAcceptValuesArgs = {
  input: CandidateNegotiationStateUpdateInput;
};


export type MutationCandidateNegotiationRoomRejectArgs = {
  input: CandidateNegotiationRoomInput;
};


export type MutationCandidateNegotiationRoomRejectValuesArgs = {
  input: CandidateNegotiationStateUpdateInput;
};


export type MutationCandidateNegotiationRoomsRejectArgs = {
  input: CandidateNegotiationRoomsRejectInput;
};


export type MutationCandidateOfferAcceptArgs = {
  input: CandidateOfferInput;
};


export type MutationCandidateOfferCreateArgs = {
  input: CandidateOfferCreateInput;
};


export type MutationCandidateOfferDeleteArgs = {
  input: DeleteCandidateOfferInput;
};


export type MutationCandidateOfferUpdateArgs = {
  input: CandidateOfferUpdateInput;
};


export type MutationCandidateOfferUpdateStatusArgs = {
  input: CandidateOfferUpdateStatusInput;
};


export type MutationCandidateOffersRejectArgs = {
  input: RejectCandidateOffersInput;
};


export type MutationCandidateRequestCreateArgs = {
  input: CandidateRequestCreateInput;
};


export type MutationCandidateRequestDeleteArgs = {
  input: DeleteCandidateRequestInput;
};


export type MutationCandidateRequestDuplicateArgs = {
  input: CandidateRequestDuplicateInput;
};


export type MutationCandidateRequestUpdateArgs = {
  input: CandidateRequestUpdateInput;
};


export type MutationCandidateRequestUpdateStatusArgs = {
  input: CandidateRequestUpdateStatusInput;
};


export type MutationGetSubscriptionBillingPortalLinkArgs = {
  input: GetSubscriptionBillingPortalLinkInput;
};


export type MutationGetSubscriptionPromoArgs = {
  input: GetSubscriptionPromoInput;
};


export type MutationImpersonateUserArgs = {
  input: ImpersonateUserInput;
};


export type MutationInvitationAcceptArgs = {
  input: AcceptInvitationInput;
};


export type MutationInvitationDeleteArgs = {
  input: DeleteInvitationInput;
};


export type MutationInvitationResendArgs = {
  input: ResendInvitationInput;
};


export type MutationNegotiatingCandidateOfferUpdateArgs = {
  input: NegotiatingCandidateOfferUpdateInput;
};


export type MutationNegotiatingOfferUpdateArgs = {
  input: NegotiatingOfferUpdateInput;
};


export type MutationOfferAcceptArgs = {
  input: OfferInput;
};


export type MutationOfferCandidateCreateArgs = {
  input: OfferCandidateCreateInput;
};


export type MutationOfferCandidateDeleteArgs = {
  input: OfferCandidateDeleteInput;
};


export type MutationOfferCandidateDeleteCvArgs = {
  input: OfferCandidateDeleteCvInput;
};


export type MutationOfferCandidateDeleteOtherFileArgs = {
  input: OfferCandidateDeleteOtherFileInput;
};


export type MutationOfferCandidateUpdateArgs = {
  input: OfferCandidateUpdateInput;
};


export type MutationOfferCandidateUpdateCvArgs = {
  files: ReadonlyArray<Scalars['Upload']['input']>;
  input: OfferCandidateCvInput;
};


export type MutationOfferCandidateUpdateFeedbackArgs = {
  input: OfferCandidateUpdateFeedbackInput;
};


export type MutationOfferCandidateUpdateOtherFilesArgs = {
  files: ReadonlyArray<Scalars['Upload']['input']>;
  input: OfferCandidateOtherFilesInput;
};


export type MutationOfferCreateArgs = {
  offerCreateInput: OfferCreateInput;
};


export type MutationOfferDeleteArgs = {
  input: DeleteOfferInput;
};


export type MutationOfferStatusUpdateArgs = {
  offerStatusUpdateInput: OfferStatusUpdateInput;
};


export type MutationOfferUpdateArgs = {
  offerUpdateInput: OfferUpdateInput;
};


export type MutationOffersRejectArgs = {
  input: RejectOffersInput;
};


export type MutationOperationUnitCreateArgs = {
  operationUnitCreateInput: OperationUnitCreateInput;
};


export type MutationOperationUnitDeleteArgs = {
  input: DeleteOperationUnitInput;
};


export type MutationOperationUnitUpdateArgs = {
  input: OperationUnitUpdateInput;
};


export type MutationRecruitmentNegotiationAddRoomsArgs = {
  input: RecruitmentNegotiationAddRoomsInput;
};


export type MutationRecruitmentNegotiationCompleteArgs = {
  input: RecruitmentNegotiationCompleteInput;
};


export type MutationRecruitmentNegotiationCreateArgs = {
  input: RecruitmentNegotiationCreateInput;
};


export type MutationRecruitmentNegotiationRoomAcceptValuesArgs = {
  input: RecruitmentNegotiationStateUpdateInput;
};


export type MutationRecruitmentNegotiationRoomCreateArgs = {
  input: RecruitmentNegotiationRoomCreateInput;
};


export type MutationRecruitmentNegotiationRoomRejectArgs = {
  input: RecruitmentNegotiationRoomInput;
};


export type MutationRecruitmentNegotiationRoomRejectValuesArgs = {
  input: RecruitmentNegotiationStateUpdateInput;
};


export type MutationRecruitmentNegotiationRoomsRejectArgs = {
  input: RecruitmentNegotiationRoomsRejectInput;
};


export type MutationRequestChatRoomCreateArgs = {
  input: RequestChatRoomCreateInput;
};


export type MutationRequestChatRoomMessageCreateArgs = {
  input: RequestChatRoomMessageCreateInput;
};


export type MutationRequestCreateArgs = {
  requestCreateInput: RequestCreateInput;
};


export type MutationRequestDeleteArgs = {
  deleteRequestInput: DeleteRequestInput;
};


export type MutationRequestDuplicateArgs = {
  input: DuplicateRequestInput;
};


export type MutationRequestStatusUpdateArgs = {
  requestStatusUpdateInput: RequestStatusUpdate;
};


export type MutationRequestUpdateArgs = {
  requestUpdateInput: RequestUpdateInput;
};


export type MutationSetEnterpriseAnonymityArgs = {
  input: RequestChatRoomSetEnterpriseAnonymityInput;
};


export type MutationUserDeleteAvatarArgs = {
  input: UserDeleteAvatarInput;
};


export type MutationUserSendEmailVerificationLinkArgs = {
  input: UserSendEmailVerificationLinkInput;
};


export type MutationUserSendPasswordChangeRequestArgs = {
  input: UserSendPasswordChangeRequestInput;
};


export type MutationUserUpdateAvatarArgs = {
  files: ReadonlyArray<Scalars['Upload']['input']>;
  input: UserUpdateAvatarInput;
};


export type MutationUserUpdateEmailArgs = {
  input: UserUpdateEmailInput;
};


export type MutationUserUpdateNotificationPreferencesArgs = {
  input: UserUpdateNotificationPreferencesInput;
};


export type MutationUserUpdateProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUserVerifyEmailArgs = {
  input: UserVerifyEmailInput;
};

export type NegotiatingCandidateOfferUpdateInput = {
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** number of hours per week the candidate is available for work (multiple choices possible) */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** unique id of the negotiating candidate offer to update */
  readonly id: Scalars['String']['input'];
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job type */
  readonly jobType?: InputMaybe<JobDurationCode>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** other payment type */
  readonly otherPaymentTypeCode?: InputMaybe<JobPaymentOtherTypesCode>;
  /** outsourcing billing period */
  readonly outsourcingConditionBillingPeriod?: InputMaybe<CandidateConditionBillingPeriod>;
  /** outsourcing minimum notice before ending contract (in weeks) */
  readonly outsourcingConditionMinimumNotice?: InputMaybe<Scalars['Int']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** recruitment additional bonus */
  readonly recruitmentConditionBonus?: InputMaybe<Scalars['String']['input']>;
  /** recruitment maximum budget */
  readonly recruitmentConditionBudget?: InputMaybe<Scalars['String']['input']>;
  /** recruitment maximum guaranteed period value (in months) */
  readonly recruitmentConditionGuaranteedPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** recruitment payment method condition value */
  readonly recruitmentConditionPaymentMethod?: InputMaybe<OfferConditionPaymentMethodCode>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** seasons a candidate need to work when selecting seasonal job type */
  readonly seasons?: InputMaybe<ReadonlyArray<Season>>;
  /** temporary placement billing period */
  readonly temporaryPlacementConditionBillingPeriod?: InputMaybe<CandidateConditionBillingPeriod>;
  /** temporary placement minimum notice before ending contract (in weeks) */
  readonly temporaryPlacementConditionMinimumNotice?: InputMaybe<Scalars['Int']['input']>;
  /** temporary placement bonus when buying back candidate */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
};

export type NegotiatingOfferUpdateInput = {
  /** bonus value */
  readonly conditionBonus?: InputMaybe<Scalars['String']['input']>;
  /** deposit value */
  readonly conditionDeposit?: InputMaybe<Scalars['String']['input']>;
  /** bonus for hiring date bonus */
  readonly conditionHiredDateBonus?: InputMaybe<Scalars['String']['input']>;
  /** date requirement for the hiring bonus */
  readonly conditionHiredDateBonusRequirement?: InputMaybe<Scalars['DateTime']['input']>;
  /** recruitment period value (in weeks) */
  readonly conditionMaxRecruitmentPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** min guaranteed period value (in months) */
  readonly conditionMinGuaranteedPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** bonus for minimum guaranteed period */
  readonly conditionMinGuaranteedPeriodBonus?: InputMaybe<Scalars['String']['input']>;
  /** number of payments */
  readonly conditionPaymentAmount?: InputMaybe<Scalars['Int']['input']>;
  /** frequency of payments */
  readonly conditionPaymentFrequency?: InputMaybe<OfferConditionPaymentFrequencyCode>;
  /** payment method condition value */
  readonly conditionPaymentMethod?: InputMaybe<OfferConditionPaymentMethodCode>;
  /** probation period condition (in weeks) */
  readonly conditionProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting fixed amount type */
  readonly conditionRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** maximum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly conditionRemunerationEstimatedHoursMax?: InputMaybe<Scalars['Int']['input']>;
  /** minimum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly conditionRemunerationEstimatedHoursMin?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting hourly rate type */
  readonly conditionRemunerationHourlyRate?: InputMaybe<Scalars['String']['input']>;
  /** remuneration percentage when selecting annual percentage type */
  readonly conditionRemunerationPercentage?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration type */
  readonly conditionRemunerationType?: InputMaybe<ConditionRemunerationType>;
  /** payment method condition for start of agreement value */
  readonly conditionStartOfAgreementPaymentMethod?: InputMaybe<OfferConditionStartOfAgreementPaymentMethodCode>;
  /** acceptable exclusivity period for headhunting value (in weeks) */
  readonly headhunterConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** maximum replacement value */
  readonly headhunterConditionMaximumReplacement?: InputMaybe<Scalars['Int']['input']>;
  /** offer unique id */
  readonly id: Scalars['String']['input'];
  /** exclusivity period for applicant search condition value (in weeks) */
  readonly internationalRecruitmentConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** maximum replacement value */
  readonly internationalRecruitmentConditionMaximumReplacement?: InputMaybe<Scalars['Int']['input']>;
  /** exclusivity period value (in weeks) */
  readonly outsourcingConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** maximum budget value in dollars */
  readonly outsourcingConditionMaximumBudget?: InputMaybe<Scalars['String']['input']>;
  /** maximum duration of mandate value */
  readonly outsourcingConditionMaximumDuration?: InputMaybe<Scalars['Int']['input']>;
  /** cnesst expenses responsibility value */
  readonly temporaryPlacementConditionCnesstExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** employee help program responsibility value */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** possible buyback of the candidate within 6 months value */
  readonly temporaryPlacementConditionPossibleBuyback?: InputMaybe<Scalars['Boolean']['input']>;
  /** possible buyback bonus */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
  /** training expenses responsibility value */
  readonly temporaryPlacementConditionTrainingExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** travel expenses responsibility value */
  readonly temporaryPlacementConditionTravelExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
};

export const NotificationCategory = {
  CandidateNegotiationCompleted: 'CANDIDATE_NEGOTIATION_COMPLETED',
  CandidateNegotiationRoomOpened: 'CANDIDATE_NEGOTIATION_ROOM_OPENED',
  CandidateNegotiationRoomRejected: 'CANDIDATE_NEGOTIATION_ROOM_REJECTED',
  CandidateNegotiationRoomUpdated: 'CANDIDATE_NEGOTIATION_ROOM_UPDATED',
  CandidateOfferCreated: 'CANDIDATE_OFFER_CREATED',
  CandidateOfferUpdated: 'CANDIDATE_OFFER_UPDATED',
  CandidateRequestMatched: 'CANDIDATE_REQUEST_MATCHED',
  OfferCreated: 'OFFER_CREATED',
  OfferUpdated: 'OFFER_UPDATED',
  RecruitmentNegotiationCompleted: 'RECRUITMENT_NEGOTIATION_COMPLETED',
  RecruitmentNegotiationRoomOpened: 'RECRUITMENT_NEGOTIATION_ROOM_OPENED',
  RecruitmentNegotiationRoomRejected: 'RECRUITMENT_NEGOTIATION_ROOM_REJECTED',
  RecruitmentNegotiationRoomUpdated: 'RECRUITMENT_NEGOTIATION_ROOM_UPDATED',
  RequestChatRoomMessageReceivedByAgency: 'REQUEST_CHAT_ROOM_MESSAGE_RECEIVED_BY_AGENCY',
  RequestChatRoomMessageReceivedByEnterprise: 'REQUEST_CHAT_ROOM_MESSAGE_RECEIVED_BY_ENTERPRISE',
  RequestCreated: 'REQUEST_CREATED',
  RequestUpdated: 'REQUEST_UPDATED'
} as const;

export type NotificationCategory = typeof NotificationCategory[keyof typeof NotificationCategory];
/**
 * An offer is made by an agency towards a company request.
 *   The offer is always attached to agency account and one request
 *   belonging to company account.
 */
export type Offer = {
  readonly __typename?: 'Offer';
  /** offer's account */
  readonly account: Account;
  /** account Id */
  readonly accountId: Scalars['String']['output'];
  /** offer available transitions */
  readonly availableTransitions: ReadonlyArray<OfferStatusTypeCode>;
  /** offer's canidates */
  readonly candidates: ReadonlyArray<OfferCandidate>;
  /** whether or not there is available candidates */
  readonly candidatesAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** general contractual conditions */
  readonly generalContractualConditions: OfferGeneralConditions;
  /** contractual conditions when selecting headhunter service */
  readonly headhunterContractualConditions?: Maybe<OfferHeadhunterConditions>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** contractual conditions when selecting international recruitment service */
  readonly internationalRecruitmentContractualConditions?: Maybe<OfferInternationalRecruitmentConditions>;
  /** whether or not offerer profile should be linked to offer */
  readonly linkProfile?: Maybe<Scalars['Boolean']['output']>;
  /** offer matching score */
  readonly matchingScore?: Maybe<Scalars['String']['output']>;
  /** id of the negotiation room room associated to this offer */
  readonly negotiationRoomId?: Maybe<Scalars['String']['output']>;
  /** explanation why there is no candidates */
  readonly noCandidatesExplaination?: Maybe<Scalars['String']['output']>;
  /** contractual conditions when selecting outsourcing service */
  readonly outsourcingContractualConditions?: Maybe<OfferOutsourcingConditions>;
  /** offer rank */
  readonly rank?: Maybe<Scalars['Int']['output']>;
  /** recruitment opportunity */
  readonly recruitmentOpportunity?: Maybe<RecruitmentOpportunity>;
  /** offer's request */
  readonly request: Request;
  /** request id of the request to which the offer is made */
  readonly requestId: Scalars['String']['output'];
  /** Offer request types */
  readonly requestTypes?: Maybe<ReadonlyArray<RequestTypeCode>>;
  /** offer's resume count */
  readonly resumeCount: Scalars['Float']['output'];
  /** sales pitch */
  readonly salesPitch?: Maybe<Scalars['String']['output']>;
  /** status of the offer */
  readonly status: OfferStatusTypeCode;
  /** contractual conditions when selecting temporary placement service */
  readonly temporaryPlacementContractualConditions?: Maybe<OfferTemporaryPlacementConditions>;
  /** offer total costs */
  readonly totalCosts?: Maybe<Scalars['String']['output']>;
  /** offer total costs */
  readonly totalCostsMax?: Maybe<Scalars['String']['output']>;
  /** offer total costs */
  readonly totalCostsMin?: Maybe<Scalars['String']['output']>;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** offer's user */
  readonly user: User;
  /** user id of the offer creator */
  readonly userId: Scalars['String']['output'];
};

/** A candidate is added to an offer by an agency at the offer creation. */
export type OfferCandidate = {
  readonly __typename?: 'OfferCandidate';
  /** number of allowed remote days */
  readonly allowedRemoteDays?: Maybe<Scalars['Int']['output']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: Maybe<Scalars['Int']['output']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: Maybe<Scalars['Int']['output']>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: Maybe<Scalars['Boolean']['output']>;
  /** cities and sub regions */
  readonly citiesSubRegions?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** absolute URL of the offer candidate's CV */
  readonly cvUrl?: Maybe<Scalars['String']['output']>;
  /** education level of a candidate */
  readonly educationLevel?: Maybe<EducationLevel>;
  /** education level code */
  readonly educationLevelCode?: Maybe<EducationLevelCode>;
  /** candidate email */
  readonly email?: Maybe<Scalars['String']['output']>;
  /** email visibility */
  readonly emailDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** years of experience */
  readonly experience?: Maybe<Scalars['Int']['output']>;
  /** offer candidate related feedback */
  readonly feedback?: Maybe<OfferCandidateFeedbackCode>;
  /** candidate first name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** first name visibility */
  readonly firstNameDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** full-time availability */
  readonly fullTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** hourly bonus */
  readonly hourlyBonus?: Maybe<Scalars['String']['output']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: Maybe<Scalars['String']['output']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: Maybe<Scalars['String']['output']>;
  /** availability in hours per week */
  readonly hoursPerWeek?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** duration of contract in months */
  readonly jobDurationInMonths?: Maybe<Scalars['Int']['output']>;
  /** job work mode */
  readonly jobMode?: Maybe<JobMode>;
  /** job work mode */
  readonly jobModeCode?: Maybe<JobModeCode>;
  /** job specialty of a candidate */
  readonly jobSpecialty: JobSpecialty;
  /** job type */
  readonly jobTypeCode?: Maybe<JobDurationCode>;
  /** candidate last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** last name visibility */
  readonly lastNameDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** date on which the candidate was met. If date is null it means the candidate was never met, so candidate is not certified. */
  readonly metAt?: Maybe<Scalars['DateTime']['output']>;
  /** offer candidate notes */
  readonly notes?: Maybe<Scalars['String']['output']>;
  /** offer Id */
  readonly offerId: Scalars['String']['output'];
  /** operation territories */
  readonly operationTerritories?: Maybe<ReadonlyArray<OperationTerritory>>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: Maybe<ReadonlyArray<OperationTerritoryCode>>;
  /** Collection of absolute URLs and saved file name of the offer candidate's other files */
  readonly otherFilesUrls?: Maybe<ReadonlyArray<LinkFileName>>;
  /** other payment type */
  readonly otherPaymentTypes?: Maybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** other remuneration amount */
  readonly otherRemunerationAmount?: Maybe<Scalars['String']['output']>;
  /** part-time availability */
  readonly partTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: Maybe<JobPaymentFrequencyCode>;
  /** remuneration amount */
  readonly remunerationAmount?: Maybe<Scalars['String']['output']>;
  /** Request types of the offer */
  readonly requestTypes?: Maybe<ReadonlyArray<RequestTypeCode>>;
  /** schedules */
  readonly schedules?: Maybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** soft skills */
  readonly softSkills?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** additional specialty info (optional) */
  readonly specialty?: Maybe<Scalars['String']['output']>;
  /** study field */
  readonly studyField?: Maybe<Scalars['String']['output']>;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
};

/** for updating the cv of an offer candidate */
export type OfferCandidateCvInput = {
  /** the filename to use */
  readonly filename: Scalars['String']['input'];
  /** the id of the offer candidate to update */
  readonly offerCandidateId: Scalars['String']['input'];
};

export type OfferCandidateCreateInput = {
  /** number of allowed remote days */
  readonly allowedRemoteDays?: InputMaybe<Scalars['Int']['input']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: InputMaybe<Scalars['Boolean']['input']>;
  /** cities and sub regions */
  readonly citiesSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** education level code */
  readonly educationLevelCode?: InputMaybe<EducationLevelCode>;
  /** candidate email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** email visibility */
  readonly emailDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** years of experience */
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  /** candidate first name */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  /** first name visibility */
  readonly firstNameDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** full-time availability */
  readonly fullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** availability in hours per week */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job type */
  readonly jobTypeCode?: InputMaybe<JobDurationCode>;
  /** candidate last name */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** last name visibility */
  readonly lastNameDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** date at which the candidate was met */
  readonly metAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** offer candidate notes */
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  /** offer id of the offer to which the candidate is attached */
  readonly offerId: Scalars['String']['input'];
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** uploaded other files */
  readonly otherFiles?: InputMaybe<ReadonlyArray<Scalars['Upload']['input']>>;
  /** other payment types */
  readonly otherPaymentTypes?: InputMaybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** other remuneration amount */
  readonly otherRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** part-time availability */
  readonly partTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** Request types of the offer candidate */
  readonly requestTypes?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
  /** uploaded resume */
  readonly resume?: InputMaybe<Scalars['Upload']['input']>;
  /** schedules */
  readonly schedules?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** soft skills */
  readonly softSkills?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** additional specialty info (optional) */
  readonly specialty?: InputMaybe<Scalars['String']['input']>;
  /** study field */
  readonly studyField?: InputMaybe<Scalars['String']['input']>;
};

/** for deleting the cv of an offer candidate */
export type OfferCandidateDeleteCvInput = {
  /** the id of the offer candidate from which to delete cv */
  readonly offerCandidateId: Scalars['String']['input'];
};

/** response to the cv delete request */
export type OfferCandidateDeleteCvResponse = {
  readonly __typename?: 'OfferCandidateDeleteCVResponse';
  readonly ok: Scalars['Boolean']['output'];
};

export type OfferCandidateDeleteInput = {
  /** offer candidate id */
  readonly candidateId: Scalars['String']['input'];
};

/** for deleting the cv of an offer candidate */
export type OfferCandidateDeleteOtherFileInput = {
  /** the url of the file to delete */
  readonly fileUrl: Scalars['String']['input'];
  /** the id of the offer candidate from which to delete the other file */
  readonly offerCandidateId: Scalars['String']['input'];
};

/** Response when deleting an offer candidate */
export type OfferCandidateDeleteResponse = {
  readonly __typename?: 'OfferCandidateDeleteResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** unique code of the feedback on an offer candidate */
export const OfferCandidateFeedbackCode = {
  Dislike: 'DISLIKE',
  Like: 'LIKE',
  Love: 'LOVE'
} as const;

export type OfferCandidateFeedbackCode = typeof OfferCandidateFeedbackCode[keyof typeof OfferCandidateFeedbackCode];
/** Input for getting a offer candidate */
export type OfferCandidateGetInput = {
  /** the id of the offer candidate to get */
  readonly id: Scalars['String']['input'];
};

export type OfferCandidateInput = {
  /** number of allowed remote days */
  readonly allowedRemoteDays?: InputMaybe<Scalars['Int']['input']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: InputMaybe<Scalars['Boolean']['input']>;
  /** cities and sub regions */
  readonly citiesSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** education level code */
  readonly educationLevelCode?: InputMaybe<EducationLevelCode>;
  /** candidate email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** email visibility */
  readonly emailDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** years of experience */
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  /** candidate first name */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  /** first name visibility */
  readonly firstNameDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** full-time availability */
  readonly fullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** availability in hours per week */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job type */
  readonly jobTypeCode?: InputMaybe<JobDurationCode>;
  /** candidate last name */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** last name visibility */
  readonly lastNameDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** date at which the candidate was met */
  readonly metAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** offer candidate notes */
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** uploaded other files */
  readonly otherFiles?: InputMaybe<ReadonlyArray<Scalars['Upload']['input']>>;
  /** other payment types */
  readonly otherPaymentTypes?: InputMaybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** other remuneration amount */
  readonly otherRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** part-time availability */
  readonly partTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** Request types of the offer candidate */
  readonly requestTypes?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
  /** uploaded resume */
  readonly resume?: InputMaybe<Scalars['Upload']['input']>;
  /** schedules */
  readonly schedules?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** soft skills */
  readonly softSkills?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** additional specialty info (optional) */
  readonly specialty?: InputMaybe<Scalars['String']['input']>;
  /** study field */
  readonly studyField?: InputMaybe<Scalars['String']['input']>;
};

/** for updating the other files of an offer candidate */
export type OfferCandidateOtherFilesInput = {
  /** the id of the offer candidate to update */
  readonly offerCandidateId: Scalars['String']['input'];
};

/** Offer candidate info */
export type OfferCandidateResponse = {
  readonly __typename?: 'OfferCandidateResponse';
  readonly offerCandidate?: Maybe<OfferCandidate>;
};

/** response to the cv update request */
export type OfferCandidateUpdateCvResponse = {
  readonly __typename?: 'OfferCandidateUpdateCVResponse';
  readonly ok: Scalars['Boolean']['output'];
};

export type OfferCandidateUpdateFeedbackInput = {
  /** feedback on the offer candidate */
  readonly feedback: OfferCandidateFeedbackCode;
  /** offer candidate id */
  readonly id: Scalars['String']['input'];
};

export type OfferCandidateUpdateInput = {
  /** number of allowed remote days */
  readonly allowedRemoteDays?: InputMaybe<Scalars['Int']['input']>;
  /** annual maximum salary */
  readonly annualSalaryMax?: InputMaybe<Scalars['Int']['input']>;
  /** annual minimum salary */
  readonly annualSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  /** ability of the candidate to work in Canada or Québec */
  readonly canWorkInCanadaOrQuebec?: InputMaybe<Scalars['Boolean']['input']>;
  /** Cities and sub regions */
  readonly citiesSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** education level code */
  readonly educationLevelCode?: InputMaybe<EducationLevelCode>;
  /** candidate email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** email visibility */
  readonly emailDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** years of experience */
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  /** candidate first name */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  /** first name visibility */
  readonly firstNameDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** full-time availability */
  readonly fullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** hourly bonus */
  readonly hourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** maximum hourly rate */
  readonly hourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** minimum hourly rate */
  readonly hourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** availability in hours per week */
  readonly hoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** offer candidate id */
  readonly id: Scalars['String']['input'];
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** job work mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** job type */
  readonly jobTypeCode?: InputMaybe<JobDurationCode>;
  /** candidate last name */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** last name visibility */
  readonly lastNameDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** date at which the candidate was met */
  readonly metAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** offer candidate notes */
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** other payment type */
  readonly otherPaymentTypes?: InputMaybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** other remuneration amount */
  readonly otherRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** part-time availability */
  readonly partTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment frequency */
  readonly paymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** remuneration amount */
  readonly remunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** Request types of the offer candidate */
  readonly requestTypes?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
  /** schedules */
  readonly schedules?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** soft skills */
  readonly softSkills?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** additional specialty info (optional) */
  readonly specialty?: InputMaybe<Scalars['String']['input']>;
  /** study field */
  readonly studyField?: InputMaybe<Scalars['String']['input']>;
};

/** response to the other files update request */
export type OfferCandidateUpdateOtherFilesResponse = {
  readonly __typename?: 'OfferCandidateUpdateOtherFilesResponse';
  readonly ok: Scalars['Boolean']['output'];
};

export type OfferCandidatesFilters = {
  /** filter on offer candidate's offer id */
  readonly offerId: Scalars['String']['input'];
  /** filter on whether the offer candidate has a resume or not */
  readonly withResume?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Response containing offer candidates */
export type OfferCandidatesResponse = {
  readonly __typename?: 'OfferCandidatesResponse';
  readonly results: ReadonlyArray<OfferCandidate>;
  readonly totalCount: Scalars['Float']['output'];
};

/** payment frequency condition of an offer */
export const OfferConditionPaymentFrequencyCode = {
  BiMonthly: 'BI_MONTHLY',
  Monthly: 'MONTHLY',
  Weekly: 'WEEKLY',
  Yearly: 'YEARLY'
} as const;

export type OfferConditionPaymentFrequencyCode = typeof OfferConditionPaymentFrequencyCode[keyof typeof OfferConditionPaymentFrequencyCode];
/** payment method condition of an offer */
export const OfferConditionPaymentMethodCode = {
  InFullOnHiring: 'IN_FULL_ON_HIRING',
  StartOfAgreement: 'START_OF_AGREEMENT'
} as const;

export type OfferConditionPaymentMethodCode = typeof OfferConditionPaymentMethodCode[keyof typeof OfferConditionPaymentMethodCode];
/** decide which party is responsible of a contractual field */
export const OfferConditionResponsibility = {
  Agency: 'AGENCY',
  Enterprise: 'ENTERPRISE',
  NonApplicable: 'NON_APPLICABLE'
} as const;

export type OfferConditionResponsibility = typeof OfferConditionResponsibility[keyof typeof OfferConditionResponsibility];
/** payment method condition of an offer when selecting start of agreement */
export const OfferConditionStartOfAgreementPaymentMethodCode = {
  AmountWanted: 'AMOUNT_WANTED',
  Deposit: 'DEPOSIT',
  PaymentFrequency: 'PAYMENT_FREQUENCY'
} as const;

export type OfferConditionStartOfAgreementPaymentMethodCode = typeof OfferConditionStartOfAgreementPaymentMethodCode[keyof typeof OfferConditionStartOfAgreementPaymentMethodCode];
export type OfferConnection = {
  readonly __typename?: 'OfferConnection';
  readonly edges?: Maybe<ReadonlyArray<OfferEdge>>;
  readonly pageInfo?: Maybe<OfferPageInfo>;
};

export type OfferCreateInput = {
  /** whether or not there is available candidates */
  readonly candidatesAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus value */
  readonly conditionBonus?: InputMaybe<Scalars['String']['input']>;
  /** bonus if candidate stays a certain amount of months */
  readonly conditionBonusMonth?: InputMaybe<Scalars['Int']['input']>;
  /** deposit value */
  readonly conditionDeposit?: InputMaybe<Scalars['String']['input']>;
  /** bonus for hiring date bonus */
  readonly conditionHiredDateBonus?: InputMaybe<Scalars['String']['input']>;
  /** date requirement for the hiring bonus */
  readonly conditionHiredDateBonusRequirement?: InputMaybe<Scalars['DateTime']['input']>;
  /** recruitment period value (in weeks) */
  readonly conditionMaxRecruitmentPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** min guaranteed period value (in months) */
  readonly conditionMinGuaranteedPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** bonus for minimum guaranteed period */
  readonly conditionMinGuaranteedPeriodBonus?: InputMaybe<Scalars['String']['input']>;
  /** number of payments */
  readonly conditionPaymentAmount?: InputMaybe<Scalars['Int']['input']>;
  /** frequency of payments */
  readonly conditionPaymentFrequency?: InputMaybe<OfferConditionPaymentFrequencyCode>;
  /** payment method condition value */
  readonly conditionPaymentMethod?: InputMaybe<OfferConditionPaymentMethodCode>;
  /** probation period condition (in weeks) */
  readonly conditionProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting fixed amount type */
  readonly conditionRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** maximum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly conditionRemunerationEstimatedHoursMax?: InputMaybe<Scalars['Int']['input']>;
  /** minimum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly conditionRemunerationEstimatedHoursMin?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting hourly rate type */
  readonly conditionRemunerationHourlyRate?: InputMaybe<Scalars['String']['input']>;
  /** remuneration percentage when selecting annual percentage type */
  readonly conditionRemunerationPercentage?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration type */
  readonly conditionRemunerationType?: InputMaybe<ConditionRemunerationType>;
  /** payment method condition for start of agreement value */
  readonly conditionStartOfAgreementPaymentMethod?: InputMaybe<OfferConditionStartOfAgreementPaymentMethodCode>;
  /** acceptable exclusivity period for headhunting value (in weeks) */
  readonly headhunterConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** maximum replacement value */
  readonly headhunterConditionMaximumReplacement?: InputMaybe<Scalars['Int']['input']>;
  /** exclusivity period for applicant search condition value (in weeks) */
  readonly internationalRecruitmentConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** mandatory work permit value */
  readonly internationalRecruitmentConditionMandatoryWorkPermit?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement value */
  readonly internationalRecruitmentConditionMaximumReplacement?: InputMaybe<Scalars['Int']['input']>;
  /** whether or not offerer profile should be linked to offer */
  readonly linkProfile?: InputMaybe<Scalars['Boolean']['input']>;
  /** explanation why there is no candidates */
  readonly noCandidatesExplaination?: InputMaybe<Scalars['String']['input']>;
  /** offer candidates */
  readonly offerCandidates?: InputMaybe<ReadonlyArray<OfferCandidateInput>>;
  /** exclusivity period value (in weeks) */
  readonly outsourcingConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** maximum budget value */
  readonly outsourcingConditionMaximumBudget?: InputMaybe<Scalars['String']['input']>;
  /** maximum duration of mandate value */
  readonly outsourcingConditionMaximumDuration?: InputMaybe<Scalars['Int']['input']>;
  /** responsibilities value */
  readonly outsourcingConditionResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** whether the offer should be immediately published or left as draft */
  readonly publishNow?: InputMaybe<Scalars['Boolean']['input']>;
  /** request id of the request to which the offer is made */
  readonly requestId: Scalars['String']['input'];
  /** Request types of the offer */
  readonly requestTypes?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
  /** sales pitch */
  readonly salesPitch?: InputMaybe<Scalars['String']['input']>;
  /** cnesst expenses responsibility value */
  readonly temporaryPlacementConditionCnesstExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** employee help program responsibility value */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** open to temporary foreign applicants value */
  readonly temporaryPlacementConditionForeignApplicants?: InputMaybe<Scalars['Boolean']['input']>;
  /** other responsibilities value */
  readonly temporaryPlacementConditionOtherResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback of the candidate within 6 months value */
  readonly temporaryPlacementConditionPossibleBuyback?: InputMaybe<Scalars['Boolean']['input']>;
  /** buyback bonus */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
  /** identified risks value */
  readonly temporaryPlacementConditionRisks?: InputMaybe<Scalars['String']['input']>;
  /** training expenses responsibility value */
  readonly temporaryPlacementConditionTrainingExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** travel expenses responsibility value */
  readonly temporaryPlacementConditionTravelExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
};

export type OfferEdge = {
  readonly __typename?: 'OfferEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Offer>;
};

/** errors that can occur when calling offer endpoints. */
export const OfferErrors = {
  /** Cannot delete an offer that is currently in a negotiation. */
  CannotDeleteInNegotiationOffer: 'CANNOT_DELETE_IN_NEGOTIATION_OFFER',
  /** Cannot delete an offer that has already been processed. */
  CannotDeleteProcessedOffer: 'CANNOT_DELETE_PROCESSED_OFFER',
  /** Cannot publish offer. The request has already been completed. */
  CannotPublishOfferRequestCompleted: 'CANNOT_PUBLISH_OFFER_REQUEST_COMPLETED',
  /** Cannot update an offer that is currently in a negotiation. */
  CannotUpdateInNegotiationOffer: 'CANNOT_UPDATE_IN_NEGOTIATION_OFFER',
  /** Cannot update an offer that has already been processed. */
  CannotUpdateProcessedOffer: 'CANNOT_UPDATE_PROCESSED_OFFER',
  /** Cannot publish offer because some required fields are missing. */
  MissingRequiredFields: 'MISSING_REQUIRED_FIELDS',
  /** Cannot update publish request because you have the VIEW ONLY subscription */
  ViewOnlyUpdateRestriction: 'VIEW_ONLY_UPDATE_RESTRICTION'
} as const;

export type OfferErrors = typeof OfferErrors[keyof typeof OfferErrors];
/** General contractual conditions of an offer. */
export type OfferGeneralConditions = {
  readonly __typename?: 'OfferGeneralConditions';
  /** bonus value */
  readonly bonus?: Maybe<Scalars['String']['output']>;
  /** bonus if candidate stays a certain amount of months */
  readonly bonusMonth?: Maybe<Scalars['Int']['output']>;
  /** deposit value */
  readonly deposit?: Maybe<Scalars['String']['output']>;
  /** bonus for hired date */
  readonly hiredDateBonus?: Maybe<Scalars['String']['output']>;
  /** hired date requirement for bonus */
  readonly hiredDateBonusRequirement?: Maybe<Scalars['DateTime']['output']>;
  /** recruitment period value (in weeks) */
  readonly maxRecruitmentPeriod?: Maybe<Scalars['Int']['output']>;
  /** min guaranteed period value (in months) */
  readonly minGuaranteedPeriod?: Maybe<Scalars['Int']['output']>;
  /** bonus for minimum guaranteed period */
  readonly minGuaranteedPeriodBonus?: Maybe<Scalars['String']['output']>;
  /** number of payments */
  readonly paymentAmount?: Maybe<Scalars['Int']['output']>;
  /** frequency of payments */
  readonly paymentFrequency?: Maybe<OfferConditionPaymentFrequencyCode>;
  /** payment method condition value */
  readonly paymentMethod?: Maybe<OfferConditionPaymentMethodCode>;
  /** probation period condition (in weeks) */
  readonly probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** remuneration amount when selecting fixed amount type */
  readonly remunerationAmount?: Maybe<Scalars['String']['output']>;
  /** maximum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly remunerationEstimatedHoursMax?: Maybe<Scalars['Int']['output']>;
  /** minimum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly remunerationEstimatedHoursMin?: Maybe<Scalars['Int']['output']>;
  /** remuneration amount when selecting hourly rate type */
  readonly remunerationHourlyRate?: Maybe<Scalars['String']['output']>;
  /** remuneration percentage when selecting annual percentage type */
  readonly remunerationPercentage?: Maybe<Scalars['Int']['output']>;
  /** remuneration type */
  readonly remunerationType?: Maybe<ConditionRemunerationType>;
  /** payment method condition for start of agreement value */
  readonly startOfAgreementPaymentMethod?: Maybe<OfferConditionStartOfAgreementPaymentMethodCode>;
};

/** Headhunter contractual conditions of an offer. */
export type OfferHeadhunterConditions = {
  readonly __typename?: 'OfferHeadhunterConditions';
  /** acceptable exclusivity period for headhunting value (in weeks) */
  readonly headhuntingExclusivityPeriod?: Maybe<Scalars['Int']['output']>;
  /** maximum replacement value */
  readonly maximumReplacement?: Maybe<Scalars['Int']['output']>;
};

export type OfferInput = {
  /** offer id */
  readonly offerId: Scalars['String']['input'];
};

/** International recruitment contractual conditions of an offer. */
export type OfferInternationalRecruitmentConditions = {
  readonly __typename?: 'OfferInternationalRecruitmentConditions';
  /** exclusivity period for applicant search condition value (in weeks) */
  readonly exclusivityPeriod?: Maybe<Scalars['Int']['output']>;
  /** mandatory work permit value */
  readonly mandatoryWorkPermit?: Maybe<Scalars['Boolean']['output']>;
  /** maximum replacement value */
  readonly maximumReplacement?: Maybe<Scalars['Int']['output']>;
};

/** Outsourcing contractual conditions of an offer. */
export type OfferOutsourcingConditions = {
  readonly __typename?: 'OfferOutsourcingConditions';
  /** exclusivity period value (in weeks) */
  readonly exclusivityPeriod?: Maybe<Scalars['Int']['output']>;
  /** maximum budget value in dollars */
  readonly maximumBudget?: Maybe<Scalars['String']['output']>;
  /** maximum duration of mandate value */
  readonly maximumDuration?: Maybe<Scalars['Int']['output']>;
  /** responsibilities value */
  readonly responsibilities?: Maybe<Scalars['String']['output']>;
};

export type OfferPageInfo = {
  readonly __typename?: 'OfferPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Offer info */
export type OfferResponse = {
  readonly __typename?: 'OfferResponse';
  readonly offer?: Maybe<Offer>;
};

/** status of an offer */
export type OfferStatus = {
  readonly __typename?: 'OfferStatus';
  /** unique code */
  readonly code: OfferStatusTypeCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** unique code of the status of an offer */
export const OfferStatusTypeCode = {
  Accepted: 'ACCEPTED',
  Draft: 'DRAFT',
  /** Should only be used in the backend. The frontend will never receive (except during negotiations) an offer with this status. */
  InNegotiation: 'IN_NEGOTIATION',
  Published: 'PUBLISHED',
  Rejected: 'REJECTED'
} as const;

export type OfferStatusTypeCode = typeof OfferStatusTypeCode[keyof typeof OfferStatusTypeCode];
/** Response to the offer status list */
export type OfferStatusTypesResponse = {
  readonly __typename?: 'OfferStatusTypesResponse';
  readonly values: ReadonlyArray<OfferStatus>;
};

export type OfferStatusUpdateInput = {
  /** the offer id */
  readonly id: Scalars['String']['input'];
  /** status of the offer */
  readonly status: OfferStatusTypeCode;
};

/** Temporary placement contractual conditions of an offer. */
export type OfferTemporaryPlacementConditions = {
  readonly __typename?: 'OfferTemporaryPlacementConditions';
  /** cnesst expenses responsibility value */
  readonly cnesstExpensesResponsibility?: Maybe<OfferConditionResponsibility>;
  /** employee help program responsibility value */
  readonly employeeHelpProgramResponsibility?: Maybe<OfferConditionResponsibility>;
  /** open to temporary foreign applicants value */
  readonly foreignApplicants?: Maybe<Scalars['Boolean']['output']>;
  /** other responsibilities value */
  readonly otherResponsibilities?: Maybe<Scalars['String']['output']>;
  /** possible buyback of the candidate within 6 months value */
  readonly possibleBuyback?: Maybe<Scalars['Boolean']['output']>;
  /** possible buyback bonus */
  readonly possibleBuybackBonus?: Maybe<Scalars['String']['output']>;
  /** identified risks value */
  readonly risks?: Maybe<Scalars['String']['output']>;
  /** training expenses responsibility value */
  readonly trainingExpensesResponsibility?: Maybe<OfferConditionResponsibility>;
  /** travel expenses responsibility value */
  readonly travelExpensesResponsibility?: Maybe<OfferConditionResponsibility>;
};

export type OfferUpdateInput = {
  /** whether or not there is available candidates */
  readonly candidatesAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus value */
  readonly conditionBonus?: InputMaybe<Scalars['String']['input']>;
  /** bonus if candidate stays a certain amount of months */
  readonly conditionBonusMonth?: InputMaybe<Scalars['Int']['input']>;
  /** deposit value */
  readonly conditionDeposit?: InputMaybe<Scalars['String']['input']>;
  /** bonus for hiring date bonus */
  readonly conditionHiredDateBonus?: InputMaybe<Scalars['String']['input']>;
  /** date requirement for the hiring bonus */
  readonly conditionHiredDateBonusRequirement?: InputMaybe<Scalars['DateTime']['input']>;
  /** recruitment period value (in weeks) */
  readonly conditionMaxRecruitmentPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** min guaranteed period value (in months) */
  readonly conditionMinGuaranteedPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** bonus for minimum guaranteed period */
  readonly conditionMinGuaranteedPeriodBonus?: InputMaybe<Scalars['String']['input']>;
  /** number of payments */
  readonly conditionPaymentAmount?: InputMaybe<Scalars['Int']['input']>;
  /** frequency of payments */
  readonly conditionPaymentFrequency?: InputMaybe<OfferConditionPaymentFrequencyCode>;
  /** payment method condition value */
  readonly conditionPaymentMethod?: InputMaybe<OfferConditionPaymentMethodCode>;
  /** probation period condition (in weeks) */
  readonly conditionProbationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting fixed amount type */
  readonly conditionRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** maximum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly conditionRemunerationEstimatedHoursMax?: InputMaybe<Scalars['Int']['input']>;
  /** minimum number of hours estimated to complete the mandate when selecting hourly rate type */
  readonly conditionRemunerationEstimatedHoursMin?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration amount when selecting hourly rate type */
  readonly conditionRemunerationHourlyRate?: InputMaybe<Scalars['String']['input']>;
  /** remuneration percentage when selecting annual percentage type */
  readonly conditionRemunerationPercentage?: InputMaybe<Scalars['Int']['input']>;
  /** remuneration type */
  readonly conditionRemunerationType?: InputMaybe<ConditionRemunerationType>;
  /** payment method condition for start of agreement value */
  readonly conditionStartOfAgreementPaymentMethod?: InputMaybe<OfferConditionStartOfAgreementPaymentMethodCode>;
  /** acceptable exclusivity period for headhunting value (in weeks) */
  readonly headhunterConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** maximum replacement value */
  readonly headhunterConditionMaximumReplacement?: InputMaybe<Scalars['Int']['input']>;
  /** the offer id */
  readonly id: Scalars['String']['input'];
  /** exclusivity period for applicant search condition value (in weeks) */
  readonly internationalRecruitmentConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** mandatory work permit value */
  readonly internationalRecruitmentConditionMandatoryWorkPermit?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement value */
  readonly internationalRecruitmentConditionMaximumReplacement?: InputMaybe<Scalars['Int']['input']>;
  /** whether or not offerer profile should be linked to offer */
  readonly linkProfile?: InputMaybe<Scalars['Boolean']['input']>;
  /** explanation why there is no candidates */
  readonly noCandidatesExplaination?: InputMaybe<Scalars['String']['input']>;
  /** offer candidates */
  readonly offerCandidates?: InputMaybe<ReadonlyArray<OfferCandidateInput>>;
  /** exclusivity period value (in weeks) */
  readonly outsourcingConditionExclusivityPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** maximum budget value */
  readonly outsourcingConditionMaximumBudget?: InputMaybe<Scalars['String']['input']>;
  /** maximum duration of mandate value */
  readonly outsourcingConditionMaximumDuration?: InputMaybe<Scalars['Int']['input']>;
  /** responsibilities value */
  readonly outsourcingConditionResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** Request types of the offer */
  readonly requestTypes?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
  /** sales pitch */
  readonly salesPitch?: InputMaybe<Scalars['String']['input']>;
  /** cnesst expenses responsibility value */
  readonly temporaryPlacementConditionCnesstExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** employee help program responsibility value */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** open to temporary foreign applicants value */
  readonly temporaryPlacementConditionForeignApplicants?: InputMaybe<Scalars['Boolean']['input']>;
  /** other responsibilities value */
  readonly temporaryPlacementConditionOtherResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback of the candidate within 6 months value */
  readonly temporaryPlacementConditionPossibleBuyback?: InputMaybe<Scalars['Boolean']['input']>;
  /** buyback bonus */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
  /** identified risks value */
  readonly temporaryPlacementConditionRisks?: InputMaybe<Scalars['String']['input']>;
  /** training expenses responsibility value */
  readonly temporaryPlacementConditionTrainingExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
  /** travel expenses responsibility value */
  readonly temporaryPlacementConditionTravelExpensesResponsibility?: InputMaybe<OfferConditionResponsibility>;
};

export type OffersFilters = {
  /** filter on creation date of the offer */
  readonly dates?: InputMaybe<ReadonlyArray<Scalars['DateTime']['input']>>;
  /** filter on offer ids */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on operation territory */
  readonly operationTerritories?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** filter on offer's request id */
  readonly requestId?: InputMaybe<Scalars['String']['input']>;
  /** filter on types of the request for the offer */
  readonly requestTypes?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
  /** filter on offer status */
  readonly status?: InputMaybe<ReadonlyArray<OfferStatusTypeCode>>;
  /** filter on parts of the offer */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing offers */
export type OffersResponse = {
  readonly __typename?: 'OffersResponse';
  readonly page: OfferConnection;
  readonly pageData?: Maybe<PageData>;
};

/** the fields by which offers can be sorted */
export const OffersSortBy = {
  Date: 'DATE',
  JobSpecialtyEn: 'JOB_SPECIALTY_EN',
  JobSpecialtyFr: 'JOB_SPECIALTY_FR',
  MatchingScore: 'MATCHING_SCORE',
  Rank: 'RANK',
  Status: 'STATUS'
} as const;

export type OffersSortBy = typeof OffersSortBy[keyof typeof OffersSortBy];
/** For offers to be sorted */
export type OffersSortDirective = {
  readonly by: OffersSortBy;
  readonly direction: SortDirection;
};

/** Response to the operation territories list */
export type OperationTerritoriesResponse = {
  readonly __typename?: 'OperationTerritoriesResponse';
  readonly values: ReadonlyArray<OperationTerritory>;
};

/** operation territory */
export type OperationTerritory = {
  readonly __typename?: 'OperationTerritory';
  /** unique code */
  readonly code: OperationTerritoryCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
  /** province code */
  readonly provinceCode: ProvinceCode;
};

/** unique code of an operation territory */
export const OperationTerritoryCode = {
  AbitibiTemiscamingue: 'ABITIBI_TEMISCAMINGUE',
  AlbertaBadlands: 'ALBERTA_BADLANDS',
  AlbertaRockies: 'ALBERTA_ROCKIES',
  AvalonPeninsulaRegion: 'AVALON_PENINSULA_REGION',
  BasSaintLaurent: 'BAS_SAINT_LAURENT',
  BrucePeninsulaSouthernGeorgianBayAndLakeSimcoe: 'BRUCE_PENINSULA_SOUTHERN_GEORGIAN_BAY_AND_LAKE_SIMCOE',
  BurinPeninsulaRegion: 'BURIN_PENINSULA_REGION',
  CapeBretonRegion: 'CAPE_BRETON_REGION',
  CapitaleNationale: 'CAPITALE_NATIONALE',
  CaribooChilcotinCoast: 'CARIBOO_CHILCOTIN_COAST',
  CentralAlberta: 'CENTRAL_ALBERTA',
  CentralEastRegionMiramichi: 'CENTRAL_EAST_REGION_MIRAMICHI',
  CentralPlains: 'CENTRAL_PLAINS',
  CentralRegion: 'CENTRAL_REGION',
  CentralRegionCharlottetown: 'CENTRAL_REGION_CHARLOTTETOWN',
  CentralWestRegionFredericton: 'CENTRAL_WEST_REGION_FREDERICTON',
  CentreDuQuebec: 'CENTRE_DU_QUEBEC',
  ChaudieresAppalaches: 'CHAUDIERES_APPALACHES',
  ClarenvilleBonavistaRegion: 'CLARENVILLE_BONAVISTA_REGION',
  ComerBrookRockyHarbourRegion: 'COMER_BROOK_ROCKY_HARBOUR_REGION',
  CoteNord: 'COTE_NORD',
  EastearnAlberta: 'EASTEARN_ALBERTA',
  EasternManitoba: 'EASTERN_MANITOBA',
  EasternRegionOLeary: 'EASTERN_REGION_O_LEARY',
  EastCentralSaskatchewan: 'EAST_CENTRAL_SASKATCHEWAN',
  Estrie: 'ESTRIE',
  FundyRegion: 'FUNDY_REGION',
  GanderNewWesValleyRegion: 'GANDER_NEW_WES_VALLEY_REGION',
  GaspesieIlesDeLaMadeleine: 'GASPESIE_ILES_DE_LA_MADELEINE',
  GrandFallsWindsorBaieVerteHarbourBretonRegion: 'GRAND_FALLS_WINDSOR_BAIE_VERTE_HARBOUR_BRETON_REGION',
  GreaterCalgaryArea: 'GREATER_CALGARY_AREA',
  GreaterEdmontonArea: 'GREATER_EDMONTON_AREA',
  GreaterTorontoArea: 'GREATER_TORONTO_AREA',
  GreaterWinnipegArea: 'GREATER_WINNIPEG_AREA',
  HaliburtonHighlandsToTheOttawaValley: 'HALIBURTON_HIGHLANDS_TO_THE_OTTAWA_VALLEY',
  HamiltonHaltonAndBrant: 'HAMILTON_HALTON_AND_BRANT',
  HighlandRegion: 'HIGHLAND_REGION',
  HuronPerthWaterlooAndWellington: 'HURON_PERTH_WATERLOO_AND_WELLINGTON',
  Interlake: 'INTERLAKE',
  KawarthaNorthumberland: 'KAWARTHA_NORTHUMBERLAND',
  KootenayRockies: 'KOOTENAY_ROCKIES',
  LabradorRegion: 'LABRADOR_REGION',
  Lanaudiere: 'LANAUDIERE',
  Laurentides: 'LAURENTIDES',
  Laval: 'LAVAL',
  Mauricie: 'MAURICIE',
  Monteregie: 'MONTEREGIE',
  Montreal: 'MONTREAL',
  MuskokaParrySoundAndAlgonquinPark: 'MUSKOKA_PARRY_SOUND_AND_ALGONQUIN_PARK',
  NiagaraCanada: 'NIAGARA_CANADA',
  NordDuQuebec: 'NORD_DU_QUEBEC',
  NortheasternOntario: 'NORTHEASTERN_ONTARIO',
  NorthernBritishColumbia: 'NORTHERN_BRITISH_COLUMBIA',
  NorthernSaskatchewan: 'NORTHERN_SASKATCHEWAN',
  NorthwestTerritories: 'NORTHWEST_TERRITORIES',
  NorthCentralRegionCampbelton: 'NORTH_CENTRAL_REGION_CAMPBELTON',
  NorthEastRegionBathurst: 'NORTH_EAST_REGION_BATHURST',
  NorthWestRegionEdmundston: 'NORTH_WEST_REGION_EDMUNDSTON',
  NortwestOntario: 'NORTWEST_ONTARIO',
  Nunavut: 'NUNAVUT',
  OttawaAndCountryside: 'OTTAWA_AND_COUNTRYSIDE',
  Outaouais: 'OUTAOUAIS',
  Parkland: 'PARKLAND',
  PeaceRiverValley: 'PEACE_RIVER_VALLEY',
  PembinaValley: 'PEMBINA_VALLEY',
  SaguenayLacSaintJean: 'SAGUENAY_LAC_SAINT_JEAN',
  SaultSteMarieAlgoma: 'SAULT_STE_MARIE_ALGOMA',
  SoutheasternOntario: 'SOUTHEASTERN_ONTARIO',
  SoutheastSaskatchewanGreaterReginaArea: 'SOUTHEAST_SASKATCHEWAN_GREATER_REGINA_AREA',
  SouthernAlberta: 'SOUTHERN_ALBERTA',
  SouthwestOntario: 'SOUTHWEST_ONTARIO',
  SouthwestSaskatchewan: 'SOUTHWEST_SASKATCHEWAN',
  SouthCentralRegionSaintJohn: 'SOUTH_CENTRAL_REGION_SAINT_JOHN',
  SouthEastRegionMoncton: 'SOUTH_EAST_REGION_MONCTON',
  SouthShoreRegion: 'SOUTH_SHORE_REGION',
  StephenvillePortAuxBasquesRegion: 'STEPHENVILLE_PORT_AUX_BASQUES_REGION',
  StAnthonyPortAuChoixRegion: 'ST_ANTHONY_PORT_AU_CHOIX_REGION',
  ThompsonOkanagan: 'THOMPSON_OKANAGAN',
  ValleyRegion: 'VALLEY_REGION',
  VancouverCoastMountains: 'VANCOUVER_COAST_MOUNTAINS',
  VancouverIsland: 'VANCOUVER_ISLAND',
  WesternManitoba: 'WESTERN_MANITOBA',
  WesternRegionSouris: 'WESTERN_REGION_SOURIS',
  WestCentralSaskatchewanGreaterSaskatoonArea: 'WEST_CENTRAL_SASKATCHEWAN_GREATER_SASKATOON_AREA',
  YorkDurhamAndHeadwaters: 'YORK_DURHAM_AND_HEADWATERS',
  Yukon: 'YUKON'
} as const;

export type OperationTerritoryCode = typeof OperationTerritoryCode[keyof typeof OperationTerritoryCode];
/** Representation of an operationUnit of the application/API */
export type OperationUnit = {
  readonly __typename?: 'OperationUnit';
  /** account Id */
  readonly accountId: Scalars['String']['output'];
  /** address of the operation unit */
  readonly address?: Maybe<Scalars['String']['output']>;
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** indicate if the operation unit is the head office */
  readonly headOffice?: Maybe<Scalars['Boolean']['output']>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** industry code */
  readonly industryCode?: Maybe<AccountIndustryType>;
  /** name of the account */
  readonly name: Scalars['String']['output'];
  /** notes */
  readonly notes?: Maybe<Scalars['String']['output']>;
  /** display of the operation unit phone number  */
  readonly phoneDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** phone number of the operation unit */
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  /** sector code */
  readonly sectorCode?: Maybe<Scalars['String']['output']>;
  /** update time */
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type OperationUnitConnection = {
  readonly __typename?: 'OperationUnitConnection';
  readonly edges?: Maybe<ReadonlyArray<OperationUnitEdge>>;
  readonly pageInfo?: Maybe<OperationUnitPageInfo>;
};

export type OperationUnitCreateInput = {
  /** the operation unit address */
  readonly address?: InputMaybe<Scalars['String']['input']>;
  /** whether the operation unit is the head office or not */
  readonly headOffice?: InputMaybe<Scalars['Boolean']['input']>;
  /** the operation unit industry code */
  readonly industryCode?: InputMaybe<AccountIndustryType>;
  /** the operation unit name */
  readonly name: Scalars['String']['input'];
  /** notes */
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  /** the operation unit phone visibility */
  readonly phoneDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** the operation unit phone number */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** the operation unit sector code */
  readonly sectorCode?: InputMaybe<Scalars['String']['input']>;
};

export type OperationUnitEdge = {
  readonly __typename?: 'OperationUnitEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<OperationUnit>;
};

/** errors that can occur when calling operationUnit endpoints. */
export const OperationUnitErrors = {
  /** An operationUnit with the same name already exists in this account. */
  AlreadyExists: 'ALREADY_EXISTS',
  /** The operationUnit is associated to a request and cannot be deleted. */
  AssociatedRequest: 'ASSOCIATED_REQUEST'
} as const;

export type OperationUnitErrors = typeof OperationUnitErrors[keyof typeof OperationUnitErrors];
/** For fetching an operation unit */
export type OperationUnitInput = {
  /** the unique id of operation unit to get */
  readonly operationUnitId: Scalars['String']['input'];
};

export type OperationUnitPageInfo = {
  readonly __typename?: 'OperationUnitPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Operation unit info */
export type OperationUnitResponse = {
  readonly __typename?: 'OperationUnitResponse';
  readonly operationUnit?: Maybe<OperationUnit>;
};

export type OperationUnitUpdateInput = {
  /** the operation unit address */
  readonly address?: InputMaybe<Scalars['String']['input']>;
  /** whether the operation unit is the head office or not */
  readonly headOffice?: InputMaybe<Scalars['Boolean']['input']>;
  /** the operation unit unique id */
  readonly id: Scalars['String']['input'];
  /** the operation unit industry code */
  readonly industryCode?: InputMaybe<AccountIndustryType>;
  /** the operation unit name */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** notes */
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  /** the operation unit phone visibility */
  readonly phoneDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** the operation unit phone number */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** the operation unit sector code */
  readonly sectorCode?: InputMaybe<Scalars['String']['input']>;
};

export type OperationUnitsFilters = {
  /** filters on the account id of the operation unit */
  readonly accountId?: InputMaybe<Scalars['String']['input']>;
  /** performs a text search across various fields */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response of the operation units search */
export type OperationUnitsResponse = {
  readonly __typename?: 'OperationUnitsResponse';
  readonly page: OperationUnitConnection;
  readonly pageData?: Maybe<PageData>;
};

/** The fields by which operation units can be sorted */
export const OperationUnitsSortBy = {
  Address: 'ADDRESS',
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  PhoneNumber: 'PHONE_NUMBER',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type OperationUnitsSortBy = typeof OperationUnitsSortBy[keyof typeof OperationUnitsSortBy];
/** For requesting operation units to be sorted */
export type OperationUnitsSortDirective = {
  readonly by: OperationUnitsSortBy;
  readonly direction: SortDirection;
};

/** used to return metadata of results */
export type PageData = {
  readonly __typename?: 'PageData';
  /** total count of results, regardless of the offset or limit set */
  readonly count: Scalars['Float']['output'];
  /** max number of results to get per offset */
  readonly limit?: Maybe<Scalars['Float']['output']>;
  /** where to start listing results from */
  readonly offset?: Maybe<Scalars['Float']['output']>;
};

export type PartialCandidateNegotiationGeneralPropertiesState = {
  /** duration state */
  readonly duration?: InputMaybe<CandidateNegotiationPropertyState>;
  /** hours state */
  readonly hours?: InputMaybe<CandidateNegotiationPropertyState>;
  /** job mode state */
  readonly jobMode?: InputMaybe<CandidateNegotiationPropertyState>;
  /** job type state */
  readonly jobType?: InputMaybe<CandidateNegotiationPropertyState>;
  /** operation territory state */
  readonly operationTerritory?: InputMaybe<CandidateNegotiationPropertyState>;
  /** other remunerations state */
  readonly otherRemunerations?: InputMaybe<CandidateNegotiationPropertyState>;
  /** salary state */
  readonly salary?: InputMaybe<CandidateNegotiationPropertyState>;
};

export type PartialCandidateNegotiationOutsourcingPropertiesState = {
  /** billing period state */
  readonly billingPeriod?: InputMaybe<CandidateNegotiationPropertyState>;
  /** minimum notice state */
  readonly minimumNotice?: InputMaybe<CandidateNegotiationPropertyState>;
};

export type PartialCandidateNegotiationRecruitmentPropertiesState = {
  /** additional bonus */
  readonly bonus?: InputMaybe<CandidateNegotiationPropertyState>;
  /** maximum budget state */
  readonly budget?: InputMaybe<CandidateNegotiationPropertyState>;
  /** maximum guaranteed period value (in months) */
  readonly guaranteedPeriod?: InputMaybe<CandidateNegotiationPropertyState>;
  /** payment method state */
  readonly paymentMethod?: InputMaybe<CandidateNegotiationPropertyState>;
};

export type PartialCandidateNegotiationTemporaryPlacementPropertiesState = {
  /** billing period state */
  readonly billingPeriod?: InputMaybe<CandidateNegotiationPropertyState>;
  /** minimum notice before ending contract (in weeks) */
  readonly minimumNotice?: InputMaybe<CandidateNegotiationPropertyState>;
  /** possible buyback bonus state */
  readonly possibleBuybackBonus?: InputMaybe<CandidateNegotiationPropertyState>;
};

export type PartialRecruitmentNegotiationGeneralPropertiesState = {
  /** bonus state */
  readonly bonus?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** deposit state */
  readonly deposit?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** recruitment period state */
  readonly maxRecruitmentPeriod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** min guaranteed period state */
  readonly minGuaranteedPeriod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** number of payments state */
  readonly paymentAmount?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** frequency of payments state */
  readonly paymentFrequency?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** payment method state */
  readonly paymentMethod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** probation period state */
  readonly probationPeriod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** payment method for start of agreement state */
  readonly startOfAgreementPaymentMethod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** total costs state */
  readonly totalCosts?: InputMaybe<RecruitmentNegotiationPropertyState>;
};

export type PartialRecruitmentNegotiationHeadhunterPropertiesState = {
  /** exclusivity period state */
  readonly exclusivityPeriod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** maximum replacement state */
  readonly maximumReplacement?: InputMaybe<RecruitmentNegotiationPropertyState>;
};

export type PartialRecruitmentNegotiationInternationalRecruitmentPropertiesState = {
  /** exclusivity period for applicant search condition state */
  readonly exclusivityPeriod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** maximum replacement state */
  readonly maximumReplacement?: InputMaybe<RecruitmentNegotiationPropertyState>;
};

export type PartialRecruitmentNegotiationOutsourcingPropertiesState = {
  /** exclusivity period state */
  readonly exclusivityPeriod?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** maximum budget state */
  readonly maximumBudget?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** maximum duration state */
  readonly maximumDuration?: InputMaybe<RecruitmentNegotiationPropertyState>;
};

export type PartialRecruitmentNegotiationTemporaryPlacementPropertiesState = {
  /** cnesst expenses responsibility state */
  readonly cnesstExpensesResponsibility?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** employee help program responsibility state */
  readonly employeeHelpProgramResponsibility?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** possible buyback of the candidate within 6 months state */
  readonly possibleBuyback?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** training expenses responsibility state */
  readonly trainingExpensesResponsibility?: InputMaybe<RecruitmentNegotiationPropertyState>;
  /** travel expenses responsibility state */
  readonly travelExpensesResponsibility?: InputMaybe<RecruitmentNegotiationPropertyState>;
};

/** unique code of a province, do not necessarily corresponds to the internationnaly approved two letters province code. */
export const ProvinceCode = {
  Alberta: 'ALBERTA',
  BritishColumbia: 'BRITISH_COLUMBIA',
  Manitoba: 'MANITOBA',
  NewfoundlandLabrador: 'NEWFOUNDLAND_LABRADOR',
  NewBrunswick: 'NEW_BRUNSWICK',
  NorthwestTerritories: 'NORTHWEST_TERRITORIES',
  NovaScotia: 'NOVA_SCOTIA',
  Nunavut: 'NUNAVUT',
  Ontario: 'ONTARIO',
  PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
  Quebec: 'QUEBEC',
  Saskatchewan: 'SASKATCHEWAN',
  Yukon: 'YUKON'
} as const;

export type ProvinceCode = typeof ProvinceCode[keyof typeof ProvinceCode];
/** for fetching public account, for now it contains all the info about the account, but it might be a bit restricted in the future. */
export type PublicAccountInput = {
  /** the unique id of the account */
  readonly accountId: Scalars['String']['input'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly account: AccountResponse;
  readonly accountAdvantageTypes: AccountAdvantageTypesResponse;
  readonly accountAvailableRoles: ReadonlyArray<UserRole>;
  /** Returns account operation territories. */
  readonly accountOperationTerritories: AccountOperationTerritoriesResponse;
  /** Returns account preferred specialties. */
  readonly accountPreferredSpecialties: AccountPreferredSpecialtiesResponse;
  readonly accountSizes: AccountSizesResponse;
  readonly accountTypes: AccountTypesResponse;
  /** Returns account users. */
  readonly accountUsers: AccountUsersResponse;
  readonly accounts: AccountsResponse;
  readonly candidateNegotiation: CandidateNegotiationResponse;
  readonly candidateNegotiationRoom: CandidateNegotiationRoomResponse;
  /**
   * Should only be used by offer creators.
   *       This query will always force the rooms to be filtered
   *       by candidateOfferAccountId matching the current user account id.
   */
  readonly candidateNegotiationRooms: CandidateNegotiationRoomsResponse;
  /**
   * Should only be used by negotiation/request creators.
   *       This query will always force the negotiations to be filtered by the current user account id.
   */
  readonly candidateNegotiations: CandidateNegotiationsResponse;
  readonly candidateOffer: CandidateOfferResponse;
  /** Returns candidate offers. */
  readonly candidateOffers: CandidateOffersResponse;
  /** Returns candidate opportunities. */
  readonly candidateOpportunities: CandidateOpportunitiesResponse;
  /** Returns candidate requests that match the request. */
  readonly candidateOpportunitiesMatchingRequest: CandidateOpportunitiesMatchingRequestResponse;
  /** Returns candidate opportunities. */
  readonly candidateOpportunity: CandidateOpportunityResponse;
  readonly candidateRequest: CandidateRequestResponse;
  /** Returns candidate request's accepted candidate offer. */
  readonly candidateRequestAcceptedOffer: CandidateRequestAcceptedOfferResponse;
  /** Returns offers of a candidate request. */
  readonly candidateRequestOffers: CandidateRequestOffersResponse;
  /** Returns candidate requests. */
  readonly candidateRequests: CandidateRequestsResponse;
  readonly educationLevels: EducationLevelsResponse;
  readonly industrySectors: IndustrySectorsResponse;
  readonly industryTypes: IndustryTypesArrayResponse;
  readonly isAdmin: BooleanAnswer;
  readonly jobDurations: JobDurationsResponse;
  readonly jobExperienceLevels: JobExperienceLevelsResponse;
  readonly jobModes: JobModesResponse;
  readonly jobPaymentFrequencies: JobPaymentFrequenciesResponse;
  readonly jobPaymentOtherTypes: JobPaymentOtherTypesResponse;
  readonly jobScheduleTypes: JobScheduleTypesResponse;
  readonly jobSpecialties: JobSpecialtiesResponse;
  /** Returns information about the authenticated user. */
  readonly me: MeResponse;
  readonly offer: OfferResponse;
  readonly offerCandidate: OfferCandidateResponse;
  /** Returns offer candidates. */
  readonly offerCandidates: OfferCandidatesResponse;
  readonly offerStatusTypes: OfferStatusTypesResponse;
  /** Returns offers. */
  readonly offers: OffersResponse;
  readonly operationTerritories: OperationTerritoriesResponse;
  readonly operationUnit: OperationUnitResponse;
  readonly operationUnits: OperationUnitsResponse;
  /** Simple operation that returns "pong" when called, to ensure the API is up */
  readonly ping: Scalars['String']['output'];
  readonly publicAccount: AccountResponse;
  readonly recruitmentNegotiation: RecruitmentNegotiationResponse;
  readonly recruitmentNegotiationRoom: RecruitmentNegotiationRoomResponse;
  /**
   * Should only be used by offer creators.
   *       This query will always force the rooms to be filtered
   *       by offerAccountId matching the current user account id.
   */
  readonly recruitmentNegotiationRooms: RecruitmentNegotiationRoomsResponse;
  /**
   * Should only be used by negotiation/request creators.
   *       This query will always force the negotiations to be filtered by the current user account id.
   */
  readonly recruitmentNegotiations: RecruitmentNegotiationsResponse;
  /** Returns recruitment opportunities. */
  readonly recruitmentOpportunities: RecruitmentOpportunitiesResponse;
  /** Returns recruitment opportunities. */
  readonly recruitmentOpportunity: RecruitmentOpportunityResponse;
  readonly request: RequestResponse;
  /** Returns request's accepted offer. */
  readonly requestAcceptedOffer: RequestAcceptedOfferResponse;
  /** Get messages of a request chat room */
  readonly requestChatRoomMessages: RequestChatRoomMessagesResponse;
  /** Get request chat rooms */
  readonly requestChatRooms: RequestChatRoomsResponse;
  /** Returns request's offers. */
  readonly requestOffers: RequestOffersResponse;
  readonly requestStatusTypes: RequestStatusTypesResponse;
  /** Returns requests. */
  readonly requests: RequestsResponse;
};


export type QueryAccountOperationTerritoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AccountOperationTerritoriesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<AccountOperationTerritoriesSortDirective>>;
};


export type QueryAccountPreferredSpecialtiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AccountPreferredSpecialtiesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<AccountPreferredSpecialtiesSortDirective>>;
};


export type QueryAccountUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AccountUsersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<AccountUsersSortDirective>>;
};


export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AccountsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<AccountsSortDirective>>;
};


export type QueryCandidateNegotiationArgs = {
  input: CandidateNegotiationInput;
};


export type QueryCandidateNegotiationRoomArgs = {
  input: CandidateNegotiationRoomInput;
};


export type QueryCandidateNegotiationRoomsArgs = {
  filterBy?: InputMaybe<CandidateNegotiationRoomsFilters>;
};


export type QueryCandidateNegotiationsArgs = {
  filterBy?: InputMaybe<CandidateNegotiationsFilters>;
};


export type QueryCandidateOfferArgs = {
  input: CandidateOfferInput;
};


export type QueryCandidateOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<CandidateOffersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<CandidateOffersSortDirective>>;
};


export type QueryCandidateOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<CandidateOpportunitiesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<CandidateOpportunitiesSortDirective>>;
};


export type QueryCandidateOpportunitiesMatchingRequestArgs = {
  input: CandidateOpportunitiesMatchingRequestInput;
  sortBy?: InputMaybe<ReadonlyArray<CandidateOpportunitiesSortDirective>>;
};


export type QueryCandidateOpportunityArgs = {
  input: CandidateOpportunityInput;
};


export type QueryCandidateRequestArgs = {
  input: CandidateRequestInput;
};


export type QueryCandidateRequestAcceptedOfferArgs = {
  input: CandidateRequestAcceptedOfferInput;
};


export type QueryCandidateRequestOffersArgs = {
  input: CandidateRequestOffersInput;
};


export type QueryCandidateRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<CandidateRequestsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<CandidateRequestsSortDirective>>;
};


export type QueryJobSpecialtiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  input: JobSpecialtiesRequest;
  last?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryOfferArgs = {
  input: GetOfferInput;
};


export type QueryOfferCandidateArgs = {
  offerCandidateGetInput: OfferCandidateGetInput;
};


export type QueryOfferCandidatesArgs = {
  filterBy: OfferCandidatesFilters;
};


export type QueryOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OffersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<OffersSortDirective>>;
};


export type QueryOperationUnitArgs = {
  input: OperationUnitInput;
};


export type QueryOperationUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OperationUnitsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<OperationUnitsSortDirective>>;
};


export type QueryPublicAccountArgs = {
  input: PublicAccountInput;
};


export type QueryRecruitmentNegotiationArgs = {
  input: RecruitmentNegotiationInput;
};


export type QueryRecruitmentNegotiationRoomArgs = {
  input: RecruitmentNegotiationRoomInput;
};


export type QueryRecruitmentNegotiationRoomsArgs = {
  filterBy?: InputMaybe<RecruitmentNegotiationRoomsFilters>;
};


export type QueryRecruitmentNegotiationsArgs = {
  filterBy?: InputMaybe<RecruitmentNegotiationsFilters>;
};


export type QueryRecruitmentOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<RecruitmentOpportunitiesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<RecruitmentOpportunitiesSortDirective>>;
};


export type QueryRecruitmentOpportunityArgs = {
  input: RecruitmentOpportunityInput;
};


export type QueryRequestArgs = {
  requestGetInput: RequestGetInput;
};


export type QueryRequestAcceptedOfferArgs = {
  input: RequestAcceptedOfferInput;
};


export type QueryRequestChatRoomMessagesArgs = {
  input: RequestChatRoomMessagesInput;
};


export type QueryRequestChatRoomsArgs = {
  input: RequestChatRoomsInput;
};


export type QueryRequestOffersArgs = {
  input: RequestOffersInput;
};


export type QueryRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<RequestsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<RequestsSortDirective>>;
};

/**
 * A recruitment negotiation is the entity that represent a negotiation between
 *   a request and multiple offers (negotiation room) in the recruitment flow. Technically, only
 *   the company that made the request can see this entity because it is linked to every offers.
 */
export type RecruitmentNegotiation = {
  readonly __typename?: 'RecruitmentNegotiation';
  /** account id of the negotiation creator, should be the same as the request's account id */
  readonly accountId: Scalars['String']['output'];
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** latest activity on negotiation */
  readonly latestActivity: Scalars['DateTime']['output'];
  /** number of unread notification for this negotiation */
  readonly notificationCount: Scalars['Int']['output'];
  /** request linked to this negotiation */
  readonly request: Request;
  /** request id of the request to which the negotiation is from */
  readonly requestId: Scalars['String']['output'];
  /** negotiations rooms linked to this negotiation */
  readonly rooms: ReadonlyArray<RecruitmentNegotiationRoom>;
  /** number of rooms linked to this negotiation */
  readonly roomsCount: Scalars['Int']['output'];
  /** status of the negotiation */
  readonly status: RecruitmentNegotiationStatus;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** user id of the negotiation creator */
  readonly userId: Scalars['String']['output'];
};

export type RecruitmentNegotiationAddRoomsInput = {
  /** offer ids to add rooms for the negotiation */
  readonly offerIds: ReadonlyArray<Scalars['String']['input']>;
  /** if of recruitment negotiation to add the rooms to */
  readonly recruitmentNegotiationId: Scalars['String']['input'];
};

export type RecruitmentNegotiationCompleteInput = {
  /** negotiation id to complete */
  readonly negotiationId: Scalars['String']['input'];
  /** room id to accept */
  readonly roomId: Scalars['String']['input'];
};

export type RecruitmentNegotiationCreateInput = {
  /** offer ids to create the negotiation from */
  readonly offerIds: ReadonlyArray<Scalars['String']['input']>;
  /** request id to create the negotiation from */
  readonly requestId: Scalars['String']['input'];
};

/** errors that can occur when calling recruitment negotiation endpoints. */
export const RecruitmentNegotiationErrors = {
  /** Can only add up to 4 rooms to recruitment negotiation. */
  MaxFourRooms: 'MAX_FOUR_ROOMS'
} as const;

export type RecruitmentNegotiationErrors = typeof RecruitmentNegotiationErrors[keyof typeof RecruitmentNegotiationErrors];
/** Define the state of the general conditions properties that are negotiable. */
export type RecruitmentNegotiationGeneralPropertiesState = {
  readonly __typename?: 'RecruitmentNegotiationGeneralPropertiesState';
  /** bonus state */
  readonly bonus: RecruitmentNegotiationPropertyState;
  /** deposit state */
  readonly deposit: RecruitmentNegotiationPropertyState;
  /** recruitment period state */
  readonly maxRecruitmentPeriod: RecruitmentNegotiationPropertyState;
  /** min guaranteed period state */
  readonly minGuaranteedPeriod: RecruitmentNegotiationPropertyState;
  /** number of payments state */
  readonly paymentAmount: RecruitmentNegotiationPropertyState;
  /** frequency of payments state */
  readonly paymentFrequency: RecruitmentNegotiationPropertyState;
  /** payment method state */
  readonly paymentMethod: RecruitmentNegotiationPropertyState;
  /** probation period state */
  readonly probationPeriod: RecruitmentNegotiationPropertyState;
  /** payment method for start of agreement state */
  readonly startOfAgreementPaymentMethod: RecruitmentNegotiationPropertyState;
  /** total costs state */
  readonly totalCosts: RecruitmentNegotiationPropertyState;
};

/** Define the state of the headhunter conditions properties that are negotiable. */
export type RecruitmentNegotiationHeadhunterPropertiesState = {
  readonly __typename?: 'RecruitmentNegotiationHeadhunterPropertiesState';
  /** exclusivity period state */
  readonly exclusivityPeriod: RecruitmentNegotiationPropertyState;
  /** maximum replacement state */
  readonly maximumReplacement: RecruitmentNegotiationPropertyState;
};

export type RecruitmentNegotiationInput = {
  /** recruitment negotiation unique id */
  readonly negotiationId: Scalars['String']['input'];
};

/** Define the state of the international recruitment conditions properties that are negotiable. */
export type RecruitmentNegotiationInternationalRecruitmentPropertiesState = {
  readonly __typename?: 'RecruitmentNegotiationInternationalRecruitmentPropertiesState';
  /** exclusivity period for applicant search condition state */
  readonly exclusivityPeriod: RecruitmentNegotiationPropertyState;
  /** maximum replacement state */
  readonly maximumReplacement: RecruitmentNegotiationPropertyState;
};

/** Define the state of the outsourcing conditions properties that are negotiable. */
export type RecruitmentNegotiationOutsourcingPropertiesState = {
  readonly __typename?: 'RecruitmentNegotiationOutsourcingPropertiesState';
  /** exclusivity period state */
  readonly exclusivityPeriod: RecruitmentNegotiationPropertyState;
  /** maximum budget state */
  readonly maximumBudget: RecruitmentNegotiationPropertyState;
  /** maximum duration state */
  readonly maximumDuration: RecruitmentNegotiationPropertyState;
};

/** Statuses that a recruitment negotiation room can have. */
export const RecruitmentNegotiationPropertyState = {
  Accepted: 'ACCEPTED',
  Initial: 'INITIAL',
  Modified: 'MODIFIED',
  Rejected: 'REJECTED'
} as const;

export type RecruitmentNegotiationPropertyState = typeof RecruitmentNegotiationPropertyState[keyof typeof RecruitmentNegotiationPropertyState];
export type RecruitmentNegotiationResponse = {
  readonly __typename?: 'RecruitmentNegotiationResponse';
  /** recruitment negotiation */
  readonly negotiation: RecruitmentNegotiation;
};

/**
 * A recruitment negotiation room is the entity that represent a negotiation between
 *   a request and a single offer in the recruitment flow. It is linked to a single recruitment negotiation.
 *   Both parties can see everything in this model.
 */
export type RecruitmentNegotiationRoom = {
  readonly __typename?: 'RecruitmentNegotiationRoom';
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** state of the general conditions properties */
  readonly generalPropertiesState: RecruitmentNegotiationGeneralPropertiesState;
  /** state of the headhunter conditions properties */
  readonly headhunterPropertiesState: RecruitmentNegotiationHeadhunterPropertiesState;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** state of the international recruitment conditions properties */
  readonly internationalRecruitmentPropertiesState: RecruitmentNegotiationInternationalRecruitmentPropertiesState;
  /** NegotiatingOffer linked to this negotiation room */
  readonly negotiatingOffer: Offer;
  /**
   * id of the offer the negotiation is being performed upon.
   *       This is different that the offerId.
   *       This offer will never be shown to any user, it is only there to be updated.
   *       This is because we cannot update the original offer because once it's published, it's frozen in time.
   *       This also gives us the ability to keep using our current matching score algorithm.
   */
  readonly negotiatingOfferId: Scalars['String']['output'];
  /** number of unread notification for this room */
  readonly notificationCount: Scalars['Int']['output'];
  /** Offer linked to this negotiation room */
  readonly offer: Offer;
  /** account id of the offer creator */
  readonly offerAccountId: Scalars['String']['output'];
  /** id of the offer linked to this room */
  readonly offerId: Scalars['String']['output'];
  /** user id of the offer creator */
  readonly offerUserId: Scalars['String']['output'];
  /** state of the outsourcing conditions properties */
  readonly outsourcingPropertiesState: RecruitmentNegotiationOutsourcingPropertiesState;
  /** id of the negotiation this room is linked to */
  readonly recruitmentNegotiationId: Scalars['String']['output'];
  /** request linked to this negotiation room */
  readonly request: Request;
  /** account id of the request creator */
  readonly requestAccountId: Scalars['String']['output'];
  /** id of the request linked to this room */
  readonly requestId: Scalars['String']['output'];
  /** user id of the request creator */
  readonly requestUserId: Scalars['String']['output'];
  /** status of the negotiation room */
  readonly status: RecruitmentNegotiationRoomStatus;
  /** state of the temporary placement conditions properties */
  readonly temporaryPlacementPropertiesState: RecruitmentNegotiationTemporaryPlacementPropertiesState;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type RecruitmentNegotiationRoomCreateInput = {
  /** offer id to create the negotiation from */
  readonly offerId: Scalars['String']['input'];
  /** recruitment negotiation id to create the negotiation room from */
  readonly recruitmentNegotiationId: Scalars['String']['input'];
  /** request id to create the negotiation room from */
  readonly requestId: Scalars['String']['input'];
};

export type RecruitmentNegotiationRoomInput = {
  /** recruitment negotiation room unique id */
  readonly roomId: Scalars['String']['input'];
};

export type RecruitmentNegotiationRoomResponse = {
  readonly __typename?: 'RecruitmentNegotiationRoomResponse';
  /** recruitment negotiation room */
  readonly room: RecruitmentNegotiationRoom;
};

/** Statuses that a recruitment negotiation room can have. */
export const RecruitmentNegotiationRoomStatus = {
  Accepted: 'ACCEPTED',
  InProgress: 'IN_PROGRESS',
  Rejected: 'REJECTED'
} as const;

export type RecruitmentNegotiationRoomStatus = typeof RecruitmentNegotiationRoomStatus[keyof typeof RecruitmentNegotiationRoomStatus];
export type RecruitmentNegotiationRoomsFilters = {
  /** filter by recruitment negotiation statuses */
  readonly statuses?: InputMaybe<ReadonlyArray<RecruitmentNegotiationRoomStatus>>;
};

export type RecruitmentNegotiationRoomsRejectInput = {
  /** recruitment negotiation rooms ids to reject */
  readonly roomIds: ReadonlyArray<Scalars['String']['input']>;
};

export type RecruitmentNegotiationRoomsRejectResponse = {
  readonly __typename?: 'RecruitmentNegotiationRoomsRejectResponse';
  /** rejected recruitment negotiation rooms */
  readonly rooms: ReadonlyArray<RecruitmentNegotiationRoom>;
};

export type RecruitmentNegotiationRoomsResponse = {
  readonly __typename?: 'RecruitmentNegotiationRoomsResponse';
  /** recruitment negotiation rooms */
  readonly results: ReadonlyArray<RecruitmentNegotiationRoom>;
  /** number of items */
  readonly totalCount: Scalars['Int']['output'];
};

export type RecruitmentNegotiationStateUpdateInput = {
  /** recruitment negotiation general properties state */
  readonly generalPropertiesState?: InputMaybe<PartialRecruitmentNegotiationGeneralPropertiesState>;
  /** recruitment negotiation headhunter properties state */
  readonly headhunterPropertiesState?: InputMaybe<PartialRecruitmentNegotiationHeadhunterPropertiesState>;
  /** recruitment negotiation room unique id */
  readonly id: Scalars['String']['input'];
  /** recruitment negotiation international recruitment properties state */
  readonly internationalRecruitmentPropertiesState?: InputMaybe<PartialRecruitmentNegotiationInternationalRecruitmentPropertiesState>;
  /** recruitment negotiation outsourcing properties state */
  readonly outsourcingPropertiesState?: InputMaybe<PartialRecruitmentNegotiationOutsourcingPropertiesState>;
  /** recruitment negotiation temporary placement properties state */
  readonly temporaryPlacementPropertiesState?: InputMaybe<PartialRecruitmentNegotiationTemporaryPlacementPropertiesState>;
};

/** Statuses that a recruitment negotiation can have. */
export const RecruitmentNegotiationStatus = {
  Completed: 'COMPLETED',
  InProgress: 'IN_PROGRESS'
} as const;

export type RecruitmentNegotiationStatus = typeof RecruitmentNegotiationStatus[keyof typeof RecruitmentNegotiationStatus];
/** Define the state of the temporary placement conditions properties that are negotiable. */
export type RecruitmentNegotiationTemporaryPlacementPropertiesState = {
  readonly __typename?: 'RecruitmentNegotiationTemporaryPlacementPropertiesState';
  /** cnesst expenses responsibility state */
  readonly cnesstExpensesResponsibility: RecruitmentNegotiationPropertyState;
  /** employee help program responsibility state */
  readonly employeeHelpProgramResponsibility: RecruitmentNegotiationPropertyState;
  /** possible buyback of the candidate within 6 months state */
  readonly possibleBuyback: RecruitmentNegotiationPropertyState;
  /** training expenses responsibility state */
  readonly trainingExpensesResponsibility: RecruitmentNegotiationPropertyState;
  /** travel expenses responsibility state */
  readonly travelExpensesResponsibility: RecruitmentNegotiationPropertyState;
};

export type RecruitmentNegotiationsFilters = {
  /** filter by recruitment negotiation statuses */
  readonly statuses?: InputMaybe<ReadonlyArray<RecruitmentNegotiationStatus>>;
};

export type RecruitmentNegotiationsResponse = {
  readonly __typename?: 'RecruitmentNegotiationsResponse';
  /** recruitment negotiations */
  readonly results: ReadonlyArray<RecruitmentNegotiation>;
  /** number of items */
  readonly totalCount: Scalars['Int']['output'];
};

export type RecruitmentOpportunitiesFilters = {
  /** filter on match score (according to the current user's and account's job preferences) */
  readonly match?: InputMaybe<ReadonlyArray<RequestMatchCode>>;
  /** filter recruitment opportunity operation territory */
  readonly operationTerritoryCode?: InputMaybe<OperationTerritoryCode>;
  /** filter recruitment opportunity type */
  readonly recruitmentOpportunityType?: InputMaybe<RecruitmentOpportunityType>;
  /** filter on parts of the recruitment opportunity */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing recruitment opportunities */
export type RecruitmentOpportunitiesResponse = {
  readonly __typename?: 'RecruitmentOpportunitiesResponse';
  readonly page: RecruitmentOpportunityConnection;
  readonly pageData?: Maybe<PageData>;
};

/** For requesting recruitment opportunities to be sorted */
export type RecruitmentOpportunitiesSortDirective = {
  readonly by: RequestsSortBy;
  readonly direction: SortDirection;
};

/**
 * A recruitment opportunity is basically a request with all its fields related to the company stripped out.
 *   This means that when an agency looks at a recruitment opportunity, it cannot identify the company. This is important
 *   so that companies do not get harassed by agencies.
 *
 */
export type RecruitmentOpportunity = {
  readonly __typename?: 'RecruitmentOpportunity';
  /** industry type of the account related to this opportunity */
  readonly accountIndustryType?: Maybe<IndustryType>;
  /** industry type of the account related to this opportunity */
  readonly accountIndustryTypeCode?: Maybe<AccountIndustryType>;
  /** sector of the account related to this opportunity */
  readonly accountSector?: Maybe<IndustrySector>;
  /** sector code of the account related to this opportunity */
  readonly accountSectorCode?: Maybe<Scalars['String']['output']>;
  /** Cities and sub regions */
  readonly citiesSubRegions?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** Desired education level codes */
  readonly desiredEducationLevelCodes?: Maybe<ReadonlyArray<EducationLevelCode>>;
  /** Desired education levels */
  readonly desiredEducationLevels?: Maybe<ReadonlyArray<EducationLevel>>;
  /** desired start date */
  readonly desiredStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** desired study field */
  readonly desiredStudyField?: Maybe<Scalars['String']['output']>;
  /** description of the entreprise related to this opportunity */
  readonly enterpriseDescription?: Maybe<Scalars['String']['output']>;
  /** general contractual conditions */
  readonly generalContractualConditions: RequestGeneralConditions;
  /** general notes */
  readonly generalNotes?: Maybe<Scalars['String']['output']>;
  /** contractual conditions when selecting headhunter service */
  readonly headhunterContractualConditions?: Maybe<RequestHeadhunterConditions>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** contractual conditions when selecting international recruitment service */
  readonly internationalRecruitmentContractualConditions?: Maybe<RequestInternationalRecruitmentConditions>;
  /** job additional notes */
  readonly jobAdditionalNotes?: Maybe<Scalars['String']['output']>;
  /** job availability in hours per week */
  readonly jobAvailabilityInHoursPerWeek?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** job description */
  readonly jobDescription?: Maybe<Scalars['String']['output']>;
  /** job duration */
  readonly jobDuration?: Maybe<JobDuration>;
  /** job duration code */
  readonly jobDurationCode?: Maybe<JobDurationCode>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: Maybe<Scalars['Int']['output']>;
  /** job maximum experience level */
  readonly jobExperienceLevelMax?: Maybe<Scalars['Int']['output']>;
  /** job minimum experience level */
  readonly jobExperienceLevelMin?: Maybe<Scalars['Int']['output']>;
  /** job full-time availability */
  readonly jobFullTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** job hourly bonus */
  readonly jobHourlyBonus?: Maybe<Scalars['String']['output']>;
  /** job maximum hourly rate */
  readonly jobHourlyRateMax?: Maybe<Scalars['String']['output']>;
  /** job minimum hourly rate */
  readonly jobHourlyRateMin?: Maybe<Scalars['String']['output']>;
  /** job internal title */
  readonly jobInternalTitle?: Maybe<Scalars['String']['output']>;
  /** job work mode */
  readonly jobMode?: Maybe<JobMode>;
  /** job work mode */
  readonly jobModeCode?: Maybe<JobModeCode>;
  /** number of job open positions */
  readonly jobOpenPositions?: Maybe<Scalars['Int']['output']>;
  /** job other remuneration amount */
  readonly jobOtherRemunerationAmount?: Maybe<Scalars['String']['output']>;
  /** job part-time availability */
  readonly jobPartTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** job payment frequency */
  readonly jobPaymentFrequencyCode?: Maybe<JobPaymentFrequencyCode>;
  /** job other payment type */
  readonly jobPaymentOtherTypesCodes?: Maybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** number of allowed remote days */
  readonly jobRemoteModeInDays?: Maybe<Scalars['Int']['output']>;
  /** job remuneration amount */
  readonly jobRemunerationAmount?: Maybe<Scalars['String']['output']>;
  /** job maximum salary */
  readonly jobSalaryMax?: Maybe<Scalars['Int']['output']>;
  /** job minimum salary */
  readonly jobSalaryMin?: Maybe<Scalars['Int']['output']>;
  /** job specialty */
  readonly jobSpecialty?: Maybe<JobSpecialty>;
  /** job speciality code */
  readonly jobSpecialtyCode?: Maybe<Scalars['String']['output']>;
  /** job working hours */
  readonly jobWorkingHours?: Maybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** is open to international recruitment, goes with the type code */
  readonly openToInternationalRecruitment?: Maybe<Scalars['Boolean']['output']>;
  /** operation territories */
  readonly operationTerritories?: Maybe<ReadonlyArray<OperationTerritory>>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: Maybe<ReadonlyArray<OperationTerritoryCode>>;
  /** operation unit */
  readonly operationUnit?: Maybe<OperationUnit>;
  /** operation unit id of the request related account */
  readonly operationUnitId?: Maybe<Scalars['String']['output']>;
  /** other perks offered */
  readonly otherPerks?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** contractual conditions when selecting outsourcing service */
  readonly outsourcingContractualConditions?: Maybe<RequestOutsourcingConditions>;
  /** perks offered */
  readonly perks?: Maybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** sector related to this opportunity */
  readonly sector?: Maybe<IndustrySector>;
  /** sector code of the request */
  readonly sectorCode?: Maybe<Scalars['String']['output']>;
  /** soft skills */
  readonly softSkills?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** additional specialty info (optional) */
  readonly specialty?: Maybe<Scalars['String']['output']>;
  /** contractual conditions when selecting temporary placement service */
  readonly temporaryPlacementContractualConditions?: Maybe<RequestTemporaryPlacementConditions>;
  /** types of the request */
  readonly types?: Maybe<ReadonlyArray<RequestTypeCode>>;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type RecruitmentOpportunityConnection = {
  readonly __typename?: 'RecruitmentOpportunityConnection';
  readonly edges?: Maybe<ReadonlyArray<RecruitmentOpportunityEdge>>;
  readonly pageInfo?: Maybe<RecruitmentOpportunityPageInfo>;
};

export type RecruitmentOpportunityEdge = {
  readonly __typename?: 'RecruitmentOpportunityEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<RecruitmentOpportunity>;
};

export type RecruitmentOpportunityInput = {
  /** unique id of the request representing this opportunity */
  readonly requestId: Scalars['String']['input'];
};

export type RecruitmentOpportunityPageInfo = {
  readonly __typename?: 'RecruitmentOpportunityPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

export type RecruitmentOpportunityResponse = {
  readonly __typename?: 'RecruitmentOpportunityResponse';
  /** recruitment opportunity */
  readonly opportunity: RecruitmentOpportunity;
};

/** recruitment opportunity type */
export const RecruitmentOpportunityType = {
  InstantApplication: 'INSTANT_APPLICATION',
  ServiceRequest: 'SERVICE_REQUEST'
} as const;

export type RecruitmentOpportunityType = typeof RecruitmentOpportunityType[keyof typeof RecruitmentOpportunityType];
/** For rejecting a candidate offer */
export type RejectCandidateOffersInput = {
  /** the unique ids of the candidate offers to reject */
  readonly ids: ReadonlyArray<Scalars['String']['input']>;
};

/** Response when rejecting multiple candidate offers */
export type RejectCandidateOffersResponse = {
  readonly __typename?: 'RejectCandidateOffersResponse';
  readonly candidateOffers: ReadonlyArray<CandidateOffer>;
};

/** For rejecting an offer */
export type RejectOffersInput = {
  /** the unique ids of the offers to reject */
  readonly ids: ReadonlyArray<Scalars['String']['input']>;
};

/** Response when rejecting multiple offers */
export type RejectOffersResponse = {
  readonly __typename?: 'RejectOffersResponse';
  readonly offers: ReadonlyArray<Offer>;
};

/**
 * A recruitment request is made by a company towards the exterior:
 *   a request for a partnership with another company,
 *   a hiring request as they are looking for someone,
 *   an availability request as someone is available for work,
 *   etc.
 *   The request is always attached to one account and one operation unit
 *   belonging to the account.
 */
export type Request = {
  readonly __typename?: 'Request';
  /** account related to this request */
  readonly account: Account;
  /** account Id */
  readonly accountId: Scalars['String']['output'];
  /** request available transitions */
  readonly availableTransitions: ReadonlyArray<RequestStatusTypeCode>;
  /** Cities and sub regions */
  readonly citiesSubRegions?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** creation time */
  readonly createdAt: Scalars['DateTime']['output'];
  /** Desired education level codes */
  readonly desiredEducationLevelCodes?: Maybe<ReadonlyArray<EducationLevelCode>>;
  /** Desired education levels */
  readonly desiredEducationLevels?: Maybe<ReadonlyArray<EducationLevel>>;
  /** desired start date */
  readonly desiredStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** desired study field */
  readonly desiredStudyField?: Maybe<Scalars['String']['output']>;
  /** general contractual conditions */
  readonly generalContractualConditions: RequestGeneralConditions;
  /** general notes */
  readonly generalNotes?: Maybe<Scalars['String']['output']>;
  /** check if the request has at least one agency to open the chat */
  readonly hasAgencyToChat?: Maybe<Scalars['Boolean']['output']>;
  /** contractual conditions when selecting headhunter service */
  readonly headhunterContractualConditions?: Maybe<RequestHeadhunterConditions>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** contractual conditions when selecting international recruitment service */
  readonly internationalRecruitmentContractualConditions?: Maybe<RequestInternationalRecruitmentConditions>;
  /** importance of the request */
  readonly isImportant?: Maybe<Scalars['Boolean']['output']>;
  /** job additional notes */
  readonly jobAdditionalNotes?: Maybe<Scalars['String']['output']>;
  /** job availability in hours per week */
  readonly jobAvailabilityInHoursPerWeek?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** job description */
  readonly jobDescription?: Maybe<Scalars['String']['output']>;
  /** job duration */
  readonly jobDuration?: Maybe<JobDuration>;
  /** job duration code */
  readonly jobDurationCode?: Maybe<JobDurationCode>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: Maybe<Scalars['Int']['output']>;
  /** job maximum experience level */
  readonly jobExperienceLevelMax?: Maybe<Scalars['Int']['output']>;
  /** job minimum experience level */
  readonly jobExperienceLevelMin?: Maybe<Scalars['Int']['output']>;
  /** job full-time availability */
  readonly jobFullTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** job hourly bonus */
  readonly jobHourlyBonus?: Maybe<Scalars['String']['output']>;
  /** job maximum hourly rate */
  readonly jobHourlyRateMax?: Maybe<Scalars['String']['output']>;
  /** job minimum hourly rate */
  readonly jobHourlyRateMin?: Maybe<Scalars['String']['output']>;
  /** job internal title */
  readonly jobInternalTitle?: Maybe<Scalars['String']['output']>;
  /** job work mode */
  readonly jobMode?: Maybe<JobMode>;
  /** job work mode */
  readonly jobModeCode?: Maybe<JobModeCode>;
  /** number of job open positions */
  readonly jobOpenPositions?: Maybe<Scalars['Int']['output']>;
  /** job other remuneration amount */
  readonly jobOtherRemunerationAmount?: Maybe<Scalars['String']['output']>;
  /** job part-time availability */
  readonly jobPartTimeAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** job payment frequency */
  readonly jobPaymentFrequency?: Maybe<JobPaymentFrequency>;
  /** job payment frequency */
  readonly jobPaymentFrequencyCode?: Maybe<JobPaymentFrequencyCode>;
  /** job other payment type */
  readonly jobPaymentOtherTypesCodes?: Maybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** number of allowed remote days */
  readonly jobRemoteModeInDays?: Maybe<Scalars['Int']['output']>;
  /** job remuneration amount */
  readonly jobRemunerationAmount?: Maybe<Scalars['String']['output']>;
  /** job maximum salary */
  readonly jobSalaryMax?: Maybe<Scalars['Int']['output']>;
  /** job minimum salary */
  readonly jobSalaryMin?: Maybe<Scalars['Int']['output']>;
  /** job specialty */
  readonly jobSpecialty?: Maybe<JobSpecialty>;
  /** job speciality code */
  readonly jobSpecialtyCode?: Maybe<Scalars['String']['output']>;
  /** job working hours */
  readonly jobWorkingHours?: Maybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** matching candidate opportunities */
  readonly matchingCandidateOpportunities?: Maybe<ReadonlyArray<CandidateOpportunity>>;
  /** number of offers for this request */
  readonly offersCount: Scalars['Float']['output'];
  /** is open to international recruitment, goes with the type code */
  readonly openToInternationalRecruitment?: Maybe<Scalars['Boolean']['output']>;
  /** operation territories */
  readonly operationTerritories?: Maybe<ReadonlyArray<OperationTerritory>>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: Maybe<ReadonlyArray<OperationTerritoryCode>>;
  /** operation unit */
  readonly operationUnit?: Maybe<OperationUnit>;
  /** operation unit id of the request related account */
  readonly operationUnitId?: Maybe<Scalars['String']['output']>;
  /** other perks offered */
  readonly otherPerks?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** contractual conditions when selecting outsourcing service */
  readonly outsourcingContractualConditions?: Maybe<RequestOutsourcingConditions>;
  /** perks offered */
  readonly perks?: Maybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** priority of the request */
  readonly priority?: Maybe<RequestPriorityType>;
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** recruitment negotiation associated to this request */
  readonly recruitmentNegotiation?: Maybe<RecruitmentNegotiation>;
  /** sector related to this request */
  readonly sector?: Maybe<IndustrySector>;
  /** sector code of the request */
  readonly sectorCode?: Maybe<Scalars['String']['output']>;
  /** soft skills */
  readonly softSkills?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  /** additional specialty info (optional) */
  readonly specialty?: Maybe<Scalars['String']['output']>;
  /** status of the request */
  readonly status: RequestStatusTypeCode;
  /** contractual conditions when selecting temporary placement service */
  readonly temporaryPlacementContractualConditions?: Maybe<RequestTemporaryPlacementConditions>;
  /** types of the request */
  readonly types?: Maybe<ReadonlyArray<RequestTypeCode>>;
  /** updated time */
  readonly updatedAt: Scalars['DateTime']['output'];
  /** request's user */
  readonly user: User;
  /** user id of the request creator */
  readonly userId: Scalars['String']['output'];
};

/** For fetching the accepted offer of a request */
export type RequestAcceptedOfferInput = {
  /** the unique id of the request */
  readonly requestId: Scalars['String']['input'];
};

/** Response when fetching the accepted offer of a request */
export type RequestAcceptedOfferResponse = {
  readonly __typename?: 'RequestAcceptedOfferResponse';
  readonly offer?: Maybe<Offer>;
};

/** A request chat room, is a chat room linked to a request, with an enterprise and an agency */
export type RequestChatRoom = {
  readonly __typename?: 'RequestChatRoom';
  /** agency account id in the request chat room */
  readonly agencyAccountId: Scalars['String']['output'];
  /** Request chat room agency last message */
  readonly agencyLastMessage?: Maybe<RequestChatRoomMessage>;
  /** Request chat room agency name */
  readonly agencyName?: Maybe<Scalars['String']['output']>;
  /** date of the request chat room creation */
  readonly creationDate: Scalars['DateTime']['output'];
  /** enterprise account id in the request chat room */
  readonly enterpriseAccountId: Scalars['String']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** define if the enterprise is anonym in the request chat room */
  readonly isEnterpriseAnonym: Scalars['Boolean']['output'];
  /** Request chat room negotiation rank */
  readonly offerData?: Maybe<RequestChatRoomOfferData>;
  /** id of request */
  readonly requestId: Scalars['String']['output'];
};

export type RequestChatRoomCreateInput = {
  /** ID of the agency in the chat room */
  readonly agencyAccountId: Scalars['String']['input'];
  /** ID of the enterprise in the chat room */
  readonly enterpriseAccountId: Scalars['String']['input'];
  /** Is enterprise anonym */
  readonly isEnterpriseAnonym: Scalars['Boolean']['input'];
  /** ID of the request to which the request chat room is linked */
  readonly requestId: Scalars['String']['input'];
};

/** A request chat room message, is a message linked to a request chat room */
export type RequestChatRoomMessage = {
  readonly __typename?: 'RequestChatRoomMessage';
  /** account id of the sender of the chat room message */
  readonly accountId: Scalars['String']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** content of the chat room message */
  readonly message: Scalars['String']['output'];
  /** request chat room id */
  readonly requestChatRoomId: Scalars['String']['output'];
  /** date the chat room message was sent */
  readonly sentAt: Scalars['DateTime']['output'];
  /** id of the sender of the chat room message */
  readonly userId: Scalars['String']['output'];
};

export type RequestChatRoomMessageCreateInput = {
  /** Message content */
  readonly message: Scalars['String']['input'];
  /** ID of the request chat room to which the request chat room message is linked */
  readonly requestChatRoomId: Scalars['String']['input'];
};

export type RequestChatRoomMessageResponse = {
  readonly __typename?: 'RequestChatRoomMessageResponse';
  /** Request chat room message */
  readonly message: RequestChatRoomMessage;
};

export type RequestChatRoomMessagesInput = {
  /** ID of the request chat room to which the request chat room message is linked */
  readonly requestChatRoomId: Scalars['String']['input'];
};

export type RequestChatRoomMessagesResponse = {
  readonly __typename?: 'RequestChatRoomMessagesResponse';
  /** Messages of a request chat room */
  readonly results: ReadonlyArray<RequestChatRoomMessage>;
  /** number of items */
  readonly totalCount: Scalars['Int']['output'];
};

/** Type for the RequestChatRoomOfferDataFieldResolver */
export type RequestChatRoomOfferData = {
  readonly __typename?: 'RequestChatRoomOfferData';
  /** define if the agency in the chat room has made an offer */
  readonly hasMadeOffer: Scalars['Boolean']['output'];
  /** define if the agency in the chat room is in negotiation */
  readonly isUnderNegotiation: Scalars['Boolean']['output'];
  /** rank of the offer */
  readonly rank?: Maybe<Scalars['Float']['output']>;
};

export type RequestChatRoomResponse = {
  readonly __typename?: 'RequestChatRoomResponse';
  /** Request chat room */
  readonly chatRoom: RequestChatRoom;
};

export type RequestChatRoomSetEnterpriseAnonymityInput = {
  /** Is enterprise anonym */
  readonly isEnterpriseAnonym: Scalars['Boolean']['input'];
  /** ID of the request to which the request chat room is linked */
  readonly requestChatRoomId: Scalars['String']['input'];
};

export type RequestChatRoomsInput = {
  /** ID of the agency in the chat room */
  readonly agencyAccountId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the enterprise in the chat room */
  readonly enterpriseAccountId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the request to which the request chat room is linked */
  readonly requestId: Scalars['String']['input'];
};

export type RequestChatRoomsResponse = {
  readonly __typename?: 'RequestChatRoomsResponse';
  /** Request chat rooms linked to a request */
  readonly results: ReadonlyArray<RequestChatRoom>;
  /** number of items */
  readonly totalCount: Scalars['Int']['output'];
};

/** payment frequency condition of a request */
export const RequestConditionPaymentFrequencyCode = {
  BiMonthly: 'BI_MONTHLY',
  Monthly: 'MONTHLY',
  Weekly: 'WEEKLY',
  Yearly: 'YEARLY'
} as const;

export type RequestConditionPaymentFrequencyCode = typeof RequestConditionPaymentFrequencyCode[keyof typeof RequestConditionPaymentFrequencyCode];
/** payment method condition of a request */
export const RequestConditionPaymentMethodCode = {
  InFullOnHiring: 'IN_FULL_ON_HIRING',
  StartOfAgreement: 'START_OF_AGREEMENT'
} as const;

export type RequestConditionPaymentMethodCode = typeof RequestConditionPaymentMethodCode[keyof typeof RequestConditionPaymentMethodCode];
/** decide which party is responsible of a contractual field */
export const RequestConditionResponsibility = {
  Agency: 'AGENCY',
  Enterprise: 'ENTERPRISE',
  NonApplicable: 'NON_APPLICABLE'
} as const;

export type RequestConditionResponsibility = typeof RequestConditionResponsibility[keyof typeof RequestConditionResponsibility];
/** payment method condition of a request when selecting start of agreement */
export const RequestConditionStartOfAgreementPaymentMethodCode = {
  AmountWanted: 'AMOUNT_WANTED',
  Deposit: 'DEPOSIT',
  PaymentFrequency: 'PAYMENT_FREQUENCY'
} as const;

export type RequestConditionStartOfAgreementPaymentMethodCode = typeof RequestConditionStartOfAgreementPaymentMethodCode[keyof typeof RequestConditionStartOfAgreementPaymentMethodCode];
/** decide responsibilities of the outsourcing agency */
export const RequestConditionsOutsourcingResponsibility = {
  ApplicationsAnalysis: 'APPLICATIONS_ANALYSIS',
  ApplicationsQualification: 'APPLICATIONS_QUALIFICATION',
  EmploymentContractManagement: 'EMPLOYMENT_CONTRACT_MANAGEMENT',
  HumanResourcesNeedsAnalysis: 'HUMAN_RESOURCES_NEEDS_ANALYSIS',
  Integration: 'INTEGRATION',
  InterviewSupport: 'INTERVIEW_SUPPORT',
  NegotiationsWithThirdParties: 'NEGOTIATIONS_WITH_THIRD_PARTIES',
  OfferSubmissionAndNegociations: 'OFFER_SUBMISSION_AND_NEGOCIATIONS',
  RequirementsDisplayManagement: 'REQUIREMENTS_DISPLAY_MANAGEMENT'
} as const;

export type RequestConditionsOutsourcingResponsibility = typeof RequestConditionsOutsourcingResponsibility[keyof typeof RequestConditionsOutsourcingResponsibility];
export type RequestConnection = {
  readonly __typename?: 'RequestConnection';
  readonly edges?: Maybe<ReadonlyArray<RequestEdge>>;
  readonly pageInfo?: Maybe<RequestPageInfo>;
};

export type RequestCreateInput = {
  /** Cities and sub regions */
  readonly citiesSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** bonus visibility */
  readonly conditionBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus if candidate stays a certain amount of months */
  readonly conditionBonusMonthValue?: InputMaybe<Scalars['Int']['input']>;
  /** bonus value */
  readonly conditionBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** maximum budget visibility */
  readonly conditionBudgetDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** candidate in hand mandatory visibility */
  readonly conditionCandidateInHandMandatoryDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** candidate in hand mandatory value */
  readonly conditionCandidateInHandMandatoryValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** deposit display */
  readonly conditionDepositDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** deposit value */
  readonly conditionDepositValue?: InputMaybe<Scalars['String']['input']>;
  /** hired date bonus visibility */
  readonly conditionHiredDateBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** date requirement for the hiring bonus */
  readonly conditionHiredDateBonusRequirement?: InputMaybe<Scalars['DateTime']['input']>;
  /** bonus value for hiring date bonus */
  readonly conditionHiredDateBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** recruitment period visibility */
  readonly conditionMaxRecruitmentPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** recruitment period value (in weeks) */
  readonly conditionMaxRecruitmentPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** maximum budget value */
  readonly conditionMaximumBudgetValue?: InputMaybe<Scalars['String']['input']>;
  /** bonus value for minimum guaranteed period */
  readonly conditionMinGuaranteedPeriodBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** min guaranteed period visibility */
  readonly conditionMinGuaranteedPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** min guaranteed period value (in months) */
  readonly conditionMinGuaranteedPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** minimum budget value */
  readonly conditionMinimumBudgetValue?: InputMaybe<Scalars['String']['input']>;
  /** number of payments */
  readonly conditionPaymentAmountValue?: InputMaybe<Scalars['Int']['input']>;
  /** frequency of payments */
  readonly conditionPaymentFrequencyValue?: InputMaybe<RequestConditionPaymentFrequencyCode>;
  /** payment method condition visibility */
  readonly conditionPaymentMethodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment method condition value */
  readonly conditionPaymentMethodValue?: InputMaybe<RequestConditionPaymentMethodCode>;
  /** probation period condition visibility */
  readonly conditionProbationPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** probation period condition (in weeks) */
  readonly conditionProbationPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** payment method condition for start of agreement value */
  readonly conditionStartOfAgreementPaymentMethodValue?: InputMaybe<RequestConditionStartOfAgreementPaymentMethodCode>;
  /** Desired education level codes */
  readonly desiredEducationLevelCodes?: InputMaybe<ReadonlyArray<EducationLevelCode>>;
  /** desired start date */
  readonly desiredStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** desired study field */
  readonly desiredStudyField?: InputMaybe<Scalars['String']['input']>;
  /** general notes */
  readonly generalNotes?: InputMaybe<Scalars['String']['input']>;
  /** acceptable exclusivity period for headhunting visibility */
  readonly headhunterConditionExclusivityPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** acceptable exclusivity period for headhunting value (in weeks) */
  readonly headhunterConditionExclusivityPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** maximum replacement visibility */
  readonly headhunterConditionMaximumReplacementDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement value */
  readonly headhunterConditionMaximumReplacementValue?: InputMaybe<Scalars['Int']['input']>;
  /** exclusivity period for applicant search condition visibility */
  readonly internationalRecruitmentConditionExclusivityPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** exclusivity period for applicant search condition value (in weeks) */
  readonly internationalRecruitmentConditionExclusivityPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** mandatory work permit visibility */
  readonly internationalRecruitmentConditionMandatoryWorkPermitDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** mandatory work permit value */
  readonly internationalRecruitmentConditionMandatoryWorkPermitValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement visibility */
  readonly internationalRecruitmentConditionMaximumReplacementDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement value */
  readonly internationalRecruitmentConditionMaximumReplacementValue?: InputMaybe<Scalars['Int']['input']>;
  /** importance of the request */
  readonly isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  /** job additional notes */
  readonly jobAdditionalNotes?: InputMaybe<Scalars['String']['input']>;
  /** job availability in hours per week */
  readonly jobAvailabilityInHoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** job description */
  readonly jobDescription?: InputMaybe<Scalars['String']['input']>;
  /** job duration code */
  readonly jobDurationCode?: InputMaybe<JobDurationCode>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Float']['input']>;
  /** job maximum experience level */
  readonly jobExperienceLevelMax?: InputMaybe<Scalars['Float']['input']>;
  /** job minimum experience level */
  readonly jobExperienceLevelMin?: InputMaybe<Scalars['Float']['input']>;
  /** job full-time availability */
  readonly jobFullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** job hourly bonus */
  readonly jobHourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** job maximum hourly rate */
  readonly jobHourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** job minimum hourly rate */
  readonly jobHourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** job internal title */
  readonly jobInternalTitle?: InputMaybe<Scalars['String']['input']>;
  /** code of the job mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** number of job open positions */
  readonly jobOpenPositions?: InputMaybe<Scalars['Float']['input']>;
  /** job other remuneration amount */
  readonly jobOtherRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** job part-time availability */
  readonly jobPartTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** job payment frequency */
  readonly jobPaymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** job other payment type */
  readonly jobPaymentOtherTypesCodes?: InputMaybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** number of allowed remote days */
  readonly jobRemoteModeInDays?: InputMaybe<Scalars['Float']['input']>;
  /** job remuneration amount */
  readonly jobRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** job maximum salary */
  readonly jobSalaryMax?: InputMaybe<Scalars['Float']['input']>;
  /** job minimum salary */
  readonly jobSalaryMin?: InputMaybe<Scalars['Float']['input']>;
  /** job speciality code */
  readonly jobSpecialtyCode?: InputMaybe<Scalars['String']['input']>;
  /** job working hours */
  readonly jobWorkingHours?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** is open to international recruitment, goes with the type code */
  readonly openToInternationalRecruitment?: InputMaybe<Scalars['Boolean']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** operation unit id of the request related account */
  readonly operationUnitId?: InputMaybe<Scalars['String']['input']>;
  /** other perks offered */
  readonly otherPerks?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** exclusivity period visibility */
  readonly outsourcingConditionExclusivityPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** exclusivity period value (in weeks) */
  readonly outsourcingConditionExclusivityPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** maximum budget visibility */
  readonly outsourcingConditionMaximumBudgetDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum budget value */
  readonly outsourcingConditionMaximumBudgetValue?: InputMaybe<Scalars['String']['input']>;
  /** maximum duration of mandate visibility */
  readonly outsourcingConditionMaximumDurationDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum duration of mandate value */
  readonly outsourcingConditionMaximumDurationValue?: InputMaybe<Scalars['Int']['input']>;
  /** responsibilities value */
  readonly outsourcingConditionResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** perks offered */
  readonly perks?: InputMaybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** priority of the request */
  readonly priority?: InputMaybe<RequestPriorityType>;
  /** whether the request should be immediately published or left as draft */
  readonly publishNow?: InputMaybe<Scalars['Boolean']['input']>;
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  /** sector code of the request */
  readonly sectorCode?: InputMaybe<Scalars['String']['input']>;
  /** soft skills */
  readonly softSkills?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** additional specialty info (optional) */
  readonly specialty?: InputMaybe<Scalars['String']['input']>;
  /** cnesst expenses responsibility value */
  readonly temporaryPlacementConditionCnesstExpensesResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** cnesst expenses responsibility value */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** open to temporary foreign applicants visibility */
  readonly temporaryPlacementConditionForeignApplicantsDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** open to temporary foreign applicants value */
  readonly temporaryPlacementConditionForeignApplicantsValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** other responsibilities value */
  readonly temporaryPlacementConditionOtherResponsibilitiesValue?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback bonus */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback of the candidate within 6 months visibility */
  readonly temporaryPlacementConditionPossibleBuybackDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** possible buyback of the candidate within 6 months value */
  readonly temporaryPlacementConditionPossibleBuybackValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** responsibilities visibility */
  readonly temporaryPlacementConditionResponsibilitiesDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** identified risks visibility */
  readonly temporaryPlacementConditionRisksDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** identified risks value */
  readonly temporaryPlacementConditionRisksValue?: InputMaybe<Scalars['String']['input']>;
  /** training expenses responsibility value */
  readonly temporaryPlacementConditionTrainingExpensesResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** travel expenses responsibility value */
  readonly temporaryPlacementConditionTravelExpensesResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** types of the request */
  readonly types?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
};

export type RequestEdge = {
  readonly __typename?: 'RequestEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Request>;
};

/** errors that can occur when calling request endpoints. */
export const RequestErrors = {
  /** The Request is associated to at least one offer and cannot be deleted. */
  AssociatedOffer: 'ASSOCIATED_OFFER',
  /** Cannot update a request with a passed deadline. */
  CannotUpdatePassedDeadlineRequest: 'CANNOT_UPDATE_PASSED_DEADLINE_REQUEST',
  /** Cannot publish request because the FREEMIUM subscription limit has been reached */
  FreemiumLimitReached: 'FREEMIUM_LIMIT_REACHED',
  /** Cannot publish request because some required fields are missing. */
  MissingRequiredFields: 'MISSING_REQUIRED_FIELDS'
} as const;

export type RequestErrors = typeof RequestErrors[keyof typeof RequestErrors];
/** General contractual conditions of a request. */
export type RequestGeneralConditions = {
  readonly __typename?: 'RequestGeneralConditions';
  /** bonus visibility */
  readonly bonusDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** bonus if candidate stays a certain amount of months */
  readonly bonusMonthValue?: Maybe<Scalars['Int']['output']>;
  /** bonus value */
  readonly bonusValue?: Maybe<Scalars['String']['output']>;
  /** maximum budget visibility */
  readonly budgetDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** candidate in hand mandatory visibility */
  readonly candidateInHandMandatoryDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** candidate in hand mandatory value */
  readonly candidateInHandMandatoryValue?: Maybe<Scalars['Boolean']['output']>;
  /** deposit visibility */
  readonly depositDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** deposit value */
  readonly depositValue?: Maybe<Scalars['String']['output']>;
  /** hired date bonus visibility */
  readonly hiredDateBonusDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** hired date requirement for bonus */
  readonly hiredDateBonusRequirement?: Maybe<Scalars['DateTime']['output']>;
  /** bonus value for hired date */
  readonly hiredDateBonusValue?: Maybe<Scalars['String']['output']>;
  /** recruitment period visibility */
  readonly maxRecruitmentPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** recruitment period value (in weeks) */
  readonly maxRecruitmentPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** maximum budget value */
  readonly maximumBudgetValue?: Maybe<Scalars['String']['output']>;
  /** bonus value for minimum guaranteed period */
  readonly minGuaranteedPeriodBonusValue?: Maybe<Scalars['String']['output']>;
  /** min guaranteed period visibility */
  readonly minGuaranteedPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** min guaranteed period value (in months) */
  readonly minGuaranteedPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** minimum budget value */
  readonly minimumBudgetValue?: Maybe<Scalars['String']['output']>;
  /** number of payments */
  readonly paymentAmountValue?: Maybe<Scalars['Int']['output']>;
  /** frequency of payments */
  readonly paymentFrequencyValue?: Maybe<RequestConditionPaymentFrequencyCode>;
  /** payment method condition visibility */
  readonly paymentMethodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** payment method condition value */
  readonly paymentMethodValue?: Maybe<RequestConditionPaymentMethodCode>;
  /** probation period condition visibility */
  readonly probationPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** probation period condition (in weeks) */
  readonly probationPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** payment method condition for start of agreement value */
  readonly startOfAgreementPaymentMethodValue?: Maybe<RequestConditionStartOfAgreementPaymentMethodCode>;
};

/** Input for getting a request */
export type RequestGetInput = {
  /** the id of the request to get */
  readonly id: Scalars['String']['input'];
};

/** Headhunter contractual conditions of a request. */
export type RequestHeadhunterConditions = {
  readonly __typename?: 'RequestHeadhunterConditions';
  /** acceptable exclusivity period for headhunting visibility */
  readonly headhuntingExclusivityPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** acceptable exclusivity period for headhunting value (in weeks) */
  readonly headhuntingExclusivityPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** maximum replacement visibility */
  readonly maximumReplacementDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** maximum replacement value */
  readonly maximumReplacementValue?: Maybe<Scalars['Int']['output']>;
};

/** International recruitment contractual conditions of a request. */
export type RequestInternationalRecruitmentConditions = {
  readonly __typename?: 'RequestInternationalRecruitmentConditions';
  /** exclusivity period for applicant search condition visibility */
  readonly exclusivityPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** exclusivity period for applicant search condition value (in weeks) */
  readonly exclusivityPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** mandatory work permit visibility */
  readonly mandatoryWorkPermitDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** mandatory work permit value */
  readonly mandatoryWorkPermitValue?: Maybe<Scalars['Boolean']['output']>;
  /** maximum replacement visibility */
  readonly maximumReplacementDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** maximum replacement value */
  readonly maximumReplacementValue?: Maybe<Scalars['Int']['output']>;
};

/** match type of a request */
export const RequestMatchCode = {
  Interesting: 'INTERESTING',
  Other: 'OTHER',
  Perfect: 'PERFECT'
} as const;

export type RequestMatchCode = typeof RequestMatchCode[keyof typeof RequestMatchCode];
/** For fetching the offers of a request */
export type RequestOffersInput = {
  /** the unique id of the request */
  readonly requestId: Scalars['String']['input'];
};

/** Response when fetching the offers of a request */
export type RequestOffersResponse = {
  readonly __typename?: 'RequestOffersResponse';
  readonly offers: ReadonlyArray<Offer>;
};

/** Outsourcing contractual conditions of a request. */
export type RequestOutsourcingConditions = {
  readonly __typename?: 'RequestOutsourcingConditions';
  /** exclusivity period visibility */
  readonly exclusivityPeriodDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** exclusivity period value (in weeks) */
  readonly exclusivityPeriodValue?: Maybe<Scalars['Int']['output']>;
  /** maximum budget visibility */
  readonly maximumBudgetDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** maximum budget value in dollars per hour */
  readonly maximumBudgetValue?: Maybe<Scalars['String']['output']>;
  /** maximum duration of mandate visibility */
  readonly maximumDurationDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** maximum duration of mandate value */
  readonly maximumDurationValue?: Maybe<Scalars['Int']['output']>;
  /** responsibilities */
  readonly responsibilities?: Maybe<Scalars['String']['output']>;
};

export type RequestPageInfo = {
  readonly __typename?: 'RequestPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** urgency of a request */
export const RequestPriorityType = {
  High: 'HIGH',
  Low: 'LOW',
  Medium: 'MEDIUM',
  Urgent: 'URGENT'
} as const;

export type RequestPriorityType = typeof RequestPriorityType[keyof typeof RequestPriorityType];
/** Request info */
export type RequestResponse = {
  readonly __typename?: 'RequestResponse';
  readonly request?: Maybe<Request>;
};

/** status of a request */
export type RequestStatus = {
  readonly __typename?: 'RequestStatus';
  /** unique code */
  readonly code: RequestStatusTypeCode;
  /** i18n descriptions of the model */
  readonly descriptions: Descriptions;
};

/** unique code of the status of a request */
export const RequestStatusTypeCode = {
  Agreement: 'AGREEMENT',
  Archived: 'ARCHIVED',
  Deleted: 'DELETED',
  Draft: 'DRAFT',
  InProgress: 'IN_PROGRESS',
  Paused: 'PAUSED'
} as const;

export type RequestStatusTypeCode = typeof RequestStatusTypeCode[keyof typeof RequestStatusTypeCode];
/** Response to the request status list */
export type RequestStatusTypesResponse = {
  readonly __typename?: 'RequestStatusTypesResponse';
  readonly values: ReadonlyArray<RequestStatus>;
};

export type RequestStatusUpdate = {
  /** the request id */
  readonly id: Scalars['String']['input'];
  /** status of the request */
  readonly status: RequestStatusTypeCode;
};

/** Temporary placement contractual conditions of a request. */
export type RequestTemporaryPlacementConditions = {
  readonly __typename?: 'RequestTemporaryPlacementConditions';
  /** cnesst expenses responsibility value */
  readonly cnesstExpensesResponsibilityValue?: Maybe<RequestConditionResponsibility>;
  /** employee help program responsibility value */
  readonly employeeHelpProgramResponsibilityValue?: Maybe<RequestConditionResponsibility>;
  /** open to temporary foreign applicants visibility */
  readonly foreignApplicantsDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** open to temporary foreign applicants value */
  readonly foreignApplicantsValue?: Maybe<Scalars['Boolean']['output']>;
  /** other responsibilities value */
  readonly otherResponsibilitiesValue?: Maybe<Scalars['String']['output']>;
  /** possible buyback bonus */
  readonly possibleBuybackBonus?: Maybe<Scalars['String']['output']>;
  /** possible buyback of the candidate within 6 months visibility */
  readonly possibleBuybackDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** possible buyback of the candidate within 6 months value */
  readonly possibleBuybackValue?: Maybe<Scalars['Boolean']['output']>;
  /** responsibilities visibility */
  readonly responsibilitiesDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** identified risks visibility */
  readonly risksDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** identified risks value */
  readonly risksValue?: Maybe<Scalars['String']['output']>;
  /** training expenses responsibility value */
  readonly trainingExpensesResponsibilityValue?: Maybe<RequestConditionResponsibility>;
  /** travel expenses responsibility value */
  readonly travelExpensesResponsibilityValue?: Maybe<RequestConditionResponsibility>;
};

/** type of a request */
export const RequestTypeCode = {
  Headhunter: 'HEADHUNTER',
  InternationalRecruitment: 'INTERNATIONAL_RECRUITMENT',
  Outsourcing: 'OUTSOURCING',
  TemporaryHelp: 'TEMPORARY_HELP'
} as const;

export type RequestTypeCode = typeof RequestTypeCode[keyof typeof RequestTypeCode];
export type RequestUpdateInput = {
  /** Cities and sub regions */
  readonly citiesSubRegions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** bonus visibility */
  readonly conditionBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** bonus if candidate stays a certain amount of months */
  readonly conditionBonusMonthValue?: InputMaybe<Scalars['Int']['input']>;
  /** bonus value */
  readonly conditionBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** maximum budget visibility */
  readonly conditionBudgetDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** candidate in hand mandatory visibility */
  readonly conditionCandidateInHandMandatoryDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** candidate in hand mandatory value */
  readonly conditionCandidateInHandMandatoryValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** deposit display */
  readonly conditionDepositDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** deposit value */
  readonly conditionDepositValue?: InputMaybe<Scalars['String']['input']>;
  /** hired date bonus visibility */
  readonly conditionHiredDateBonusDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** date requirement for the hiring bonus */
  readonly conditionHiredDateBonusRequirement?: InputMaybe<Scalars['DateTime']['input']>;
  /** bonus value for hiring date bonus */
  readonly conditionHiredDateBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** recruitment period visibility */
  readonly conditionMaxRecruitmentPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** recruitment period value (in weeks) */
  readonly conditionMaxRecruitmentPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** maximum budget value */
  readonly conditionMaximumBudgetValue?: InputMaybe<Scalars['String']['input']>;
  /** bonus value for minimum guaranteed period */
  readonly conditionMinGuaranteedPeriodBonusValue?: InputMaybe<Scalars['String']['input']>;
  /** min guaranteed period visibility */
  readonly conditionMinGuaranteedPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** min guaranteed period value (in months) */
  readonly conditionMinGuaranteedPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** minimum budget value */
  readonly conditionMinimumBudgetValue?: InputMaybe<Scalars['String']['input']>;
  /** number of payments */
  readonly conditionPaymentAmountValue?: InputMaybe<Scalars['Int']['input']>;
  /** frequency of payments */
  readonly conditionPaymentFrequencyValue?: InputMaybe<RequestConditionPaymentFrequencyCode>;
  /** payment method condition visibility */
  readonly conditionPaymentMethodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment method condition value */
  readonly conditionPaymentMethodValue?: InputMaybe<RequestConditionPaymentMethodCode>;
  /** probation period condition visibility */
  readonly conditionProbationPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** probation period condition (in weeks) */
  readonly conditionProbationPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** payment method condition for start of agreement value */
  readonly conditionStartOfAgreementPaymentMethodValue?: InputMaybe<RequestConditionStartOfAgreementPaymentMethodCode>;
  /** Desired education level codes */
  readonly desiredEducationLevelCodes?: InputMaybe<ReadonlyArray<EducationLevelCode>>;
  /** desired start date */
  readonly desiredStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** desired study field */
  readonly desiredStudyField?: InputMaybe<Scalars['String']['input']>;
  /** general notes */
  readonly generalNotes?: InputMaybe<Scalars['String']['input']>;
  /** acceptable exclusivity period for headhunting visibility */
  readonly headhunterConditionExclusivityPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** acceptable exclusivity period for headhunting value (in weeks) */
  readonly headhunterConditionExclusivityPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** maximum replacement visibility */
  readonly headhunterConditionMaximumReplacementDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement value */
  readonly headhunterConditionMaximumReplacementValue?: InputMaybe<Scalars['Int']['input']>;
  /** the request id */
  readonly id: Scalars['String']['input'];
  /** exclusivity period for applicant search condition visibility */
  readonly internationalRecruitmentConditionExclusivityPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** exclusivity period for applicant search condition value (in weeks) */
  readonly internationalRecruitmentConditionExclusivityPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** mandatory work permit visibility */
  readonly internationalRecruitmentConditionMandatoryWorkPermitDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** mandatory work permit value */
  readonly internationalRecruitmentConditionMandatoryWorkPermitValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement visibility */
  readonly internationalRecruitmentConditionMaximumReplacementDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum replacement value */
  readonly internationalRecruitmentConditionMaximumReplacementValue?: InputMaybe<Scalars['Int']['input']>;
  /** importance of the request */
  readonly isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  /** job additional notes */
  readonly jobAdditionalNotes?: InputMaybe<Scalars['String']['input']>;
  /** job availability in hours per week */
  readonly jobAvailabilityInHoursPerWeek?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** job description */
  readonly jobDescription?: InputMaybe<Scalars['String']['input']>;
  /** job duration code */
  readonly jobDurationCode?: InputMaybe<JobDurationCode>;
  /** duration of contract in months */
  readonly jobDurationInMonths?: InputMaybe<Scalars['Float']['input']>;
  /** job maximum experience level */
  readonly jobExperienceLevelMax?: InputMaybe<Scalars['Float']['input']>;
  /** job minimum experience level */
  readonly jobExperienceLevelMin?: InputMaybe<Scalars['Float']['input']>;
  /** job full-time availability */
  readonly jobFullTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** job hourly bonus */
  readonly jobHourlyBonus?: InputMaybe<Scalars['String']['input']>;
  /** job maximum hourly rate */
  readonly jobHourlyRateMax?: InputMaybe<Scalars['String']['input']>;
  /** job minimum hourly rate */
  readonly jobHourlyRateMin?: InputMaybe<Scalars['String']['input']>;
  /** job internal title */
  readonly jobInternalTitle?: InputMaybe<Scalars['String']['input']>;
  /** code of the job mode */
  readonly jobModeCode?: InputMaybe<JobModeCode>;
  /** number of job open positions */
  readonly jobOpenPositions?: InputMaybe<Scalars['Float']['input']>;
  /** job other remuneration amount */
  readonly jobOtherRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** job part-time availability */
  readonly jobPartTimeAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** job payment frequency */
  readonly jobPaymentFrequencyCode?: InputMaybe<JobPaymentFrequencyCode>;
  /** job other payment type */
  readonly jobPaymentOtherTypesCodes?: InputMaybe<ReadonlyArray<JobPaymentOtherTypesCode>>;
  /** number of allowed remote days */
  readonly jobRemoteModeInDays?: InputMaybe<Scalars['Float']['input']>;
  /** job remuneration amount */
  readonly jobRemunerationAmount?: InputMaybe<Scalars['String']['input']>;
  /** job maximum salary */
  readonly jobSalaryMax?: InputMaybe<Scalars['Float']['input']>;
  /** job minimum salary */
  readonly jobSalaryMin?: InputMaybe<Scalars['Float']['input']>;
  /** job speciality code */
  readonly jobSpecialtyCode?: InputMaybe<Scalars['String']['input']>;
  /** job working hours */
  readonly jobWorkingHours?: InputMaybe<ReadonlyArray<JobScheduleTypeCode>>;
  /** is open to international recruitment, goes with the type code */
  readonly openToInternationalRecruitment?: InputMaybe<Scalars['Boolean']['input']>;
  /** operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** operation unit id of the request related account */
  readonly operationUnitId?: InputMaybe<Scalars['String']['input']>;
  /** other perks offered */
  readonly otherPerks?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** exclusivity period visibility */
  readonly outsourcingConditionExclusivityPeriodDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** exclusivity period value (in weeks) */
  readonly outsourcingConditionExclusivityPeriodValue?: InputMaybe<Scalars['Int']['input']>;
  /** maximum budget visibility */
  readonly outsourcingConditionMaximumBudgetDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum budget value */
  readonly outsourcingConditionMaximumBudgetValue?: InputMaybe<Scalars['String']['input']>;
  /** maximum duration of mandate visibility */
  readonly outsourcingConditionMaximumDurationDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** maximum duration of mandate value */
  readonly outsourcingConditionMaximumDurationValue?: InputMaybe<Scalars['Int']['input']>;
  /** responsibilities value */
  readonly outsourcingConditionResponsibilities?: InputMaybe<Scalars['String']['input']>;
  /** perks offered */
  readonly perks?: InputMaybe<ReadonlyArray<AccountAdvantageTypeCode>>;
  /** priority of the request */
  readonly priority?: InputMaybe<RequestPriorityType>;
  /** deadline for receiving an offer */
  readonly receivingOfferDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  /** sector code of the request */
  readonly sectorCode?: InputMaybe<Scalars['String']['input']>;
  /** soft skills */
  readonly softSkills?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** additional specialty info (optional) */
  readonly specialty?: InputMaybe<Scalars['String']['input']>;
  /** cnesst expenses responsibility value */
  readonly temporaryPlacementConditionCnesstExpensesResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** cnesst expenses responsibility value */
  readonly temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** open to temporary foreign applicants visibility */
  readonly temporaryPlacementConditionForeignApplicantsDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** open to temporary foreign applicants value */
  readonly temporaryPlacementConditionForeignApplicantsValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** other responsibilities value */
  readonly temporaryPlacementConditionOtherResponsibilitiesValue?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback bonus */
  readonly temporaryPlacementConditionPossibleBuybackBonus?: InputMaybe<Scalars['String']['input']>;
  /** possible buyback of the candidate within 6 months visibility */
  readonly temporaryPlacementConditionPossibleBuybackDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** possible buyback of the candidate within 6 months value */
  readonly temporaryPlacementConditionPossibleBuybackValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** responsibilities visibility */
  readonly temporaryPlacementConditionResponsibilitiesDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** identified risks visibility */
  readonly temporaryPlacementConditionRisksDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** identified risks value */
  readonly temporaryPlacementConditionRisksValue?: InputMaybe<Scalars['String']['input']>;
  /** training expenses responsibility value */
  readonly temporaryPlacementConditionTrainingExpensesResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** travel expenses responsibility value */
  readonly temporaryPlacementConditionTravelExpensesResponsibilityValue?: InputMaybe<RequestConditionResponsibility>;
  /** types of the request */
  readonly types?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
};

export type RequestsFilters = {
  /** filter on creation date of the request */
  readonly dates?: InputMaybe<ReadonlyArray<Scalars['DateTime']['input']>>;
  /** filter on request ids */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on job specialty code */
  readonly jobSpecialtyCodes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on operation territory codes */
  readonly operationTerritoryCodes?: InputMaybe<ReadonlyArray<OperationTerritoryCode>>;
  /** filter on  operation unit id of the request */
  readonly operationUnitIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** filter on request status */
  readonly status?: InputMaybe<ReadonlyArray<RequestStatusTypeCode>>;
  /** filter on parts of the request */
  readonly text?: InputMaybe<Scalars['String']['input']>;
  /** filter on types of the request */
  readonly types?: InputMaybe<ReadonlyArray<RequestTypeCode>>;
};

/** Response containing requests */
export type RequestsResponse = {
  readonly __typename?: 'RequestsResponse';
  readonly page: RequestConnection;
  readonly pageData?: Maybe<PageData>;
};

/** the fields by which requests can be sorted */
export const RequestsSortBy = {
  Date: 'DATE',
  Deadline: 'DEADLINE',
  JobSpecialtyEn: 'JOB_SPECIALTY_EN',
  JobSpecialtyFr: 'JOB_SPECIALTY_FR',
  OperationUnit: 'OPERATION_UNIT',
  Status: 'STATUS'
} as const;

export type RequestsSortBy = typeof RequestsSortBy[keyof typeof RequestsSortBy];
/** For requesting requests to be sorted */
export type RequestsSortDirective = {
  readonly by: RequestsSortBy;
  readonly direction: SortDirection;
};

/** For resending an invitation */
export type ResendInvitationInput = {
  /** the unique id of the account user to resend the invitation to */
  readonly accountUserId: Scalars['String']['input'];
};

/** Response when resending an invitation */
export type ResendInvitationResponse = {
  readonly __typename?: 'ResendInvitationResponse';
  readonly ok: Scalars['Boolean']['output'];
};

export const Season = {
  Fall: 'FALL',
  Spring: 'SPRING',
  Summer: 'SUMMER',
  Winter: 'WINTER'
} as const;

export type Season = typeof Season[keyof typeof Season];
/** type of service */
export const ServiceType = {
  Consultation: 'CONSULTATION',
  Headhunter: 'HEADHUNTER',
  InternationalRecruitment: 'INTERNATIONAL_RECRUITMENT',
  Outsourcing: 'OUTSOURCING',
  TemporaryHelp: 'TEMPORARY_HELP'
} as const;

export type ServiceType = typeof ServiceType[keyof typeof ServiceType];
export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
/** For updating the role of an account user */
export type UpdateAccountUserRoleInput = {
  /** the unique id of account user to update */
  readonly accountUserId: Scalars['String']['input'];
  /** the new role */
  readonly newRole: UserRole;
};

/** Response when updating the role of an account user */
export type UpdateAccountUserRoleResponse = {
  readonly __typename?: 'UpdateAccountUserRoleResponse';
  readonly accountUser: AccountUser;
};

/** For updating the status of an account user */
export type UpdateAccountUserStatusInput = {
  /** the unique id of account user to update */
  readonly accountUserId: Scalars['String']['input'];
  /** the new status */
  readonly newStatus: AccountUserStatus;
};

/** Response when updating the status of an account user */
export type UpdateAccountUserStatusResponse = {
  readonly __typename?: 'UpdateAccountUserStatusResponse';
  readonly accountUser: AccountUser;
};

/** used to update profile of a user */
export type UpdateUserProfileInput = {
  /** biography */
  readonly bio?: InputMaybe<Scalars['String']['input']>;
  /** Link to facebook account */
  readonly facebookUrl?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  /** id of user to update */
  readonly id: Scalars['String']['input'];
  /** Link to Instagram account */
  readonly instagramUrl?: InputMaybe<Scalars['String']['input']>;
  /** Short description of current job */
  readonly jobDescription?: InputMaybe<Scalars['String']['input']>;
  /** Last name */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** Link to LinkedIn account */
  readonly linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Current location */
  readonly location?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show the phone number or not */
  readonly phoneDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  /** Phone number */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** professional email of the user */
  readonly professionalEmail?: InputMaybe<Scalars['String']['input']>;
  /** List of spoken languages (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes) */
  readonly spokenLanguages?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** Link to Twitter/X account */
  readonly twitterUrl?: InputMaybe<Scalars['String']['input']>;
  /** The "why" this person should be contacted */
  readonly why?: InputMaybe<Scalars['String']['input']>;
};

/**
 * A user of Kehops, who can theoretically be attached to multiple accounts,
 *     with a specific role for each account.
 */
export type User = {
  readonly __typename?: 'User';
  /** accounts linked to a given user */
  readonly accounts?: Maybe<ReadonlyArray<AccountUser>>;
  /** absolute URL of the user's avatar */
  readonly avatarUrl?: Maybe<Scalars['String']['output']>;
  /** biography */
  readonly bio?: Maybe<Scalars['String']['output']>;
  /** user default account Id */
  readonly defaultAccountId?: Maybe<Scalars['String']['output']>;
  /** email of the user */
  readonly email: Scalars['String']['output'];
  /** email verification status */
  readonly emailVerified: Scalars['Boolean']['output'];
  /** The id that links this user to the external notification service */
  readonly externalNotificationId: Scalars['String']['output'];
  /** Link to facebook account */
  readonly facebookUrl?: Maybe<Scalars['String']['output']>;
  /** First name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** impersonated by user id */
  readonly impersonatedByUserId?: Maybe<Scalars['String']['output']>;
  /** impersonating user id */
  readonly impersonatingUserId?: Maybe<Scalars['String']['output']>;
  /** Link to Instagram account */
  readonly instagramUrl?: Maybe<Scalars['String']['output']>;
  /** Short description of current job */
  readonly jobDescription?: Maybe<Scalars['String']['output']>;
  /** preferred language */
  readonly language?: Maybe<Scalars['String']['output']>;
  /** Last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** Link to LinkedIn account */
  readonly linkedinUrl?: Maybe<Scalars['String']['output']>;
  /** Current location */
  readonly location?: Maybe<Scalars['String']['output']>;
  /** name of the user */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** notification preferences of a given user */
  readonly notificationPreferences: UserNotificationPreferences;
  /** Whether to show the phone number or not */
  readonly phoneDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** Phone number */
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  /** professional email of the user */
  readonly professionalEmail?: Maybe<Scalars['String']['output']>;
  /** List of spoken languages (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes) */
  readonly spokenLanguages: ReadonlyArray<Scalars['String']['output']>;
  /** terms and conditions acceptance status */
  readonly termsAndConditionsAccepted: Scalars['Boolean']['output'];
  /** Link to Twitter/X account */
  readonly twitterUrl?: Maybe<Scalars['String']['output']>;
  /** The "why" this person should be contacted */
  readonly why?: Maybe<Scalars['String']['output']>;
};

/** for deleting the avatar of a user */
export type UserDeleteAvatarInput = {
  /** the id of the user to delete */
  readonly userId: Scalars['String']['input'];
};

/** response to the avatar delete request */
export type UserDeleteAvatarResponse = {
  readonly __typename?: 'UserDeleteAvatarResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** errors that can occur when calling user endpoints. */
export const UserErrors = {
  /** The email you're trying to change to is already in use. */
  EmailAlreadyExists: 'EMAIL_ALREADY_EXISTS'
} as const;

export type UserErrors = typeof UserErrors[keyof typeof UserErrors];
/** The notification preferences of a user. */
export type UserNotificationPreferences = {
  readonly __typename?: 'UserNotificationPreferences';
  /** whether user wants to receive notification by email or not */
  readonly email: Scalars['Boolean']['output'];
  /** whether user wants to receive notification in app or not */
  readonly inApp: Scalars['Boolean']['output'];
};

/** the role a user can have in an account (except for the super admin, restricted to Kehops staff) */
export const UserRole = {
  Admin: 'ADMIN',
  Member: 'MEMBER',
  Owner: 'OWNER',
  SuperAdmin: 'SUPER_ADMIN'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];
/** for sending an email verification link */
export type UserSendEmailVerificationLinkInput = {
  /** the unique id of the user */
  readonly userId: Scalars['String']['input'];
};

/** response to the send email verification link request */
export type UserSendEmailVerificationLinkResponse = {
  readonly __typename?: 'UserSendEmailVerificationLinkResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** for sending a password change request */
export type UserSendPasswordChangeRequestInput = {
  /** the email of the user */
  readonly email: Scalars['String']['input'];
};

/** response to the send password change request request */
export type UserSendPasswordChangeRequestResponse = {
  readonly __typename?: 'UserSendPasswordChangeRequestResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** for updating the avatar of a user */
export type UserUpdateAvatarInput = {
  /** the filename to use */
  readonly filename: Scalars['String']['input'];
  /** the id of the user to update */
  readonly userId: Scalars['String']['input'];
};

/** response to the avatar update request */
export type UserUpdateAvatarResponse = {
  readonly __typename?: 'UserUpdateAvatarResponse';
  readonly ok: Scalars['Boolean']['output'];
};

/** for updating the email of a user */
export type UserUpdateEmailInput = {
  /** the new email */
  readonly email: Scalars['String']['input'];
  /** the id of the user to update */
  readonly userId: Scalars['String']['input'];
};

/** response to the email update request */
export type UserUpdateEmailResponse = {
  readonly __typename?: 'UserUpdateEmailResponse';
  readonly user: User;
};

/** For updating the user's notification preferences. If none is true, it will take precedence over other properties. */
export type UserUpdateNotificationPreferencesInput = {
  readonly email: Scalars['Boolean']['input'];
  readonly inApp: Scalars['Boolean']['input'];
  /** the unique id of the user */
  readonly userId: Scalars['String']['input'];
};

/** response to the update notification preferences request */
export type UserUpdateNotificationPreferencesResponse = {
  readonly __typename?: 'UserUpdateNotificationPreferencesResponse';
  readonly preferences: UserNotificationPreferences;
};

/** for verifying the user's email */
export type UserVerifyEmailInput = {
  /** the unique id of the user */
  readonly userId: Scalars['String']['input'];
};

/** response to the verify email request */
export type UserVerifyEmailResponse = {
  readonly __typename?: 'UserVerifyEmailResponse';
  readonly user: User;
};
