import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGrid, SectionGridRow } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import SummaryBadges from '@application/components/summary/SummaryBadges';
import SummaryDescription from '@application/components/summary/SummaryDescription';
import {
  JobPaymentFrequencyCode,
  RecruitmentOpportunity,
} from '@domain/graphql.types';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';

type RemunerationProps = {
  data: RecruitmentOpportunity | undefined;
  isInOpportunityOffer: boolean;
};

const Remuneration = ({ data, isInOpportunityOffer }: RemunerationProps) => {
  const { t, i18n } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });
  const { t: tGlobal } = useTranslation();

  const remuneration = useMemo(() => {
    if (data?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.Yearly) {
      return t('labels.perYear', {
        min: data.jobSalaryMin,
        max: data.jobSalaryMax,
      });
    }

    if (data?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.Hourly) {
      return t('labels.perHour', {
        min: data.jobHourlyRateMin,
        max: data.jobHourlyRateMax,
      });
    }

    if (data?.jobPaymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount) {
      return t('labels.fixedAmount', {
        amount: data.jobRemunerationAmount,
      });
    }

    return '-';
  }, [data, t]);

  const jobTypeOne = useMemo(() => {
    const duration = getLocalizedDescription(
      data?.jobDuration?.descriptions,
      i18n.language
    );

    const period = data?.jobDurationInMonths
      ? t('labels.forPeriod', { months: data.jobDurationInMonths })
      : '';

    return period ? `${duration} ${period}` : duration;
  }, [data, i18n.language, t]);

  const jobTypeTwo = useMemo(() => {
    const options = [];

    if (data?.jobFullTimeAvailability) {
      options.push(t('labels.fullTime').toLowerCase());
    }

    if (data?.jobPartTimeAvailability) {
      options.push(t('labels.partTime').toLowerCase());
    }

    const result = options.length > 0 ? options.join(', ') : '-';
    return result.charAt(0).toUpperCase() + result.slice(1);
  }, [data, t]);

  const workingHours = useMemo(
    () =>
      data?.jobWorkingHours?.map((j) =>
        tGlobal(`enum.jobWorkingHours.${j.toLowerCase()}`)
      ) || [],
    [data, tGlobal]
  );

  const perks = useMemo(
    () =>
      data?.perks
        ?.map((perk) =>
          t(`enum.accountAdvantageTypeCode.${perk.toLowerCase()}`)
        )
        .concat(data?.otherPerks || []),
    [data?.otherPerks, data?.perks, t]
  );

  const jobPaymentOtherTypesCodes = useMemo(
    () =>
      data?.jobPaymentOtherTypesCodes?.map((jobPaymentOtherTypesCode) =>
        t(
          `labels.otherCompensations.${jobPaymentOtherTypesCode.toLowerCase()}`,
          { amout: data.jobOtherRemunerationAmount }
        )
      ),
    [data?.jobPaymentOtherTypesCodes, data?.jobOtherRemunerationAmount, t]
  );

  const remunerationFields = useMemo(
    () => [
      {
        text: t('labels.set1'),
        description: jobTypeOne || '-',
      },
      {
        text: t('labels.hoursPerWeek'),
        badges:
          data?.jobAvailabilityInHoursPerWeek
            ?.map((hoursPerWeek) => `${hoursPerWeek}h`)
            .concat(jobTypeTwo) || [],
      },
      {
        text: t('labels.schedule'),
        badges: workingHours,
      },
      {
        text: t('labels.remunerationType'),
        description: remuneration,
      },
      {
        text: t('labels.otherCompensation'),
        badges: jobPaymentOtherTypesCodes || [],
      },
      {
        text: t('labels.perks'),
        badges: perks || [],
      },
    ],
    [
      data?.jobAvailabilityInHoursPerWeek,
      jobPaymentOtherTypesCodes,
      jobTypeOne,
      jobTypeTwo,
      perks,
      remuneration,
      t,
      workingHours,
    ]
  );

  return (
    <>
      <SummaryHeader
        className={cn('mt-s-16', {
          '!border-0 !rounded-none': isInOpportunityOffer,
        })}
        headerTitle={t('subtitles.offeredConditionsInformation')}
      />

      <SectionGrid
        readOnly
        hideTitle
        gridColsClassName={cn('grid-cols-[1fr]', {
          'border-0 ': isInOpportunityOffer,
        })}
      >
        {remunerationFields.map((remunerationField, index) => (
          <SectionGridRow key={remunerationField.text} isTopRow={index === 0}>
            {remunerationField.text} : &nbsp;
            {remunerationField.badges ? (
              <SummaryBadges badges={remunerationField.badges} />
            ) : (
              <SummaryDescription description={remunerationField.description} />
            )}
          </SectionGridRow>
        ))}
      </SectionGrid>
    </>
  );
};

export default Remuneration;
