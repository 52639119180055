import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { PageLoader } from '@application/components';
import { AccountContext } from '@application/context';
import { getLocalizedDescription } from '@utils/i18n-utils';

import { useGetRecruitmentOpportunity } from '../recruitment/opportunity/hooks';
import RecruitmentOpportunityChatContent from './RecruitmentOpportunityChatContent';

const RecruitmentOpportunityChatPage = () => {
  const { account } = useContext(AccountContext);
  const { id = '' } = useParams();
  const { i18n } = useTranslation();

  const {
    viewModel: {
      data: recruitmentOpportunityData,
      isLoading: isRecruitmentOpportunityLoading,
    },
  } = useGetRecruitmentOpportunity(id);

  return !isRecruitmentOpportunityLoading &&
    recruitmentOpportunityData &&
    account ? (
    <RecruitmentOpportunityChatContent
      opportunityId={id}
      accountId={account.id}
      title={
        getLocalizedDescription(
          recruitmentOpportunityData.jobSpecialty?.descriptions,
          i18n.language
        ) ?? ''
      }
      subtitle={recruitmentOpportunityData.operationUnit?.name ?? ''}
    />
  ) : (
    <PageLoader />
  );
};

export default RecruitmentOpportunityChatPage;
