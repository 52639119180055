import { gql } from '@urql/core';

const MutationCandidateRequestUpdateStatus = gql`
  mutation CandidateRequestUpdateStatus(
    $input: CandidateRequestUpdateStatusInput!
  ) {
    candidateRequestUpdateStatus(input: $input) {
      candidateRequest {
        id
        status
      }
    }
  }
`;

export default MutationCandidateRequestUpdateStatus;
