import { useCallback } from 'react';

import {
  useCreateOfferCandidate,
  useUpdateCandidateAndCV,
  useUpdateCandidateAndOtherFiles,
  useUpdateCandidateCVAndOtherFiles,
  useUpdateCandidateOffer,
} from '@application/views/recruitment/offer/hooks';

import { normalizeCandidateData } from '../../../normalizers';
import { OFFER_CANDIDATE_SCHEMA } from '../../../schema';
import { OfferCandidateFormFields } from '../../../types';

const useSaveCandidate = () => {
  const {
    updateCandidateAndCv,
    viewModel: { isLoading: isCVLoading },
  } = useUpdateCandidateAndCV();
  const {
    updateCandidateAndOtherFiles,
    viewModel: { isLoading: isOtherFilesLoading },
  } = useUpdateCandidateAndOtherFiles();
  const {
    updateCandidateCvAndOtherFiles,
    viewModel: { isLoading: isCvAndOtherFilesLoading },
  } = useUpdateCandidateCVAndOtherFiles();
  const { updateOfferCandidate } = useUpdateCandidateOffer();
  const { createOfferCandidate } = useCreateOfferCandidate();

  const isLoading =
    isCvAndOtherFilesLoading || isOtherFilesLoading || isCVLoading;

  const saveCandidate = useCallback(
    async (candidate: OfferCandidateFormFields, offerId: string) => {
      const { resume, otherFiles, ...otherValues }: any = candidate;
      const modifiedValues = {
        ...otherValues,
        ...OFFER_CANDIDATE_SCHEMA.cast(otherValues),
      };

      if (!modifiedValues.id) {
        await createOfferCandidate({
          input: {
            ...normalizeCandidateData(OFFER_CANDIDATE_SCHEMA.cast(candidate)),
            offerId,
          },
        } as any);
      } else {
        const updateResume = !!resume && typeof resume !== 'string';
        const updateOtherFiles =
          !!otherFiles &&
          otherFiles.some((otherFile: any) => typeof otherFile !== 'string');

        if (!updateResume && !updateOtherFiles) {
          await updateOfferCandidate({
            input: normalizeCandidateData(modifiedValues),
          } as any);
        } else if (updateResume && updateOtherFiles) {
          await updateCandidateCvAndOtherFiles({
            input: normalizeCandidateData(modifiedValues),
            cvFiles: resume,
            cvInput: {
              filename: resume?.name,
              offerCandidateId: modifiedValues.id,
            },
            otherFiles: otherFiles.filter(
              (otherFile: any) => typeof otherFile !== 'string'
            ),
            otherFilesInput: { offerCandidateId: modifiedValues.id },
          });
        } else if (updateResume) {
          await updateCandidateAndCv({
            input: normalizeCandidateData(modifiedValues),
            files: resume,
            cvInput: {
              filename: resume?.name,
              offerCandidateId: modifiedValues.id,
            },
          });
        } else {
          await updateCandidateAndOtherFiles({
            input: normalizeCandidateData(modifiedValues),
            files: otherFiles.filter(
              (otherFile: any) => typeof otherFile !== 'string'
            ),
            otherFilesInput: {
              offerCandidateId: modifiedValues.id,
            },
          });
        }
      }
    },
    [
      createOfferCandidate,
      updateOfferCandidate,
      updateCandidateCvAndOtherFiles,
      updateCandidateAndCv,
      updateCandidateAndOtherFiles,
    ]
  );

  return { saveCandidate, isLoading };
};

export default useSaveCandidate;
