import { useMutation } from 'urql';

import {
  Mutation,
  MutationGetSubscriptionPromoArgs,
} from '@domain/graphql.types';
import GetSubscriptionPromo from '@infrastructure/graphql/billing/get.subscriptionPromo';

const useGetSubscriptionPromo = () => {
  const [result, getSubscriptionPromo] = useMutation<
    Pick<Mutation, 'getSubscriptionPromo'>,
    MutationGetSubscriptionPromoArgs
  >(GetSubscriptionPromo);

  const { data, fetching, error } = result;

  return {
    data: data?.getSubscriptionPromo,
    fetching,
    error,
    getSubscriptionPromo,
  };
};

export default useGetSubscriptionPromo;
