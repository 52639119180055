/**
 * NOTE: the `[&&]:` notation allows to be more specific in CSS
 * without the use of `!important` when it can be avoided.
 * (i.e. [&&]:border => .border.border { ... })
 */
export const CONTROL_STYLES = {
  base: '[&&]:border [&&]:rounded-md [&&]:bg-base-100 [&&]:hover:border-stroke-default [&&]:shadow-none h-s-48 text-base font-normal hover:cursor-pointer',
  focus: '!border-primary',
  nonFocus: 'border-stroke-default',
  disabled: 'text-disabled-content bg-disabled-default',
  invalid: '[&&]:text-error-content [&&]:bg-error [&&]:border-error-content',
  multiple: 'h-auto [&&]:min-h-[3rem]',
};
export const VALUE_CONTAINER_STYLES = 'flex gap-s-8 p-s-8';
export const SELECT_INPUT_STYLES = 'px-s-8';
export const PLACEHOLDER_STYLES = 'px-s-8 opacity-80';
export const SINGLE_VALUE_STYLES = 'px-s-8';
export const MENU_STYLES =
  'mt-s-4 bg-base-100 rounded-md border border-stroke-default shadow-xs';
export const OPTION_STYLES = {
  base: 'px-s-16 py-s-10 hover:cursor-pointer text-neutral-secondary first:rounded-t-md last:rounded-b-md',
  focus: 'bg-neutral active:bg-neutral rounded-[inherit]',
  selected: 'bg-secondary text-primary rounded-[inherit]',
  multiple: 'px-s-8 py-s-2 first:pt-s-8 last:pb-s-8 bg-transparent w-[auto]',
};
export const MULTI_VALUE_STYLES =
  'flex items-center h-[1.875rem] [&&]:bg-secondary [&&]:rounded-sm [&&]:m-s-0';
export const MULTI_VALUE_LABEL_STYLES =
  'flex items-center [&&]:pl-s-8 [&&]:pr-s-4 h-[inherit] border border-secondary [&&]:rounded-l-sm [&&]:text-14';
export const MULTI_VALUE_REMOVE_STYLES =
  'px-s-4 [&&]:rounded-r-sm border border-secondary hover:bg-base-200 hover:border-base-200';
export const INDICATORS_CONTAINER_STYLES =
  '[&>*+*]:border-l [&>*+*]:border-stroke-default';
export const CLEAR_INDICATOR_STYLES = '[&&]:p-s-0';
export const DROPDOWN_INDICATOR_STYLES = 'px-s-16';
