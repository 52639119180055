import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@utils/lib-utils';

import { LoadingSpinner } from '../spinner';
import Pagination from './Pagination';
import TableBody from './TableBody';
import TableHead from './TableHead';
import { TableHorizontalScrollDivRefContext } from './TableHorizontalScrollDivRefContext';
import { RowHeight } from './types';

type TableProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  onSortingChange?: OnChangeFn<SortingState>;
  sortingState?: SortingState;
  noDataComponent?: ReactNode;
  paginationState?: PaginationState;
  onPaginationChange?: OnChangeFn<any>;
  onPaginationReset?: () => void;
  count?: number;
  rowHeight?: RowHeight;
  isRowColorChangeOnHover?: boolean;
  isLoading?: boolean;
};

const Table = <T,>({
  data,
  columns,
  onSortingChange,
  sortingState,
  noDataComponent,
  count,
  paginationState,
  onPaginationChange,
  onPaginationReset,
  rowHeight,
  isRowColorChangeOnHover,
  isLoading,
}: TableProps<T>) => {
  const tableHorizontalScrollDivRef = useRef(null);
  const { t } = useTranslation();

  const pageCount = count ? Math.ceil(count / 10) : 1;

  const table = useReactTable({
    data,
    columns,
    state: { sorting: sortingState, pagination: paginationState },
    pageCount,
    manualSorting: true,
    manualPagination: true,
    onSortingChange,
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const columnsCount = columns.length;

  return (
    <>
      <TableHorizontalScrollDivRefContext.Provider
        value={tableHorizontalScrollDivRef}
      >
        <div ref={tableHorizontalScrollDivRef} className="overflow-x-auto">
          <table
            className={cn('w-full overflow-hidden', {
              'paginated rounded-b-none': !!paginationState,
            })}
          >
            <TableHead headerGroups={table.getHeaderGroups()} />

            {isLoading ? (
              <tbody>
                <tr className="bg-base-100">
                  <td
                    className="text-center py-s-64"
                    colSpan={columnsCount}
                    aria-label={t('states.loading')}
                  >
                    <LoadingSpinner />
                  </td>
                </tr>
              </tbody>
            ) : (
              <TableBody
                noDataComponent={noDataComponent}
                rows={table.getRowModel().rows}
                columnsCount={columns.length}
                rowHeight={rowHeight}
                isRowColorChangeOnHover={isRowColorChangeOnHover}
              />
            )}
          </table>
        </div>
      </TableHorizontalScrollDivRefContext.Provider>

      {paginationState && (
        <Pagination
          table={table}
          count={count}
          onPaginationReset={onPaginationReset}
        />
      )}
    </>
  );
};

export default Table;
