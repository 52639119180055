import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { OptionType } from '@application/components/select-field/select';
import { debounce } from '@application/utils';
import {
  RecruitmentOpportunitiesFilters,
  RecruitmentOpportunityType,
} from '@domain/graphql.types';

const defaultFilters: RecruitmentOpportunitiesFilters = {
  match: undefined,
  operationTerritoryCode: undefined,
  recruitmentOpportunityType: undefined,
  text: undefined,
};

const typeCodeMap: Record<string, RecruitmentOpportunityType> = {
  '#instant-application': RecruitmentOpportunityType.InstantApplication,
  '#service-request': RecruitmentOpportunityType.ServiceRequest,
};

type UseRecruitmentOpportunityFiltersProps = {
  resetPagination?: () => void;
};

const useRecruitmentOpportunityFilters = ({
  resetPagination,
}: UseRecruitmentOpportunityFiltersProps) => {
  const [filters, setFilters] =
    useState<RecruitmentOpportunitiesFilters>(defaultFilters);
  const location = useLocation();

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      recruitmentOpportunityType: typeCodeMap[location.hash] || undefined,
    }));
  }, [location.hash]);

  useEffect(() => {
    if (resetPagination) {
      resetPagination();
    }
  }, [filters, resetPagination]);

  const updateTextFilter = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      text: event.target.value,
    }));
  });

  const updateSelectFilter =
    (fieldName: keyof RecruitmentOpportunitiesFilters) =>
    (option: OptionType) => {
      const filterValue =
        fieldName === 'operationTerritoryCode' ? option.value : option;

      setFilters((currentFilters) => ({
        ...currentFilters,
        [fieldName]: filterValue,
      }));
    };

  return {
    filters,
    updateSelectFilter,
    updateTextFilter,
  };
};

export default useRecruitmentOpportunityFilters;
