import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';
import * as yup from 'yup';

import {
  ConfirmationModal,
  PageHeader,
  PageLoader,
  Stack,
} from '@application/components';
import { ToggleButtons } from '@application/components/buttons';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { useWizard } from '@application/hooks';
import { useCandidateOpportunitiesMatchingRequestModal } from '@application/views/candidate/opportunity/hooks';
import {
  CandidateOpportunity,
  RequestStatusTypeCode,
} from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

import { DEFAULT_VALUES, REQUEST_STEPS } from './constants';
import {
  useCalculateRequestBudget,
  useGetRequest,
  useSaveAndPublishRequest,
  useUpdateRequest,
} from './hooks';
import {
  normalizeRequestEditData,
  normalizeRequestInputData,
} from './normalizers';
import {
  REQUEST_DESCRIPTION_PUBLISH_SCHEMA,
  REQUEST_HR_NEEDS_SCHEMA,
  REQUEST_PUBLISH_SCHEMA,
  REQUEST_SCHEMA,
  RequestFormFields,
} from './schema';
import { ConditionsPanel, DescriptionPanel } from './steps';

const EditRequestPage = () => {
  const [currentSchema, setCurrentSchema] =
    useState<yup.ObjectSchema<any>>(REQUEST_SCHEMA);
  const [needValidation, setNeedValidation] = useState<boolean>(false);

  const { t, i18n } = useTranslation('requests');

  const {
    updateRequest,
    viewModel: { isLoading: isSaveLoading },
  } = useUpdateRequest();
  const {
    saveAndPublish,
    viewModel: { isLoading: isPublishLoading },
  } = useSaveAndPublishRequest();

  const { setModal } = useContext(ModalContext);
  const { openModal: openCandidateOpportunitiesMatchingRequestModal } =
    useCandidateOpportunitiesMatchingRequestModal();

  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { activeStep, nextStep, setActive } = useWizard({
    steps: REQUEST_STEPS,
  });

  const {
    control,
    register,
    formState,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<RequestFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(currentSchema),
  });

  useCalculateRequestBudget({
    dirtyFields: formState.dirtyFields,
    setValue,
    watch,
  });

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
  } = useGetRequest(id);

  useEffect(() => {
    if (
      data &&
      data?.status === RequestStatusTypeCode.InProgress &&
      new Date(data.receivingOfferDeadline) < new Date()
    ) {
      navigate(generatePath(PrivatePage.REQUEST_DETAILS, { id: data.id }), {
        replace: true,
      });
    }
  }, [data, id, navigate]);

  useEffect(() => {
    if (data) {
      const d = {
        ...normalizeRequestEditData(data),
        desiredStartDate: data.desiredStartDate
          ? formatDate(data.desiredStartDate, i18n.language, 'short')
          : undefined,
        receivingOfferDeadline: data.receivingOfferDeadline
          ? formatDate(data.receivingOfferDeadline, i18n.language, 'short')
          : undefined,
        conditionHiredDateBonusRequirement: data.generalContractualConditions
          .hiredDateBonusRequirement
          ? formatDate(
              data.generalContractualConditions.hiredDateBonusRequirement,
              i18n.language,
              'short'
            )
          : undefined,
        id,
      };
      reset(d as RequestFormFields);
    }
  }, [id, data, reset, i18n.language]);

  const onSubmit: SubmitHandler<RequestFormFields> = useCallback(
    async (values) => {
      const { publishNow, ...otherValues } = values;
      const modifiedValues = { ...otherValues };

      if (publishNow) {
        const response = await saveAndPublish({
          input: normalizeRequestInputData(modifiedValues) as any,
          statusInput: {
            id,
            status: RequestStatusTypeCode.InProgress,
          },
        });

        if (
          response.data?.requestUpdate.request?.matchingCandidateOpportunities
        ) {
          openCandidateOpportunitiesMatchingRequestModal(
            response.data?.requestUpdate.request
              ?.matchingCandidateOpportunities as CandidateOpportunity[]
          );
        }
      } else {
        await updateRequest({
          requestUpdateInput: normalizeRequestInputData(modifiedValues) as any,
        });
      }
    },
    [
      saveAndPublish,
      id,
      openCandidateOpportunitiesMatchingRequestModal,
      updateRequest,
    ]
  );

  const handleOnSave = () => {
    if (data?.status === RequestStatusTypeCode.Draft) {
      setValue('publishNow', false);
      setCurrentSchema(REQUEST_SCHEMA);
    } else {
      setCurrentSchema(REQUEST_PUBLISH_SCHEMA);
      setNeedValidation(true);
    }
  };

  const handleOnPublish = () => {
    setValue('publishNow', true);
    setCurrentSchema(REQUEST_PUBLISH_SCHEMA);
    setNeedValidation(true);
  };

  useEffect(() => {
    if (!needValidation || Object.keys(formState.errors).length === 0) {
      return;
    }

    const foundErrorsFromRequestFirstStep = Object.keys(
      formState.errors
    ).filter(
      (e) =>
        Object.keys(REQUEST_DESCRIPTION_PUBLISH_SCHEMA.fields).includes(e) ||
        Object.keys(REQUEST_HR_NEEDS_SCHEMA.fields).includes(e)
    );

    // We have an error for Full and Part time but only counts has one.
    const foundErrorsFromRequestFirstStepCount = formState.errors
      .jobFullTimeAvailability
      ? foundErrorsFromRequestFirstStep.length - 1
      : foundErrorsFromRequestFirstStep.length;

    if (foundErrorsFromRequestFirstStep.length === 0) {
      return;
    }

    setModal({
      title: t('modal.titles.fillOutEmptyRequiredField', {
        count: foundErrorsFromRequestFirstStepCount,
      }),
      maxWidth: '2xl',
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p>
                <Trans
                  i18nKey="modal.contents.requiredFieldError"
                  t={t}
                  values={{
                    count: foundErrorsFromRequestFirstStepCount,
                    step: t(REQUEST_STEPS[0].title),
                  }}
                />
              </p>
              <p>
                {t('modal.contents.confirmRedirectStep', {
                  count: foundErrorsFromRequestFirstStepCount,
                })}
              </p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            /**
             * FIXME: This is a only temporary. The redirection is made explicitly
             * to the first step (0) of the form in case of error since all required fields are present in this step.
             * It should be handle dynamically by redirecting to the first step containing errors on required fields.
             */
            setActive(0);
          }}
        />
      ),
    });

    setNeedValidation(false);
    // We want to execute it only on publishing AND presence of errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors, needValidation]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className="flex flex-col items-center justify-center w-full">
      <PageHeader
        title={t('title.edit')}
        backTo={generatePath(PrivatePage.REQUEST_DETAILS, { id })}
      >
        <ToggleButtons
          activeStep={activeStep}
          setActive={setActive}
          leftLabel={t('subtitles.description')}
          rightLabel={t('subtitles.conditions')}
        />
      </PageHeader>

      <form
        className="md:px-s-48 w-[inherit]"
        onSubmit={handleSubmit(onSubmit)}
      >
        {activeStep === 0 && (
          <DescriptionPanel
            control={control}
            nextStep={nextStep}
            register={register}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            watch={watch}
            errors={formState.errors}
            trigger={trigger}
            isEditing
            isHRNeedsValidated
          />
        )}

        {activeStep === 1 && (
          <ConditionsPanel
            control={control}
            register={register}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
            errors={formState.errors}
            onPublish={
              data?.status === RequestStatusTypeCode.Draft
                ? handleOnPublish
                : undefined
            }
            onSave={handleOnSave}
            isEditing
            saveLoading={isSaveLoading}
            publishLoading={isPublishLoading}
          />
        )}
      </form>
    </div>
  );
};

export default EditRequestPage;
