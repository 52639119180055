import { useQuery } from 'urql';

import { Query, QueryRequestChatRoomMessagesArgs } from '@domain/graphql.types';
import { RequestChatRoomMessages } from '@infrastructure/graphql/chat';

const useRequestChatRoomMessages = (
  input: QueryRequestChatRoomMessagesArgs
) => {
  const [result, reexecuteQuery] = useQuery<
    Pick<Query, 'requestChatRoomMessages'>,
    QueryRequestChatRoomMessagesArgs
  >({
    query: RequestChatRoomMessages,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useRequestChatRoomMessages;
