import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Stack } from '@application/components/container-layouts';
import { ConfirmationModal } from '@application/components/modal';
import { Stepper } from '@application/components/stepper';
import { ModalContext } from '@application/context';
import { useWizard } from '@application/hooks';
import { useCandidateOpportunitiesMatchingRequestModal } from '@application/views/candidate/opportunity/hooks';
import { CandidateOpportunity } from '@domain/graphql.types';

import { DEFAULT_VALUES, REQUEST_STEPS } from './constants';
import { useCalculateRequestBudget, useCreateRequest } from './hooks';
import { normalizeRequestInputData } from './normalizers';
import {
  REQUEST_DESCRIPTION_PUBLISH_SCHEMA,
  REQUEST_HR_NEEDS_SCHEMA,
  REQUEST_PUBLISH_SCHEMA,
  REQUEST_SCHEMA,
  RequestFormFields,
} from './schema';
import { ConditionsPanel, DescriptionPanel } from './steps';

const CreateRequestPage = () => {
  const [currentSchema, setCurrentSchema] =
    useState<yup.ObjectSchema<any>>(REQUEST_SCHEMA);

  const [publishing, setPublishing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [isHRNeedsValidated, setIsHRNeedsValidated] = useState(false);

  const { t } = useTranslation('requests');

  const {
    createRequest,
    viewModel: { isLoading },
  } = useCreateRequest();

  const { setModal } = useContext(ModalContext);
  const { openModal: openCandidateOpportunitiesMatchingRequestModal } =
    useCandidateOpportunitiesMatchingRequestModal();

  const {
    control,
    register,
    clearErrors,
    formState,
    handleSubmit,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<RequestFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(currentSchema),
  });

  useCalculateRequestBudget({
    dirtyFields: formState.dirtyFields,
    setValue,
    watch,
  });

  const { activeStep, nextStep, prevStep, completedSteps, setActive } =
    useWizard({
      steps: REQUEST_STEPS,
    });

  const onSubmit: SubmitHandler<RequestFormFields> = useCallback(
    async (data) => {
      if (currentSchema !== REQUEST_HR_NEEDS_SCHEMA) {
        const response = await createRequest({
          requestCreateInput: normalizeRequestInputData(data) as any,
        });
        if (
          response.data?.requestCreate.request?.matchingCandidateOpportunities
        ) {
          openCandidateOpportunitiesMatchingRequestModal(
            response.data?.requestCreate.request
              ?.matchingCandidateOpportunities as CandidateOpportunity[]
          );
        }
      } else {
        setIsHRNeedsValidated(true);
      }
    },
    [
      createRequest,
      currentSchema,
      openCandidateOpportunitiesMatchingRequestModal,
    ]
  );

  const handleOnSave = () => {
    setSaving(true);
    setValue('publishNow', false);
    setCurrentSchema(REQUEST_SCHEMA);
  };

  const handleOnPublish = () => {
    setSaving(false);
    setValue('publishNow', true);
    setCurrentSchema(REQUEST_PUBLISH_SCHEMA);
    setPublishing(true);
  };

  const handleOnValidateHrNeeds = () => {
    setCurrentSchema(REQUEST_HR_NEEDS_SCHEMA);
  };

  useEffect(() => {
    if (!publishing || Object.keys(formState.errors).length === 0) {
      return;
    }

    const foundErrorsFromRequestDescriptionStep = Object.keys(
      formState.errors
    ).filter((e) =>
      Object.keys(REQUEST_DESCRIPTION_PUBLISH_SCHEMA.fields).includes(e)
    );

    // We have an error for Full and Part time but only counts has one.
    const foundErrorsFromRequestDescriptionStepCount = formState.errors
      .jobFullTimeAvailability
      ? foundErrorsFromRequestDescriptionStep.length - 1
      : foundErrorsFromRequestDescriptionStep.length;

    if (foundErrorsFromRequestDescriptionStep.length === 0) {
      return;
    }

    setModal({
      title: t('modal.titles.fillOutEmptyRequiredField', {
        count: foundErrorsFromRequestDescriptionStepCount,
      }),
      maxWidth: '2xl',
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p>
                <Trans
                  i18nKey="modal.contents.requiredFieldError"
                  t={t}
                  values={{
                    count: foundErrorsFromRequestDescriptionStepCount,
                    step: t(REQUEST_STEPS[0].title),
                  }}
                />
              </p>
              <p>
                {t('modal.contents.confirmRedirectStep', {
                  count: foundErrorsFromRequestDescriptionStepCount,
                })}
              </p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            /**
             * FIXME: This is a only temporary. The redirection is made explicitly
             * to the first step (0) of the form in case of error since all required fields are present in this step.
             * It should be handle dynamically by redirecting to the first step containing errors on required fields.
             */
            setActive(0);
          }}
        />
      ),
    });

    setPublishing(false);
    // We want to execute it only on publishing AND presence of errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors, publishing]);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="max-w-[66rem]">
        <Stepper
          steps={REQUEST_STEPS}
          activeStep={activeStep}
          completedSteps={completedSteps}
          setActive={setActive}
          t={t}
        />

        <form className="md:px-s-48" onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && (
            <DescriptionPanel
              onValidateHrNeeds={handleOnValidateHrNeeds}
              nextStep={nextStep}
              control={control}
              register={register}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              watch={watch}
              errors={formState.errors}
              trigger={trigger}
              isHRNeedsValidated={isHRNeedsValidated}
            />
          )}

          {activeStep === 1 && (
            <ConditionsPanel
              control={control}
              prevStep={prevStep}
              register={register}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
              errors={formState.errors}
              onSave={handleOnSave}
              onPublish={handleOnPublish}
              saveLoading={isLoading && saving}
              publishLoading={isLoading && !saving}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateRequestPage;
