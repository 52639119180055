import { useAccounts } from '@domain/account';
import { AccountsFilters, AccountsSortDirective } from '@domain/graphql.types';
import { Pagination } from '@domain/pagination/pagination';

import { toViewModel } from '../view-models/get-accounts.vm';

const useGetAccounts = (
  sortBy: AccountsSortDirective[],
  filterBy: AccountsFilters,
  pagination?: Pagination
) => {
  const { data, fetching, error, reexecuteQuery } = useAccounts({
    sortBy,
    filterBy,
    ...pagination,
  });

  const viewModel = toViewModel({
    accounts: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchAccounts: reexecuteQuery,
  };
};

export default useGetAccounts;
