import { createContext } from 'react';

import { ModalMaxWidth } from '@application/components/modal/Modal';

type ModalContextProps = {
  setModal: (
    modal: {
      title: string;
      content: React.ReactNode | null;
      hideTitle?: boolean;
      centered?: boolean;
      maxWidth?: ModalMaxWidth;
      modifier?: 'error' | 'warning' | 'info' | 'success';
    } | null
  ) => void;
};

const ModalContext = createContext<ModalContextProps>({
  setModal: () => {},
});

export default ModalContext;
