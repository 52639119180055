import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';

import {
  Badge,
  Button,
  ButtonLink,
  Cluster,
  ConfirmationModal,
  PageHeader,
  PageLoader,
  RankBadge,
  SelectField,
  Stack,
} from '@application/components';
import { PictureBadges } from '@application/components/picture-badges';
import { OptionType } from '@application/components/select-field/select';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { usePremiumPublishCandidateOfferValidation } from '@application/views/billing';
import { OfferStatusTypeCode } from '@domain/graphql.types';
import { calculateTimeSince } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';
import { getOfferDropDownStatusClassNames } from '@utils/styles-utils';

import {
  useDeleteCandidateOffer,
  useGetCandidateOffer,
  useUpdateCandidateOfferStatus,
} from './hooks';
import { AgencySummary, CandidateSummary } from './offer-summary';

const ViewCandidateOfferPage = () => {
  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { setModal } = useContext(ModalContext);

  const { deleteCandidateOffer } = useDeleteCandidateOffer();
  const { updateCandidateOfferStatus } = useUpdateCandidateOfferStatus();

  const { canPublishCandidateOffer } =
    usePremiumPublishCandidateOfferValidation();

  const {
    viewModel: { data, isLoading },
    refetchCandidateOffer,
  } = useGetCandidateOffer(id);

  const canEditOffer = useMemo(
    () =>
      data &&
      (data.status === OfferStatusTypeCode.Draft ||
        data.status === OfferStatusTypeCode.Published) &&
      !data.negotiationRoomId,
    [data]
  );

  const timeSinceCreated = useMemo(
    () => calculateTimeSince(data?.createdAt),
    [data?.createdAt]
  );

  const statusOptions = useMemo(() => {
    const options: OptionType[] = data?.status
      ? [
          {
            value: data?.status,
            label: t(`enum.offerStatusTypeCode.${data?.status.toLowerCase()}`),
          },
        ]
      : [];
    data?.availableTransitions.map((a) =>
      options.push({
        value: a,
        label: t(`enum.offerStatusTypeCode.${a.toLowerCase()}`),
      })
    );
    return options;
  }, [data, t]);

  const handleStatusUpdate = useCallback(
    async (status: OfferStatusTypeCode) => {
      await updateCandidateOfferStatus({
        input: { id, status },
      });
      refetchCandidateOffer();
    },
    [updateCandidateOfferStatus, id, refetchCandidateOffer]
  );

  const handleDelete = useCallback(async () => {
    setModal(null);
    await deleteCandidateOffer({ input: { candidateOfferId: id } });

    navigate(RootPrivatePage.CANDIDATE_OFFERS);
  }, [deleteCandidateOffer, setModal, id, navigate]);

  const confirmDelete = useCallback(() => {
    setModal({
      title: t('modal.titles.deleteOffer'),
      content: (
        <ConfirmationModal
          content={t('modal.contents.confirmDeleteOffer')}
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
        />
      ),
    });
  }, [setModal, t, handleDelete]);

  const statusClassName = getOfferDropDownStatusClassNames(
    data?.status || OfferStatusTypeCode.Draft
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={t('title.detailsOffer')}
        backTo={RootPrivatePage.CANDIDATE_OFFERS}
      >
        {data?.negotiationRoomId && (
          <ButtonLink
            primary
            className="mr-s-16"
            icon={<i className="ri-shake-hands-line" />}
            to={generatePath(PrivatePage.CANDIDATE_NEGOTIATION_ROOM_DETAILS, {
              id: data?.negotiationRoomId || null,
            })}
            from={location.pathname}
          >
            {t('button.negotiationsZone')}
          </ButtonLink>
        )}
      </PageHeader>

      <Stack space={24}>
        <Cluster className="justify-between mb-s-24">
          <div className="flex flex-row flex-wrap gap-s-24 items-center">
            <RankBadge
              rank={Number(data?.rank)}
              className="min-w-[2rem] text-center"
            />
            <h2 className="text-24 font-semibold">
              {data?.jobSpecialty
                ? getLocalizedDescription(
                    data?.jobSpecialty.descriptions,
                    i18n.language
                  )
                : '-'}
            </h2>

            {data?.status === OfferStatusTypeCode.Draft &&
            canPublishCandidateOffer ? (
              <SelectField
                label="status"
                hideLabel
                name="status"
                controlClassName={statusClassName}
                options={statusOptions}
                value={statusOptions.find(
                  (o: OptionType) => o.value === data?.status
                )}
                onChange={(option: any) =>
                  handleStatusUpdate(option.value as OfferStatusTypeCode)
                }
              />
            ) : (
              <Badge
                value={t(
                  `enum.offerStatusTypeCode.${data?.status.toLowerCase()}`
                )}
                placeholder="-"
                className={cn(
                  getOfferDropDownStatusClassNames(
                    data?.status || OfferStatusTypeCode.Draft
                  ),
                  'rounded-md h-s-48 w-s-112'
                )}
                valueClassName="text-16 font-semibold"
              />
            )}

            <PictureBadges
              pictures={[
                {
                  name: data?.user.name ?? undefined,
                  url: data?.user.avatarUrl,
                },
              ]}
              rounded="rounded-md"
              tooltipPosition="right"
            />

            <span>
              {timeSinceCreated.value > 1
                ? tGlobal('date.since', {
                    date: tGlobal(`date.options.${timeSinceCreated.unit}`, {
                      count: timeSinceCreated.value,
                    }),
                  })
                : tGlobal('date.today')}
            </span>
          </div>

          <div>
            {data?.candidateOpportunity?.id && (
              <ButtonLink
                to={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_DETAILS, {
                  id: data?.candidateOpportunity?.id,
                })}
                className={canEditOffer ? 'mr-s-16' : ''}
                from={location.pathname}
              >
                {t('button.opportunityDetails')}
              </ButtonLink>
            )}
            {canEditOffer && (
              <>
                <Button
                  icon={<i className="ri-delete-bin-line" />}
                  className="mr-s-16"
                  onClick={confirmDelete}
                >
                  {tGlobal('button.delete')}
                </Button>
                <ButtonLink
                  to={generatePath(PrivatePage.CANDIDATE_OFFER_EDIT, { id })}
                  icon={<i className="ri-pencil-line" />}
                >
                  {tGlobal('button.edit')}
                </ButtonLink>
              </>
            )}
          </div>
        </Cluster>
      </Stack>

      <CandidateSummary offer={data} request={data?.candidateOpportunity} />

      <AgencySummary offer={data} request={data?.candidateOpportunity} />
    </>
  );
};

export default ViewCandidateOfferPage;
