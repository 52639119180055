import { gql } from '@urql/core';

const MutationCandidateOfferCreate = gql`
  mutation CandidateOfferCreate($input: CandidateOfferCreateInput!) {
    candidateOfferCreate(input: $input) {
      candidateOffer {
        id
        candidateRequestId
        status
      }
    }
  }
`;

export default MutationCandidateOfferCreate;
