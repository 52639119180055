import { TFunction } from 'i18next';
import { Link, useLocation } from 'react-router-dom';

type StatusTabsProps = {
  t: TFunction<'translation', undefined>;
  statuses: string[];
  mainPath?: string;
  type: string;
};

const StatusTabs = ({ statuses, mainPath, t, type }: StatusTabsProps) => {
  const location = useLocation();

  const tabClassNames =
    'tab text-16 px-s-0 h-[3.063rem] pb-s-1 text-neutral-secondary whitespace-nowrap min-w-full w-full hover:text-primary';
  const activeClassNames = 'border-b border-primary pb-s-0 text-primary';

  return (
    <ul
      role="tablist"
      className="tabs border-b overflow-x-scroll flex-nowrap mr-s-32"
      style={{
        scrollbarWidth: 'none',
      }}
    >
      {mainPath && (
        <li className="mr-s-32">
          <Link
            tabIndex={location.hash.length ? 0 : -1}
            aria-controls={`${type}-list`}
            aria-selected={!location.hash.length}
            role="tab"
            id="tab-all"
            to={mainPath}
            className={`${tabClassNames} ${!location.hash && activeClassNames}`}
          >
            {t('tabs.all')}
          </Link>
        </li>
      )}

      {statuses.map((status) => (
        <li key={status} className="[&:not(:last-child)]:mr-s-32">
          <Link
            tabIndex={location.hash === `#${status.toLowerCase()}` ? -1 : 0}
            aria-controls={`${type}-list`}
            aria-selected={location.hash === `#${status.toLowerCase()}`}
            role="tab"
            id={`tab-${status.toLowerCase()}`}
            to={`#${status.toLowerCase().replace('_', '-')}`}
            className={`${tabClassNames} ${
              location.hash === `#${status.toLowerCase().replace('_', '-')}` &&
              activeClassNames
            }`}
          >
            {t(`tabs.${status.toLowerCase().replace('-', '_')}`)}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default StatusTabs;
