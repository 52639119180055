import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Countdown from '@application/components/countdown/CountDown';

import BillingPortalButton from './BillingPortalButton';

const TrialButton = ({
  trialEndDate,
  extendedDisplayInDays,
}: {
  trialEndDate: Date;
  extendedDisplayInDays: number;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topnav.trial',
  });

  const button = useMemo(() => {
    const todayDate = new Date();

    if (trialEndDate >= todayDate) {
      return (
        <BillingPortalButton className="w-fit p-0">
          <div className="flex flex-col">
            <span className="font-bold">{t('trialEnding')}</span>
            <Countdown
              targetDate={DateTime.fromISO(trialEndDate.toISOString())}
            />
          </div>
        </BillingPortalButton>
      );
    }

    const extendedDisplayEndDate = new Date(trialEndDate);

    extendedDisplayEndDate.setDate(
      extendedDisplayEndDate.getDate() + extendedDisplayInDays
    );

    if (extendedDisplayEndDate >= todayDate) {
      return (
        <BillingPortalButton className="w-fit p-0">
          <div className="flex flex-col">
            <span className="font-bold">{t('trialEnded')}</span>
          </div>
        </BillingPortalButton>
      );
    }

    return null;
  }, [extendedDisplayInDays, t, trialEndDate]);

  return button;
};

export default TrialButton;
