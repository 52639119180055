import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions, PagePanel } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { useFreemiumPublishRequestValidation } from '@application/views/billing';
import { RequestTypeCode } from '@domain/graphql.types';

import { RequestFormFields } from '../../schema';
import HeadHunterFields from './HeadHunterFields';
import InternationalRecruitmentFields from './InternationalRecruitmentFields';
import OutsourcingFields from './OutsourcingFields';
import TemporaryPlacementFields from './TemporaryPlacementFields';
import MainFields from './main-fields/MainFields';

type ConditionsPanelProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  prevStep?: () => void;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  onSave?: () => void;
  onPublish?: () => void;
  isEditing?: boolean;
  saveLoading: boolean;
  publishLoading: boolean;
};

const ConditionsPanel = ({
  control,
  errors,
  register,
  setValue,
  prevStep,
  trigger,
  watch,
  onSave,
  onPublish,
  isEditing,
  saveLoading,
  publishLoading,
}: ConditionsPanelProps) => {
  const { t } = useTranslation('requests');

  const types = watch('types');
  const openToInternationalRecruitment = watch(
    'openToInternationalRecruitment'
  );

  const { canPublishRequest } = useFreemiumPublishRequestValidation();

  return (
    <PagePanel
      title={t('steps.conditions')}
      text={t('steps.conditionsText')}
      isEditing={isEditing}
    >
      <MainFields
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        trigger={trigger}
        watch={watch}
      />

      {types?.includes(RequestTypeCode.Headhunter) && (
        <HeadHunterFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      )}

      {openToInternationalRecruitment && (
        <InternationalRecruitmentFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      )}

      {types?.includes(RequestTypeCode.Outsourcing) && (
        <OutsourcingFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      )}

      {types?.includes(RequestTypeCode.TemporaryHelp) && (
        <TemporaryPlacementFields
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      )}

      <FormActions
        isLastStep
        prevStep={prevStep}
        onSave={onSave}
        onPublish={onPublish}
        cancelPath={RootPrivatePage.REQUESTS}
        saveLoading={saveLoading}
        publishLoading={publishLoading}
        publishDisabled={!canPublishRequest}
        publishToolTip={
          !canPublishRequest ? t('labels.freemiumLimitReached') : undefined
        }
      />
    </PagePanel>
  );
};

export default ConditionsPanel;
