import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation } from 'react-router-dom';

import { Box } from '@application/components';
import { BadgeList } from '@application/components/badge';
import { PictureBadges } from '@application/components/picture-badges';
import { RequestTypeBadges } from '@application/components/request-type-badges';
import { PrivatePage } from '@application/enums/pagesUrl';
import { mapDescriptions } from '@application/utils';
import { RecruitmentNegotiation } from '@domain/graphql.types';
import { calculateTimeSince } from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';

import ListIconButtonLink from './ListIconButtonLink';

type ListCardProps = {
  data: RecruitmentNegotiation;
};

const ListCard = ({ data }: ListCardProps) => {
  const { i18n, t } = useTranslation('recruitment', {
    keyPrefix: 'negotiation.list',
  });
  const location = useLocation();
  const { t: tGlobal } = useTranslation();

  const timeSinceLastActivity = useMemo(
    () => calculateTimeSince(data?.latestActivity, 'minute'),
    [data?.latestActivity]
  );

  const operationTerritories = useMemo(
    () =>
      mapDescriptions(
        data?.request?.operationTerritories?.map(
          (territory) => territory.descriptions
        ) || [],
        i18n.language
      ),
    [data?.request?.operationTerritories, i18n.language]
  );

  const pathToNegotiation = useMemo(
    () =>
      generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
        id: data.id,
      }),
    [data.id]
  );

  const pathToRequestChatRoom = useMemo(
    () =>
      generatePath(PrivatePage.REQUEST_CHAT, {
        id: data.requestId,
      }),
    [data.requestId]
  );

  return (
    <Box className="min-w-[66rem] w-full h-s-96 shadow-xs">
      <div className="grid grid-cols-[58%_12%_20%_10%] items-center justify-center">
        <div className="flex flex-col gap-s-8">
          <div className="flex gap-s-16 items-center">
            <Link
              to={pathToNegotiation}
              className="hover:underline focus:underline"
            >
              <span className="text-18 font-semibold">
                {getLocalizedDescription(
                  data?.request?.jobSpecialty?.descriptions,
                  i18n.language
                ) || '-'}
              </span>
            </Link>

            <span className="text-14 border border-stroke-default py-s-4 px-s-8 rounded-sm">
              {t('rooms', { count: data?.roomsCount })}
            </span>
          </div>

          <div className="flex gap-s-8 items-center text-neutral-secondary">
            <span className="text-14">
              {data?.request?.operationUnit?.name || '-'}
            </span>

            <i className="ri-circle-fill text-6" />

            <BadgeList values={operationTerritories} />

            <i className="ri-circle-fill text-6" />

            <span className="text-14">
              {tGlobal('date.latestActivity', {
                date: tGlobal(`date.options.${timeSinceLastActivity.unit}`, {
                  count: timeSinceLastActivity.value,
                }),
              })}
            </span>
          </div>
        </div>

        {data?.request?.types?.length ? (
          <RequestTypeBadges types={[...data.request.types]} />
        ) : (
          <span>-</span>
        )}

        {data?.rooms?.length ? (
          <PictureBadges
            pictures={data.rooms.map(({ offer: { account } }) => ({
              name: account.name,
              url: account.logoUrl,
            }))}
          />
        ) : (
          <span>-</span>
        )}

        <div className="flex justify-end gap-s-16">
          <ListIconButtonLink
            count={data.notificationCount}
            iconName="ri-alarm-warning-line"
            to={pathToNegotiation}
          />

          <ListIconButtonLink
            // count={10}
            iconName="ri-question-answer-line"
            to={pathToRequestChatRoom}
            from={location.pathname}
          />
        </div>
      </div>
    </Box>
  );
};

export default ListCard;
