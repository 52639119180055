import { gql } from '@urql/core';

const MutationOfferStatusUpdate = gql`
  mutation OfferStatusUpdate($offerStatusUpdateInput: OfferStatusUpdateInput!) {
    offerStatusUpdate(offerStatusUpdateInput: $offerStatusUpdateInput) {
      offer {
        id
        status
      }
    }
  }
`;

export default MutationOfferStatusUpdate;
