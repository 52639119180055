import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@application/components';
import { BillingPortalButton } from '@application/views/billing';
import { AccountTypeCode } from '@domain/graphql.types';

export type NoAvailableSeatsModalProps = {
  onClose: (shouldRefresh: boolean) => void;
  accountType: AccountTypeCode;
  modifier: 'invite' | 'activate';
};

const NoAvailableSeatsModal = ({
  onClose,
  accountType,
  modifier,
}: NoAvailableSeatsModalProps) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('organization', {
    keyPrefix: 'users.noAvailableSeatsModal',
  });

  const content = useMemo(() => {
    if (accountType === AccountTypeCode.Enterprise) {
      return modifier === 'invite'
        ? t('inviteEnterpriseContent')
        : t('activateEnterpriseContent');
    }
    return modifier === 'invite'
      ? t('inviteAgencyContent')
      : t('activateAgencyContent');
  }, [accountType, modifier, t]);

  return (
    <>
      <Modal.CloseBtn onClick={() => onClose(shouldRefresh)} />
      {content}

      <Modal.Action>
        <Button onClick={() => onClose(shouldRefresh)}>
          {tGlobal('button.cancel')}
        </Button>
        {accountType === AccountTypeCode.Enterprise ? (
          <BillingPortalButton
            className="content-center"
            icon={<i className="ri-bank-card-line text-24" />}
            buttonType="primary"
            onClick={() => setShouldRefresh(true)}
          />
        ) : (
          <Button primary onClick={() => onClose(shouldRefresh)}>
            {tGlobal('button.confirm')}
          </Button>
        )}
      </Modal.Action>
    </>
  );
};

export default NoAvailableSeatsModal;
