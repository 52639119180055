import { gql } from '@urql/core';

const MutationOfferCreate = gql`
  mutation OfferCreate($offerCreateInput: OfferCreateInput!) {
    offerCreate(offerCreateInput: $offerCreateInput) {
      offer {
        id
        status
      }
    }
  }
`;

export default MutationOfferCreate;
