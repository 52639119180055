import { useCallback, useContext } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { UseQueryExecute } from 'urql';

import { Button, Stack } from '@application/components';
import { ConfirmationModal } from '@application/components/modal';
import { LoadingSpinner } from '@application/components/spinner';
import { ModalContext } from '@application/context';
import { useDeleteOfferCandidate } from '@application/views/recruitment/offer/hooks';
import { OfferFormFields } from '@application/views/recruitment/offer/types';

import useSaveCandidate from '../useSaveCandidate';
import AttachedFilesFields from './AttachedFilesFields';
import CandidateExpectationFields from './CandidateExpectationsFields';
import JobInformationFields from './JobInformationFields';
import MoreInformationFields from './MoreInformationFields';
import PersonalInformationFields from './PersonalInformationFields';
import SoftSkillsFields from './SoftSkillsFields';

type CandidateFieldsProps = {
  index: number;
  displayDeleteButton: boolean;
  isEditing?: boolean;
  onDelete: () => void;
  refetchOffer?: UseQueryExecute;
};

const CandidateFields = ({
  index,
  onDelete,
  refetchOffer,
  isEditing,
  displayDeleteButton,
}: CandidateFieldsProps) => {
  const { t } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const { id = '' } = useParams();

  const { trigger, watch }: UseFormReturn<OfferFormFields> = useFormContext();

  const offerCandidates = watch('offerCandidates') || [];
  const { id: candidateId }: any = offerCandidates[index];

  const { deleteOfferCandidate } = useDeleteOfferCandidate();
  const { getValues } = useFormContext();
  const { setModal } = useContext(ModalContext);

  const { saveCandidate, isLoading } = useSaveCandidate();

  const areFieldsEmpty = useCallback(() => {
    const fields = getValues('offerCandidates')[index] || [];
    return !Object.values(fields).some((val) => val);
  }, [getValues, index]);

  const handleDeleteCandidate = useCallback(() => {
    onDelete();
    setModal(null);
  }, [onDelete, setModal]);

  const handleDeleteCandidateEditing = useCallback(async () => {
    if (!candidateId) {
      onDelete();
      setModal(null);
      return;
    }

    const result = await deleteOfferCandidate({ input: { candidateId } });

    if (result.data?.offerCandidateDelete.ok) {
      onDelete();
    }

    setModal(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const confirmDeleteCandidate = useCallback(() => {
    if (areFieldsEmpty() && !candidateId) {
      onDelete();
      return;
    }

    setModal({
      title: t('modal.deleteCandidateTitle'),
      content: (
        <ConfirmationModal
          content={t('modal.deleteCandidateContent')}
          onCancel={() => setModal(null)}
          onConfirm={
            isEditing ? handleDeleteCandidateEditing : handleDeleteCandidate
          }
        />
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleDeleteCandidate,
    handleDeleteCandidateEditing,
    index,
    isEditing,
    areFieldsEmpty,
    onDelete,
    setModal,
    t,
  ]);

  const handleUpdateCandidate = useCallback(async () => {
    const isValid = await trigger(`offerCandidates`);

    if (!isValid) {
      return;
    }

    await saveCandidate(offerCandidates[index], id);

    if (refetchOffer) refetchOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, saveCandidate, index, id, refetchOffer]);

  return (
    <Stack>
      {isLoading && <LoadingSpinner size="lg" overPage />}
      <PersonalInformationFields index={index} />
      <JobInformationFields index={index} />
      <AttachedFilesFields index={index} />
      <MoreInformationFields index={index} />

      <hr className="mb-s-20" />

      <CandidateExpectationFields index={index} />

      <SoftSkillsFields index={index} />

      <div className="flex justify-end gap-s-16">
        {(displayDeleteButton || isEditing) && (
          <Button
            icon={<i className="ri-delete-bin-line" />}
            onClick={confirmDeleteCandidate}
          >
            {t('buttons.deleteCandidate')}
          </Button>
        )}

        {isEditing && (
          <Button
            disabled={isLoading}
            icon={<i className="ri-save-line" />}
            onClick={handleUpdateCandidate}
            primary
          >
            {tGlobal('button.save')}
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default CandidateFields;
