import { useRequestChatRooms } from '@domain/chat';
import { RequestChatRoomsInput } from '@domain/graphql.types';

import { toViewModel } from '../../view-models/chat-room/get-request-chat-room.vm';

const useGetRequestChatRooms = (input: RequestChatRoomsInput) => {
  const { data, fetching, error, reexecuteQuery } = useRequestChatRooms({
    input,
  });

  const viewModel = toViewModel({
    requestChatRooms: {
      data,
      fetching,
      error,
    },
  });

  return {
    viewModel,
    refetchRequestChatRooms: reexecuteQuery,
  };
};

export default useGetRequestChatRooms;
