import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountContext, AlertContext } from '@application/context';
import { useCandidateOfferUpdateStatus } from '@domain/candidate/offer';
import {
  CandidateRequestErrors,
  OfferStatusTypeCode,
} from '@domain/graphql.types';

import { toViewModel } from '../view-models/update-candidate-offer-status.vm';

const useUpdateCandidateOfferStatus = () => {
  const { data, fetching, error, updateCandidateOfferStatus } =
    useCandidateOfferUpdateStatus();

  const { t } = useTranslation('candidates', {
    keyPrefix: 'messages',
  });

  const { setAlertContent } = useContext(AlertContext);
  const { refreshAccount } = useContext(AccountContext);

  const viewModel = toViewModel({
    candidateOffer: { data, fetching, error },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    const missingRequiredFields = error.graphQLErrors.find(
      (e) => e.message === CandidateRequestErrors.MissingRequiredFields
    );

    setAlertContent(
      missingRequiredFields
        ? {
            modifier: 'alert-error',
            text: t('hasMissingRequiredFieldsError'),
          }
        : {
            modifier: 'alert-error',
            action: t('updateOfferStatusError'),
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.candidateOfferUpdateStatus.candidateOffer) {
      setAlertContent({
        action: t('updateOfferStatusSuccess'),
        modifier: 'alert-success',
      });

      if (
        data?.candidateOfferUpdateStatus?.candidateOffer.status ===
        OfferStatusTypeCode.Published
      ) {
        refreshAccount();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { updateCandidateOfferStatus, viewModel };
};

export default useUpdateCandidateOfferStatus;
